@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&display=swap')
@import url('../plugins/bain/style.css')
@import url('../plugins/bootstrap/css/bootstrap.min.css')
@import url('../plugins/fontawesome/css/all.min.css')

$mainFontFamily: 'Nunito Sans', sans-serif

$blackColor: #000000
$whiteColor: #ffffff

// Frontend-1 Start
$primaryColor: #27C1B2
$secondColor: #401FE5
$color3: #7287FD
$color4: #E04149
$color5: #FFE0B5
$color6: #D0D7FF
$color7: #041614
$color8: #C65051
$color9: #454545
$color10: #F0EDFF
$color11: #272727
$color12: #3F22E4
$color13: #F6FDFD
$color14: #331AB1
$color15: #3830DC
$color16: #E7F2F2
$color17: #F4F5F7
$color18: #D3D3D3
$color19: #AAAAAA
$color20: #696969
$color21: #D1D1D1
$color22: #BEEBE7
$color23: #707070
$color24: #D0D7FF
$color25: #5DBEB2
$color26: #DEDEDE
$color27: #F0C76A
$color28: #D6DADE
$color29: #1A1A1A
$color30: rgba(105, 105, 105, 0.5)
$color31: #242424
$color32: #DEEAEA
$color33: #FFCCCF
$color34: #EFA036
$color35: #E8E8E8
$color36: #838383
$color37: #FFBA00
$color38: #35D634
$color39: #8BB1AB
$color40: #FF081B
$color41: #F0F0F0
$color42: #FAFAFA
$color43: #20C553
$color44: #EAEAEA
$color45: #F5BC41
$color46: #99E5DD
$color47: #E6E6E6
$color48: #EDEDED
$color49: #FFC850
$color50: #33D73A
$color51: #D9474F
$color52: #C5CDCD
$color53: #1DC321

$borderColor: #EDEDED

$radius1: 5px
$radius2: 10px
$radius3: 18px
$radius4: 22px
$radius5: 12px
$radius6: 40px
$radius7: 30px
$radius8: 20px
$radius9: 25px
$radius10: 50px
$radius11: 14px
$radius12: 8px
$radius13: 4px
$radius14: 1px

// Frontend-2 Start -- Value start 100
$color100: #0C0C0C
$color101: #838383
$color102: #35D634
$color103: #FF081B
$color104: #F7F8FD
$color105: #1FA5A7


$radius100: 45px
$radius101: 23px
$radius102: 3px
// Frontend-3 Start -- Value start 200
$radius200: 35px
$radius201: 60px
$radius202: 50%
$radius203: 16px

$color200: #EAECF4
$color201: #007AFF
$color202: #FDFDFD
$color203: #83908E
$color204: #DBDBDB
$color205: #91EFE6
$color206: #64646f33
$color207: #B7B7B7
$color208: rgba(114,135,253,0.15)
$color209: rgb(114 135 253 / 11%)
$color210: #F7F8FF
$color211: #495057
$color212: #ced4da
$color213: #3B2BDC
$color214: #201073
$color215: #838383
$color216: #FFBA00
$color217: #8BB1AB
$color218: #35D634
$color219: #F0F0F0
$color220: rgba(149, 157, 165, 0.2)
$color221: #007EFF
$color222: #ededed72
$color223: #FFEFEF
$color224: #FFB100
$color225: #CFEFBE
$color226:#52BE19
$color227: #13B212
$color228: #1b998d
$color229: #eeeeee
$color229:rgba(99, 99, 99, 0.2)

$fontWeightExtraLight: 200
$fontWeightLight: 300
$fontWeightRegular: 400
$fontWeightMedium: 500
$fontWeightSemiBold: 600
$fontWeightBold: 700
$fontWeightExtraBold: 800
$fontWeightBlack: 900
$fontWeightWeight: 1000

$fontSizeSmall: 14px
$fontSizeMedium: 18px
$fontSizeLarge: 22px
