@use 'variables'
@use 'short-code'

*
    font-family: variables.$mainFontFamily
html
    scroll-behavior: smooth
a,button
    @include short-code.text-DO
    cursor: pointer
a
    &:hover, &:focus
        @include short-code.text-DO
button
    &:hover, &:focus
        @include short-code.text-DO
#goTpButton
    display: inline-block
    background-color: variables.$secondColor
    width: 50px
    height: 50px
    text-align: center
    border-radius: variables.$radius13
    position: fixed
    bottom: 65px
    right: -3px
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s
    opacity: 0
    visibility: hidden
    z-index: 8
    &::after
        @include short-code.fontAwesomeCreate('\f077')
        font-size: 2em
        line-height: 50px
        color: variables.$whiteColor
    &.show-active
        opacity: 1
        visibility: visible
.container-2
    width: 100%
    padding:
        right: 15px
        left: 15px
    margin:
        right: auto
        left: auto
.cs-sticky-header-1
    position: sticky
    top: 0
    z-index: 10
    background-color: variables.$whiteColor
    @include short-code.backgroundShadow2()
.cs-header-row-1
    @include short-code.rowCreate(-15px)
    align-items: center
    .cshr1-col-1
        @include short-code.colCreate(15px, 250px)
    .cshr1-col-2
        @include short-code.colCreate(15px, calc(100% - 650px))
    .cshr1-col-3
        @include short-code.colCreate(15px, 400px)
.cs-main-logo-1
    img
        max-height: 62px
.cs-main-menu-1
    @include short-code.menuFlex1()
    .main-li-1
        margin:
            right: 15px
        &:last-child
            margin:
                right: 0px
        .main-a-1
            display: block
            padding: 32px 10px
            border-radius: variables.$radius3
            background-color: variables.$whiteColor
            transition: all .3s ease
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color7
            &:hover
                color: variables.$secondColor
            &.active
                color: variables.$primaryColor
                &::before
                    content: ''
                    background-color: variables.$primaryColor
                    width: 10px
                    height: 10px
                    display: inline-block
                    border-radius: 50%
                    margin-right: 5px
                    margin-left: -15px
            &.main-dd-l-1
                &::after
                    @include short-code.bainIconCreate('\e95e')
                    font-size: 6px
                    position: relative
                    top: -3px
                    padding:
                        left: 5px
        &.dd-active-1
            .main-a-1
                background-color: variables.$secondColor
                color: variables.$whiteColor
                &.main-dd-l-1
                    &::after
                        content: '\e95c'
                &.active
                    &::before
                        display: none
            .main-dd-d-1
                opacity: 1
                visibility: visible
        .main-dd-d-1
            background-color: variables.$whiteColor
            border-radius: variables.$radius3
            border: 0.5px solid variables.$secondColor
            @include short-code.backgroundShadow3()
            position: absolute
            margin-top: -30px
            z-index: 4
            width: 260px
            transition: all .2s ease
            opacity: 0
            visibility: hidden
            .mdd-m-1
                @include short-code.menuClear1()
                padding: 5px 0px
                .mdd-m-i-1
                    border-bottom: 1px solid variables.$borderColor
                    &:last-child
                        border: 0
                .mdd-m-l-1
                    display: block
                    padding: 10px 25px
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightBold
                    color: variables.$color9
                    &:hover
                        color: variables.$secondColor
                    &.active
                        color: variables.$primaryColor
                        &::before
                            content: ''
                            background-color: variables.$primaryColor
                            width: 10px
                            height: 10px
                            display: inline-block
                            border-radius: 50%
                            margin-right: 5px
                            margin-left: -15px
.cs-header-row-2
    @include short-code.rowCreate(-10px)
    align-items: center
    .cshr2-col-1
        @include short-code.colCreate(10px, 65px)
    .cshr2-col-2
        @include short-code.colCreate(10px, calc(100% - 195px))
.cs-header-btn-1
    display: flex
    position: relative
    width: 100%
    height: 45px
    align-items: center
    justify-content: center
    border-radius: 50%
    font-size: 24px
    line-height: 1
    color: variables.$color11
    background-color: variables.$color10
    border: 1px solid transparent
    transition: all .3s ease
    &:hover
        border-color: variables.$color12
        background-color: variables.$whiteColor
        @include short-code.backgroundShadow4()
        color: variables.$color12
    &.notification
        @include short-code.backgroundShadow4()
        &::before
            content: ''
            position: absolute
            top: 8px
            right: 12px
            background-color: variables.$secondColor
            width: 10px
            height: 10px
            border-radius: 50%
.cs-header-btn-2
    display: flex
    position: relative
    width: 100%
    height: 45px
    align-items: center
    justify-content: center
    border-radius: 50%
    font-size: 24px
    line-height: 1
    color: variables.$secondColor
    background-color: variables.$color10
    border: 1px solid transparent
    transition: all .3s ease
    &:hover
        border-color: variables.$color12
        background-color: variables.$whiteColor
        @include short-code.backgroundShadow4()
        color: variables.$color12
    &.active
        @include short-code.backgroundShadow4()
.cs-header-btn-3
    display: flex
    position: relative
    width: 100%
    min-height: 45px
    padding: 5px 10px
    align-items: center
    justify-content: center
    border-radius: variables.$radius4
    font-size: 14px
    line-height: 22px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color13
    background-color: variables.$secondColor
    border: 1px solid transparent
    transition: all .3s ease
    white-space: nowrap
    i
        font-size: 20px
        padding:
            left: 5px
        position: relative
        top: 2px
    &:hover
        border-color: variables.$color12
        background-color: variables.$whiteColor
        @include short-code.backgroundShadow4()
        color: variables.$secondColor
.cs-globe-dd-1
    &.dd-active-1
        .csgdd1-d-1
            opacity: 1
            visibility: visible
    .csgdd1-d-1
        background-color: variables.$whiteColor
        border-radius: variables.$radius3
        border: 0.5px solid variables.$secondColor
        @include short-code.backgroundShadow3()
        position: absolute
        margin-top: 0px
        left: 10px
        z-index: 99
        width: calc(100% - 20px)
        transition: all .2s ease
        opacity: 0
        visibility: hidden
        .csgdd1-m-1
            @include short-code.menuClear1()
            padding: 5px 0px
            .mdd-m-i-1
                border-bottom: 1px solid variables.$borderColor
                &:last-child
                    border: 0
            .csgdd1-m-l-1
                display: block
                padding: 10px 25px
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightBold
                color: variables.$color9
                &:hover
                    color: variables.$secondColor
                &.active
                    color: variables.$primaryColor
                    &::before
                        content: ''
                        background-color: variables.$primaryColor
                        width: 10px
                        height: 10px
                        display: inline-block
                        border-radius: 50%
                        margin-right: 5px
                        margin-left: -15px
.cs-header-btn-4
    display: flex
    position: relative
    width: 100%
    min-height: 45px
    padding: 2px
    align-items: center
    border-radius: variables.$radius4
    font-size: 16px
    line-height: 22px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color13
    background-color: variables.$secondColor
    border: 1px solid transparent
    transition: all .3s ease
    .image-d-1
        flex: 0 0 40px
        max-width: 40px
        img
            max-height: 40px
            border-radius: 50%
        .fake-char-i-1
            display: flex
            height: 40px
            width: 100%
            align-items: center
            justify-content: center
            border-radius: 50%
            padding: 1px
            background-color: variables.$primaryColor
            color: variables.$whiteColor
    .name-d-1
        flex: 0 0 calc(100% - 40px)
        max-width: calc(100% - 40px)
        padding:
            left: 5px
        .name-t-1
            font-size: 16px
            line-height: 22px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color13
            margin: 0
            @include short-code.textAfterDots(22px, 22px, 1)
            transition: all .3s ease
    &:hover
        border-color: variables.$color12
        background-color: variables.$whiteColor
        @include short-code.backgroundShadow4()
        color: variables.$secondColor
        .name-t-1
            color: variables.$secondColor
.cs-humberge-m-1
    cursor: pointer
    position: relative
    height: 14px
    max-width: 32px
    width: 100%
    margin: 0 auto
.cs-humberge-m-1-top
    border-radius: variables.$radius14
    height: 4px
    width: 100%
    background: variables.$secondColor
    position: absolute
    transform: rotate(0deg)
    top: 0
    animation-name: topreverse
    animation-duration: 0.65s
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1)
    animation-fill-mode: forwards
.cs-humberge-m-1-bottom
    border-radius: variables.$radius14
    height: 4px
    width: 100%
    background: variables.$secondColor
    position: absolute
    transform: rotate(0deg)
    bottom: 0
    animation-name: bottomreverse
    animation-duration: 0.65s
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1)
    animation-fill-mode: forwards
.cs-humberge-m-1
    &.is-active
        .cs-humberge-m-1-top
            top: 28.8333333333%
            animation-name: top
            animation-duration: 0.65s
            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1)
            animation-fill-mode: forwards
        .cs-humberge-m-1-bottom
            bottom: 45.8333333333%
            animation-name: bottom
            animation-duration: 0.65s
            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1)
            animation-fill-mode: forwards
        .no-animation
            animation: none
@-webkit-keyframes top
    0%
        top: 0%
    60%
        top: 45.8333333333%
        transform: rotate(0deg)
    100%
        transform: rotate(45deg)
@-moz-keyframes top
    0%
        top: 0%
    60%
        top: 45.8333333333%
        transform: rotate(0deg)
    100%
        transform: rotate(45deg)
@keyframes top
    0%
        top: 0%
    60%
        top: 45.8333333333%
        transform: rotate(0deg)
    100%
        transform: rotate(45deg)
@-webkit-keyframes bottom
    0%
        bottom: 0%
    60%
        bottom: 45.8333333333%
        transform: rotate(0deg)
    100%
        transform: rotate(-45deg)
@-moz-keyframes bottom
    0%
        bottom: 0%
    60%
        bottom: 45.8333333333%
        transform: rotate(0deg)
    100%
        transform: rotate(-45deg)
@keyframes bottom
    0%
        bottom: 0%
    60%
        bottom: 45.8333333333%
        transform: rotate(0deg)
    100%
        transform: rotate(-45deg)
@-webkit-keyframes topreverse
    0%
        transform: rotate(45deg)
        top: 45.8333333333%
    60%
        transform: rotate(0deg)
        top: 45.8333333333%
    100%
        top: 0%
@-moz-keyframes topreverse
    0%
        transform: rotate(45deg)
        top: 45.8333333333%
    60%
        transform: rotate(0deg)
        top: 45.8333333333%
    100%
        top: 0%
@keyframes topreverse
    0%
        transform: rotate(45deg)
        top: 45.8333333333%
    60%
        transform: rotate(0deg)
        top: 45.8333333333%
    100%
        top: 0%
@-webkit-keyframes bottomreverse
    0%
        transform: rotate(-45deg)
        bottom: 45.8333333333%
    60%
        transform: rotate(0deg)
        bottom: 45.8333333333%
    100%
        bottom: 0%
@-moz-keyframes bottomreverse
    0%
        transform: rotate(-45deg)
        bottom: 45.8333333333%
    60%
        transform: rotate(0deg)
        bottom: 45.8333333333%
    100%
        bottom: 0%
@keyframes bottomreverse
    0%
        transform: rotate(-45deg)
        bottom: 45.8333333333%
    60%
        transform: rotate(0deg)
        bottom: 45.8333333333%
    100%
        bottom: 0%
.cs-mobile-menu-d-1
    position: fixed
    top: 94px
    right: 0
    left: 0
    bottom: 0
    z-index: 98
    display: block
    margin: 0
    width: 100%
    height: 0px
    background-color: variables.$whiteColor
    overflow: hidden
    transition: background .44s .2s cubic-bezier(0.52, 0.16, 0.24, 1),height .56s cubic-bezier(0.52, 0.16, 0.24, 1)
    &.active
        height: calc(100vh - 94px)
        .content-area-1
            opacity: 1
            overflow-y: auto
        .image-area-1
            .image-3
                left: 5vw
    .content-area-1
        display: block
        max-width: 600px
        margin: 0 auto
        padding: 45px 40px
        opacity: 0
        transition: opacity .3s ease
        transition-delay: 250ms
        overflow: hidden
        max-height: 60vh
    .image-area-1
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        z-index: -1
        overflow: hidden
        .image-1
            position: absolute
            left: 50%
            top: 0
            z-index: -1
            transform: translateX(-50%)
        .image-2
            position: absolute
            bottom: 50px
            left: 0
            z-index: -1
            width: 100%
            max-width: unset
        .image-3
            position: absolute
            bottom: 0
            left: 90vw
            z-index: 1
            transition: all 2.5s ease
            transition-delay: .3s
.cs-mobile-menu-1
    @include short-code.menuClear1
    padding:
        bottom: 30px
    .level-1
        line-height: 1
        margin:
            bottom: 5px
    .level-link-1
        position: relative
        display: block
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightBold
        color: variables.$secondColor
        padding: 10px 20px 10px 0px
        transition: all 0.3s ease
    .level-2
        line-height: 1
        margin:
            bottom: 5px
        border:
            bottom: 1px solid variables.$secondColor
    .level-link-2
        position: relative
        display: block
        font-size: 14px
        line-height: 20px
        font-weight: variables.$fontWeightBold
        color: variables.$secondColor
        padding: 10px 10px 10px 0px
        transition: all 0.3s ease
    .level-link-3
        position: relative
        display: block
        font-size: 14px
        line-height: 20px
        font-weight: variables.$fontWeightRegular
        color: variables.$secondColor
        padding: 10px 10px 10px 0px
        transition: all 0.3s ease
    .dd-menu-l-1, .dd-menu-l-2
        &::after
            @include short-code.bainIconCreate('\e95e')
            font-size: 8px
            line-height: 1
            position: absolute
            right: 4px
            top: 15px
            z-index: 1
            pointer-events: none
            transition: all 0.3s ease
        &.active
            &::after
                transform: rotate(-180deg)
    .dd-menu-d-1
        display: none
        background-color: variables.$color10
        padding: 10px
        border-radius: variables.$radius1 variables.$radius1 0px 0px
    .dd-menu-1
        @include short-code.menuClear1
        .level-2
            &:last-child
                border: 0
                margin: 0
.cs-footer-1
    background-color: variables.$secondColor
    padding: 35px 0px
    position: relative
    z-index: 1
.cs-footer-row-1
    @include short-code.rowCreate(-15px)
    .csfr1-col-1
        @include short-code.colCreate(15px, 240px)
    .csfr1-col-2
        @include short-code.colCreate(15px, calc(calc(100% - 240px) / 5))
.cs-footer-image-1
    margin:
        bottom: 40px
    img
        max-height: 190px
.cs-download-app-1
    margin:
        bottom: 30px
    .text-1
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$whiteColor
        margin:
            bottom: 20px
    .image-m-1
        @include short-code.menuFlex1()
        li
            margin:
                bottom: 15px
                right: 8px
            &:last-child
                margin:
                    right: 0px
            img
                max-height: 42px
                min-height: 42px
                width:100%
                height:100%
.cs-social-menu-1
    @include short-code.menuFlex1()
    justify-content: space-between
    li
        margin:
            right: 8px
            bottom: 10px
        &:last-child
            margin:
                right: 0px
        a
            display: flex
            align-items: center
            justify-content: center
            width: 30px
            height: 30px
            border-radius: variables.$radius1
            font-size: 18px
            line-height: 1
            color: variables.$primaryColor
            background-color: transparent
            transition: all .4s ease
            &:hover
                color: variables.$primaryColor
                background-color: variables.$whiteColor
.cs-wbg-head-1
    font-size: 18px
    line-height: 26px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    margin:
        bottom: 30px
    span
        display: inline-block
        position: relative
        z-index: 1
        padding: 0px 3px
        &::before
            content: ''
            height: 8px
            width: 100%
            display: block
            background-color: variables.$color14
            position: absolute
            bottom: 5px
            left: 0
            z-index: -1
            border-radius: variables.$radius5
.cs-footer-menu-1
    @include short-code.menuClear1()
    li
        margin:
            bottom: 15px
        a
            font-size: 18px
            line-height: 26px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$whiteColor
            transition: all .3s ease
            &:hover
                opacity: .5
.cs-footer-menu-2
    @include short-code.menuFlex1()
    justify-content: flex-end
    li
        position: relative
        z-index: 1
        margin:
            right: 24px
        &::after
            content: ''
            display: block
            position: absolute
            right: -12px
            top: 2px
            z-index: 1
            width: 1px
            height: 20px
            background-color: variables.$whiteColor
        &:last-child
            margin:
                right: 0px
            &::after
                display: none
        a
            font-size: 18px
            line-height: 26px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$whiteColor
            opacity: .5
            transition: all .3s ease
        &.active
            a
                opacity: 1
.cs-full-container-1
    max-width: 1920px
    margin: 0 auto
    width: 100%
.cs-slider-1
    background-color: variables.$whiteColor
    &.owl-theme
        .owl-nav
            bottom: 80px
            left: 50%
            transform: translateX(-50%)
            position: absolute
            z-index: 1
            max-width: 1390px
            width: 100%
            margin: 0 auto
            text-align: left
            padding: 0px 45px
            [class*=owl-]
                font-size: 30px
                line-height: 1
                color: variables.$primaryColor
                &:first-child
                    margin:
                        right: 20px
                &:hover, &:focus
                    background: transparent
                &:hover
                    opacity: .6
    .slider-container-1
        background-position: center !important
        background-repeat: no-repeat !important
        background-size: cover !important
        height: 550px
        background-blend-mode: multiply
        display: flex
        align-items: center
        flex-wrap: wrap
    .slider-container-2
        background-position: right !important
        background-repeat: no-repeat !important
        background-size: contain !important
        height: 550px
        display: flex
        align-items: center
        flex-wrap: wrap
    .slider-content-1
        max-width: 460px
        width: 100%
        padding:
            left: 30px
    .desc-1
        font-size: 24px
        line-height: 30px
        letter-spacing: .2px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$whiteColor
        margin:
            bottom: 30px
    .desc-2
        font-size: 24px
        line-height: 30px
        letter-spacing: .2px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        margin:
            bottom: 30px
    .action-menu-1
        @include short-code.menuFlex1()
        li
            margin:
                right: 25px
                bottom: 10px
            &:last-child
                margin:
                    right: 0px
    .logo-m-1
        @include short-code.menuClear1()
        position: absolute
        right: 15px
        bottom: -120px
        z-index: 1
        li
            margin:
                bottom: 15px
            &:last-child
                margin:
                    bottom: 0
            img
                min-height: 55px
                max-width: 155px
.cs-white-btn-1
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: variables.$whiteColor
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 10px
    &:hover
        color: variables.$whiteColor
        background-color: variables.$color7
.cs-white-outline-btn-1
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: transparent
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 1px solid variables.$whiteColor
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 10px
    &:hover
        color: variables.$color7
        background-color: variables.$whiteColor
.cs-primary-btn-1
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: variables.$primaryColor
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 10px
    &:hover
        color: variables.$whiteColor
        background-color: variables.$secondColor
.cs-black-outline-btn-1
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: transparent
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    border: 1px solid variables.$color7
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 10px
    &:hover
        color: variables.$whiteColor
        background-color: variables.$color7
.cs-secondary-btn-1
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 58px
    background-color: variables.$secondColor
    border-radius: variables.$radius7
    min-width: 130px
    font-size: 22px
    line-height: 26px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 1px solid transparent
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            right: 10px
    &:hover
        color: variables.$secondColor
        background-color: variables.$whiteColor
        border-color: variables.$secondColor
        @include short-code.backgroundShadow4()
.cs-homepage-sec-2
    margin:
        top: -35px
        bottom: 80px
    position: relative
    z-index: 2
.cs-loc-date-d-1
    max-width: 970px
    min-height: 70px
    width: 100%
    background-color: variables.$whiteColor
    border-radius: variables.$radius6
    border: 1px solid variables.$color15
    @include short-code.rowCreate(0px)
    margin: 0 auto
    align-items: center
    .csld1-col-1
        @include short-code.colCreate(0px, 310px)
        padding: 6px 15px
        border: 1px solid transparent
        border-right-color: variables.$color15
        transition: all .2s ease
        &.active
            background-color: variables.$color16
            border-radius: variables.$radius6
            border-color: variables.$color15
            .cs-icon-box-1
                &::after
                    content: '\e949'
                    font-size: 12px
            .search-m-d-1
                opacity: 1
                visibility: visible
        &.deactive
            border-right-color: variables.$whiteColor
    .csld1-col-2
        @include short-code.colCreate(0px, 500px)
        padding: 6px 15px
        border-radius: variables.$radius6
        border: 1px solid transparent
        transition: all .2s ease
        &.active
            background-color: variables.$color16
            border-radius: variables.$radius6
            border-color: variables.$color15
            .cs-icon-box-1
                &::after
                    content: '\e949'
                    font-size: 12px
        .row
            .col-12
                &:first-child
                    .cs-icon-box-1
                        &::after
                            display: none
    .csld1-col-3
        @include short-code.colCreate(0px, 158px)
        padding: 6px
        text-align: right
.cs-icon-box-1
    @include short-code.rowCreate(-5px)
    cursor: pointer
    position: relative
    align-items: center
    &::after
        @include short-code.bainIconCreate('\e95e')
        pointer-events: none
        font-size: 10px
        line-height: 1
        color: variables.$color7
        position: absolute
        right: 0
        top: 50%
        transform: translateY(-50%)
        z-index: 1
    .csib1-col-1
        @include short-code.colCreate(5px, 45px)
        text-align: center
    .csib1-col-2
        @include short-code.colCreate(5px, calc(100% - 45px))
    .desc-1
        font-size: 34px
        line-height: 1
        color: variables.$color3
        margin: 0
    .desc-2
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        margin: 0
    label
        font-size: 14px
        line-height: 22px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color3
        margin:
            bottom: 8px
        cursor: pointer
    input
        padding: 1px 0px
        height: auto
        border: 0
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        background-color: transparent
        &:focus
            box-shadow: none
            outline: none
            color: variables.$color7
            background-color: transparent
        &::placeholder
            font-size: 16px
            line-height: 22px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color7
    .search-m-d-1
        position: absolute
        left: -10px
        top: 75px
        width: calc(100% + 25px)
        z-index: 9
        border-radius: variables.$radius6
        background-color: variables.$color16
        border: 1px solid variables.$color15
        padding: 20px 25px
        opacity: 0
        visibility: hidden
        transition: all .3s ease
        &.active
            opacity: 1
            visibility: visible
        .smm-d-1
            position: relative
            max-height: 150px
            overflow: hidden
            overflow-y: auto
            scrollbar-width: thin
            scrollbar-color: variables.$color17
            &::-webkit-scrollbar
                height: 5px
                width: 5px
            &::-webkit-scrollbar-track
                background: variables.$color17
            &::-webkit-scrollbar-thumb
                background: variables.$color18
                border-radius: variables.$radius8
            &::-webkit-scrollbar-thumb
                &:hover
                    background: variables.$color18
        ul
            @include short-code.menuClear1()
            li
                line-height: 1
        a
            display: block
            font-size: 16px
            line-height: 24px
            font-weight: 600
            color: variables.$color7
            margin:
                bottom: 10px
            transition: all .3s ease
            &:hover
                opacity: 0.6
.cs-datetime-picker-1
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    z-index: -1
.daterangepicker
    background-color: variables.$color16
    border-radius: variables.$radius6
    border: 1px solid variables.$color15
    user-select: none
    &::before, &::after
        display: none
    .calendar-table
        background-color: variables.$color16
        border: 0
    .drp-calendar
        &.left
            border-right: 1px solid variables.$color15
            padding: 15px 0 8px 8px
        &.right
            padding: 15px 8px 8px 8px
    .drp-buttons
        border-color: variables.$color15
        padding: 15px 20px
    td
        position: relative
        &.in-range
            background-color: rgba(39, 193, 178, 0.3)
        &.disabled
            color: variables.$color19
            background: transparent
        &.off
            color: variables.$color19
            background: transparent
            &.in-range
                color: variables.$color19
                background: transparent
            &.start-date
                color: variables.$color19
                background: transparent
            &.end-date
                color: variables.$color19
                background: transparent
        &.available
            &:hover
                background-color: rgba(39, 193, 178, 0.4)
                color: variables.$color11
        &.active
            background-color: variables.$primaryColor
            &:hover
                background-color: variables.$primaryColor
        &.today
            background-color: transparent
            color: variables.$color11
            border: 2px solid variables.$color25
            font-weight: 700
    th
        &.available
            &:hover
                background-color: rgba(39, 193, 178, 0.4)
                color: variables.$color11
    option
        &.disabled
            color: variables.$color19
            background: transparent
    select
        &.hourselect, &.minuteselect, &.secondselect, &.ampmselect
            background: transparent
            border: 1px solid variables.$color15
            border-radius: variables.$radius2
            padding: 2px 5px
    .applyBtn1
        background-color: variables.$primaryColor !important
        border-radius: variables.$radius2 !important
        height: 30px !important
        padding: 2px 10px !important
        min-width: 110px !important
        font-size: 12px !important
        line-height: 1 !important
        font-weight: 700 !important
        color: variables.$color13 !important
        &:focus
            outline: none
            box-shadow: none
    .cancelBtn1
        background-color: transparent !important
        border-radius: variables.$radius2 !important
        height: 30px !important
        padding: 2px 10px !important
        min-width: 110px !important
        font-size: 12px !important
        line-height: 1 !important
        font-weight: 700 !important
        color: variables.$color20 !important
        text-decoration: underline !important
        &:focus
            outline: none
            box-shadow: none
    .drp-selected
        display: none
    &.single
        .drp-calendar
            &.left
                border-right: 0
        .drp-buttons
            padding: 15px 8px
            text-align: center
            .btn
                margin:
                    left: 0
                    right: 8px
.cs-tab-slider-d-1
    position: relative
    overflow-x: hidden
.cs-homepage-sec-3
    margin:
        bottom: 80px
.cs-slider-row-1
    @include short-code.rowCreate(-15px)
    .cssr1-col-1
        @include short-code.colCreate(15px, 300px)
    .cssr1-col-2
        @include short-code.colCreate(15px, calc(100% - 300px))
        padding:
            left: 70px
    .cssr1-col-3
        @include short-code.colCreate(15px, calc(100% - 300px))
        padding:
            right: 70px
.cs-slider-cont-1
    .bg-image-1
        position: absolute
        right: -50px
        top: 0
        z-index: -1
        &.right
            left: -50px
        img
            width: 600px
            max-width: unset
.cs-slider-2
    &.owl-theme
        .owl-dots
            .owl-dot
                span
                    margin: 5px
                    background: variables.$color21
                    transition: all .2s ease
                &.active
                    span
                        background-color: variables.$primaryColor
                        width: 24px
.cs-tab-container-1
    padding: 30px 0px 10px
    max-width: 820px
.cs-wbg-head-2
    font-size: 44px
    line-height: 58px
    font-weight: variables.$fontWeightExtraBold
    color: variables.$color7
    margin:
        bottom: 50px
    span
        display: inline-block
        position: relative
        z-index: 1
        padding: 0px 3px
        &::before
            content: ''
            height: 25px
            width: 100%
            display: block
            background-color: variables.$color22
            position: absolute
            bottom: 0px
            left: 0
            z-index: -1
            border-radius: variables.$radius5
    &.color-1
        span
            &::before
                background-color: variables.$color24
    &.color-2
        span
            &::before
                background-color: variables.$color26
.cs-navTabs-1
    justify-content: space-between
    &.nav
        margin:
            bottom: 40px
    &.nav-tabs
        border-color: variables.$color23
        .nav-link
            border-radius: 0
            border: 0
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            padding: 15px 20px
            position: relative
            transition: all .3s ease
            &.active
                color: variables.$primaryColor
                background-color: transparent
                border-color: unset
                &::after
                    content: ''
                    width: 50px
                    height: 5px
                    border-radius: variables.$radius1
                    background-color: variables.$primaryColor
                    position: absolute
                    bottom: -2px
                    left: 50%
                    transform: translateX(-50%)
                    z-index: 1
        .nav-item
            &.show
                color: variables.$primaryColor
                background-color: transparent
                border-color: unset
    &.color-1
        &.nav-tabs
            .nav-link
                &.active
                    color: variables.$secondColor
                    &::after
                        background-color: variables.$secondColor
            .nav-item
                &.show
                    color: variables.$secondColor
.cs-navTabs-content-1
    padding: 0px 25px
    .in-content-1
        h1, h2, h3, h4, h5, h6
            font-size: 24px
            line-height: 32px
            font-weight: variables.$fontWeightBold
            color: variables.$color7
            margin:
                bottom: 25px
        p
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        ul, ol
            padding:
                right: 15px
        li
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        a
            color: variables.$primaryColor
.cs-homepage-sec-4
    margin:
        bottom: 80px
.cs-homepage-sec-5
    margin:
        bottom: 65px
    .left-side
        text-align: left
        margin:
            bottom: 15px
        border-right: 2px solid variables.$color25
    .right-side
        text-align: right
        margin:
            bottom: 15px
    .image-d-1
        margin:
            bottom: 10px
    .content-d-1
        margin:
            bottom: 40px
.cs-global-desc-1
    font-size: 20px
    line-height: 28px
    font-weight: variables.$fontWeightRegular
    color: variables.$color7
    margin:
        bottom: 25px
.cs-primary-btn-2
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: variables.$primaryColor
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    &:hover
        color: variables.$whiteColor
        @include short-code.backgroundShadow5()
.cs-secondary-btn-2
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: variables.$secondColor
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    &:hover
        color: variables.$whiteColor
        @include short-code.backgroundShadow6()
.cs-homepage-sec-6
    margin:
        bottom: 60px
    .header-area-1
        padding:
            right: 120px
    .slider-area-1
        padding:
            left: 14.5%
.cs-slider-3
    &.owl-carousel
        .owl-stage
            padding:
                left: 0 !important
    &.owl-theme
        .owl-nav
            position: absolute
            top: -100px
            left: -15px
            max-width: 1230px
            margin: 0 auto
            width: 100%
            z-index: 1
            text-align: right
            padding: 0px 15px
            [class*=owl-]
                font-size: 45px
                line-height: 1
                color: variables.$secondColor
                margin: 0 10px
                &:hover
                    color: variables.$secondColor
                    background-color: transparent
            .disabled
                color: variables.$color20
                opacity: 1
                &:hover
                    color: variables.$color20
.cs-comment-box-1
    padding: 25px 20px
    margin:
        bottom: 15px
    background-color: variables.$color13
    border: 0.8px solid variables.$color25
    border-radius: variables.$radius8
    .head-area-1
        @include short-code.rowCreate(-10px)
        margin:
            bottom: 20px
        .ha1-col-1
            @include short-code.colCreate(10px, 112px)
        .ha1-col-2
            @include short-code.colCreate(10px, calc(100% - 112px))
    .user-profile-1
        background-position: center !important
        background-repeat: no-repeat !important
        background-size: cover !important
        background-color: variables.$secondColor
        border-radius: variables.$radius9
        text-align: center
        display: flex
        align-items: center
        justify-content: center
        font-size: 45px
        line-height: 60px
        font-weight: variables.$fontWeightBold
        color: variables.$whiteColor
        height: 92px
        width: 100%
    .head-1
        font-size: 22px
        line-height: 30px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 5px
    .desc-1
        font-size: 20px
        line-height: 26px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        margin:
            bottom: 5px
    .desc-2
        font-size: 16px
        line-height: 30px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        margin:
            bottom: 20px
        @include short-code.textAfterDots(60px, 60px, 2)
    .desc-3
        font-size: 16px
        line-height: 30px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 0px
    .link-1
        display: block
        font-size: 16px
        line-height: 30px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        text-decoration: underline !important
    .star-list-1
        @include short-code.menuFlex1()
        li
            font-size: 18px
            color: variables.$color27
            margin:
                right: 5px
            &:last-child
                margin:
                    right: 0px
            &::before
                @include short-code.bainIconCreate('\e922')
            &.full-star
                &::before
                    content: '\e94d'
            &.half-star
                &::before
                    content: '\e94c'
    .bottom-area-1
        @include short-code.rowCreate(-10px)
        .ba1-col-1
            @include short-code.colCreate(10px, calc(100% - 130px))
        .ba1-col-2
            @include short-code.colCreate(10px, 130px)
            text-align: right
.cs-homepage-sec-7
    margin:
        bottom: 40px
    .header-area-1
        padding:
            right: 120px
    .cs-global-desc-1
        margin:
            bottom: 60px
    .cs-slider-4
        &.owl-theme
            .owl-nav
                top: -185px
.cs-slider-4
    &.owl-theme
        .owl-nav
            position: absolute
            top: -100px
            left: -15px
            max-width: 1230px
            margin: 0 auto
            width: 100%
            z-index: 1
            text-align: right
            padding: 0px 15px
            [class*=owl-]
                font-size: 45px
                line-height: 1
                color: variables.$secondColor
                margin: 0 10px
                &:hover
                    color: variables.$secondColor
                    background-color: transparent
            .disabled
                color: variables.$color20
                opacity: 1
                &:hover
                    color: variables.$color20
.cs-city-box-1
    text-align: center
    margin:
        bottom: 15px
    .image-div-1
        margin:
            bottom: 15px
        img
            max-height: 195px
            width: auto !important
            margin: 0 auto
    .head-1
        font-size: 30px
        line-height: 38px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin: 0
        i
            font-size: 17px
            padding:
                left: 10px
.cs-homepage-sec-8
    margin:
        bottom: 70px
    .cs-full-container-1
        overflow-x: hidden
    .cs-wbg-head-2
        margin:
            bottom: 20px
    .cs-global-desc-1
        max-width: 490px
.cs-download-app-row-1
    @include short-code.rowCreate(-15px)
    align-items: center
    // .csdar1-col-1
    //     @include short-code.colCreate(15px, 45%)
    .csdar1-col-2
        // @include short-code.colCreate(15px, 55%)
        position: relative
    .download-app-i-1
        height: 580px
        img
            // position: absolute
            // left: 0
            // top: 0
            // z-index: 1
            // height: 100%
            // width: auto
            // max-width: unset
            max-width: 100%
            width: 100%
.cs-download-app-2
    margin:
        bottom: 0px
    position: absolute
    bottom: 0
    left: 15.5%
    .image-m-1
        @include short-code.menuFlex1()
        li
            margin:
                bottom: 15px
                right: 10px
            &:last-child
                margin:
                    right: 0px
            img
                max-height: 50px
                min-height: 50px
                width:100%
                height:100%
.cs-homepage-sec-9
    margin:
        bottom: 70px
.cs-hwl-row-1
    @include short-code.rowCreate(-15px)
    align-items: center
    margin:
        bottom: 35px
    .cshwlr1-col-1
        @include short-code.colCreate(15px, calc(100% - 150px))
        margin:
            bottom: 15px
    .cshwlr1-col-2
        @include short-code.colCreate(15px, 150px)
        text-align: right
        margin:
            bottom: 15px
.cs-global-link-1
    display: inline-block
    font-size: 20px
    line-height: 28px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$secondColor
    margin: 0
    text-decoration: underline !important
    transition: all .3s ease
    &:hover
        color: variables.$primaryColor
.cs-campaign-box-1
    margin:
        bottom: 40px
    .image-div-1
        margin:
            bottom: 10px
        img
            border-radius: variables.$radius8 variables.$radius8 0 0
            height: 250px
    .text-div-1
        .head-1
            font-size: 28px
            line-height: 38px
            font-weight: variables.$fontWeightBold
            color: variables.$blackColor
            margin:
                bottom: 10px
            @include short-code.textAfterDots(38px, 38px, 1)
        .desc-1
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightRegular
            color: variables.$blackColor
            margin:
                bottom: 5px
            @include short-code.textAfterDots(48px, 48px, 2)
            a
                color: variables.$secondColor
        .desc-2
            text-align: right
        .link-1
            display: inline-block
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$secondColor
            text-decoration: underline !important
            transition: all .3s ease
            &:hover
                color: variables.$primaryColor
                text-decoration: none !important
.cs-homepage-sec-10
    margin:
        bottom: 60px
    .slider-content-1
        padding:
            top: 115px
.cs-blog-content-row-1
    @include short-code.rowCreate(-15px)
    .csbcr1-col-1
        @include short-code.colCreate(15px, 340px)
        margin:
            bottom: 15px
    .csbcr1-col-2
        @include short-code.colCreate(15px, calc(100% - 340px))
        margin:
            bottom: 15px
.cs-blog-box-1
    margin:
        bottom: 15px
    .image-div-1
        margin:
            bottom: 10px
        border-radius: variables.$radius8 variables.$radius8 0 0
        background-position: center !important
        background-repeat: no-repeat !important
        background-size: 113% 100%!important
        height: 200px
        transition: all .4s ease
    &:hover
        .image-div-1
            background-size: 133% 120% !important
    .head-1
        font-size: 22px
        line-height: 30px
        font-weight: variables.$fontWeightBold
        color: variables.$blackColor
        margin:
            bottom: 10px
        @include short-code.textAfterDots(60px, 60px, 2)
    .desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$blackColor
        margin:
            bottom: 10px
        @include short-code.textAfterDots(48px, 48px, 2)
    .link-1
        display: block
        display: inline-block
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$secondColor
        transition: all .3s ease
        span
            text-decoration: underline
        i
            font-size: 12px
            padding:
                left: 5px
        &:hover
            color: variables.$primaryColor
            span
                text-decoration: none
.cs-homepage-sec-11
    margin:
        bottom: 60px
    .content-area-1
        max-width: 950px
        margin: 0 auto
        padding: 30px 0px 0px
.cs-accordion-1
    .card
        border: 0
        border-bottom: 0.5px solid variables.$color28
        margin:
            bottom: 5px
    .card-header
        border: 0
        border-radius: 0
        padding: 15px
        background-color: transparent
        &:first-child
            border-radius: 0
    .btn-link
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        padding: 0 15px 0 15px
        border: 0
        width: 100%
        text-align: left
        white-space: normal
        position: relative
        &:hover, &:focus
            color: variables.$color7
        &::before
            content: ''
            width: 5px
            height: 105%
            border-radius: variables.$radius13
            background-color: variables.$primaryColor
            position: absolute
            left: 0
            top: -2px
            z-index: 1
        &::after
            @include short-code.bainIconCreate('\e95c')
            font-size: 8px
            color: variables.$color7
            line-height: 1
            position: absolute
            right: 0
            top: 50%
            transform: translateY(-50%)
            pointer-events: none
            transition: all .3s ease
        &.collapsed
            &::after
                transform: translateY(-50%) rotate(180deg)
    .card-body
        padding: 0
        h1, h2, h3, h4, h5, h6
            font-size: 24px
            line-height: 32px
            font-weight: variables.$fontWeightBold
            color: variables.$color7
            margin:
                bottom: 25px
        p
            font-size: 18px
            line-height: 26px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        ul, ol
            padding:
                right: 15px
        li
            font-size: 18px
            line-height: 26px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        a
            color: variables.$secondColor
.cs-accordion-2
    .card
        border: 0
        border-bottom: 0
        margin:
            bottom: 0px
        border-bottom: 0.5px solid variables.$color28
    .card-header
        border: 0
        border-radius: 0
        padding: 15px 35px
        background-color: transparent
        &:first-child
            border-radius: 0
    .btn-link
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$secondColor
        padding: 0 15px 0 0px
        border: 0
        width: 100%
        text-align: left
        white-space: normal
        position: relative
        &:hover, &:focus
            color: variables.$secondColor
        &::before
            display: none
        &::after
            @include short-code.bainIconCreate('\e907')
            font-size: 14px
            color: variables.$secondColor
            line-height: 1
            position: absolute
            right: 0
            top: 50%
            transform: translateY(-50%)
            pointer-events: none
            transition: all .3s ease
        &.collapsed
            color: variables.$color7
            &::after
                color: variables.$color7
                content: '\e94a'
                transform: translateY(-50%) rotate(0deg)
    .card-body
        padding: 25px 35px
        background-color: variables.$color13
        h1, h2, h3, h4, h5, h6
            font-size: 24px
            line-height: 32px
            font-weight: variables.$fontWeightBold
            color: variables.$color7
            margin:
                bottom: 25px
        p
            font-size: 18px
            line-height: 26px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        ul, ol
            padding:
                right: 15px
        li
            font-size: 18px
            line-height: 26px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        a
            color: variables.$secondColor
.modal-backdrop
    &.show
        opacity: 0.6
.cs-modal-1
    .modal-dialog
        max-width: 650px
        margin: 3rem auto
        padding: 15px
    .modal-content
        border-radius: variables.$radius5
        padding: 0
        border: 0
    .close
        font-size: 22px
        line-height: 1
        opacity: 1
        color: variables.$color29
        position: absolute
        right: 20px
        top: 20px
        z-index: 2
    .modal-body
        padding: 0
        border-radius: variables.$radius5
        img
            border-radius: variables.$radius5
            width: 100%
            max-width: unset
.cs-modal-2
    .modal-dialog-centered
        max-width: 650px
        margin: 0 auto
        padding: 15px
    .modal-content
        border-radius: variables.$radius5
        padding: 0
        border: 0
    .close
        font-size: 22px
        line-height: 1
        opacity: 1
        color: variables.$color29
        position: absolute
        right: 20px
        top: 20px
        z-index: 2
    .modal-body
        padding: 0
        border-radius: variables.$radius5
        .image-d-1
            border-radius: variables.$radius5 variables.$radius5 0 0
            img
                border-radius: variables.$radius5 variables.$radius5 0 0
                width: 100%
                max-width: unset
        .text-d-1
            padding: 15px
            background-color: variables.$color16
            border-radius: 0 0 variables.$radius5 variables.$radius5
            .in-content-1
                max-width: 510px
                margin: 0 auto
            h1, h2, h3, h4, h5, h6
                font-size: 22px
                line-height: 30px
                font-weight: variables.$fontWeightBold
                color: variables.$color11
                margin:
                    bottom: 20px
            p
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightRegular
                color: variables.$color11
                margin:
                    bottom: 15px
            ul, ol
                padding:
                    right: 15px
            li
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightRegular
                color: variables.$color11
                margin:
                    bottom: 15px
            a
                color: variables.$secondColor
.cs-login-main-row-1
    @include short-code.rowCreate(0px)
    .cslmr1-col-1
        @include short-code.colCreate(0px, 50%)
    .cslmr1-col-2
        @include short-code.colCreate(0px, 50%)
.cs-login-bg-div-1
    background-position: center !important
    background-repeat: no-repeat !important
    background-size: cover !important
    min-height: calc(100vh - 90px)
    height: 100%
    background-blend-mode: multiply
    display: flex
    align-items: flex-end
    flex-wrap: wrap
    border-radius: 0 variables.$radius10 variables.$radius10 0
.cs-login-text-c-1
    max-width: 700px
    padding: 0px 15px
    position: relative
    width: 100%
    margin: 0 0 80px auto
    z-index: 1
    &::before
        content: url(../img/other/cloud-bg-1.svg)
        position: absolute
        left: -65px
        top: -110px
        z-index: -1
    .text-d-1
        max-width: 640px
        .head-1
            font-size: 54px
            line-height: 70px
            letter-spacing: .2px
            font-weight: variables.$fontWeightRegular
            color: variables.$whiteColor
            margin:
                bottom: 20px
            strong
                font-weight: variables.$fontWeightBold
        .desc-1
            font-size: 24px
            line-height: 32px
            letter-spacing: .2px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$whiteColor
            margin:
                bottom: 0px
.cs-login-form-c-1
    max-width: 700px
    padding: 30px 15px
    position: relative
    width: 100%
    margin: 0 auto 0 0
    z-index: 1
    height: 100%
    display: flex
    align-items: center
    flex-wrap: wrap
    .form-d-1
        max-width: 490px
        margin: 0 auto
        text-align: center
    .form-d-2
        max-width: 320px
        margin: 0 auto
        .form-input-1
            padding:
                top: 50px
                bottom: 50px
.cs-wbg-head-3
    font-size: 40px
    line-height: 54px
    font-weight: variables.$fontWeightExtraBold
    color: variables.$color7
    margin:
        bottom: 20px
    span
        display: inline-block
        position: relative
        z-index: 1
        padding: 0px 3px
        &::before
            content: ''
            height: 25px
            width: 100%
            display: block
            background-color: variables.$color22
            position: absolute
            bottom: 0px
            left: 0
            z-index: -1
            border-radius: variables.$radius5
    &.color-1
        span
            &::before
                background-color: variables.$color24
    &.color-2
        span
            &::before
                background-color: variables.$color26
.cs-global-desc-2
    font-size: 22px
    line-height: 32px
    font-weight: variables.$fontWeightRegular
    color: variables.$color7
    margin:
        bottom: 25px
.cs-form-1
    .form-group
        margin:
            bottom: 20px
    .invalid-feedback
        padding: 5px 20px
    .iti
        width: 100% !important
        .iti__arrow
            width: 10px
            height: 10px
            border: 0
            position: relative
            margin:
                left: 5px
            &::after
                @include short-code.bainIconCreate('\e95e')
                color: variables.$color20
                font-size: 6px
                position: absolute
                right: 0px
                top: 2px
            &.iti__arrow--up
                &::after
                    content: '\e95c'
        .iti--separate-dial-code, .iti__selected-dial-code
            font-size: 16px
            line-height: 22px
            font-weight: 500
            color: variables.$color20
    .iti--separate-dial-code
        .iti__selected-flag
            background-color: transparent
            border-radius: 0
            box-shadow: none
            border: 0
            padding: 0 8px 0 8px
            &::after
                content: ''
                width: 0.7px
                height: 18px
                background-color: variables.$color23
                opacity: 0.4
                position: absolute
                right: 1px
                top: 50%
                transform: translateY(-50%)
    .iti--allow-dropdown
        .iti__flag-container
            &:hover
                .iti__selected-flag
                    background-color: transparent
    label
        display: block
        cursor: pointer
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color29
        margin:
            bottom: 12px
        text-align: left
    ::placeholder
        color: variables.$color30
    input:not([type="submit"]), select
        border-radius: variables.$radius11
        border: 0.5px solid variables.$color20
        height: 50px
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        transition: all .2s ease
        padding: 5px 20px
        &:focus
            outline: none
            border-color: variables.$primaryColor
            @include short-code.backgroundShadow1
    .form-control
        &:disabled, &:read-only
            background-color: variables.$whiteColor
            cursor: not-allowed
        &[id^="newFileModalUpload"]
            height: 100%
            cursor: pointer !important
    textarea
        resize: none
        border-radius: variables.$radius11
        border: 0.5px solid variables.$color20
        font-size: 16px
        line-height: 26px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        transition: all .2s ease
        padding: 15px 20px
        &:focus
            outline: none
            border-color: variables.$primaryColor
            @include short-code.backgroundShadow1
    .info-text-1
        font-size: 16px
        line-height: 26px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        margin:
            bottom: 20px
        a
            font-weight: variables.$fontWeightBold
            color: variables.$secondColor
            text-decoration: underline !important
            &:hover
                text-decoration: none !important
    .date-box-1
        position: relative
        label
            &::before
                @include short-code.bainIconCreate('\e94f')
                font-size: 20px
                color: variables.$color20
                position: absolute
                left: 10px
                bottom: 15px
                z-index: 1
        input
            padding:
                left: 45px
    .date-i-1
        label
            &::before
                @include short-code.bainIconCreate('\e94f')
                font-size: 23px
                color: variables.$color20
                position: absolute
                left: 15px
                top: 48px
        input
            padding:
                left: 40px
.cs-primary-btn-3
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: variables.$primaryColor
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    &:hover
        color: variables.$whiteColor
        @include short-code.backgroundShadow5()
    &:disabled
        opacity: 0.5
        cursor: not-allowed
.cs-verification-i-1
    @include short-code.menuFlex1()
    justify-content: center
    margin:
        bottom: 40px
    li
        margin:
            right: 15px
        width: 50px
        &:last-child
            margin:
                right: 0
        input
            font-size: 30px !important
            color: variables.$color31 !important
            font-weight: variables.$fontWeightBold !important
            height: 64px !important
            text-align: center
            padding: 5px !important
            border: 1.2px solid variables.$color32 !important
            border-radius: variables.$radius12 !important
            &.active
                border-color: variables.$primaryColor !important
    &.error
        input
            border-color: variables.$color4 !important
            &.active
                border-color: variables.$color4 !important
.cs-countdown-div-1
    position: relative
    padding: 25px 10px 40px
    .countdown-div-data
        position: absolute
        top: 46%
        left: 50%
        z-index: 1
        transform: translate(-50%, -50%)
        font-size: 24px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
    svg
        transform: rotate(-90deg)
    .circle_animation
        stroke-dasharray: 315
        stroke-dashoffset: 315
.cs-sweetalert2-1
    max-width: 660px !important
    width: 100% !important
    .swal2-toast
        top: 90px
        cursor: pointer
        &.swal2-popup
            @include short-code.backgroundShadow7()
            min-height: 90px
            align-items: center
            border-radius: variables.$radius2
            &::before
                content: ''
                background-color: variables.$whiteColor
                width: 25px
                height: 100%
                position: absolute
                left: 0
                top: 0
                z-index: 1
            &.swal2-toast
                .swal2-title
                    margin: 0px 30px
                    font-size: 18px
                    line-height: 26px
                    font-weight: variables.$fontWeightSemiBold
                    color: variables.$color11
    &.color-1
        .swal2-toast
            &.swal2-popup
                background-color: variables.$color6
                &::before
                    background-color: variables.$color15
    &.color-2
        .swal2-toast
            &.swal2-popup
                background-color: variables.$color33
                &::before
                    background-color: variables.$color4
    &.color-3
        .swal2-toast
            &.swal2-popup
                background-color: variables.$color5
                &::before
                    background-color: variables.$color34
.cs-all-overflow-h
    overflow: hidden
.cs-checkbox-1
    position: relative
    label
        font-size: 16px !important
        line-height: 22px !important
        font-weight: variables.$fontWeightRegular !important
        color: variables.$color7 !important
        margin: 0
        cursor: pointer
        border: 0.5px solid variables.$color20
        background-color: variables.$whiteColor
        display: flex
        align-items: center
        justify-content: center
        min-height: 50px
        padding: 15px 20px
        border-radius: 15px
        transition: all .3s ease
    input
        cursor: pointer
        opacity: 0
        position: absolute
        left: 0
        top: 0
        z-index: -1
        &:checked
            + label
                background-color: variables.$primaryColor
                border-color: variables.$primaryColor
                color: variables.$whiteColor !important
.custom-range-1
    padding: 0px 25px
    margin:
        bottom: 40px
    .text-div-1
        height: 1px
        margin:
            bottom: 0
        text-align: center
        width: 100%
        input
            display: none
        span
            display: inline-block
            font-size: 16px
            line-height: 20px
            font-weight: variables.$fontWeightRegular
            color: variables.$color29
            width: 100px
            text-align: center
            padding:
                top: 5px
    .ui-widget
        &.ui-widget-content
            border: 0
            height: 8px
            border-radius: variables.$radius2
            background-color: variables.$color204
    .ui-widget-header
        background: variables.$primaryColor
    .ui-slider
        .ui-slider-handle
            background: variables.$primaryColor
            border: 1px solid variables.$whiteColor
            border-radius: 50%
            cursor: pointer
            height: 24px
            width: 24px
    .ui-slider-horizontal
        .ui-slider-handle
            top: -.5em
            margin:
                left: -12px
            &:focus
                outline: none
.cs-signal-cont-1
    .cssc1-d-1
        opacity: 0
        visibility: hidden
        transition: all .3s ease
        &.active
            opacity: 1
            visibility: visible
        .cssc1d1-inn-1
            position: absolute
            right: -50px
            top: 90px
            z-index: 10
            background-color: variables.$whiteColor
            border: 0.5px solid variables.$borderColor
            border-radius: variables.$radius8
            width: 475px
            @include short-code.backgroundShadow8()
            padding: 0px 5px
        .cssc1d1-inn-2
            position: fixed
            left: 0
            top: 90px
            bottom: 0
            right: 0
            width: 100%
            height: calc(100vh - 90px)
            background-color: variables.$blackColor
            opacity: 0.6
            z-index: 9
        .close-btn-a-1
            padding: 10px 15px 0px
            text-align: right
            .close-btn-1
                width: 30px
                height: 30px
                padding: 3px
                font-size: 16px
                line-height: 1
                color: variables.$color29
                background-color: transparent
                border: 0
        .form-content-1
            position: relative
            max-height: 65vh
            overflow: hidden
            overflow-y: auto
            scrollbar-width: thin
            scrollbar-color: variables.$color17
            &::-webkit-scrollbar
                height: 5px
                width: 5px
            &::-webkit-scrollbar-track
                background: variables.$color17
            &::-webkit-scrollbar-thumb
                background: variables.$color18
                border-radius: variables.$radius8
            &::-webkit-scrollbar-thumb
                &:hover
                    background: variables.$color18
        .form-bottom-content-1
            padding: 30px 0px 20px
            max-width: 325px
            margin: 0 auto
        .form-val-cont-1
            padding: 15px 15px 12px
            &.border-w-1
                border-bottom: 1px solid variables.$color35
            .fvc1-head-1
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color29
                margin:
                    bottom: 20px
                text-align: left
            .item-list-1
                @include short-code.menuFlex1()
                li
                    line-height: 1
                    margin:
                        right: 24px
                        bottom: 10px
                    &:nth-child(3n)
                        margin:
                            right: 0px
.cs-search-locDate-area-1
    background-color: variables.$whiteColor
    padding: 15px 0px 25px
    .inn-cont-1
        max-width: 970px
        width: 100%
        margin: 0 auto
.cs-rentcar-section-2
    position: relative
    z-index: 1
    transition: all .4s ease
    &.active
        &::before
            content: ''
            -webkit-backdrop-filter: saturate(200%) blur(5px)
            backdrop-filter: saturate(200%) blur(5px)
            background-color: rgba(255, 255, 255, 0.6)
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            z-index: 1
.cs-rentcar-row-1
    @include short-code.rowCreate(0px)
    .csrcr1-col-1
        @include short-code.colCreate(0px, 50%)
        min-height: calc(100vh - 200px)
        border: 1px solid variables.$borderColor
        border-top: 0px
    .csrcr1-col-2
        @include short-code.colCreate(0px, 50%)
.cs-page-filter-d-1
    background-color: variables.$whiteColor
    border: 1px solid variables.$borderColor
    @include short-code.backgroundShadow9()
    padding: 25px 0px 10px
    margin:
        bottom: 15px
    .inner-f-div-1
        max-width: 740px
        width: 100%
        padding: 0px 30px
        margin: 0 0 0 auto
    .filter-mm-1
        @include short-code.menuFlex1()
        position: relative
        z-index: 5
        .fmm-item-1
            position: relative
            line-height: 1
            margin:
                right: 20px
                bottom: 15px
            &:last-child
                margin:
                    right: 0px
        .fmm-action-1
            display: inline-flex
            align-items: center
            background-color: variables.$whiteColor
            border: 1px solid variables.$borderColor
            border-radius: variables.$radius2
            @include short-code.backgroundShadow9()
            padding: 12px
            min-height: 50px
            min-width: 130px
            font-size: 16px
            line-height: 22px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color7
            i
                padding:
                    right: 12px
                position: relative
                top: -1px
    .fmm-f-div-1
        background-color: variables.$whiteColor
        border: 0.5px solid variables.$borderColor
        border-radius: 14px
        width: 100%
        min-width: 490px
        padding: 10px 0px 0px
        position: absolute
        left: 0
        top: 60px
        z-index: 4
        transition: all .3s ease
        opacity: 0
        visibility: hidden
        @include short-code.backgroundShadow10()
        &.active
            opacity: 1
            visibility: visible
        &.type-2
            min-width: 320px
        .fmm-input-m-1
            @include short-code.menuClear1()
            li
                line-height: 1
                border-bottom: 1px solid rgba(237, 237, 237, 0.5)
                padding: 15px 25px
        .fmm-top-area-1
            padding: 10px 15px
            text-align: right
            .close-btn-1
                width: 30px
                height: 30px
                padding: 3px
                font-size: 16px
                line-height: 1
                color: variables.$color29
                background-color: transparent
                border: 0
        .fmm-middle-area-1
            position: relative
            max-height: 45vh
            min-height: 45vh
            overflow: hidden
            overflow-y: auto
        .fmm-bottom-area-1
            padding: 30px 30px 15px
            a, button
                min-width: auto
        .fmm-val-cont-1
            padding: 20px
            &.border-w-1
                border-bottom: 1px solid variables.$color35
            .fmm-head-1
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color29
                margin:
                    bottom: 20px
                text-align: left
            .fmm-item-d-1
                .fmm-item-list-1
                    padding:
                        top: 20px
                .fmm-item-list-2
                    padding:
                        top: 20px
            &.jsAccDiv1
                .fmm-head-1
                    cursor: pointer
                    position: relative
                    margin:
                        bottom: 0px
                    &::after
                        @include short-code.bainIconCreate('\e95e')
                        position: absolute
                        right: 0
                        top: 50%
                        transform: translate(-50%, -50%) rotate(0deg)
                        font-size: 10px
                        transition: all .3s ease
                .fmm-item-d-1
                    display: none
                &.active
                    .fmm-head-1
                        &::after
                            transform: translate(-50%, -50%) rotate(180deg)
                &.show-in
                    .fmm-item-d-1
                        display: block
            .fmm-item-list-1
                @include short-code.menuFlex1()
                li
                    line-height: 1
                    margin:
                        right: 20px
                        bottom: 20px
                    width: calc(calc(100%/3) - 15px)
                    &:nth-child(3n)
                        margin:
                            right: 0px
                .cs-checkbox-1
                    label
                        padding: 15px
            .fmm-item-list-2
                @include short-code.menuFlex1()
                li
                    line-height: 1
                    margin:
                        right: 20px
                        bottom: 20px
                    width: calc(calc(100%/2) - 10px)
                    &:nth-child(2n)
                        margin:
                            right: 0px
                .cs-checkbox-1
                    label
                        padding: 15px
.cs-page-list-1
    max-width: 740px
    width: 100%
    padding: 0px 30px
    margin: 0 0 0 auto
    .inner-pl-1
        position: relative
        max-height: calc(100vh - 320px)
        overflow: hidden
        overflow-y: auto
        scrollbar-width: thin
        scrollbar-color: variables.$color17
        padding:
            right: 5px
            left: 5px
        &::-webkit-scrollbar
            height: 5px
            width: 5px
        &::-webkit-scrollbar-track
            background: variables.$color17
        &::-webkit-scrollbar-thumb
            background: variables.$color18
            border-radius: variables.$radius8
        &::-webkit-scrollbar-thumb
            &:hover
                background: variables.$color18
.cs-list-row-1
    @include short-code.rowCreate(-12px)
    .cslr1-col-1
        @include short-code.colCreate(12px, 50%)
.cs-car-box-1
    position: relative
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    margin:
        bottom: 25px
    &.not-available
        &::before
            content: ''
            background-color: variables.$color203
            border: 1px solid variables.$borderColor
            border-radius: variables.$radius8
            position: absolute
            right: 0
            top: 0
            left: 0
            bottom: 0
            z-index: 9
            opacity: 0.45
    .cscb-top-area-1
        padding: 20px 12px
    .cscb-bottm-area-1
        background-color: variables.$color41
        text-align: center
        border-radius: 0 0 variables.$radius8 variables.$radius8
        .desc-2
            padding: 20px 10px
    .like-btn-1
        font-size: 20px
        line-height: 1
        color: variables.$color20
        display: flex
        align-items: center
        justify-content: center
        width: 30px
        height: 30px
        border-radius: 50%
        background-color: variables.$whiteColor
        position: absolute
        z-index: 0
        right: 12px
        top: 18px
        &::before
            @include short-code.bainIconCreate('\e940')
            position: relative
            top: 1px
        &.active
            color: variables.$color40
            &::before
                @include short-code.bainIconCreate('\e923')
    .head-1
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 8px
    .desc-1
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color36
        margin:
            bottom: 8px
    .desc-2
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 0px
    .desc-3
        font-size: 14px
        line-height: 20px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color20
        margin:
            bottom: 0px
        i
            text-align: center
            display: inline-block
            min-width: 35px
            font-size: 22px
            position: relative
            top: 4px
            padding:
                right: 8px
            color: variables.$blackColor
    .status-1
        position: relative
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightRegular
        color: variables.$color36
        margin:
            bottom: 0px
        &::before
            content: ''
            display: inline-block
            position: relative
            top: 2px
            width: 14px
            height: 14px
            border-radius: 50%
            margin:
                right: 10px
            background-color: variables.$color36
        &.suitable
            color: variables.$color38
            &::before
                background-color: variables.$color38
        &.res-available
            color: variables.$color37
            &::before
                background-color: variables.$color37
        &.full
            color: variables.$color39
            &::before
                background-color: variables.$color39
    .img-div-1
        text-align: center
        border-bottom: 1px solid variables.$borderColor
        img
            max-height: 240px
            height: 100%
            object-fit: cover
            width: 100%
    .info-div-1
        padding: 20px 10px 5px
        .idr1-col-1
            .cs-desc-1
                position: relative
                .cs-cd-info-hover
                    position: absolute
                    top: -15px
                    left: -10px
                    i
                        font-size: 12px
                    .cs-speech-box
                        line-height: 14px
            
    .info-drow-1
        @include short-code.rowCreate(-6px)
        .idr1-col-1
            @include short-code.colCreate(6px, 50%)
            margin:
                bottom: 15px
.cs-checkbox-2
    position: relative
    label
        font-size: 16px !important
        line-height: 22px !important
        font-weight: variables.$fontWeightRegular !important
        color: variables.$color9 !important
        margin: 0
        cursor: pointer
        border: 0
        background-color: variables.$whiteColor
        display: flex
        align-items: center
        text-align: left
        min-height: auto
        padding: 0px
        border-radius: 0px
        transition: all .3s ease
    input
        cursor: pointer
        opacity: 0
        position: absolute
        left: 0
        top: 0
        z-index: -1
        &:checked
            + label
                color: variables.$color9 !important
                font-weight: variables.$fontWeightBold !important
                &::after
                    @include short-code.bainIconCreate('\e947')
                    color: variables.$primaryColor !important
                    position: absolute
                    right: 0
                    top: 50%
                    transform: translateY(-50%)
                    z-index: 1
.select2
    width: 100% !important
.select2-container--default
    .select2-selection--single
        .select2-selection__arrow
            b
                border: 0
                height: 0
                left: auto
                margin-left: 0
                margin-top: 0
                position: absolute
                top: auto
                width: auto
.cs-global-select2-container-1
    label
        display: block
        cursor: pointer
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color29
        margin:
            bottom: 12px
        text-align: left
    ::placeholder
        color: variables.$color30
    .select2-container--default
        &.select2-container--open
            .select2-selection--single
                .select2-selection__arrow
                    b
                        &::after
                            @include short-code.bainIconCreate('\e95c')
            &.select2-container--below
                .select2-selection--single, .select2-selection--multiple
                    border-radius: variables.$radius5
            &.select2-container--above
                .select2-selection--single, .select2-selection--multiple
                    border-radius: variables.$radius5
        .select2-selection--single, .select2-selection--multiple
            cursor: pointer
            background-color: variables.$whiteColor
            border: 0.5px solid variables.$color20
            border-radius: variables.$radius5
            height: 50px
        .select2-selection--single
            .select2-selection__arrow
                height: 25px
                position: absolute
                top: 12px
                right: 20px
                width: 14px
            .select2-selection__arrow
                b
                    &::after
                        @include short-code.bainIconCreate('\e95e')
                        font-size: 8px
                        color: variables.$color20
            .select2-selection__rendered
                font-size: 16px
                line-height: 26px
                font-weight: variables.$fontWeightRegular
                color: variables.$color7
                padding: 12px 25px
            .select2-selection__placeholder
                color: variables.$color7
                opacity: 0.5
        .select2-selection--multiple
            .select2-selection__placeholder
                color: variables.$color7
                opacity: 0.5
        .select2-search--inline
            .select2-search__field
                cursor: pointer
                margin: 10px 0px 0px -10px
                color: variables.$color7
                font-size: 15px
                line-height: 20px
                font-weight: variables.$fontWeightRegular
                &::placeholder
                    color: variables.$color7
                    font-size: 15px
                    line-height: 20px
                    font-weight: variables.$fontWeightRegular
                    padding: 0 25px
    &.type-2
        label
            position: absolute
            z-index: 1
            margin-left: 14px
            margin-top: 14px
            color: variables.$color3
            font-size: 24px
        .select2-container--default
            .select2-selection--single
                .select2-selection__rendered
                    padding-left: 45px
.select2-container--open
    .cs-global-select2-dd-1
        &.select2-dropdown--below
            border: 0.5px solid variables.$borderColor
            border-radius: variables.$radius11
            background-color: variables.$whiteColor
            margin-top: 10px
            padding: 10px 20px
            @include short-code.backgroundShadow10()
        &.select2-dropdown--above
            border: 0.5px solid variables.$borderColor
            border-radius: variables.$radius11
            background-color: variables.$whiteColor
            margin-top: 10px
            padding: 10px 20px
            @include short-code.backgroundShadow10()
.cs-global-select2-dd-1
    .select2-results__option
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        padding: 10px 0px
        border: 0
        border-bottom: 1px solid rgba(237, 237, 237, 0.5)
        &:last-child
            border: 0
.select2-container--default
    .cs-global-select2-dd-1
        .select2-results__option--highlighted
            &.select2-results__option--selectable
                background-color: variables.$whiteColor
                color: variables.$color7
        .select2-results__option--selected
            background-color: variables.$whiteColor
            color: variables.$color7
            font-weight: variables.$fontWeightBold
        .select2-search--dropdown
            position: relative
            padding: 0
            margin:
                bottom: 15px
            &::before
                @include short-code.bainIconCreate('\e94e')
                position: absolute
                left: 12px
                top: 50%
                transform: translateY(-50%)
                z-index: 0
                pointer-events: none
                color: variables.$color15
            .select2-search__field
                border: 1px solid rgba(114, 135, 253, 0.5)
                border-radius: 10px
                padding-left: 30px
                &:focus
                    outline: none
                    box-shadow: none
.cs-global-select2-container-2
    label
        display: block
        cursor: pointer
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color29
        margin:
            bottom: 12px
        text-align: left
    ::placeholder
        color: variables.$color30
    .select2-container--default
        &.select2-container--open
            .select2-selection--single
                .select2-selection__arrow
                    b
                        &::after
                            @include short-code.bainIconCreate('\e95c')
            &.select2-container--below
                .select2-selection--single, .select2-selection--multiple
                    border-radius: variables.$radius5
            &.select2-container--above
                .select2-selection--single, .select2-selection--multiple
                    border-radius: variables.$radius5
        .select2-selection--single, .select2-selection--multiple
            cursor: pointer
            background-color: variables.$whiteColor
            border: 0.5px solid variables.$color20
            border-radius: variables.$radius5
            height: 50px
        .select2-selection--single
            .select2-selection__arrow
                height: 25px
                position: absolute
                top: 15px
                right: 20px
                width: 14px
            .select2-selection__arrow
                b
                    &::after
                        @include short-code.bainIconCreate('\e95e')
                        font-size: 8px
                        color: variables.$color20
            .select2-selection__rendered
                font-size: 16px
                line-height: 26px
                font-weight: variables.$fontWeightRegular
                color: variables.$color7
                padding: 12px 25px
            .select2-selection__placeholder
                color: variables.$color7
                opacity: 0.5
        .select2-selection--multiple
            .select2-selection__placeholder
                color: variables.$color7
                opacity: 0.5
        .select2-search--inline
            .select2-search__field
                cursor: pointer
                margin: 10px 0px 0px -10px
                color: variables.$color7
                font-size: 15px
                line-height: 20px
                font-weight: variables.$fontWeightRegular
                &::placeholder
                    color: variables.$color7
                    font-size: 15px
                    line-height: 20px
                    font-weight: variables.$fontWeightRegular
                    padding: 0 25px
.select2-container--open
    .cs-global-select2-dd-2
        &.select2-dropdown--below
            border: 0.5px solid variables.$borderColor
            border-radius: variables.$radius11
            background-color: variables.$whiteColor
            margin-top: 10px
            padding: 10px 20px
            @include short-code.backgroundShadow10()
        &.select2-dropdown--above
            border: 0.5px solid variables.$borderColor
            border-radius: variables.$radius11
            background-color: variables.$whiteColor
            margin-top: 10px
            padding: 10px 20px
            @include short-code.backgroundShadow10()
.cs-global-select2-dd-2
    .select2-results__option
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        padding: 10px 0px
        border: 0
        border-bottom: 1px solid rgba(237, 237, 237, 0.5)
        &:last-child
            border: 0
.select2-container--default
    .cs-global-select2-dd-2
        .select2-results__option--highlighted
            &.select2-results__option--selectable
                background-color: variables.$whiteColor
                color: variables.$color7
        .select2-results__option--selected
            background-color: variables.$whiteColor
            color: variables.$color7
            font-weight: variables.$fontWeightBold
        .select2-search--dropdown
            position: relative
            padding: 0
            margin:
                bottom: 15px
            &::before
                @include short-code.bainIconCreate('\e94e')
                position: absolute
                left: 12px
                top: 50%
                transform: translateY(-50%)
                z-index: 0
                pointer-events: none
                color: variables.$color15
            .select2-search__field
                border: 1px solid rgba(114, 135, 253, 0.5)
                border-radius: 10px
                padding-left: 30px
                &:focus
                    outline: none
                    box-shadow: none
.cs-order-summary-m-d-1
    background-color: variables.$color42
    padding: 0px 0px 45px
.cs-orderSum-cont-1
    max-width: 1180px
    width: 100%
    margin: 0 auto
.cs-orderSum-row-1
    @include short-code.rowCreate(-15px)
    .csorr1-col-1
        @include short-code.colCreate(15px, calc(100% - 510px))
    .csorr1-col-2
        @include short-code.colCreate(15px, 510px)
.cs-orderSum-head-1
    font-size: 18px
    line-height: 26px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color7
    margin:
        bottom: 20px
.cs-orderSum-stic-1
    position: sticky
    top: 120px
    z-index: 1
.cs-orderSum-right-a-1
    background-color: variables.$color13
    @include short-code.backgroundShadow9()
    padding: 15px 0px 5px
    margin:
        bottom: 20px
    .csosr-top-a-1
        padding: 0px 40px
    .csosr-bottom-a-1
        padding: 15px 40px
        .sum-sub-btn-1
            max-width: 310px
            width: 100%
            margin: 0 auto
            display: flex
            margin:
                top: 15px
    .sum-table-1
        margin:
            bottom: 0
        tr
            td
                position: relative
                font-size: 18px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color7
                padding: 15px 0px
                &:last-child
                    text-align: right
                &:first-child
                    padding:
                        right: 5px
                    &::after
                        content: ':'
                        position: absolute
                        right: 0
                        top: 50%
                        transform: translateY(-50%)
                        font-size: 18px
                        line-height: 24px
                        font-weight: variables.$fontWeightSemiBold
                        color: variables.$color7
            &:nth-child(3)
                td
                    &:first-child
                        color: variables.$primaryColor
    .sum-table-2
        margin:
            bottom: 0
        tr
            td
                position: relative
                font-size: 18px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color7
                padding: 15px 0px
                &:last-child
                    text-align: right
                    font-size: 24px
                    font-weight: variables.$fontWeightBold
                &:first-child
                    padding:
                        right: 5px
                    &::after
                        content: ':'
                        position: absolute
                        right: 0
                        top: 50%
                        transform: translateY(-50%)
                        font-size: 18px
                        line-height: 24px
                        font-weight: variables.$fontWeightSemiBold
                        color: variables.$color7
.cs-package-box-1
    margin:
        bottom: 5px
    border-radius: variables.$radius5
    background-color: variables.$color104
    padding: 12px
    .pack-desc-1
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightBold
        color: variables.$secondColor
        margin:
            bottom: 5px
    .pack-desc-2
        font-size: 14px
        line-height: 20px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        margin:
            bottom: 10px
    .pack-desc-3
        font-size: 14px
        line-height: 20px
        font-weight: variables.$fontWeightRegular
        color: variables.$color20
        margin:
            bottom: 12px
.cs-speech-info-box-1
    cursor: pointer
    position: relative
    display: inline-block
    top: 2px
    padding:
        left: 8px
    .icon-a-1
        font-size: 20px
        line-height: 1
        display: block
        color: variables.$color3
    .speech-box-container
        position: absolute
        left: 5px
        opacity: 0
        visibility: hidden
        transition: all .3s ease
        z-index: 5
        .speech-box
            min-width: 210px
            background: variables.$whiteColor
            border-radius: variables.$radius5
            padding: 10px
            border: 1px solid variables.$secondColor
            color: variables.$color7
            font-size: 12px
            line-height: 20px
            font-weight: variables.$fontWeightSemiBold
            p
                color: variables.$color7
                font-size: 12px
                line-height: 20px
                font-weight: variables.$fontWeightSemiBold
                margin:
                    bottom: 5px
    &.form-v-1
        position: absolute
        padding: 0
        right: 15px
        top: 50%
        z-index: 1
        transform: translateY(-50%)
        .speech-box-container
            left: auto
            right: 25px
            top: 0px
    &:hover
        .speech-box-container
            opacity: 1
            visibility: visible
.cs-check-input-1
    position: relative
    input
        cursor: pointer !important
        position: absolute !important
        left: 0 !important
        top: 0 !important
        opacity: 0 !important
        &:checked, &:not(:checked)
            + label
                position: relative !important
                padding-left: 35px !important
                padding-right: 0 !important
                cursor: pointer !important
                font-size: 14px !important
                line-height: 25px !important
                color: variables.$color11 !important
                display: inline-block !important
                left: 0 !important
                top: 0 !important
        &:not(:checked)
            + label
                &:before
                    content: ''
                    position: absolute
                    left: 0px
                    top: 1px
                    width: 24px
                    height: 24px
                    border: 1px solid variables.$color207
                    background: variables.$whiteColor
                    border-radius: variables.$radius13
        &:checked
            + label
                &:before
                    content: ''
                    position: absolute
                    left: 0px
                    top: 1px
                    width: 24px
                    height: 24px
                    border: 1px solid variables.$primaryColor
                    background: variables.$primaryColor
                    border-radius: variables.$radius13
        &:checked
            + label
                &:after
                    @include short-code.bainIconCreate('\e947')
                    position: absolute
                    left: 5px
                    top: 8px
                    font-size: 12px
                    line-height: 1
                    color: variables.$whiteColor
    label
        a
            color: variables.$color11
            text-decoration: underline !important
        span
            color: variables.$color11
            text-decoration: underline !important
.cs-discount-coupon-d-1
    @include short-code.rowCreate(-8px)
    .csdcd1-col-1
        @include short-code.colCreate(8px, calc(100% - 146px))
    .csdcd1-col-2
        @include short-code.colCreate(8px, 146px)
    .csdcd1-col-3
        @include short-code.colCreate(8px, 100%)
    ::placeholder
        font-size: 16px !important
        line-height: 22px !important
        font-weight: variables.$fontWeightBold !important
        color: variables.$color7 !important
    input
        font-size: 16px !important
        line-height: 22px !important
        font-weight: variables.$fontWeightBold !important
        color: variables.$color7 !important
        height: 50px !important
        border-radius: variables.$radius5 !important
        border: 1px solid variables.$borderColor !important
        padding: 10px 15px 10px 55px !important
        &:focus
            box-shadow: none
            outline: none
            border-color: variables.$primaryColor !important
        &.active
            border-color: variables.$primaryColor !important
    label
        position: absolute !important
        left: 22px !important
        top: 50% !important
        z-index: 1 !important
        transform: translateY(-50%) !important
        color: variables.$secondColor !important
        font-size: 20px !important
        line-height: 1 !important
        margin: 0 !important
    .disCo-btn-1
        display: flex
        align-items: center
        justify-content: center
        min-height: 50px
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightBold
        color: variables.$primaryColor
        padding: 10px
        transition: all .3s ease
        border: 1px solid variables.$primaryColor
        background-color: transparent
        border-radius: variables.$radius5
        opacity: 0
        visibility: hidden
        &:hover
            background-color: variables.$primaryColor
            color: variables.$whiteColor
        &.active
            opacity: 1
            visibility: visible
    .disCo-desc-1
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightRegular
        color: variables.$color20
        padding:
            top: 20px
        margin:
            bottom: 10px
        &.successful
            display: none
            &::before
                @include short-code.bainIconCreate('\e947')
                display: inline-flex
                width: 26px
                height: 26px
                border-radius: 50%
                background-color: variables.$color43
                align-items: center
                justify-content: center
                font-size: 11px
                line-height: 1
                color: variables.$whiteColor
                margin-right: 10px
        &.unsuccessful
            display: none
            &::before
                @include short-code.bainIconCreate('\e949')
                display: inline-flex
                width: 26px
                height: 26px
                border-radius: 50%
                background-color: variables.$color4
                align-items: center
                justify-content: center
                font-size: 11px
                line-height: 1
                color: variables.$whiteColor
                margin-right: 10px
    &.successful
        .disCo-btn-1
            opacity: 0 !important
            visibility: hidden !important
        .disCo-desc-1
            &.successful
                display: block !important
    &.unsuccessful
        .disCo-btn-1
            opacity: 0 !important
            visibility: hidden !important
        .disCo-desc-1
            &.unsuccessful
                display: block !important
        input
            border-color: variables.$color4 !important
.cs-orderSum-left-a-1
    background-color: variables.$whiteColor
    @include short-code.backgroundShadow9()
    border-radius: variables.$radius8
    padding: 15px 0px
    .csosla-sec-1
        padding: 15px
        border-bottom: 1px solid variables.$borderColor
        &:last-child
            border: 0
        .csosla-sec-cont-1
            max-width: 510px
            width: 100%
            margin: 0 auto
    .img-div-1
        text-align: center
        margin:
            bottom: 10px
        img
            max-height: 135px
            height: 100%
    .head-1
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 12px
    .desc-1
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color36
        margin:
            bottom: 12px
    .desc-2
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color20
        margin:
            bottom: 20px
    .in-table-1
        margin: 0
        tr
            td
                font-size: 16px
                line-height: 22px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color20
                padding: 10px 0px
                &:first-child
                    font-weight: variables.$fontWeightBold
                    color: variables.$color7
                &:last-child
                    text-align: right
    .in-table-2
        margin: 0
        tr
            td
                font-size: 16px
                line-height: 22px
                font-weight: variables.$fontWeightBold
                color: variables.$color20
                padding: 10px 0px
                &:first-child
                    font-weight: variables.$fontWeightSemiBold
                &:last-child
                    text-align: right
    .head-2
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 15px
    .package-list-1
        @include short-code.menuFlex1()
        align-items: flex-start
        margin: 0px -6px
        li
            line-height: 1
            width: calc(100% / 4)
            padding: 0px 6px
    .pac-add-btn-1
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightBold
        color: variables.$secondColor
        border: 0
        padding: 0
        margin:
            bottom: 20px
        background-color: transparent
        i
            padding:
                right: 5px
            font-size: 20px
            position: relative
            top: 3px
    .list-div-1
        margin:
            bottom: 3px
        .list-1
            @include short-code.menuFlex1()
            margin: 0px -6px
            li
                line-height: 1
                width: calc(100% / 2)
                padding: 0px 6px
                margin:
                    bottom: 15px
    .s-box-div-1
        max-width: 275px
        width: 100%
        margin:
            bottom: 25px
.cs-card-box-1
    position: relative
    label
        display: flex
        position: relative
        min-height: 80px
        background-color: variables.$whiteColor
        border: 1px solid variables.$color200
        border-radius: variables.$radius5
        padding: 12px 35px 12px 10px
        margin: 0
        cursor: pointer
        .cscb1-col-1
            flex: 0 0 35px
            max-width: 35px
        .cscb1-col-2
            flex: 0 0 calc(100% - 35px)
            max-width: calc(100% - 35px)
            padding:
                left: 10px
    input
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: -1
        opacity: 0
        &:checked, &:not(:checked)
            + label
                &::before
                    content: ''
                    width: 24px
                    height: 24px
                    display: flex
                    align-items: center
                    justify-content: center
                    border-radius: 50%
                    background-color: variables.$color44
                    position: absolute
                    right: 10px
                    top: 15px
                    z-index: 1
                    transition: all .3s ease
        &:checked
            + label
                &::before
                    background-color: variables.$color43
                &::after
                    @include short-code.bainIconCreate('\e947')
                    position: absolute
                    right: 14px
                    top: 22px
                    z-index: 2
                    font-size: 12px
                    color: variables.$whiteColor
    .cscb1-desc-1
        font-size: 24px
        line-height: 1
        color: variables.$color3
        margin: 0
    .cscb1-desc-2
        font-size: 16px
        line-height: 20px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        margin:
            bottom: 5px
    .cscb1-desc-3
        font-size: 16px
        line-height: 20px
        font-weight: variables.$fontWeightRegular
        color: variables.$color11
        margin:
            bottom: 0px
.cs-modal-3
    .modal-dialog
        max-width: 500px
        margin: 3rem auto
        padding: 15px
    .modal-content
        border-radius: variables.$radius3
        padding: 0
        border: 0
    .close
        font-size: 30px
        line-height: 1
        opacity: 1
        color: variables.$color29
        position: absolute
        right: 20px
        top: 20px
        z-index: 2
        text-shadow: none
    .modal-body
        padding: 30px 70px
        border-radius: variables.$radius3
    .modal-head-1
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
        color: variables.$color100
        margin:
            bottom: 25px
    .scroll-modal-a-1
        position: relative
        max-height: 500px
        padding:
            right: 15px
        margin:
            bottom: 20px
        overflow: hidden
        overflow-y: auto
        scrollbar-width: thin
        scrollbar-color: variables.$color17
        &::-webkit-scrollbar
            height: 4px
            width: 4px
        &::-webkit-scrollbar-track
            background: variables.$color17
        &::-webkit-scrollbar-thumb
            background: rgba(114, 135, 253, 0.15)
            border-radius: variables.$radius8
        &::-webkit-scrollbar-thumb
            &:hover
                background: variables.$color3
.cs-modal-4
    .modal-dialog
        max-width: 640px
        margin: 3rem auto
        padding: 15px
    .modal-content
        border-radius: variables.$radius3
        padding: 0
        border: 0
    .close
        font-size: 30px
        line-height: 1
        opacity: 1
        color: variables.$color29
        position: absolute
        right: 20px
        top: 20px
        z-index: 2
        text-shadow: none
    .modal-body
        padding: 30px 50px
        border-radius: variables.$radius3
    .modal-head-1
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
        color: variables.$color100
        margin:
            bottom: 25px
        &.cancel-icon-1
            &::before
                @include short-code.bainIconCreate('\e934')
                display: inline-flex
                margin:
                    right: 15px
                width: 30px
                height: 30px
                border-radius: 50%
                background-color: variables.$color4
                color: variables.$whiteColor
                font-size: 12px
                line-height: 1
                align-items: center
                justify-content: center
                position: relative
                top: -2px
    .modal-ab-icon-b-1
        display: flex
        width: 50px
        height: 50px
        background-color: variables.$color200
        border-radius: 50%
        align-items: center
        justify-content: center
        font-size: 30px
        line-height: 1
        color: variables.$secondColor
        position: absolute
        left: 30px
        top: 30px
    .modal-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color20
        margin:
            bottom: 25px
        padding:
            bottom: 15px
        border-bottom: 1px solid variables.$borderColor
    .modal-desc-2
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color20
        margin:
            bottom: 25px
    .modal-desc-3
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$blackColor
        margin:
            bottom: 15px
    .scroll-modal-a-1
        position: relative
        max-height: 500px
        padding:
            right: 15px
        margin:
            bottom: 20px
        overflow: hidden
        overflow-y: auto
        scrollbar-width: thin
        scrollbar-color: variables.$color17
        &::-webkit-scrollbar
            height: 6px
            width: 6px
        &::-webkit-scrollbar-track
            background: variables.$color17
        &::-webkit-scrollbar-thumb
            background: rgba(114, 135, 253, 0.15)
            border-radius: variables.$radius8
        &::-webkit-scrollbar-thumb
            &:hover
                background: variables.$color3
        h1, h2, h3, h4, h5, h6
            font-size: 22px
            line-height: 30px
            font-weight: variables.$fontWeightBold
            color: variables.$color7
            margin:
                bottom: 25px
        p
            font-size: 16px
            line-height: 22px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        ul, ol
            padding:
                right: 15px
        li
            font-size: 16px
            line-height: 22px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            margin:
                bottom: 15px
        a
            color: variables.$primaryColor
    .bottom-btn-a-1
        text-align: center
        display: none
    .modal-form-short-d-1
        max-width: 375px
        margin: 0 auto
.cs-secondary-btn-3
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 50px
    background-color: variables.$secondColor
    border-radius: variables.$radius7
    min-width: 130px
    font-size: 18px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$whiteColor
    border: 1px solid transparent
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 20px
    &:hover
        color: variables.$secondColor
        background-color: variables.$whiteColor
        border-color: variables.$secondColor
        @include short-code.backgroundShadow4()
.cs-secondary-btn-4
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 50px
    background-color: variables.$secondColor
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    &:hover
        color: variables.$whiteColor
        @include short-code.backgroundShadow6()
.cs-speech-info-box-2
    cursor: pointer
    position: relative
    display: inline-block
    top: 2px
    padding:
        left: 8px
    .icon-a-1
        font-size: 20px
        line-height: 1
        display: block
        color: variables.$color3
    .speech-box-container
        position: absolute
        left: -85px
        top: 0px
        opacity: 0
        visibility: hidden
        transition: all .3s ease
        z-index: 9
        &.active
            opacity: 1
            visibility: visible
        &::before
            content: ''
            position: fixed
            top: 0
            left: 0
            width: 100%
            height: 100%
            z-index: -1
            background-color: variables.$blackColor
            opacity: 0.6
        .speech-box
            min-width: 510px
            background: variables.$whiteColor
            border-radius: variables.$radius5
            padding: 10px 15px
            border: 1px solid variables.$color45
            p
                font-size: 12px
                line-height: 20px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color7
                margin:
                    bottom: 5px
            ul
                @include short-code.menuClear1()
                li
                    position: relative
                    font-size: 11px
                    line-height: 18px
                    font-weight: variables.$fontWeightSemiBold
                    color: variables.$color7
                    margin:
                        bottom: 15px
                    padding:
                        left: 16px
                    &:last-child
                        margin: 0
                    &::before
                        content: ''
                        width: 10px
                        height: 10px
                        background-color: variables.$color37
                        border-radius: 50%
                        position: absolute
                        left: 0
                        top: 4px
                        z-index: 1
    .speech-head-1
        font-size: 14px
        line-height: 20px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 5px
    .speech-close-btn-1
        font-size: 10px
        display: inline-flex
        align-items: center
        justify-content: center
        line-height: 1
        width: 20px
        height: 20px
        padding: 0
        color: variables.$color11
        border: 0
        background-color: transparent
        position: absolute
        right: 15px
        top: 15px
        z-index: 2
.cs-modal-info-div-1
    max-width: 500px
    width: 100%
    margin: 0 auto
    text-align: center
    .icon-text-1
        font-size: 54px
        line-height: 1
        color: variables.$whiteColor
        margin:
            bottom: 30px
        span
            display: inline-flex
            width: 100px
            height: 100px
            align-items: center
            justify-content: center
            background-color: variables.$color4
            border-radius: 50%
        &.type-2
            font-size: 40px
    .csmid1-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color20
        letter-spacing: 0.1px
        margin:
            bottom: 35px
        strong
            font-weight: variables.$fontWeightBold
            color: variables.$color7
    .csmid1-head-1
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
        color: variables.$color100
        margin:
            bottom: 20px
.cs-white-btn-2
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 50px
    background-color: variables.$whiteColor
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 10px
    &:hover
        color: variables.$whiteColor
        background-color: variables.$color7
.cs-lottie-cont-1
    max-width: 470px
    width: 100%
    margin: 0 auto 20px
.cs-order-successful-cont-1
    max-width: 670px
    width: 100%
    margin: 0 auto 30px
    .csosc1-head-1
        font-size: 30px
        line-height: 38px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 15px
    .csosc1-desc-1
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightRegular
        color: variables.$blackColor
        margin:
            bottom: 5px
        strong, a
            color: variables.$secondColor
            font-weight: variables.$fontWeightBold
    .csosc1-row-1
        @include short-code.rowCreate(0px)
        .csosc1r1-col-1
            @include short-code.colCreate(5px, calc(100% - 120px))
        .csosc1r1-col-2
            @include short-code.colCreate(5px, 120px)
.cs-order-successful-info-div-1
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    margin:
        bottom: 30px
    padding: 50px 45px
    .csosid1-image-div-1
        position: relative
        text-align: center
        max-width: 234px
        width: 100%
        &::before
            content: ''
            background-color: variables.$color46
            border-radius: 50%
            height: 100px
            width: 100px
            position: absolute
            z-index: 0
            left: -10px
            top: -10px
        img
            position: relative
            z-index: 1
            margin:
                bottom: 5px
    .csosid1-head-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 10px
    .csosid1-head-2
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color36
        margin:
            bottom: 5px
    .csosid1-head-3
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 15px
    .csosid1-head-4
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color20
        margin:
            bottom: 0px
    .csosid1-c-1
        border-right: 1px solid variables.$borderColor
    .csosid1-info-div-1
        position: relative
        text-align: left
        max-width: 200px
        margin: 0 0 0 auto
        width: 100%
    .csosid1-hr-1
        border-color: variables.$borderColor
        margin: 2rem 0
.cs-account-main-div-1
    background-color: variables.$color42
    padding: 30px 0px
.cs-account-m-row-1
    @include short-code.rowCreate(-15px)
    .csamr1-col-1
        @include short-code.colCreate(15px, 320px)
        margin:
            bottom: 15px
    .csamr1-col-2
        @include short-code.colCreate(15px, calc(100% - 320px))
        margin:
            bottom: 15px
.cs-account-left-div-1
    background-color: variables.$whiteColor
    border-radius: variables.$radius5
    border: 1px solid variables.$color200
    padding: 20px 0px
.cs-account-menu-section-1
    padding: 0px 15px 35px
.cs-account-info-d-1
    text-align: center
    .account-i-1
        position: relative
        display: flex
        align-items: center
        justify-content: center
        width: 100px
        height: 100px
        background-position: center !important
        background-repeat: no-repeat !important
        background-size: cover !important
        margin: 0 auto
        border: 2px solid variables.$color3
        border-radius: 50%
        margin-bottom: 15px
        background-color: variables.$primaryColor
        font-size: 42px
        line-height: 1
        font-weight: variables.$fontWeightBold
        color: variables.$whiteColor
        cursor: pointer
        &::before
            content: ''
            transition: all .3s ease
            width: 100%
            height: 100%
            border-radius: 50%
            opacity: .4
        &::after
            @include short-code.bainIconCreate('\e924')
            transition: all .3s ease
            opacity: 0
            visibility: hidden
            font-size: 30px
            line-height: 1
            color: variables.$whiteColor
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
        &:hover
            &::before
                background-color: variables.$color7
            &::after
                opacity: 1
                visibility: visible
    .account-head-1
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 0px
.cs-account-menu-section-2
    padding: 0px 0px 25px
.cs-account-menu-1
    @include short-code.menuClear1()
    .acc-level-1
        line-height: 1
        padding:
            right: 35px
        margin:
            bottom: 15px
    .acc-level-link-1
        display: block
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        padding: 7px 15px 7px 30px
        display: flex
        min-height: 40px
        align-items: center
        transition: all .3s ease
        &:hover
            color: variables.$primaryColor
        i
            font-size: 25px
            padding:
                right: 8px
            display: inline-block
            width: 35px
            text-align: center
            &.bain-empty-heart
                font-size: 22px
        &.active
            font-weight: variables.$fontWeightBold
            color: variables.$secondColor
            background-color: variables.$color200
            border-radius: 0px variables.$radius8 variables.$radius8 0px
            + .acc-sub-m-d-1
                display: block
    .acc-sub-m-d-1
        padding: 25px 35px 20px
        display: none
    .acc-level-2-m-1
        @include short-code.menuClear1()
        position: relative
        padding:
            left: 22px
        &::before
            content: ''
            width: 2px
            height: 90%
            background-color: variables.$color200
            position: absolute
            left: 0
            top: 50%
            transform: translateY(-50%)
            z-index: 1
        li
            position: relative
            margin:
                bottom: 20px
            &::before
                content: ''
                width: 10px
                height: 10px
                background-color: variables.$color200
                border-radius: 50%
                position: absolute
                top: 6px
                left: -26px
                z-index: 1
            &:last-child
                margin: 0
            a
                display: block
                font-size: 16px
                line-height: 22px
                font-weight: variables.$fontWeightRegular
                color: variables.$color20
                transition: all .3s ease
                &:hover
                    color: variables.$primaryColor
            &.active
                &::before
                    background-color: variables.$secondColor
                a
                    color: variables.$color7
                    font-weight: variables.$fontWeightSemiBold
.cs-account-logout-d-1
    border-top: 1px solid variables.$borderColor
    border-bottom: 1px solid variables.$borderColor
    padding: 23px 30px
    .logout-link-1
        display: block
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color20
        transition: all .3s ease
        &:hover
            color: variables.$color4
.cs-account-menu-section-3
    padding: 0px 23px 10px
.cs-account-menu-section-4
    padding: 10px 23px
.cs-colored-link-box-1
    border: 1px solid variables.$blackColor
    padding: 12px 13px
    text-align: center
    background-color: rgba(0, 0, 0, 0.3)
    border-radius: variables.$radius11
    .cclb1-desc-1
        font-size: 12px
        line-height: 18px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$blackColor
        margin:
            bottom: 10px
    .cclb1-link-1
        display: inline-block
        font-size: 14px
        line-height: 20px
        font-weight: variables.$fontWeightBold
        color: variables.$blackColor
        margin:
            bottom: 0px
        text-decoration: underline !important
    &.color-1
        background-color: rgba(234, 236, 244, 0.3)
        border-color: variables.$color15
        .cclb1-link-1
            color: variables.$secondColor
    &.color-2
        background-color: rgba(246, 253, 253, 0.3)
        border-color: variables.$primaryColor
        .cclb1-link-1
            color: variables.$primaryColor
.cs-account-right-div-1
    background-color: variables.$whiteColor
    border-radius: variables.$radius5
    border: 1px solid variables.$color200
    padding: 25px
    min-height: 100%
.cs-account-form-d-1
    max-width: 690px
    width: 100%
    margin: 0 auto
    .cs-nc-step
        display: none
.cs-account-navs-d-1
    display: flex
    justify-content: center
    margin:
        bottom: 20px
.cs-navTabs-2
    justify-content: space-between
    &.nav-tabs
        border-color: variables.$color23
        .nav-link
            border-radius: 0
            border: 0
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            padding: 15px 20px
            position: relative
            transition: all .3s ease
            &.active
                color: variables.$secondColor
                background-color: transparent
                border-color: unset
                font-weight: variables.$fontWeightBold
                &::after
                    content: ''
                    width: 100%
                    height: 5px
                    border-radius: variables.$radius1
                    background-color: variables.$secondColor
                    position: absolute
                    bottom: -2px
                    left: 50%
                    transform: translateX(-50%)
                    z-index: 1
        .nav-item
            &.show
                color: variables.$secondColor
                background-color: transparent
                border-color: unset
.cs-account-verification-p-1
    font-size: 14px
    line-height: 22px
    font-weight: variables.$fontWeightRegular
    color: variables.$color227
    padding:
        top: 15px
    margin:
        bottom: 15px
    i
        font-size: 20px
        padding:
            right: 5px
        position: relative
        top: 3px
.cs-account-verification-p-2
    font-size: 14px
    line-height: 22px
    font-weight: variables.$fontWeightRegular
    color: variables.$color7
    padding:
        top: 15px
    margin:
        bottom: 15px
    i
        font-size: 20px
        padding:
            right: 5px
        position: relative
        top: 3px
        color: variables.$color4
    a
        color: variables.$color7
        text-decoration: underline !important
        &:hover
            color: variables.$color7
            text-decoration: none !important
.cs-form-info-d-1
    max-width: 475px
    width: 100%
    margin:
        bottom: 20px
    padding:
        bottom: 20px
    border-bottom: 1px solid variables.$color23
    .csfid1-head-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color29
        margin: 0
    span
        display: inline-flex
        align-items: center
        justify-content: center
        width: 30px
        height: 30px
        margin-right: 5px
        background: rgb(39,193,178)
        background: linear-gradient(180deg, rgba(39,193,178,1) 0%, rgba(40,180,181,1) 20%, rgba(46,147,192,1) 40%, rgba(54,94,209,1) 70%, rgba(64,31,229,1) 100%)
        border-radius: 50%
        font-size: 16px
        line-height: 1
        color: variables.$color202
    a
        color: variables.$color29
        &:hover
            color: variables.$color29
.cs-primary-btn-4
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 50px
    background-color: variables.$primaryColor
    border-radius: variables.$radius11
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            right: 10px
        font-size: 24px
    &:hover
        color: variables.$whiteColor
        @include short-code.backgroundShadow5()
.cs-white-btn-3
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 50px
    background-color: variables.$whiteColor
    border-radius: variables.$radius11
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 10px
    &:hover
        color: variables.$whiteColor
        background-color: variables.$color7
.cs-account-btn-d-1
    display: none
.cs-edit-form-d-1
    position: relative
    z-index: 0
    .csefd1-btn-1
        position: absolute
        font-size: 20px
        line-height: 1
        color: variables.$color20
        display: flex
        align-items: center
        justify-content: center
        top: 5px
        right: 5px
        height: 40px
        z-index: 1
        border-radius: variables.$radius12
        width: 40px
        border: 0
        background-color: variables.$whiteColor
.cs-account-approves-div-1
    max-width: 480px
    width: 100%
    margin: 0 auto
    .csaad1-table-1
        margin:
            bottom: 20px
        tr
            td
                border-bottom: 1px solid variables.$color23
                padding: 24px 0px
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color29
                &:last-child
                    text-align: right
    .csaad1-status-desc-1
        margin: 0
        font-weight: variables.$fontWeightRegular
        &.status-1
            color: variables.$color4
            &::after
                @include short-code.bainIconCreate('\e949')
                display: inline-flex
                align-items: center
                justify-content: center
                width: 24px
                height: 24px
                border-radius: 50%
                background-color: variables.$color4
                margin-left: 10px
                position: relative
                top: -1px
                font-size: 10px
                line-height: 1
                color: variables.$whiteColor
        &.status-2
            color: variables.$color38
            &::after
                @include short-code.bainIconCreate('\e947')
                display: inline-flex
                align-items: center
                justify-content: center
                width: 24px
                height: 24px
                border-radius: 50%
                background-color: variables.$color38
                margin-left: 10px
                position: relative
                top: -1px
                font-size: 10px
                line-height: 1
                color: variables.$whiteColor
        &.status-3
            color: variables.$color37

            &::after
                @include short-code.bainIconCreate('\e947')
                display: inline-flex
                align-items: center
                justify-content: center
                width: 24px
                height: 24px
                border-radius: 50%
                background-color: variables.$color37
                margin-left: 10px
                position: relative
                top: -1px
                font-size: 10px
                line-height: 1
                color: variables.$whiteColor

        &.status-4
            color: variables.$color12

            &::after
                @include short-code.bainIconCreate('\e947')
                display: inline-flex
                align-items: center
                justify-content: center
                width: 24px
                height: 24px
                border-radius: 50%
                background-color: variables.$color12
                margin-left: 10px
                position: relative
                top: -1px
                font-size: 10px
                line-height: 1
                color: variables.$whiteColor
.cs-address-empty-d-1
    text-align: center
    padding:
        top: 10px
    img
        margin:
            bottom: 25px
    .csaed1-desc-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color11
        margin:
            bottom: 20px
.cs-single-btn-d-1
    max-width: 320px
    width: 100%
    margin: 0 auto
    padding: 50px 0px
.cs-primary-btn-5
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 50px
    background-color: variables.$primaryColor
    border-radius: variables.$radius11
    min-width: 145px
    font-size: 18px
    line-height: 24px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$whiteColor
    border: 0
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            right: 10px
    &:hover
        color: variables.$whiteColor
        @include short-code.backgroundShadow5()
.cs-address-div-1
    max-width: 680px
    width: 100%
    margin: 0 auto
    padding: 10px 0px 30px
.cs-address-box-1
    margin:
        bottom: 25px
    position: relative
    background-color: variables.$whiteColor
    border: 1px solid variables.$color200
    border-radius: variables.$radius12
    padding: 20px 15px 25px
    @include short-code.rowCreate(-15px)
    align-items: center
    .csab1-col-1
        @include short-code.colCreate(0px, 40px)
        text-align: center
    .csab1-col-2
        @include short-code.colCreate(15px, calc(100% - 80px))
    .csab1-col-3
        @include short-code.colCreate(0px, 40px)
    .csab1-icon-1
        font-size: 30px
        line-height: 1
        color: rgba(114, 135, 253, 0.5)
        margin:
            bottom: 0px
    .csab1-head-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 12px
    .csab1-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color11
        margin:
            bottom: 0px
    .csab1-action-m-1
        @include short-code.menuClear1()
        li
            line-height: 1
            margin:
                bottom: 15px
            &:last-child
                margin: 0
            button
                font-size: 20px
                line-height: 1
                display: flex
                align-items: center
                justify-content: center
                color: variables.$color20
                width: 100%
                height: 30px
                padding: 0
                border: 0
                background-color: transparent
                transition: all .3s ease
                &:hover
                    color: variables.$secondColor
.cs-account-mobile-d-1
    padding: 0px 30px 20px
    .csamd1-btn-1
        display: flex
        align-items: center
        width: 100%
        text-align: left
        border-radius: 12px
        min-height: 40px
        border: 0
        padding: 5px 30px
        font-size: 18px
        line-height: 22px
        font-weight: variables.$fontWeightBold
        color: variables.$whiteColor
        background-color: variables.$secondColor
        position: relative
        &::after
            @include short-code.bainIconCreate('\e95e')
            transition: all .3s ease
            font-size: 12px
            line-height: 1
            position: absolute
            right: 30px
            top: 50%
            transform: translateY(-50%) rotate(0deg)
            z-index: 1
        &.active
            &::after
                transform: translateY(-50%) rotate(180deg)
.cs-card-empty-d-1
    text-align: center
    padding:
        top: 10px
    img
        margin:
            bottom: 25px
    .csced1-desc-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color11
        margin:
            bottom: 20px
.cs-account-head-1
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    margin:
        bottom: 30px
.cs-card-box-2
    margin:
        bottom: 25px
    position: relative
    background-color: variables.$whiteColor
    border: 1px solid variables.$color200
    border-radius: variables.$radius12
    padding: 15px 10px 20px
    @include short-code.rowCreate(0px)
    align-items: center
    .csab2-col-1
        @include short-code.colCreate(0px, 40px)
        text-align: center
    .csab2-col-2
        @include short-code.colCreate(15px, calc(100% - 80px))
    .csab2-col-3
        @include short-code.colCreate(0px, 40px)
    .csab2-icon-1
        font-size: 30px
        line-height: 1
        color: rgba(114, 135, 253, 0.5)
        margin:
            bottom: 0px
    .csab2-head-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 12px
    .csab2-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color11
        margin:
            bottom: 0px
    .csab2-action-m-1
        @include short-code.menuClear1()
        li
            line-height: 1
            margin:
                bottom: 15px
            &:last-child
                margin: 0
            button
                font-size: 24px
                line-height: 1
                display: flex
                align-items: center
                justify-content: center
                color: variables.$color20
                width: 100%
                height: 30px
                padding: 0
                border: 0
                background-color: transparent
                transition: all .3s ease
                &:hover
                    color: variables.$secondColor
.cs-global-congratulations-d-1
    background-color: variables.$whiteColor
    padding: 25px 0px 100px
    .csgcd1-lottie-cont-1
        max-width: 400px
        width: 100%
        margin: 0 auto 20px
    .csgcd1-lottie-cont-2
        max-width: 400px
        width: 100%
        margin: 0 auto 20px
    .csgcd1-text-cont-1
        padding: 20px 0px
        text-align: center
    .csgcd1-head-1
        font-size: 40px
        line-height: 48px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 20px
    .csgcd1-desc-1
        font-size: 24px
        line-height: 32px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        margin:
            bottom: 10px
    .csgcd1-lottie-d-1
        position: relative
        text-align: center
        max-width: 750px
        margin: 0 auto
        .csgcd1-lottie-cont-1
            position: absolute
            top: 50%
            left: 60%
            transform: translate(-50%, -50%)
            z-index: 1
        .csgcd1-lottie-cont-2
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            z-index: 1
.cs-upload-container-1
    position: relative
    margin: 0 auto 50px
    max-width: 400px
    width: 100%
    border-radius: variables.$radius3
    border: 2px dashed variables.$primaryColor
    text-align: center
    input
        cursor: pointer
        opacity: 0
        // visibility: hidden
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 0
    label
        cursor: pointer
        display: block
        margin: 0
        width: 100%
        height: 100%
        padding: 60px 25px 20px
    .csuc1-desc-1
        font-size: 64px
        line-height: 1
        color: variables.$color32
        margin:
            bottom: 20px
    .csuc1-desc-2
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        margin:
            bottom: 8px
        span
            font-weight: variables.$fontWeightSemiBold
            color: variables.$secondColor
            text-decoration: underline
        strong
            font-weight: variables.$fontWeightBold
    .csuc1-desc-3
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color20
        opacity: 0.5
        margin:
            bottom: 20px
    .csuc1-desc-4
        font-size: 14px
        line-height: 22px
        font-weight: variables.$fontWeightRegular
        color: variables.$color20
        margin:
            bottom: 0px
    .jsInfoTextUp1
        &.error
            color: variables.$color4
        &.successful
            color: variables.$secondColor
    .jsFileUploadInputInfoT1
        &.error
            color: variables.$color4
        &.successful
            color: variables.$secondColor
.cs-global-upload-btn-1
    position: relative
    border-radius: variables.$radius11
    display: block
    background-color: variables.$primaryColor
    width: 200px
    margin: 0 auto
    svg
        display: none
        position: absolute
        left: 50%
        top: 50%
        margin: -15px 0 0 -15px
        fill: variables.$whiteColor
    .csgub1-anime-d-1
        height: 4px
        margin: -2px 0 0 0
        position: absolute
        top: 50%
        left: 72px
        right: 25px
        background-color: variables.$color47
        display: none
        border-radius: 2px
        span
            position: absolute
            background-color: variables.$primaryColor
            height: 6px
            left: 0
            top: 0
            width: 0
            display: block
            border-radius: variables.$radius11
    a
        position: relative
        display: block
        background-color: variables.$primaryColor
        z-index: 2
        line-height: 50px
        height: 50px
        border-radius: variables.$radius11
        width: 100%
        text-align: center
        font-size: 18px
        font-weight: variables.$fontWeightBold
        color: variables.$color13
        &:hover
            color: variables.$color13
        span
            cursor: pointer
            display: block
    &.active
        border: 1px solid variables.$color3
        background: rgb(117,137,245)
        background: linear-gradient(90deg, rgba(117,137,245,0.21) 0%, rgba(205,210,239,0.28) 30%, rgba(255,255,255,1) 100%)
        border-radius: variables.$radius10
        a
            border-radius: variables.$radius10
            background-color: variables.$secondColor
.cs-global-croppie-d-1
    width: 300px
    margin: 0 auto
    &.croppie-container
        .cr-boundary
            border: 2px dashed variables.$primaryColor
        .cr-slider-wrap
            width: 100%
            margin: 30px auto 15px
.cs-global-croppie-step-2
    display: none
.cs-account-head-2
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color29
    margin:
        bottom: 15px
.cs-global-add-btn-1
    font-size: 16px !important
    line-height: 22px
    font-weight: variables.$fontWeightBold !important
    color: variables.$secondColor !important
    border: 0
    padding: 0
    margin:
        bottom: 20px
    background-color: transparent
    i
        padding:
            right: 5px
        font-size: 20px
        position: relative
        top: 3px
.cs-file-box-1
    margin:
        bottom: 25px
    position: relative
    background-color: variables.$whiteColor
    border: 1px solid variables.$color200
    border-radius: variables.$radius12
    padding: 20px 15px 25px
    @include short-code.rowCreate(-15px)
    align-items: center
    .csfb1-col-1
        @include short-code.colCreate(0px, 40px)
        text-align: center
    .csfb1-col-2
        @include short-code.colCreate(15px, calc(100% - 80px))
    .csfb1-col-3
        @include short-code.colCreate(0px, 40px)
    .csfb1-icon-1
        font-size: 30px
        line-height: 1
        color: rgba(114, 135, 253, 0.5)
        margin:
            bottom: 0px
    .csfb1-head-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 12px
    .csfb1-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color11
        margin:
            bottom: 0px
    .csfb1-action-m-1
        @include short-code.menuClear1()
        li
            line-height: 1
            margin:
                bottom: 15px
            &:last-child
                margin: 0
            button
                font-size: 20px
                line-height: 1
                display: flex
                align-items: center
                justify-content: center
                color: variables.$color20
                width: 100%
                height: 30px
                padding: 0
                border: 0
                background-color: transparent
                transition: all .3s ease
                &:hover
                    color: variables.$secondColor
    .csfb1-pdf-d-1
        border: 0.5px solid variables.$borderColor
        border-radius: variables.$radius12
        min-height: 40px
        background-position: center !important
        background-repeat: no-repeat !important
        background-size: cover !important
        canvas
            width: 100%
            border-radius: variables.$radius12
.cs-account-earning-d-1
    min-height: 54px
    padding: 12px 10px
    min-width: 250px
    border-radius: 0px 0px variables.$radius8 variables.$radius8
    background-color: variables.$color200
    position: absolute
    top: 0
    right: 7rem
    z-index: 1
    @include short-code.backgroundShadow11()
    .csaed1-desc-1
        font-size: 16px
        line-height: 30px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        margin: 0
        span
            display: inline-flex
            width: 30px
            height: 30px
            align-items: center
            justify-content: center
            background-color: variables.$whiteColor
            border-radius: 50%
            font-size: 12px
            line-height: 1
            color: variables.$secondColor
            margin:
                right: 5px
        strong
            font-weight: variables.$fontWeightBold
.select2-container--open
    .cs-global-select2-dd-3
        &.select2-dropdown--below
            border: 0.5px solid variables.$borderColor
            border-radius: variables.$radius11
            background-color: variables.$whiteColor
            margin-top: 10px
            padding: 10px 20px
            @include short-code.backgroundShadow10()
        &.select2-dropdown--above
            border: 0.5px solid variables.$borderColor
            border-radius: variables.$radius11
            background-color: variables.$whiteColor
            margin-top: 10px
            padding: 10px 20px
            @include short-code.backgroundShadow10()
.cs-global-select2-dd-3
    .select2-results__option
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        padding: 10px 0px
        border: 0
        border-bottom: 1px solid rgba(237, 237, 237, 0.5)
        &:last-child
            border: 0
.select2-container--default
    .cs-global-select2-dd-3
        .select2-results__option--highlighted
            &.select2-results__option--selectable
                background-color: variables.$whiteColor
                color: variables.$color7
        .select2-results__option--selected
            background-color: variables.$whiteColor
            color: variables.$color7
            font-weight: variables.$fontWeightBold
            position: relative
            &::after
                @include short-code.bainIconCreate('\e947')
                font-size: 14px
                color: variables.$primaryColor
                position: absolute
                right: 10px
                top: 50%
                transform: translateY(-50%)
        .select2-search--dropdown
            position: relative
            padding: 0
            margin:
                bottom: 15px
            &::before
                @include short-code.bainIconCreate('\e94e')
                position: absolute
                left: 12px
                top: 50%
                transform: translateY(-50%)
                z-index: 0
                pointer-events: none
                color: variables.$color15
            .select2-search__field
                border: 1px solid rgba(114, 135, 253, 0.5)
                border-radius: 10px
                padding-left: 30px
                &:focus
                    outline: none
                    box-shadow: none
        .select2-results > .select2-results__options
            scrollbar-width: thin
            scrollbar-color: variables.$color17
            &::-webkit-scrollbar
                height: 4px
                width: 4px
            &::-webkit-scrollbar-track
                background: variables.$color17
            &::-webkit-scrollbar-thumb
                background: rgba(114, 135, 253, 0.15)
                border-radius: variables.$radius8
            &::-webkit-scrollbar-thumb
                &:hover
                    background: variables.$color3
.cs-global-select2-container-3
    label
        display: block
        cursor: pointer
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color29
        margin:
            bottom: 12px
        text-align: left
    ::placeholder
        color: variables.$color30
    .select2-container--default
        &.select2-container--open
            .select2-selection--single
                .select2-selection__arrow
                    b
                        &::after
                            @include short-code.bainIconCreate('\e95c')
            &.select2-container--below
                .select2-selection--single, .select2-selection--multiple
                    border-radius: variables.$radius5
            &.select2-container--above
                .select2-selection--single, .select2-selection--multiple
                    border-radius: variables.$radius5
        .select2-selection--single, .select2-selection--multiple
            cursor: pointer
            background-color: variables.$whiteColor
            border: 0.5px solid variables.$color20
            border-radius: variables.$radius5
            height: 50px
            padding: 10px 0px
            overflow: hidden
        .select2-selection--single
            .select2-selection__arrow
                height: 25px
                position: absolute
                top: 12px
                right: 20px
                width: 14px
            .select2-selection__arrow
                b
                    &::after
                        @include short-code.bainIconCreate('\e95e')
                        font-size: 8px
                        color: variables.$color20
            .select2-selection__rendered
                font-size: 16px
                line-height: 26px
                font-weight: variables.$fontWeightRegular
                color: variables.$color7
                padding: 12px 25px
            .select2-selection__placeholder
                color: variables.$color7
                opacity: 0.5
        .select2-selection--multiple
            .select2-selection__placeholder
                color: variables.$color7
                opacity: 0.5
        .select2-search--inline
            .select2-search__field
                cursor: pointer
                margin: 2px 0px
                color: variables.$color7
                font-size: 15px
                line-height: 20px
                font-weight: variables.$fontWeightRegular
                padding: 0px 20px
                position: absolute
                top: 45%
                transform: translateY(-50%)
                &::placeholder
                    color: variables.$color7
                    font-size: 15px
                    line-height: 20px
                    font-weight: variables.$fontWeightRegular
                    padding: 0
    .select2-container
        .select2-selection--multiple
            .select2-selection__rendered
                display: inline-flex
                margin: 0
    .select2-container--default
        .select2-selection--multiple
            .select2-selection__choice__remove
                display: none
            .select2-selection__choice
                margin: 0 5px 0px 0px
                padding: 0
                border: 0
                background-color: transparent
                &::after
                    content: ','
                &:first-child
                    margin:
                        left: 20px
                &:last-child
                    margin:
                        right: 10px
                    &::after
                        display: none
            .select2-selection__choice__display
                padding: 0px
    .selection
        position: relative
        &::after
            @include short-code.bainIconCreate('\e95e')
            font-size: 8px
            color: variables.$color20
            position: absolute
            right: 1px
            top: 50%
            transform: translateY(-50%)
            pointer-events: none
            background-color: variables.$whiteColor
            height: 30px
            width: 50px
            display: flex
            align-items: center
            justify-content: center
    .select2-container--open
        .selection
            &::after
                @include short-code.bainIconCreate('\e95c')
.cs-hr-1
    border-color: variables.$color23
    margin:
        bottom: 25px
        top: 25px
.cs-global-upload-cont-1
    max-width: 510px
    width: 100%
    margin:
        bottom: 30px
.cs-iw-desc-1
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color11
    margin:
        bottom: 20px
    display: flex
    align-items: baseline
    i
        color: variables.$color3
        font-size: 23px
        position: relative
        top: 5px
        padding:
            right: 10px
.cs-uploaded-box-1
    position: relative
    border-radius: variables.$radius11
    border: 1px dashed variables.$primaryColor
    margin:
        bottom: 15px
    min-height: 195px
    background-position: center !important
    background-repeat: no-repeat !important
    background-size: cover !important
    &::before
        content: ''
        border-radius: variables.$radius11
        background-color: rgba(4, 22, 20, 0.6)
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        z-index: 1
    .csub1-btn-1
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        border: 0
        padding: 0
        width: 30px
        height: 30px
        position: absolute
        right: 10px
        bottom: 10px
        z-index: 3
        background-color: variables.$color202
        font-size: 18px
        line-height: 1
        color: variables.$secondColor
    input
        position: absolute
        top: 0
        left: 0
        z-index: -1
        opacity: 0
        visibility: hidden
.cs-checkbox-toggle-1
    height: 30px
    display: inline-block
    .switch
        display: inline-block
        height: 28px
        position: relative
        width: 60px
        margin: 0
        input
            display: none
    .slider
        background-color: #B2C1C7
        bottom: 0
        cursor: pointer
        left: 0
        position: absolute
        right: 0
        top: 0
        transition: 0.4s
        &::before
            background-color: variables.$whiteColor
            content: ''
            height: 24px
            left: 3px
            position: absolute
            transition: 0.4s
            width: 24px
            top: 2px
        &.round
            border-radius: 20px
            &::before
                border-radius: 50%
    input
        &:checked +
            .slider
                background-color: variables.$primaryColor
                &::before
                    transform: translateX(30px)
.cs-toggle-desc-1
    display: inline-block
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightRegular
    color: variables.$color11
    position: relative
    top: -8px
    padding-left: 10px
.cs-account-form-d-2
    max-width: 760px
    width: 100%
    margin: 0 auto
.cs-back-page-d-1
    padding: 10px 0px 20px
    .csbpd1-text-1
        display: inline-block
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        i
            font-size: 15px
            position: relative
            top: 1px
            margin:
                right: 10px
.cs-earnings-info-div-1
    border-radius: variables.$radius8
    padding: 16px
    background: rgb(39,193,178)
    background: linear-gradient(180deg, rgba(39,193,178,0.05) 0%, rgba(40,180,181,0.05) 20%, rgba(46,147,192,0.05) 40%, rgba(54,94,209,0.05) 80%, rgba(64,31,229,0.05) 100%)
    @include short-code.rowCreate(-10px)
    justify-content: center
    text-align: center
    margin:
        bottom: 50px
    .cseid1-col-1
        @include short-code.colCreate(10px, 25%)
    .cseid1-head-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color11
        margin:
            bottom: 8px
    .cseid1-desc-1
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
        color: variables.$color11
        margin: 0
.cs-doughnut-container-1
    position: relative
    max-width: 400px
    width: 100%
    margin: 0 auto
    padding: 30px 0px 50px
.cs-favorites-d-1
    text-align: center
    padding:
        top: 10px
    img
        margin:
            bottom: 25px
    .csfd1-desc-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color11
        margin:
            bottom: 35px
    .csfd1-btn-d-1
        max-width: 250px
        width: 100%
        margin: 0 auto 30px
.cs-secondary-btn-5
    display: flex
    position: relative
    width: 100%
    min-height: 45px
    padding: 5px 10px
    align-items: center
    justify-content: center
    border-radius: variables.$radius4
    font-size: 16px
    line-height: 22px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color13
    background-color: variables.$secondColor
    border: 1px solid transparent
    transition: all .3s ease
    i
        font-size: 18px
        padding:
            left: 15px
        position: relative
        top: 0px
    &:hover
        border-color: variables.$color12
        background-color: variables.$whiteColor
        @include short-code.backgroundShadow4()
        color: variables.$secondColor
.cs-account-navs-d-2
    display: flex
    justify-content: center
    margin:
        bottom: 30px
.cs-fake-navTabs-1
    justify-content: space-between
    @include short-code.menuFlex1()
    border-bottom: 1px solid variables.$color23
    line-height: 1
    a
        display: block
        border-radius: 0
        border: 0
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        padding: 15px 20px
        position: relative
        transition: all .3s ease
        &:hover
            color: variables.$color7
        &.active
            color: variables.$secondColor
            background-color: transparent
            border-color: unset
            &::after
                content: ''
                width: 100%
                height: 5px
                border-radius: variables.$radius1
                background-color: variables.$secondColor
                position: absolute
                bottom: -2px
                left: 50%
                transform: translateX(-50%)
                z-index: 1
.cs-rentals-cont-1
    max-width: 480px
    width: 100%
    margin: 0 auto
.cs-rentals-info-1
    margin:
        bottom: 15px
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    padding: 12px 0px 24px
    &.type-1
        padding:
            bottom: 0px
        .csri1-table-1
            margin:
                bottom: 0px
            tr
                &:last-child
                    td
                        border: 0
    .csri1-table-1
        margin:
            bottom: 15px
        td
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color7
            border-bottom: 1px solid variables.$borderColor
            padding: 12px 24px
            &:first-child
                font-weight: variables.$fontWeightRegular
                color: variables.$color36
            &:last-child
                text-align: right
    .csri1-countdown-1
        max-width: 260px
        margin: 0 auto
        border-radius: variables.$radius8
        background-color: variables.$blackColor
        text-align: center
        display: flex
        align-items: center
        justify-content: center
        padding: 7px 12px 7px 32px
        position: relative
        min-height: 36px
        font-size: 16px
        line-height: 20px
        font-weight: variables.$fontWeightBold
        color: variables.$whiteColor
        &::before
            @include short-code.bainIconCreate('\e913')
            font-size: 22px
            position: absolute
            left: 8px
            top: 50%
            transform: translateY(-50%)
            z-index: 1
        &.type-1
            background-color: variables.$color38
            @include short-code.backgroundShadow12()
        &.type-2
            background-color: variables.$color224
            @include short-code.backgroundShadow13()
    .csri1-result-d-1
        background-color: variables.$primaryColor
        text-align: center
        border-radius: 0 0 variables.$radius8 variables.$radius8
        padding: 16px 10px
        .csri1rd1-t-1
            display: block
            font-size: 16px
            line-height: 22px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color11
            &::before
                content: ''
                display: inline-block
                width: 22px
                height: 22px
                background-color: variables.$secondColor
                border-radius: 50%
                position: relative
                top: 5px
                margin:
                    right: 10px
        &.completed
            background-color: variables.$color225
            .csri1rd1-t-1
                color: variables.$color11
                &::before
                    background-color: variables.$color226
        &.cancelled
            background-color: variables.$color223
            .csri1rd1-t-1
                color: variables.$color4
                &::before
                    background-color: variables.$color4
.cs-rentals-info-desc-1
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightRegular
    color: variables.$color11
    margin:
        bottom: 15px
    position: relative
    padding:
        left: 24px
    &::before
        @include short-code.bainIconCreate('\e946')
        color: variables.$color3
        font-size: 21px
        position: absolute
        left: 0
        top: 6px
    img
        max-height: 38px
.cs-acc-bot-btn-mar-1
    margin:
        bottom: 100px
.cs-account-bottom-btn-d-1
    padding: 23px 15px
    background-color: variables.$whiteColor
    margin-top: -90px
    position: relative
    z-index: 1
    text-align: center
    @include short-code.backgroundShadow14()
    .csabbd1-btn-1
        margin: 0 auto
        max-width: 250px
.cs-account-head-3
    font-size: 18px
    line-height: 26px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    margin:
        bottom: 25px
.cs-rentals-details-d-1
    margin:
        bottom: 30px
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    padding: 12px 0px 24px
    &.type-1
        padding:
            bottom: 0px
        .csri1-table-1
            margin:
                bottom: 0px
            tr
                &:last-child
                    td
                        border: 0
    .csrdd1-table-1
        margin:
            bottom: 20px
        td
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color7
            border-bottom: 1px solid variables.$borderColor
            padding: 12px 24px
            &:first-child
                font-weight: variables.$fontWeightRegular
                color: variables.$color36
            &:last-child
                text-align: right
    .csrdd1-countdown-1
        max-width: 260px
        margin: 0 20px
        border-radius: variables.$radius8
        background-color: variables.$blackColor
        text-align: center
        display: flex
        align-items: center
        justify-content: center
        padding: 7px 12px 7px 32px
        position: relative
        min-height: 36px
        font-size: 16px
        line-height: 20px
        font-weight: variables.$fontWeightBold
        color: variables.$whiteColor
        &::before
            @include short-code.bainIconCreate('\e913')
            font-size: 22px
            position: absolute
            left: 8px
            top: 50%
            transform: translateY(-50%)
            z-index: 1
        &.type-1
            background-color: variables.$color38
            @include short-code.backgroundShadow12()
        &.type-2
            background-color: variables.$color224
            @include short-code.backgroundShadow13()
        &.type-3
            background-color: variables.$secondColor
    .csrdd1-result-d-1
        padding: 0px 25px
    .csrdd1-result-desc-1
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color11
        margin: 0
        &::before
            content: ''
            display: inline-block
            width: 22px
            height: 22px
            background-color: variables.$secondColor
            border-radius: 50%
            position: relative
            top: 5px
            margin:
                right: 10px
        &.completed
            color: variables.$color226
            &::before
                background-color: variables.$color226
        &.cancelled
            color: variables.$color4
            &::before
                background-color: variables.$color4
.cs-rentals-payment-d-1
    margin:
        bottom: 35px
    background-color: variables.$color13
    padding: 5px 25px 20px
    .csrpd1-table-1
        margin:
            bottom: 0px
        tr
            td
                padding: 8px 0px
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color7
                &:last-child
                    text-align: right
            &:last-child
                td
                    border-top: 1px dashed variables.$color20
                    font-weight: variables.$fontWeightBold
                    padding:
                        top: 15px
            &:nth-last-child(2)
                td
                    padding:
                        bottom: 15px
.cs-action-input-menu-1
    @include short-code.menuClear1()
    li
        line-height: 1
        margin:
            bottom: 25px
.cs-check-input-2
    position: relative
    input
        cursor: pointer !important
        position: absolute !important
        left: 0 !important
        top: 0 !important
        opacity: 0 !important
        &:checked, &:not(:checked)
            + label
                position: relative !important
                padding-right: 35px !important
                padding-left: 0 !important
                cursor: pointer !important
                font-size: 14px !important
                line-height: 25px !important
                color: variables.$color11 !important
                display: block !important
                left: 0 !important
                top: 0 !important
                margin: 0 !important
        &:not(:checked)
            + label
                &:before
                    content: ''
                    position: absolute
                    right: 0px
                    top: 1px
                    width: 24px
                    height: 24px
                    border: 1px solid variables.$color207
                    background: variables.$whiteColor
                    border-radius: variables.$radius13
        &:checked
            + label
                &:before
                    content: ''
                    position: absolute
                    right: 0px
                    top: 1px
                    width: 24px
                    height: 24px
                    border: 1px solid variables.$primaryColor
                    background: variables.$primaryColor
                    border-radius: variables.$radius13
        &:checked
            + label
                &:after
                    @include short-code.bainIconCreate('\e947')
                    position: absolute
                    right: 4px
                    top: 8px
                    font-size: 12px
                    line-height: 1
                    color: variables.$whiteColor
.cs-icon-row-1
    @include short-code.rowCreate(-15px)
    margin:
        bottom: 40px
    .csir1-col-1
        @include short-code.colCreate(15px, 25%)
        border-right: 1px solid variables.$color204
        &:last-child
            border-right: 0
.cs-icon-box-2
    text-align: center
    .img-div-1
        margin:
            bottom: 15px
        display: flex
        align-items: center
        justify-content: center
        min-height: 52px
        img
            max-height: 52px
    .csib2-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color20
        margin:
            bottom: 10px
    .csib2-desc-2
        font-size: 22px
        line-height: 30px
        font-weight: variables.$fontWeightBold
        color: variables.$color11
        margin:
            bottom: 0px
.cs-iw-desc-2
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color11
    margin:
        bottom: 20px
    display: flex
    align-items: baseline
    i
        color: variables.$color20
        font-size: 23px
        position: relative
        top: 2px
        padding:
            right: 10px
.cs-rentals-timeout-d-1
    position: relative
    padding: 16px 0px
    background-color: variables.$color13
    margin:
        bottom: 20px
    &::before, &::after
        content: ''
        background-color: variables.$color13
        height: 100%
        position: absolute
        width: 25px
        top: 0
        z-index: 1
    &::before
        left: -25px
    &::after
        right: -25px
.cs-global-table-cont-1
    max-width: 350px
.cs-global-table-1
    margin: 0
    tr
        td
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightRegular
            color: variables.$color11
            padding: 10px 0px
            &:last-child
                text-align: right
                font-weight: variables.$fontWeightBold
.cs-iw-link-1
    font-size: 16px
    line-height: 22px
    font-weight: variables.$fontWeightBold
    color: variables.$secondColor
    display: inline-flex
    align-items: center
    justify-content: center
    padding: 0
    border: 0
    background-color: transparent
    i
        display: inline-flex
        justify-content: center
        align-items: center
        width: 30px
        height: 30px
        border-radius: 50%
        background-color: variables.$color200
        margin:
            right: 10px
    span
        text-decoration: underline
    &:hover
        color: variables.$secondColor
        span
            text-decoration: none
.cs-hr-2
    border-color: variables.$borderColor
    margin:
        bottom: 25px
        top: 25px
.cs-rating-box-1
    display: block
    width: 100%
    height: 35px
    margin:
        bottom: 20px
    .rating
        border: none
        float: left
        margin: 0
        padding: 0
        > input
            display: none
        > label
            color: variables.$color48
            float: right
            margin: 0 !important
            &:before
                @include short-code.bainIconCreate('\e94d')
                margin: 5px
                font-size: 30px
                display: inline-block
        > .half
            &:before
                content: '\e94c'
                position: absolute
        > input:checked ~ label, &:not(:checked) > label:hover, &:not(:checked) > label:hover ~ label
            color: variables.$color49
        > input:checked + label:hover, > input:checked ~ label:hover, > label:hover ~ input:checked ~ label,  > input:checked ~ label:hover ~ label
            color: variables.$color49
.cs-navTabs-3
    justify-content: space-between
    &.nav
        margin:
            bottom: 30px
    &.nav-tabs
        border-color: variables.$color23
        .nav-link
            border-radius: 0
            border: 0
            font-size: 16px
            line-height: 22px
            font-weight: variables.$fontWeightRegular
            color: variables.$color7
            padding: 10px 15px
            position: relative
            transition: all .3s ease
            &.active
                color: variables.$primaryColor
                background-color: transparent
                border-color: unset
                font-weight: variables.$fontWeightBold
                &::after
                    content: ''
                    width: 80px
                    height: 5px
                    border-radius: variables.$radius1
                    background-color: variables.$primaryColor
                    position: absolute
                    bottom: -2px
                    left: 50%
                    transform: translateX(-50%)
                    z-index: 1
        .nav-item
            &.show
                color: variables.$primaryColor
                background-color: transparent
                border-color: unset
.cs-rentals-doc-d-1
    max-width: 400px
    margin: 0 auto
.jsRentalsDocsModalStep-2
    display: none
.cs-rentals-modal-doc-cont-1
    text-align: center
    .csrmdc1-img-1
        margin:
            bottom: 35px
    .csrmdc1-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color11
        margin:
            bottom: 60px
.cs-account-cars-row-1
    @include short-code.rowCreate(-8px)
    margin:
        bottom: 10px
    .csacr1-col-1
        @include short-code.colCreate(8px, calc(100% - 455px))
        margin:
            bottom: 20px
    .csacr1-col-2
        @include short-code.colCreate(8px, 220px)
        margin:
            bottom: 10px
    .csacr1-col-3
        @include short-code.colCreate(8px, 235px)
        margin:
            bottom: 10px
.cs-secondary-btn-6
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 50px
    background-color: variables.$color200
    border-radius: variables.$radius2
    min-width: 145px
    font-size: 18px
    line-height: 24px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$secondColor
    border: 0
    padding: 5px 10px
    transition: all .3s ease
    i
        padding:
            right: 10px
    &:hover
        color: variables.$secondColor
        @include short-code.backgroundShadow15()
.cs-account-cars-search-1
    position: relative
    max-width: 260px
    i
        position: absolute
        font-size: 25px
        line-height: 1
        color: variables.$color20
        left: 15px
        top: 50%
        transform: translateY(-50%)
        pointer-events: none
    input
        padding:
            left: 50px !important
.cs-account-cars-status-m-1
    @include short-code.menuFlex1()
    justify-content: center
    margin:
        bottom: 10px
    li
        line-height: 1
        margin:
            right: 20px
            bottom: 15px
    button
        position: relative
        display: flex
        align-items: center
        justify-content: center
        min-height: 36px
        background-color: variables.$whiteColor
        border: 1px solid variables.$color48
        border-radius: variables.$radius2
        min-width: 110px
        padding: 5px
        &.active
            background-color: variables.$color17
        &.type-1
            &::before
                content: ''
                width: 22px
                height: 22px
                border-radius: 50%
                background-color: variables.$color50
                margin:
                    right: 8px
        &.type-2
            &::before
                content: ''
                width: 22px
                height: 22px
                border-radius: 50%
                background-color: variables.$color51
                margin:
                    right: 8px
        &.type-3
            &::before
                content: ''
                width: 22px
                height: 22px
                border-radius: 50%
                background-color: variables.$color201
                margin:
                    right: 8px
        &.type-4
            &::before
                content: ''
                width: 22px
                height: 22px
                border-radius: 50%
                background-color: variables.$color52
                margin:
                    right: 8px
        &.type-5
            &::before
                content: ''
                width: 22px
                height: 22px
                border-radius: 50%
                background-color: variables.$color34
                margin:
                    right: 8px
.cs-account-cars-row-2
    @include short-code.rowCreate(-11px)
    .csacr2-col-1
        @include short-code.colCreate(11px, calc(100% / 3))
.cs-cars-box-1
    position: relative
    min-height: 220px
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    padding: 35px 15px
    margin:
        bottom: 30px
    .cscb1-head-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 8px
    .cscb1-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color36
        margin:
            bottom: 8px
        @include short-code.textAfterDots(72px, 72px, 3)
    .cscb1-desc-2
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 0px
        background-color: variables.$color41
        border-radius: variables.$radius8
        min-height: 36px
        display: inline-flex
        align-items: center
        justify-content: center
        padding: 5px 15px
        max-width: 160px
        width: 100%
    &::before
        content: ''
        background-color: variables.$color41
        border-radius: 50%
        width: 22px
        height: 22px
        position: absolute
        right: 15px
        top: 15px
        z-index: 0
    &.status-1
        &::before
            background-color: variables.$color50
    &.status-2
        &::before
            background-color: variables.$color51
    &.status-3
        &::before
            background-color: variables.$color201
    &.status-4
        &::before
            background-color: variables.$color52
    &.status-5
        &::before
            background-color: variables.$color34
.cs-iw-head-1
    font-size: 16px
    line-height: 22px
    font-weight: variables.$fontWeightBold
    color: variables.$secondColor
    border: 0
    padding: 0
    margin:
        bottom: 25px
    background-color: transparent
    i
        padding:
            right: 5px
        font-size: 20px
        position: relative
        top: 3px
.cs-iwb-head-1
    position: relative
    font-size: 16px
    line-height: 22px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    margin:
        bottom: 24px
    padding:
        bottom: 12px
        right: 26px
    border-bottom: 1px solid variables.$color23
    &.active
        &::after
            @include short-code.bainIconCreate('\e947')
            display: flex
            align-items: center
            justify-content: center
            width: 26px
            height: 26px
            border-radius: 50%
            background-color: variables.$color53
            font-size: 12px
            line-height: 1
            color: variables.$whiteColor
            position: absolute
            right: 0
            top: 0
            z-index: 1
.cs-global-desc-3
    font-size: 16px
    line-height: 23px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color11
    margin:
        bottom: 25px
.cs-modal-5
    .modal-dialog
        max-width: 980px
        margin: 3rem auto
        padding: 15px
    .modal-content
        border-radius: variables.$radius3
        padding: 0
        border: 0
    .close
        font-size: 30px
        line-height: 1
        opacity: 1
        color: variables.$color29
        position: absolute
        right: 20px
        top: 20px
        z-index: 2
        text-shadow: none
    .modal-body
        padding: 30px 70px
        border-radius: variables.$radius3
    .cs-damage-btn-group
        margin: 30px auto
        max-width: 310px
    .modal-header
        padding-bottom: 0 !important
        border-bottom: 0 !important
    .modal-footer
        justify-content: center
    #damage_area_modal_label
        color: variables.$color100
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
        text-align: center
        margin-bottom: 15px
    .cs-hasar-bolgesi-main
        background-size: cover
        max-width: 410px
        width: 100%
        height: 652px
        background-repeat: no-repeat
        display: flex
        flex-wrap: wrap
        border: 1px solid black
        margin: 0 auto
        .form-check
            padding: 0
            width: 30%
            border-bottom: 1px solid variables.$secondColor
            &:nth-child(3n+2)
                width: 40% !important
                border-top: none
                border-right: 1px solid variables.$secondColor
                border-left: 1px solid variables.$secondColor
                border-bottom: 1px solid variables.$secondColor
            &:nth-child(n+7)
                border-bottom: none
            .cs-choose-check
                display: none
            label
                height: 100%
                width: 100%
                cursor: pointer
            .form-check-input
                display: none
                cursor: pointer
                &:checked
                    &+label
                        background: rgba(39, 193, 178, 0.5)
                        background: linear-gradient(180deg, rgba(39, 193, 178, 0.5) 0%, rgba(40, 180, 181, 0.5) 19%, rgba(46, 147, 192, 0.5) 38%, rgba(54, 94, 209, 0.5) 71%, rgba(64, 31, 229, 0.5) 100%)
                        .cs-choose-check
                            display: block
                            display: flex
                            flex-direction: column
                            align-items: center
                            justify-content: center
                            position: absolute
                            left: 50%
                            top: 65%
                            transform: translate(-50%,-50%)
                            .cs-cc-text
                                font-weight: variables.$fontWeightBold
                                font-size: 16px
                                line-height: 24px
                                color: variables.$whiteColor
.cs-cars-upload-list-1
    @include short-code.rowCreate(-7px)
    .cscul1-col-1
        @include short-code.colCreate(7px, 20%)
.cs-cars-upload-box-1
    position: relative
    margin:
        bottom: 14px
    border-radius: variables.$radius11
    background-position: center !important
    background-repeat: no-repeat !important
    background-size: cover !important
    height: 120px
    transition: all .3s ease
    &::before
        content: ''
        border-radius: variables.$radius11
        background-color: variables.$color7
        opacity: 0.6
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        width: 100%
        transition: all .3s ease
        opacity: 0
        visibility: hidden
    .cscub1-delete-btn-1
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        z-index: 1
        visibility: hidden
        opacity: 0
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        width: 30px
        height: 30px
        background-color: variables.$color202
        font-size: 15px
        line-height: 1
        color: variables.$secondColor
        border: 0
    &:hover
        &::before
            visibility: visible
            opacity: 0.6
        .cscub1-delete-btn-1
            visibility: visible
            opacity: 1
.cs-rentals-info-head-1
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    margin:
        bottom: 15px
    position: relative
    padding:
        left: 32px
    &::before
        @include short-code.bainIconCreate('\e946')
        color: variables.$color3
        font-size: 21px
        position: absolute
        left: 0
        top: 0px
.cs-rentals-info-desc-2
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    color: variables.$color20
    margin:
        bottom: 25px
.cs-cars-work-plain-cont-1
    max-width: 1010px
    width: 100%
    margin: 0 auto
.cs-cars-wp-breadcrumb-1
    ul
        @include short-code.menuFlex1()
        margin:
            bottom: 5px
        padding:
            top: 10px
        li
            margin:
                bottom: 10px
            font-size: 18px
            line-height: 24px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color7
            &::after
                content: ''
                background-color: variables.$color48
                width: 1px
                height: 20px
                display: inline-block
                margin:
                    right: 20px
                    left: 15px
                position: relative
                top: 4px
            &:last-child
                &::after
                    display: none
            a
                font-size: 18px
                line-height: 24px
                font-weight: variables.$fontWeightBold
                color: variables.$color7
                &:hover
                    color: variables.$color7
.cs-cars-wp-box-1
    @include short-code.rowCreate(-15px)
    align-items: center
    padding: 35px 30px
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    margin:
        bottom: 30px
    .cscwb1-col-1
        @include short-code.colCreate(15px, 230px)
        text-align: center
    .cscwb1-col-2
        @include short-code.colCreate(15px, calc(100% - 470px))
    .cscwb1-col-3
        @include short-code.colCreate(15px, 240px)
    .cscwb1-head-1
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 12px
    .cscwb1-desc-1
        font-size: 16px
        line-height: 22px
        font-weight: variables.$fontWeightRegular
        color: variables.$color36
        margin:
            bottom: 0px
    .cscwb1-price-1
        font-size: 18px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 0px
        min-height: 36px
        display: inline-flex
        align-items: center
        justify-content: center
        background-color: variables.$color41
        border-radius: variables.$radius8
        padding: 5px 10px
        min-width: 160px
        &::after
            content: ''
            background-color: variables.$color41
            width: 22px
            height: 22px
            position: absolute
            right: 20px
            top: 50%
            transform: translateY(-50%)
            border-radius: 50%
        &.status-1
            &::after
                background-color: variables.$color50
        &.status-2
            &::after
                background-color: variables.$color51
        &.status-3
            &::after
                background-color: variables.$color201
        &.status-4
            &::after
                background-color: variables.$color52
.cs-cars-wp-empty-div-1
    text-align: center
    margin:
        bottom: 45px
    img
        width: auto
        max-height: 230px
        margin:
            bottom: 40px
    .cscwed1-desc-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color11
        margin:
            bottom: 45px
    .cscwed1-btn-1
        max-width: 310px
        width: 100%
.cs-cars-wp-list-box-1
    margin:
        bottom: 25px
    position: relative
    background-color: variables.$whiteColor
    border: 1px solid variables.$color200
    border-radius: variables.$radius5
    padding: 10px 15px 10px
    @include short-code.rowCreate(0px)
    align-items: center
    .cscwlb1-col-1
        @include short-code.colCreate(0px, 40px)
        text-align: center
    .cscwlb1-col-2
        @include short-code.colCreate(15px, calc(100% - 80px))
    .cscwlb1-col-3
        @include short-code.colCreate(0px, 40px)
    .cscwlb1-icon-1
        font-size: 30px
        line-height: 1
        color: variables.$secondColor
        margin:
            bottom: 0px
    .cscwlb1-head-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        margin:
            bottom: 0px
    .cscwlb1-desc-1
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color11
        margin:
            bottom: 0px
    .cscwlb1-action-m-1
        @include short-code.menuClear1()
        li
            line-height: 1
            margin:
                bottom: 5px
            &:last-child
                margin: 0
            button
                font-size: 20px
                line-height: 1
                display: flex
                align-items: center
                justify-content: center
                color: variables.$color20
                width: 100%
                height: 30px
                padding: 0
                border: 0
                background-color: transparent
                transition: all .3s ease
                &:hover
                    color: variables.$secondColor
            a
                font-size: 20px
                line-height: 1
                display: flex
                align-items: center
                justify-content: center
                color: variables.$color20
                width: 100%
                height: 30px
                padding: 0
                border: 0
                background-color: transparent
                transition: all .3s ease
                &:hover
                    color: variables.$secondColor
.cs-cars-wp-head-1
    font-size: 18px
    line-height: 26px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    margin:
        bottom: 30px
.cs-cars-wp-row-1
    @include short-code.rowCreate(-10px)
    .cscwpr1-col-1
        @include short-code.colCreate(10px, 50%)
        margin:
            bottom: 20px
    .cscwpr1-col-2
        @include short-code.colCreate(10px, 50%)
        margin:
            bottom: 20px
.cs-cars-wp-btn-a-1
    max-width: 310px
    margin: 0 auto 50px
    padding:
        top: 50px
.cs-cars-wp-c-box-1
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    padding: 25px
    height: 100%
    .cscwpcb1-in-1
        max-width: 320px
        margin: 0 auto
    .cscwpcb1-br-d-1
        border-top: 1px solid variables.$borderColor
        padding:
            top: 35px
        margin:
            top: 35px
.cs-cars-wp-c-box-2
    background-color: variables.$color13
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    padding: 10px 15px 0px
    height: 100%
.cs-cars-wp-table-1
    thead
        th
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightBold
            color: variables.$color11
            padding: 20px 10px
            vertical-align: middle
    tbody
        td
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$color11
            padding: 20px 10px
            vertical-align: middle
            position: relative
    .activeDayTimeClass1
        position: absolute
        left: 50%
        top: 50%
        width: calc(100% - 20px)
        transform: translate(-50%, -50%)
        z-index: -1
        opacity: 0
    .time-add-btn-1
        display: flex
        align-items: center
        justify-content: center
        font-size: 22px
        line-height: 1
        color: variables.$color201
        width: 35px
        height: 30px
        border: 0
        background-color: transparent
    .table-ii-1
        font-size: 22px
        line-height: 1
        color: variables.$primaryColor
        display: block
        text-align: center
        padding:
            right: 10px
    input[type='time']
        background: transparent
        border: none
        outline: none
.cs-check-input-3
    position: relative
    min-height: 30px
    input
        cursor: pointer !important
        position: absolute !important
        left: 0 !important
        top: 0 !important
        opacity: 0 !important
        &:checked, &:not(:checked)
            + label
                position: relative !important
                padding-left: 35px !important
                padding-right: 0 !important
                cursor: pointer !important
                font-size: 14px !important
                line-height: 25px !important
                color: variables.$color11 !important
                display: inline-block !important
                left: 0 !important
                top: 0 !important
                margin:
                    bottom: 15px !important
        &:not(:checked)
            + label
                &:before
                    content: ''
                    position: absolute
                    left: 0px
                    top: 0px
                    width: 24px
                    height: 24px
                    border: 1px solid variables.$color207
                    background: variables.$whiteColor
                    border-radius: variables.$radius13
        &:checked
            + label
                &:before
                    content: ''
                    position: absolute
                    left: 0px
                    top: 0px
                    width: 24px
                    height: 24px
                    border: 1px solid variables.$primaryColor
                    background: variables.$whiteColor
                    border-radius: variables.$radius13
        &:checked
            + label
                &:after
                    @include short-code.bainIconCreate('\e947')
                    position: absolute
                    left: 5px
                    top: 6px
                    font-size: 12px
                    line-height: 1
                    color: variables.$primaryColor
.daterangepicker
    &.mnt-type-2
        border-radius: variables.$radius8
        .drp-calendar
            &.left
                padding:
                    right: 8px
        .drp-buttons
            text-align: center
.cs-cars-wp-c-box-3
    background-color: variables.$whiteColor
    border-radius: variables.$radius8
    @include short-code.backgroundShadow9()
    padding: 25px
    height: 100%
    .cscwpcb3-in-1
        max-width: 320px
        margin: 0 auto
.cs-cars-wp-form-d-1
    .empty
        border-color: variables.$color4 !important
.cs-cars-wp-form-d-2
    display: none
.cs-soon-div-1
    background-position: center !important
    background-repeat: no-repeat !important
    background-size: cover !important
    min-height: 100vh
    height: 100%
    background-blend-mode: multiply
    display: flex
    align-items: flex-end
    flex-wrap: wrap
    padding:
        top: 2%
.cs-soon-container-1
    max-width: 1390px
    width: 100%
    margin: 0 auto
    padding:
        left: 15px
        right: 15px
.cs-soon-top-s-1
    text-align: center
    margin:
        bottom: 100px
    img
        margin:
            bottom: 50px
        max-height: 160px
    .head-1
        font-size: 80px
        line-height: 90px
        font-weight: variables.$fontWeightExtraBold
        color: variables.$whiteColor
        letter-spacing: 5px
        margin:
            bottom: 0px
.cs-soon-row-1
    @include short-code.rowCreate(-15px)
    align-items: center
    .cssr1-col-1
        @include short-code.colCreate(15px, calc(100% - 480px))
    .cssr1-col-2
        @include short-code.colCreate(15px, 480px)
.cs-soon-text-con-1
    max-width: 580px
.cs-soon-head-1
    font-size: 44px
    line-height: 52px
    font-weight: variables.$fontWeightExtraBold
    color: variables.$whiteColor
    margin:
        bottom: 25px
.cs-soon-desc-1
    font-size: 22px
    line-height: 30px
    font-weight: variables.$fontWeightRegular
    color: variables.$whiteColor
    margin:
        bottom: 25px
.cs-soon-image-m-1
    @include short-code.menuFlex1()
    li
        margin:
            bottom: 15px
            right: 8px
        &:last-child
            margin:
                right: 0px
        img
            max-height: 44px
            min-height: 44px
.cs-file-name-item-1,.cs-file-name-item-1::placeholder
    border: none
    color: #041614 !important
    font-size: 16px !important
    font-weight: 700 !important
    line-height: 24px !important
    margin-bottom: 12px !important
    padding: 0 !important
.cs-file-name-item-1:focus-visible,.cs-file-name-item-1:focus
    border: none !important
    outline: none !important
    box-shadow: none !important
.cs-vale-form-c-1
    max-width: 700px
    padding: 30px 15px
    position: relative
    width: 100%
    margin: 0 auto 0 0
    z-index: 1
    height: 100%
    display: flex
    align-items: center
    flex-wrap: wrap
    .form-d-1
        max-width: 490px
        margin: 0 auto
        .cs-wbg-head-3
            text-align: center
    .form-d-2
        max-width: 320px
        margin: 0 auto
        .cs-global-document-upload-1
            width: 100%
        .form-input-1
            padding:
                top: 10px
                bottom: 10px
        .cs-primary-btn-3
            opacity: .5
            pointer-events: none
            &.active
                cursor: pointer
                opacity: 1!important
                pointer-events: visible!important

    .form-d-3
        margin: 0 auto
        .form-input-1
            padding:
                top: 50px
                bottom: 50px
        .cs-vale-main-1
            .cs-vale-select-1
                border: 1px solid variables.$primaryColor
                border-radius: variables.$radius3
                padding: 7px
                position: relative
                text-align: center
                .cs-vale-title
                    color: variables.$blackColor
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightSemiBold
                .cs-vale-desc
                    color: variables.$blackColor
                    font-size: 14px
                    line-height: 24px
                    font-weight: variables.$fontWeightRegular
                .cs-vale-icon-1
                    border: 1px solid variables.$primaryColor
                    border-radius: 50%
                    display: flex
                    justify-content: center
                    align-items: center
                    padding: 5px 8px
                    width: max-content
                    position: absolute
                    bottom: -14px
                    left: 50%
                    transform: translateX(-50%)
                    background: variables.$whiteColor
                    i
                        color : variables.$primaryColor
            .cs-vale-select-2
                border: 1px solid variables.$secondColor
                border-radius: variables.$radius3
                padding: 7px
                position: relative
                text-align: center
                .cs-vale-title
                    color: variables.$blackColor
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightSemiBold
                .cs-vale-desc
                    color: variables.$blackColor
                    font-size: 14px
                    line-height: 24px
                    font-weight: variables.$fontWeightRegular
                .cs-vale-icon-2
                    border: 1px solid variables.$secondColor
                    border-radius: 50%
                    display: flex
                    justify-content: center
                    align-items: center
                    padding: 5px 8px
                    width: max-content
                    position: absolute
                    bottom: -14px
                    left: 50%
                    transform: translateX(-50%)
                    background: variables.$whiteColor
                    i
                        color : variables.$secondColor
.cs-vale-info
    margin: 0 auto
    max-width: 450px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    min-height: 75vh
    gap: 20px
    text-align: center
    .cs-desc-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightSemiBold
    .cs-link-1
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightRegular
        color: variables.$color34
        text-decoration: underline !important
    .cs-link-2
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightRegular
        color: variables.$color4
        text-decoration: underline !important

//cookies and privacy policy

.cs-global-area-1
    .cs-cookie-fly-d-1
        background-color: variables.$secondColor
        padding: 15px 50px 0px
        position: fixed
        bottom: 0
        left: 0
        right: 0
        width: 100%
        z-index: 20
        -webkit-box-shadow: 0px -3px 6px 0px rgba(0,0,0,0.2)
        -moz-box-shadow: 0px -3px 6px 0px rgba(0,0,0,0.2)
        box-shadow: 0px -3px 6px 0px rgba(0,0,0,0.2)
        text-align: center
        opacity: 1
        visibility: visible
        transition: all .2s ease
        &.deactive
            opacity: 0
            visibility: hidden
        .desc-1
            font-size: 13px
            line-height: 20px
            font-weight: 500
            color: variables.$whiteColor
            margin-bottom: 10px
            a
                color: variables.$primaryColor
                font-weight: 600
                text-decoration: underline
        .btn-menu-1
            list-style: none
            padding: 0
            margin: 0
            display: flex
            align-items: center
            justify-content: center
            li
                line-height: 1
                margin-bottom: 15px
                padding: 0px 10px
        .cookies-btn-1
            display: flex
            align-items: center
            justify-content: center
            min-height: 28px
            background-color: transparent
            border: 1px solid variables.$whiteColor
            border-radius: 40px
            padding: 5px 15px
            color: variables.$whiteColor
            font-size: 12px
            line-height: 14px
            font-weight: 700
            transition: all .2s ease
            &:hover
                background-color: variables.$whiteColor
                color: variables.$secondColor
        .cookies-btn-2
            display: flex
            align-items: center
            justify-content: center
            min-height: 28px
            background-color: variables.$primaryColor
            border: 1px solid variables.$primaryColor
            border-radius: 40px
            padding: 5px 15px
            color: #ffffff
            font-size: 12px
            line-height: 14px
            font-weight: 700
            transition: all .2s ease
            &:hover
                background-color: transparent
                color: variables.$primaryColor
    .cs-modal-7
        .modal-content
            border: none
            border-radius: variables.$radius11
            .cs-wbg-head-2
                font-size: 25px
                span
                    &::before
                        content: none
            .modal-body
                padding: 0 25px 25px

                .cs-desc-1
                    color: variables.$blackColor
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightSemiBold
.cs-language-select
    border: none
    align-items: center
    background-color: #f0edff
    border: 1px solid transparent
    border-radius: 50%
    color: #401fe5
    display: flex
    font-size: 24px
    height: 45px
    justify-content: center
    line-height: 1
    position: relative
    transition: all .3s ease
    width: 100%
    appearance: none
    -webkit-appearance: none
    -moz-appearance: none
    text-align: center
    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible
        outline: none
    &:hover
        background-color: #fff
        border-color: #3f22e4
        color: #3f22e4

.scroll-modal-a-1
    table
        width: 100% !important
.cs-rentals-info-wrapper
    .cs-iwb-head-1
        display: flex
        justify-content: space-between
        align-items: center
        span
            font-size: 20px
            text-transform: capitalize
        .cs-delete-btn
            color: variables.$primaryColor
            &:hover
                color: variables.$secondColor
            i
                font-size: 20px
.calendar-fully
    pointer-events: none

    &::after
        border-radius: 50%
        content: ""
        display: block
        height: 7px
        left: 50%
        position: absolute
        bottom: -2px
        transform: translateX(-50%)
        width: 7px
        background: variables.$color4
.calendar-half
    &::after
        border-radius: 50%
        content: ""
        display: block
        height: 7px
        left: 50%
        position: absolute
        bottom: -2px
        transform: translateX(-50%)
        width: 7px
        background: variables.$color37
.calendar-invalid
    pointer-events: none
    &::after
        border-radius: 50%
        content: ""
        display: block
        height: 7px
        left: 50%
        position: absolute
        bottom: -2px
        transform: translateX(-50%)
        width: 7px
        background: variables.$color19
.calendar-scroll
    &::-webkit-scrollbar-track
        border-radius:  variables.$radius1
        background-color: transparent
    &::-webkit-scrollbar
        width: 4px
        background-color: transparent
    &::-webkit-scrollbar-thumb
        border-radius: variables.$radius1
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
        background-color: transparent
.leaflet-popup-content-wrapper
    .leaflet-popup-content
        .cs-car-box-1
            box-shadow: none
            margin: 0
            .img-div-1
                img
                    height: 210px
            .cscb-top-area-1
                padding: 0
            .cscb-bottm-area-1
                .desc-2
                    padding: 5px
            .info-div-1
                padding: 5px
            .info-drow-1
                .idr1-col-1
                    margin-bottom: 5px
            p
                margin: 0
                margin-bottom: 5px
