@use 'variables'
@use 'short-code'

.cs-global-breadcrumb-1
    padding: 35px 0
    .breadcrumb
        background: transparent
        padding: 0
        margin:0
        .breadcrumb-item
            &+.breadcrumb-item
                &::before
                    content: "|"
                    color: variables.$color7
                &:last-child
                    &::before
                        color: variables.$primaryColor
            .cs-breadcrumb-link
                color: variables.$color7
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                i
                    font-size: variables.$fontSizeLarge
            &.active
                color: variables.$primaryColor
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
.cs-about-us-sec-1
    .cs-logo-text-title-1
        font-size: 30px
        line-height: 38px
        font-weight: variables.$fontWeightExtraBold
        color: variables.$secondColor
        span
            color: variables.$primaryColor
    .cs-desc-1
        font-size: 20px
        line-height: 28px
        color: variables.$color20
    .cs-about-us-content-wrapper-1
        padding: 25px 0
        .cs-about-us-content-item-1
            display: flex
            padding: 10px 0
            gap: 17px
            .cs-about-us-imagebox-1
                flex: 0 0 388px
                max-width: 388px
                width: 100%
                border-radius: variables.$radius100
                img
                    width: 100%
                    border-radius: variables.$radius100
            .cs-about-us-textbox-1
                .cs-desc-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$blackColor
                    font-weight: variables.$fontWeightRegular
.cs-banner-sec-1
    .cs-banner-wrapper
        height: 315px
        background-size: cover
        background-repeat: no-repeat
        display: flex
        justify-content: center
        align-items: center
        position: relative
        .cs-aub-banner-1
            max-width: 100% !important
        .cs-banner-textbox-1
            text-align: center
            max-width: 650px
            width: 100%
            .cs-title-1
                font-size: 40px
                line-height: 48px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$whiteColor
            .cs-desc-1
                font-size: 30px
                line-height: 38px
                color: variables.$whiteColor
.cs-banner-socialbox-1
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: 0
    width: 195px
    height: 45px
    background-color: rgba(64, 31, 229, 0.871)
    border-radius: variables.$radius101 variables.$radius101 0px 0px
    padding: 0 20px
    display: flex
    justify-content: space-between
    align-items: center
    i
        color: variables.$whiteColor
        font-size: 16px
        line-height: 24px
.cs-career-sec-1
    .cs-career-content-wrapper-1
        padding: 25px 0
        .cs-career-item-1
            display: flex
            padding: 10px 0
            gap: 17px
            align-items: center
            .cs-career-imagebox-1
                flex: 0 0 388px
                max-width: 388px
                width: 100%
                border-radius: variables.$radius100
                img
                    width: 100%
                    border-radius: variables.$radius100
            .cs-career-textbox-1
                .cs-desc-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$blackColor
                    font-weight: variables.$fontWeightRegular
.cs-career-sec-2
    .cs-career-advert-wrapper
        width: 100%
        background-color: variables.$color13
        padding: 30px 0
        margin: 30px 0
        .cs-career-advert-itembox-1
            display: flex
            gap: 30px
            justify-content: center
            .cs-career-advert-item
                display: block
                position: relative
                width: 310px
                padding: 15px 20px
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
                background: variables.$whiteColor
                border-radius: variables.$radius100
                display: flex
                flex-direction: column
                align-items: center
                .cs-item-top-area
                    width: 130px
                    height: 13px
                    background-color: variables.$secondColor
                    border-radius:0px 0px  variables.$radius8 variables.$radius8
                    position: absolute
                    left: 50%
                    top: 0
                    transform: translateX(-50%)
                .cs-title-1
                    font-size: 20px
                    line-height: 28px
                    font-weight: variables.$fontWeightBold
                    color: variables.$color11
                .cs-desc-1
                    font-size: 18px
                    line-height: 26px
                    font-weight: variables.$fontWeightRegular
                    color: variables.$color11
                    margin: 0
                .cs-item-info-1
                    display: flex
                    width: 100%
                    justify-content: space-between
                    margin: 25px 0 0px
                    .cs-desc-2
                        font-size: 18px
                        line-height: 26px
                        font-weight: variables.$fontWeightBold
                        color: variables.$secondColor
                        margin: 0
                    span
                        &.cs-desc-1
                            text-align: right
                        .cs-desc-1
                            font-size: 13px
.cs-career-detail-sec-1
    margin-bottom: 150px
    .cs-career-detail-main-1
        display: flex
        align-items: flex-start
        gap: 30px
        padding-right: 8.5%
    .cs-career-detail-text-wrapper
        flex: 1
        .cs-cd-text-top-1
            display: flex
            justify-content: space-between
            align-items: center
            .cs-item-info-1
                display: contents
                .cs-desc-2
                    font-size: 18px
                    line-height: 26px
                    font-weight: variables.$fontWeightBold
                    color: variables.$secondColor
                    margin: 0
                span
                    &.cs-desc-1
                        text-align: right
                    .cs-desc-1
                        font-size: 13px
        .cs-textbox-1
            .cs-desc-1
                font-size: 16px
                line-height: 24px
                color: variables.$blackColor
                font-weight: variables.$fontWeightRegular
            .cs-text-list-1
                padding: 1rem
                li
                    &::marker
                        content: ' - '
                        font-size: 1.2em
    .cs-career-detail-inputs-1
        flex: 1
        .cs-cd-apply-1
            margin: 0 auto
            max-width: 365px
            .cs-form-1
                input[type='checkbox']
                    height: auto
                .cs-form-check-text-1
                    font-size: 14px
                    line-height: 22px
                    color: variables.$color11
                    .cs-form-check-text-link-1
                        color: variables.$color11
                        text-decoration: underline !important
.cs-upload-file-1
    color: variables.$secondColor
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightBold
    display: flex
    align-items: center
    gap: 10px
    &:hover
        color: variables.$primaryColor
        .cs-square-plus
            border: 2px solid variables.$primaryColor
    .cs-square-plus
        display: flex
        justify-content: center
        align-items: center
        border: 2px solid variables.$secondColor
        padding: 1px 1px
        border-radius: variables.$radius102
        i
            font-size: 12px

.cs-half-banner-1
    background: rgb(39,193,178)
    background: linear-gradient(180deg, rgba(39,193,178,1) 0%, rgba(40,180,181,1) 18%, rgba(46,147,192,1) 38%, rgba(54,94,209,1) 66%, rgba(64,31,229,1) 100%)
    position: relative
    flex: 1
    border-radius: 0px variables.$radius10 0px 0px
    padding: 50px 0
    .cs-textbox-1
        display: flex
        flex-direction: column
        align-items: center
        gap: 15px
        .cs-title-1
            font-size: 40px
            line-height: 48px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$whiteColor
        .cs-desc-1
            font-size: 30px
            line-height: 38px
            font-weight: variables.$fontWeightSemiBold
            color: variables.$whiteColor
        .cs-item-info-1
            display: flex
            justify-content: space-between
            gap: 30px
            .cs-desc-1
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$whiteColor
.cs-dd-modal-file-1
    .modal-title
        color: variables.$color100
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
    .modal-content
        border-radius: variables.$radius9
        padding-bottom: 32px
    .cs-modal-input-wrapper
        max-width: 397px
        height: 368px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        border: 2px dashed variables.$primaryColor
        border-radius: variables.$radius3
        margin: 0 auto
        position: relative
        .cs-dd-modal-text
            position: absolute
            display: flex
            flex-direction: column
            justify-content: center
            gap: 10px
            align-items: center
            text-align: center
            padding: 1rem
            width: 100%
            height: 100%
            .cs-desc-1
                color: variables.$color7
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightRegular
                .cs-desc-strong-1
                    color: variables.$color7
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightBold
                .cs-upload-label-1
                    text-decoration: underline !important
                    color: variables.$secondColor
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightBold
                    cursor: pointer
            .cs-desc-2
                color: variables.$color20
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightRegular
            .cs-desc-3
                color: variables.$color20
                font-size: 14px
                line-height: 22px
                font-weight: variables.$fontWeightRegular
            .cs-file-name-text
                color: variables.$secondColor
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightBold
        input[type='file']
            width: 100%
            height: 100%
            opacity: 0
            position: relative
            z-index: 2
            cursor: pointer

.cs-file-info-js-1
    display: none
    white-space: nowrap
    align-items: center
    .cs-file-name-text
        color: variables.$color7
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
    .cs-file-reset-btn-1
        border: none
        background: none
.cs-form-flexbox-1
    display: flex
    gap: 10px
    .cs-upload-file-1
        white-space: nowrap
    .cs-file-name-text
        display: inline-block
        max-width: 17ch
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
.cs-popular-search
    padding: 30px 0
    display: flex
    justify-content: space-between
    align-items: center
    .cs-ps-item
        display: flex
        justify-content: center
        align-items: center
        background-color: variables.$whiteColor
        border-radius: variables.$radius3
        padding: 5px 12px
        color: variables.$color7
        font-size: 14px
        line-height: 22px
        font-weight: variables.$fontWeightSemiBold
        transition: all 0.3s
        &.active
            color: variables.$primaryColor
        &:hover
            background: variables.$primaryColor
            color: variables.$whiteColor
            transition: all 0.3s
    .cs-desc-1
        margin: 0
        font-size: 18px !important
        line-height: 26px !important
        font-weight: variables.$fontWeightSemiBold
.cs-faq-sec-1
    padding: 50px 0
.cs-form-subtitle
    color: variables.$color29
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    border-bottom: 1px solid variables.$color29
    padding-bottom: 10px
.cs-contact-us-sec-1
    .cs-contact-us-main
        padding: 30px 0
        .cs-contact-us-wrapper
            display: flex
            .cs-contact-us-imagebox-1
                flex: 0 0 50%
                img
                    width: 100%
        .cs-contact-us-form-wrapper
            width: 100%
            display: flex
            justify-content: center
            .cs-form-1
                max-width: 350px
                width: 100%
            .cs-arabanipaylas-line
                position: absolute
                left: -30px
                top: 45%
                z-index: 0
                max-width: max-content
            .cs-full-input
                width: 100%
    .cs-form-login-1
        border: 1px solid variables.$primaryColor
        border-radius: variables.$radius8
        padding: 16px
        .cs-desc-1
            color: variables.$color20
            font-size: 14px
            line-height: 22px
            font-weight: variables.$fontWeightSemiBold
            margin: 0
        .cs-form-login-top
            display: flex
            justify-content: space-between
            align-items: center
            .cs-desc-1
                color: variables.$color7
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                margin: 0

.cs-primary-link-1
    color: variables.$primaryColor
    font-size: 18px
    line-height: 26px
    font-weight: variables.$fontWeightBold
    text-decoration: underline !important
    text-transform: capitalize
    &:hover
        transition: all 0.3s linear
        color: variables.$secondColor
        text-decoration: underline !important

.cs-primary-select2-1
    .select2-container
        &--default
            .select2-selection
                &--single
                    .select2-selection__arrow
                        top: 10px !important

        .selection
            .select2-selection--single
                border: 0.5px solid variables.$color11
                border-radius: variables.$radius11
                color: variables.$color7
                font-size: 16px
                font-weight: 400
                height: 50px
                line-height: 24px
                padding: 5px 20px
                transition: all .2s ease
                display: flex
                align-items: center
.cs-select2-dd-1
    border-radius: variables.$radius11 !important
    transform: translateY(5px)
    padding: 20px 0
    border: none !important
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
    color: variables.$color9
    .select2-results
        border-radius: variables.$radius11
        &__options
            .select2-results__option--highlighted
                &.select2-results__option--selected
                    background-color: variables.$whiteColor
                    color: variables.$color9
                    position: relative
                    &::after
                        @include short-code.fontAwesomeCreate('\f00C')
                        display: block
                        position: absolute
                        right: 20px
                        top: 50%
                        transform: translateY(-50%)
                        color: variables.$primaryColor
                &.select2-results__option--selectable
                    background-color: variables.$color22
                    color: variables.$blackColor
    .select2-results__option
        padding: 16px 20px 10px
        border-bottom: 1px solid variables.$color26
        &--selected
            background-color: variables.$whiteColor
            position: relative
            &::after
                @include short-code.fontAwesomeCreate('\f00C')
                display: block
                position: absolute
                right: 20px
                top: 50%
                transform: translateY(-50%)
                color: variables.$primaryColor
.cs-car-detail-sec-1
    .cs-car-detail-main-1
        display: flex
        gap: 100px
        margin-bottom: 50px
        .cs-cd-infobox-1
            background: variables.$whiteColor
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
            position: relative
            border-radius: variables.$radius4
            display: flex
            flex-direction: column
            padding: 15px 20px
            .cs-form-1
                .date-box-1
                    input
                        max-width: 350px
                        width: 100% !important
            .cs-price-1
                padding: 0 15px
                height: 60px
                background: variables.$secondColor
                border-radius: 0px variables.$radius4 0px variables.$radius4
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                position: absolute
                top: 0
                right: 0
                .cs-desc-1
                    color: variables.$whiteColor
                    font-size: 20px
                    font-weight: variables.$fontWeightBold
                    line-height: 28px
                    margin: 0
                .cs-desc-2
                    color: variables.$whiteColor
                    font-size: 14px
                    font-weight: variables.$fontWeightRegular
                    line-height: 22px
                    margin: 0
            .cs-favorite-1
                a
                    color: variables.$color103
            .cs-info-status-1
                border-bottom: 1px solid variables.$borderColor
                .cs-title-1
                    color: variables.$color7
                    font-size: 18px
                    line-height: 26px
                    font-weight: variables.$fontWeightBold
                .cs-desc-1
                    color: variables.$color101
                    font-size: 18px
                    line-height: 26px
                    font-weight: variables.$fontWeightSemiBold
                .cs-status-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color102
                    font-weight: variables.$fontWeightRegular
                    text-transform: capitalize
                    margin-left: 10px
            .cs-cd-feature-1
                display: flex
                justify-content: space-between
                padding: 16px 0
                border-bottom: 1px solid variables.$borderColor
                gap: 10px
                .cs-desc-1
                    color: variables.$color20
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightSemiBold
                    margin: 0
                    display: flex
                    align-items: center
                    gap: 10px
                    position: relative
                    i
                        font-size: 24px
                        color: variables.$blackColor
                    .cs-cd-info-hover
                        position: absolute
                        top: -15px
                        left: 15%
                        i
                            font-size: 12px
            .cs-cd-rules-1
                display: flex
                gap: 16px
                padding: 16px 0
                border-bottom: 1px solid variables.$borderColor
                .cs-rules-item-1
                    width: 165px
                    height: 45px
                    background-color: variables.$color104
                    color: variables.$color7
                    display: flex
                    justify-content: center
                    align-items: center
                    gap: 5px
                    border-radius: variables.$radius5
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightSemiBold
                    i
                        color: variables.$secondColor
            .cs-cd-list-wrapper
                padding: 16px 0
                border-bottom: 1px solid variables.$borderColor

                .cs-cd-list-1
                    list-style: none !important
                    padding: 0
                    li:not(.collapse)
                        display: flex
                        justify-content: space-between
                        margin-bottom: 10px
                        .cs-desc-1
                            color: variables.$color7
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightSemiBold
                        .cs-desc-2
                            color: variables.$color20
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightSemiBold
                            .bain-star
                                color: variables.$color27
                                margin: 0 5px
                        .bain-angle-up
                            font-size: 10px

                        &.cs-list-item-1
                            display: flex
                            flex-direction: column
                    .cs-collapse-list

                        .cs-desc-1
                            color: variables.$color201 !important
                            font-size: 14px !important
                            line-height: 22px !important
                            font-weight: variables.$fontWeightSemiBold
                            text-align: center
                            margin-top: 10px
                    .cs-list-map-1
                        width: 100%
                        height: 175px
                        border-radius: variables.$radius11
            .cs-cd-loan-wrapper
                padding: 16px 0
                border-bottom: 1px solid variables.$borderColor
                .cs-loan-options
                    display: flex
                    justify-content: space-between
                    flex-wrap: wrap
                    gap: 10px
                    .cs-loan-item-1
                        background-color: variables.$color104
                        color: variables.$color7
                        display: flex
                        flex-direction: column
                        justify-content: center
                        align-items: flex-start
                        gap: 5px
                        border-radius: variables.$radius5
                        font-size: 16px
                        line-height: 24px
                        font-weight: variables.$fontWeightSemiBold
                        padding: 8px
                        width: 120px
                        height: 110px
                        p
                            margin: 0
                        .cs-title-1
                            color: variables.$secondColor
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightBold
                        .cs-title-2
                            color: variables.$color7
                            font-size: 14px
                            line-height: 22px
                            font-weight: variables.$fontWeightSemiBold
                        .cs-desc-1
                            color: variables.$color20
                            font-size: 14px
                            line-height: 22px
                            font-weight: variables.$fontWeightRegular
            .cs-form-1
                display: flex
                flex-direction: column
                gap: 16px
                margin: 16px 0
                align-items: center
                .cs-cd-form-main-1
                    .cs-desc-1
                        color: variables.$color7
                        font-size: 16px
                        line-height: 24px
                        font-weight: variables.$fontWeightBold
                        span
                            color: variables.$primaryColor
                    .cs-date-messages
                        padding: 16px 0
                        .cs-desc-1
                            color: variables.$color11
                            font-size: 13px
                            line-height: 19px
                            font-weight: variables.$fontWeightSemiBold
    .cs-cd-imagebox-1
        max-width: 400px
        margin: 0 auto
        .cs-cd-image-head
            display: flex
            justify-content: space-between
            padding:0 10px 15px
            .cs-title-1
                color: variables.$color7
                font-size: 18px
                line-height: 26px
                font-weight: variables.$fontWeightBold
                margin: 0
            .cs-link-1
                color: variables.$secondColor
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightRegular
                margin: 0
        .cs-cd-images
            [class^="col"]
                padding: 10px
            .row
                margin: 0
            .cs-image-grid-1
                .cs-cd-image-item-1
                    border-radius: variables.$radius11
                    display: block
                    width: 100%
                    height: 370px
                    img
                        border-radius: variables.$radius11
                        width: 100%
                        height: 100%
                        object-fit: cover
                .cs-cd-image-item-2
                    border-radius: variables.$radius11
                    display: block
                    max-width: 190px
                    height: 175px
                    img
                        border-radius: variables.$radius11
                        width: 100%
                        height: 100%
                        object-fit: cover
    .like-btn-1
        font-size: 20px
        line-height: 1
        color: variables.$color20
        display: flex
        align-items: center
        justify-content: center
        width: 30px
        height: 30px
        border-radius: 50%

        &::before
            @include short-code.bainIconCreate('\e940')
            position: relative
            top: 1px
        &.active
            color: variables.$color40
            &::before
                @include short-code.bainIconCreate('\e923')
    .cs-list-area-3
        font-size: 16px
        line-height: 24px
        position: relative
        padding-left: 18px
        &::before
            position: absolute
            left: 0
            top: 50%
            transform: translateY(-50%)
            width: 14px
            height: 14px
            border-radius: variables.$radius202
            content: ""
            display: block
    .cs-list-yellow
        color: variables.$color216
        &::before
            background: variables.$color216
    .cs-list-grey
        color: variables.$color217
        &::before
            background: variables.$color217
    .cs-list-green
        color: variables.$color218
        &::before
            background: variables.$color218
.cs-cd-info-hover
    cursor: pointer
    position: relative
    .bain-info
        color: variables.$secondColor
        font-size: 20px
    .cs-speech-box-container
        display: none
        position: absolute
        left: -60px
        z-index: 99
        .cs-speech-box
            min-width: 210px
            background: variables.$whiteColor
            border-radius: variables.$radius5
            padding: 10px
            border: 1px solid variables.$secondColor
            color: variables.$color7
            font-size: 12px
            line-height: 20px
            font-weight: variables.$fontWeightSemiBold
    &:hover
        .cs-speech-box-container
            display: block
.cs-register-form-c-1
    max-width: 700px
    padding: 30px 15px
    position: relative
    width: 100%
    margin: 0 auto 0
    z-index: 1
    max-height: 100%
    display: flex
    align-items: center
    flex-wrap: wrap
    #next
        opacity: .5
        pointer-events: none
        &.active
            cursor: pointer
            opacity: 1!important
            pointer-events: visible!important
    .cs-backtoprofile-1
        position: absolute
        left: 10px
        top: 65px
    .form-d-1
        // max-width: 490px
        margin: 0 auto
        text-align: center
        .cs-form-tabs-1
            border-bottom: 1px solid variables.$color7
            max-width: 440px
            margin: 0 auto
            .cs-form-tabs-nav-1
                .nav
                    justify-content: center
                    flex-wrap: nowrap
                    .nav-link
                        border: none
                        font-size: 22px
                        line-height: 30px
                        font-weight: variables.$fontWeightRegular
                        color: variables.$color7
                        white-space: nowrap
                        &.active
                            border: none
                            position: relative
                            font-size: 22px
                            line-height: 30px
                            font-weight: variables.$fontWeightBold
                            color: variables.$primaryColor
                            &::before
                                content: ' '
                                width: 100%
                                height: 5px
                                border-radius: variables.$radius9
                                background-color: variables.$primaryColor
                                display: flex
                                position: absolute
                                bottom: -2px
                                left: 0
                        &:hover
                            border: none
    .form-d-2
        // max-width: 440px
        margin: 0 auto
        h3.cs-title-1
            color: variables.$color7
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightBold
        .form-row
            height: 760px
            overflow: hidden
            overflow-y: scroll
            justify-content: center
            &::-webkit-scrollbar-track
                border-radius:  variables.$radius1
                background-color: transparent
            &::-webkit-scrollbar
                width: 2px
                background-color: transparent
            &::-webkit-scrollbar-thumb
                border-radius: variables.$radius1
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
                background-color: transparent
            .form-row
                height: auto !important
                overflow-y: hidden !important
            .cs-foreign-national
                justify-content: center
                .col-12
                    padding: 0 !important
            .form-input-1
                max-width: 350px
                position: relative
                text-align: left
                &.form-check
                    display: flex
                    padding-left: 25px
                    margin: 0
                    input[type="checkbox"]
                        width: 22px
                        height: 22px
                        border-radius: variables.$radius13
                        accent-color: variables.$color105
                    label
                        font-size: 12px
                        line-height: 30px
                        font-weight: variables.$fontWeightRegular
                        color: variables.$color11
                        margin-left: 10px
                        user-select: none
                        span
                            font-size: 14px
                            line-height: 22px
                            font-weight: variables.$fontWeightRegular
                            color: variables.$color11
                            text-decoration: underline !important
                input[type="number"]
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button
                        -webkit-appearance: none
                .cs-cd-info-hover
                    position: absolute
                    right: 10px
                    bottom: 10px
                    .cs-speech-box-container
                        right: 0
                        left: revert
            .cs-form-subtitle-1
                color: variables.$color29
                font-size: 15px
                line-height: 23px
                font-weight: variables.$fontWeightBold
                border-bottom: 1px solid variables.$color29
                padding-bottom: 10px
                text-align: left
                span
                    width: 30px
                    height: 30px
                    display: inline-flex
                    justify-content: center
                    align-items: center
                    color: variables.$whiteColor
                    border-radius: 50%
                    background: rgb(39,193,178)
                    background: linear-gradient(180deg, rgba(39,193,178,1) 0%, rgba(40,180,181,1) 18%, rgba(46,147,192,1) 38%, rgba(54,94,209,1) 66%, rgba(64,31,229,1) 100%)
        .cs-input-line-1
            width: 100%
            height: 1px
            background-color: variables.$color23
            margin: 30px 0
        .cs-form-image-message-1
            display: flex
            align-items: flex-start
            text-align: left
            gap: 10px
            .cs-desc-1
                margin: 0
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color11
        .cs-step-3-wrapper
            width: 100
            .cs-title-1
                color: variables.$color7
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightBold
            .cs-video-rules-d-1
                display: flex
                align-items: center
                gap: 10px
                .cs-desc-1
                    font-size: 14px
                    line-height: 22px
                    color: variables.$color11
                    font-weight: variables.$fontWeightSemiBold
                    text-align: left
                img
                    width: 110px
                    height: 140px
                    object-fit: contain
            .cs-register-add-video
                margin: 30px 0
                .cs-rav-body
                    max-width: 397px
                    height: 368px
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    border: 2px dashed variables.$primaryColor
                    border-radius: variables.$radius3
                    margin: 0 auto
                    position: relative
                    .cs-rav-input-wrapper
                        position: absolute
                        display: flex
                        flex-direction: column
                        justify-content: center
                        gap: 10px
                        align-items: center
                        text-align: center
                        padding: 1rem
                        width: 100%
                        height: 100%
                        input[type='file']
                            position: absolute
                            z-index: 2
                            width: 100%
                            height: 100%
                            opacity: 0
                        .cs-desc-1
                            color: variables.$color7
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightRegular
                            margin-top: 30px
                            .cs-desc-strong-1
                                color: variables.$color7
                                font-size: 16px
                                line-height: 24px
                                font-weight: variables.$fontWeightBold
                            .cs-upload-label-1
                                text-decoration: underline !important
                                color: variables.$secondColor
                                font-size: 16px
                                line-height: 24px
                                font-weight: variables.$fontWeightBold
                                cursor: pointer
                        .cs-desc-2
                            color: variables.$color20
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightRegular
                        .cs-desc-3
                            color: variables.$color20
                            font-size: 14px
                            line-height: 22px
                            font-weight: variables.$fontWeightRegular
                            position: absolute
                            bottom: 10px
                            left: 50%
                            transform: translateX(-50%)
                            margin: 0
                        .cs-file-name-text
                            color: variables.$secondColor
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightBold
            .cs-register-add-video-2
                display: none
                flex-direction: column
                .cs-rav2-head
                    position: relative
                    display: flex
                    justify-content: center
                    align-items: center
                    margin:
                        bottom: 30px
                        top: 30px
                    img
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50% , -50%)
                        z-index: -1
                    .cs-desc-1
                        color: variables.$color7
                        font-size: 24px
                        line-height: 32px
                        font-weight: variables.$fontWeightBold
                .cs-upload-container-1
                    .cs-register-video-wrapper
                        width: 100%
                        height: 300px
                        position: relative
                        .cs-video-button
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50% , -50%)
                            width: 50px
                            height: 50px
                            border-radius: 50%
                            background-color: #27c1b2a3
                            color: variables.$whiteColor
                            display: flex
                            justify-content: center
                            align-items: center
                            z-index: 9
                        video
                            width: 100%
                            height: 100%
                            object-fit: cover
                            border-radius: variables.$radius3
        .cs-step-4-wrapper
            width: 100%
            .cs-title-1
                color: variables.$color7
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightBold
            .cs-photo-rules-1
                display: flex
                align-items: center
                gap: 10px
                .cs-desc-1
                    font-size: 14px
                    line-height: 22px
                    color: variables.$color11
                    font-weight: variables.$fontWeightRegular
                    text-align: center
                    span
                        font-weight: variables.$fontWeightBold
                img
                    width: 170px
                    height: 185px
                    object-fit: contain
            .cs-register-add-image
                margin: 30px 0
                .cs-rav-body
                    max-width: 397px
                    height: 368px
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    border: 2px dashed variables.$primaryColor
                    border-radius: variables.$radius3
                    margin: 0 auto
                    position: relative
                    .cs-rav-input-wrapper
                        position: absolute
                        display: flex
                        flex-direction: column
                        justify-content: center
                        gap: 10px
                        align-items: center
                        text-align: center
                        padding: 1rem
                        width: 100%
                        height: 100%
                        input[type='file']
                            position: absolute
                            z-index: 2
                            width: 100%
                            height: 100%
                            opacity: 0
                        .cs-desc-1
                            color: variables.$color7
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightRegular
                            margin-top: 30px
                            .cs-desc-strong-1
                                color: variables.$color7
                                font-size: 16px
                                line-height: 24px
                                font-weight: variables.$fontWeightBold
                            .cs-upload-label-1
                                text-decoration: underline !important
                                color: variables.$secondColor
                                font-size: 16px
                                line-height: 24px
                                font-weight: variables.$fontWeightBold
                                cursor: pointer
                        .cs-desc-2
                            color: variables.$color20
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightRegular
                        .cs-desc-3
                            color: variables.$color20
                            font-size: 14px
                            line-height: 22px
                            font-weight: variables.$fontWeightRegular
                            position: absolute
                            bottom: 10px
                            left: 50%
                            transform: translateX(-50%)
                            margin: 0
                        .cs-file-name-text
                            color: variables.$secondColor
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightBold
            .cs-register-add-image-2
                display: none
                flex-direction: column
                margin: 30px 0
                .cs-register-image-wrapper
                    width: 100%
                    height: 100%
                    border-radius: variables.$radius3
                    position: relative
                    img
                        border-radius: variables.$radius3
                        width: 100%
                        object-fit: cover
                    .cs-trash-button
                        position: absolute
                        bottom: 10px
                        right: 10px
                        width: 50px
                        height: 50px
                        background-color: variables.$whiteColor
                        border-radius: 50%
                        display: flex
                        justify-content: center
                        align-items: center
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
                        i
                            color: variables.$color20
                            font-size: 20px
                .cs-succes-file-message
                    margin:
                        top:-35px
                        left: 25px
                    text-align: left
                    .cs-sfm-icon
                        width: 26px
                        height: 26px
                        background-color: variables.$secondColor
                        display: inline-flex
                        justify-content: center
                        align-items: center
                        border-radius: 50%
                        i
                            color: variables.$whiteColor
                    .cs-desc-1
                        color: variables.$color7
                        font-size: 16px
                        line-height: 24px
                        font-weight: variables.$fontWeightSemiBold


        .cs-step-5-wrapper
            width: 100%
            .cs-title-1
                color: variables.$color7
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightBold
            .cs-rules-list-1
                display: flex
                align-items: center
                gap: 10px
                ul
                    text-align: left
                    list-style-type: none
                    li
                        position: relative
                        font-size: 16px
                        line-height: 24px
                        color: variables.$color11
                        font-weight: variables.$fontWeightSemiBold
                        &::before
                            content: " "
                            background-color: variables.$secondColor
                            display: block
                            width: 10px
                            height: 10px
                            border-radius: 50%
                            position: absolute
                            left: -20px
                            top: 7px
            .cs-resigter-add-file
                margin: 30px 0
                .cs-rav-body
                    max-width: 397px
                    height: 368px
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    border: 2px dashed variables.$primaryColor
                    border-radius: variables.$radius3
                    margin: 0 auto
                    position: relative
                    .cs-rav-input-wrapper
                        position: absolute
                        display: flex
                        flex-direction: column
                        justify-content: center
                        gap: 10px
                        align-items: center
                        text-align: center
                        padding: 1rem
                        width: 100%
                        height: 100%
                        input[type='file']
                            position: absolute
                            z-index: 2
                            width: 100%
                            height: 100%
                            opacity: 0
                        .cs-desc-1
                            color: variables.$color7
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightRegular
                            margin-top: 30px
                            .cs-desc-strong-1
                                color: variables.$color7
                                font-size: 16px
                                line-height: 24px
                                font-weight: variables.$fontWeightBold
                            .cs-upload-label-1
                                text-decoration: underline !important
                                color: variables.$secondColor
                                font-size: 16px
                                line-height: 24px
                                font-weight: variables.$fontWeightBold
                                cursor: pointer
                        .cs-desc-2
                            color: variables.$color20
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightRegular
                        .cs-desc-3
                            color: variables.$color20
                            font-size: 14px
                            line-height: 22px
                            font-weight: variables.$fontWeightRegular
                            position: absolute
                            bottom: 10px
                            left: 50%
                            transform: translateX(-50%)
                            margin: 0
                        .cs-file-name-text
                            color: variables.$secondColor
                            font-size: 16px
                            line-height: 24px
                            font-weight: variables.$fontWeightBold
            .cs-register-add-file-2
                display: none
                flex-direction: column
                margin: 30px 0
                .cs-register-file-wrapper
                    width: 100%
                    height: 310px
                    border-radius: variables.$radius3
                    position: relative
                    .csfb1-pdf-d-1
                        height: 100%
                    #pdfViewerCanvas1
                        object-fit: cover
                        object-position: top
                        width: 100%
                        height: 100%
                        border-radius: variables.$radius3
                    iframe
                        border-radius: variables.$radius3
                        width: 100%
                        height: 100%
                        border: none
                    .cs-trash-button
                        position: absolute
                        bottom: 10px
                        right: 10px
                        width: 50px
                        height: 50px
                        background-color: variables.$whiteColor
                        border-radius: 50%
                        display: flex
                        justify-content: center
                        align-items: center
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
                        i
                            color: variables.$color20
                            font-size: 20px
                .cs-register-file-wrapper2
                    width: 100%
                    height: 310px
                    border-radius: variables.$radius3
                    position: relative
                    .csfb1-pdf-d-2
                        height: 100% !important
                    #pdfViewerCanvas2
                        object-fit: cover
                        object-position: top
                        width: 100%
                        height: 100%
                        border-radius: variables.$radius3
                    iframe
                        border-radius: variables.$radius3
                        width: 100%
                        height: 100%
                        border: none
                    .cs-trash-button
                        position: absolute
                        bottom: 10px
                        right: 10px
                        width: 50px
                        height: 50px
                        background-color: variables.$whiteColor
                        border-radius: 50%
                        display: flex
                        justify-content: center
                        align-items: center
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
                        i
                            color: variables.$color20
                            font-size: 20px
                .cs-register-file-wrapper3
                    width: 100%
                    height: 310px
                    border-radius: variables.$radius3
                    position: relative
                    .csfb1-pdf-d-3
                        height: 100% !important
                    #pdfViewerCanvas3
                        object-fit: cover
                        object-position: top
                        width: 100%
                        height: 100%
                        border-radius: variables.$radius3
                    iframe
                        border-radius: variables.$radius3
                        width: 100%
                        height: 100%
                        border: none
                    .cs-trash-button
                        position: absolute
                        bottom: 10px
                        right: 10px
                        width: 50px
                        height: 50px
                        background-color: variables.$whiteColor
                        border-radius: 50%
                        display: flex
                        justify-content: center
                        align-items: center
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
                        i
                            color: variables.$color20
                            font-size: 20px
                .cs-register-file-wrapper4
                    width: 100%
                    height: 310px
                    border-radius: variables.$radius3
                    position: relative
                    .csfb1-pdf-d-4
                        height: 100% !important
                    #pdfViewerCanvas4
                        object-fit: cover
                        object-position: top
                        width: 100%
                        height: 100%
                        border-radius: variables.$radius3
                    iframe
                        border-radius: variables.$radius3
                        width: 100%
                        height: 100%
                        border: none
                    .cs-trash-button
                        position: absolute
                        bottom: 10px
                        right: 10px
                        width: 50px
                        height: 50px
                        background-color: variables.$whiteColor
                        border-radius: 50%
                        display: flex
                        justify-content: center
                        align-items: center
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
                        i
                            color: variables.$color20
                            font-size: 20px
                .cs-succes-file-message
                    margin:
                        top:-35px
                        left: 25px
                    text-align: left
                    .cs-sfm-icon
                        width: 26px
                        height: 26px
                        background-color: variables.$secondColor
                        display: inline-flex
                        justify-content: center
                        align-items: center
                        border-radius: 50%
                        i
                            color: variables.$whiteColor
                    .cs-desc-1
                        color: variables.$color7
                        font-size: 16px
                        line-height: 24px
                        font-weight: variables.$fontWeightSemiBold

.cs-register-button-group-1
    margin: 30px 0
    .cs-reload-btn-1
        color: variables.$color11
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        border: none
        background-color: transparent
.cs-input-image-wrapper-1
    display: flex
    justify-content: space-between
    align-items: flex-start
    padding: 16px
    gap: 30px
    .cs-input-box-dashed-1
        width: 180px
        height: 180px
        border: 1px dashed variables.$primaryColor
        border-radius: variables.$radius11
        position: relative
        cursor: pointer
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        .cs-text-box-1
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50% , -50%)
            z-index: 0 !important
            i
                color: variables.$color32
                font-size: 36px
            .cs-desc-1
                font-size: 16px
                line-height: 24px
                color: variables.$color23
                font-weight: variables.$fontWeightSemiBold
                margin: 0
        &::before
            content: ''
            border-radius: variables.$radius11
            background-color: rgba(4, 22, 20, 0.6)
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            z-index: 1
            display: none
        input
            position: absolute
            top: 0
            left: 0
            z-index: -1
            opacity: 0
            visibility: hidden
    .csub1-btn-1
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        border: 0
        padding: 0
        width: 30px
        height: 30px
        position: absolute
        right: 10px
        bottom: 10px
        z-index: 3
        background-color: variables.$color202
        font-size: 18px
        line-height: 1
        color: variables.$secondColor
        position: absolute
        bottom: 10px
        right: 10px
.cs-address-icon-input-1
    display: flex
    align-items: center
    justify-content: space-between
    border: 1px solid variables.$color20
    border-radius: variables.$radius11 !important
    input
        border: none !important
        &:focus,
        &:focus-visible
            outline: none !important
            box-shadow: none !important

    a
        color: variables.$color20
        font-size: 24px
        padding: 0 10px
        display: flex
        align-items: center
        &:hover
            color: variables.$primaryColor
.cs-primary-step-line-2
    width: 185px
    height: 1px
    background-color: variables.$color204
    position: relative
    margin: 30px auto
    .cs-step-line-item-1
        width: 18px
        height: 18px
        background-color: variables.$color204
        border-radius: 50%
        position: absolute
        transform: translateY(-8px)
        &:nth-child(2)
            left: calc(25%)
        &:nth-child(3)
            left: calc(50%)
        &:nth-child(4)
            left: calc(75%)
        &:first-child
            left: 0
        &:last-child
            left: 100%
        &.active
            background-color: variables.$primaryColor
            border: 3px solid variables.$color205
    &.four-item
        .cs-step-line-item-1
            width: 18px
            height: 18px
            background-color: variables.$color204
            border-radius: 50%
            position: absolute
            transform: translateY(-8px)
            &:nth-child(2)
                left: calc(33%)
            &:nth-child(3)
                left: calc(66%)
            &:first-child
                left: 0
            &:last-child
                left: 100%
            &.active
                background-color: variables.$primaryColor
                border: 3px solid variables.$color205



.cs-add-adress-modal-1
    .modal-content
        border-radius: variables.$radius3
        .modal-header
            border: none
            .modal-title
                margin-left: (calc( 50% - 60px ))
        .cs-register-form-c-1
            padding: 0
            .form-d-2
                .form-row
                    height:500px
                    .form-input-1
                        margin-bottom: 20px
    .cs-primary-btn-3
        opacity: .5
        pointer-events: none
        &.active
            cursor: pointer
            opacity: 1!important
            pointer-events: visible!important

.cs-primary-select2-2
    .select2-container
        width: 100% !important
        &--default
            .select2-selection
                &--single
                    .select2-selection__arrow
                        top: 10px !important
        .select2-dropdown
            padding: 50px
        .selection
            .select2-selection--single
                border: 0.5px solid variables.$color11
                border-radius: variables.$radius11
                color: variables.$color7
                font-size: 16px
                font-weight: 400
                height: 50px
                line-height: 24px
                padding: 5px 20px
                transition: all .2s ease
                display: flex
                align-items: center
.input-warning-1
    input
        border-color: red !important
        color: red !important
        accent-color: red !important
.step-d-1:not(.cs-nowidth-1)
    max-width: 470px
    padding:0 15px
.step-d-1.cs-nowidth-1
    max-width: 470px
    width: 100%
    padding: 0 10px
.step-d-1:not(.active)
    display: none
.checkbox-wrapper-6
  display: flex
  align-items: center
  gap: 10px
  .tgl
    display: none
    box-sizing: border-box

    &:after, &:before
      box-sizing: border-box

    *
      box-sizing: border-box

      &:after, &:before
        box-sizing: border-box

    + .tgl-btn
      box-sizing: border-box

    &::-moz-selection, &:after::-moz-selection, &:before::-moz-selection
      background: none

    *
      &::-moz-selection, &:after::-moz-selection, &:before::-moz-selection
        background: none

    + .tgl-btn::-moz-selection, &::selection, &:after::selection, &:before::selection
      background: none

    *
      &::selection, &:after::selection, &:before::selection
        background: none

    + .tgl-btn
      &::selection
        background: none

      outline: 0
      display: block
      width: 4em
      height: 2em
      position: relative
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      &:after, &:before
        position: relative
        display: block
        content: ""
        width: 50%
        height: 100%

      &:after
        left: 0

      &:before
        display: none

    &:checked + .tgl-btn:after
      left: 50%

  .tgl-light
    + .tgl-btn
      background: variables.$color41
      border-radius: 2em
      padding: 2px
      transition: all 0.4s ease

      &:after
        border-radius: 50%
        background: variables.$whiteColor
        transition: all 0.2s ease

    &:checked + .tgl-btn
      background: variables.$primaryColor

.cs-about-us-business-sec-1
    .cs-aub-main-1
        margin: 50px 0px 150px
        .cs-aub-item-1
            display: flex
            flex-direction: column
            align-items: center
            img
                width: 197px
                height: 197px
                object-fit: cover
            .cs-link-1
                color: variables.$secondColor
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightBold
                text-decoration: underline !important
.cs-notification-sec-1
    .cs-notification-main-1
        max-width: 785px
        margin: 0 auto
        min-height: 100vh
        .cs-not-empty-d-1
            display: flex
            flex-direction: column
            align-items: center
            min-height: 50vh
            img
                margin: 20px 0
            .cs-desc-1
                color: variables.$color11
                font-size: 18px
                line-height: 26px
                font-weight: variables.$fontWeightBold
                text-align: center
        .cs-notification-tabs-1
            display: flex
            align-items: center
            flex-direction: column
            .cs-notification-nav-1
                border-bottom: 1px solid variables.$color7
                width: 100%
            .nav
                &.nav-tabs
                    justify-content: space-evenly
                    .nav-item
                        border: none
                        font-size: 18px
                        line-height: 26px
                        font-weight: variables.$fontWeightSemiBold
                        color: variables.$color20
                        position: relative
                        &.active
                            color: variables.$secondColor
                            &::after
                                content:" "
                                display: block
                                width: 100%
                                height: 5px
                                border-radius: variables.$radius2
                                background-color: variables.$secondColor
                                position: absolute
                                bottom: -2.5px
                                left: 0
            .tab-content
                width: 100%
                .cs-notification-list-wrapper
                    padding: 16px 0
                    .cs-notification-list-item-1
                        display: flex
                        justify-content: space-between
                        align-items: center
                        padding: 16px
                        background-color: variables.$whiteColor
                        border-radius: variables.$radius5
                        border: 1px solid variables.$color200
                        margin-bottom: 20px
                        width: 100%
                        height: 120px
                        cursor: pointer
                        &.unread-notification
                            background-color: variables.$color13
                        i
                            font-size: 24px
                            color: variables.$primaryColor
                        .cs-notification-item-d-1
                            display: flex
                            align-items: center
                            gap: 16px
                            .cs-textbox-1
                                .cs-title-1
                                    color: variables.$color7
                                    font-size: 16px
                                    line-height: 24px
                                    font-weight: variables.$fontWeightBold
                                    word-break: break-all
                                .cs-desc-1
                                    font-size: 16px
                                    line-height: 24px
                                    color: variables.$color20
                                    font-weight: variables.$fontWeightRegular
                                    word-break: break-all
                                    span
                                        color: variables.$secondColor
                                        font-size: 16px
                                        line-height: 24px
                                        font-weight: variables.$fontWeightBold
                        .cs-textbox-2
                            display: flex
                            flex-direction: column
                            align-items: flex-end
                            .cs-desc-1
                                font-size: 16px
                                line-height: 24px
                                color: variables.$color20
                                font-weight: variables.$fontWeightRegular
                                white-space: nowrap
                                word-break: break-all
                            i
                                font-size: 24px
                                color: variables.$color20
.notificationNoImageModal
    .modal-dialog
        max-width: 610px
        .modal-content
            border-radius: variables.$radius3
            padding: 20px 50px
            .modal-header
                padding-bottom: 0
            .modal-body
                padding-top: 0
                .cs-title-1
                    text-align: center
                    color: variables.$color100
                    font-size: 20px
                    line-height: 28px
                    font-weight: variables.$fontWeightBold
                .cs-desc-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$blackColor
                    font-weight: variables.$fontWeightRegular
                    span
                        color: variables.$secondColor
                        font-size: 16px
                        line-height: 24px
                        font-weight: variables.$fontWeightBold
.cs-modal-notification
    .modal-dialog
        max-width: 650px
        margin: 3rem auto
        padding: 15px
    .modal-content
        border-radius: variables.$radius8
        padding: 0
        border: 0
    .close
        font-size: 22px
        line-height: 1
        opacity: 1
        color: variables.$color29
        position: absolute
        right: 20px
        top: 20px
        z-index: 2
    .modal-body
        padding: 0
        border-radius: variables.$radius8
        .image-d-1
            border-radius: variables.$radius8 variables.$radius8 0 0
            img
                border-radius: variables.$radius8 variables.$radius8 0 0
                width: 100%
                max-width: unset
        .text-d-1
            padding: 15px
            background-color: variables.$color16
            border-radius: 0 0 variables.$radius8 variables.$radius8
            .in-content-1
                max-width: 510px
                margin: 0 auto
                h1, h2, h3, h4, h5, h6
                    text-align: center
                    font-size: 22px
                    line-height: 30px
                    font-weight: variables.$fontWeightBold
                    color: variables.$color11
                    margin:
                        bottom: 20px
                p
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightRegular
                    color: variables.$blackColor
                    margin:
                        bottom: 15px
                    span
                        font-weight: variables.$fontWeightBold
                ul, ol
                    padding:
                        right: 15px
                li
                    font-size: 16px
                    line-height: 24px
                    font-weight: variables.$fontWeightRegular
                    color: variables.$color11
                    margin:
                        bottom: 15px
                a
                    color: variables.$secondColor
.cs-sa-back-button
    .swal2-popup
        display: flex !important
        justify-content: space-between
        .swal2-cancel
            background-color: transparent
            color: variables.$color20
            &:hover
                background-color: transparent
                background-image: revert
                color: variables.$whiteColor
            &:focus
                box-shadow: none
                border: none
.cs-sharecar-btn1
    button
        opacity: .5
        pointer-events: none
        &.active
            cursor: pointer
            opacity: 1!important
            pointer-events: visible!important
.valeModal
    .modal-dialog
        max-width: 875px
        .cs-vale-modal-main
            display: flex
            img
                width: 100%
            .cs-vale-modal-content
                padding: 0 15px
                .cs-title-1
                    color: variables.$secondColor
                    font-size: 20px
                    line-height: 28px
                    font-weight: variables.$fontWeightBold
                .cs-desc-1
                    color: variables.$color7
                    font-size: 18px
                    line-height: 26px
                    font-weight: variables.$fontWeightRegular
    .modal-footer
        justify-content: center
        padding: 0

















