@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&display=swap");
@import url("../plugins/bain/style.css");
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../plugins/fontawesome/css/all.min.css");
* {
  font-family: "Nunito Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

a, button {
  text-decoration: none !important;
  outline: none !important;
  cursor: pointer;
}

a:hover, a:focus {
  text-decoration: none !important;
  outline: none !important;
}

button:hover, button:focus {
  text-decoration: none !important;
  outline: none !important;
}

#goTpButton {
  display: inline-block;
  background-color: #401FE5;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 65px;
  right: -3px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 8;
}
#goTpButton::after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 2em;
  line-height: 50px;
  color: #ffffff;
}
#goTpButton.show-active {
  opacity: 1;
  visibility: visible;
}

.container-2 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.cs-sticky-header-1 {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.33);
}

.cs-header-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}
.cs-header-row-1 .cshr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 250px;
  flex: 0 0 250px;
}
.cs-header-row-1 .cshr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 650px);
  flex: 0 0 calc(100% - 650px);
}
.cs-header-row-1 .cshr1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 400px;
  flex: 0 0 400px;
}

.cs-main-logo-1 img {
  max-height: 62px;
}

.cs-main-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-main-menu-1 .main-li-1 {
  margin-right: 15px;
}
.cs-main-menu-1 .main-li-1:last-child {
  margin-right: 0px;
}
.cs-main-menu-1 .main-li-1 .main-a-1 {
  display: block;
  padding: 32px 10px;
  border-radius: 18px;
  background-color: #ffffff;
  transition: all 0.3s ease;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
}
.cs-main-menu-1 .main-li-1 .main-a-1:hover {
  color: #401FE5;
}
.cs-main-menu-1 .main-li-1 .main-a-1.active {
  color: #27C1B2;
}
.cs-main-menu-1 .main-li-1 .main-a-1.active::before {
  content: "";
  background-color: #27C1B2;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: -15px;
}
.cs-main-menu-1 .main-li-1 .main-a-1.main-dd-l-1::after {
  content: "\e95e";
  font-family: "bain" !important;
  font-size: 6px;
  position: relative;
  top: -3px;
  padding-left: 5px;
}
.cs-main-menu-1 .main-li-1.dd-active-1 .main-a-1 {
  background-color: #401FE5;
  color: #ffffff;
}
.cs-main-menu-1 .main-li-1.dd-active-1 .main-a-1.main-dd-l-1::after {
  content: "\e95c";
}
.cs-main-menu-1 .main-li-1.dd-active-1 .main-a-1.active::before {
  display: none;
}
.cs-main-menu-1 .main-li-1.dd-active-1 .main-dd-d-1 {
  opacity: 1;
  visibility: visible;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 {
  background-color: #ffffff;
  border-radius: 18px;
  border: 0.5px solid #401FE5;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-top: -30px;
  z-index: 4;
  width: 260px;
  transition: all 0.2s ease;
  opacity: 0;
  visibility: hidden;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 .mdd-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px 0px;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 .mdd-m-1 .mdd-m-i-1 {
  border-bottom: 1px solid #EDEDED;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 .mdd-m-1 .mdd-m-i-1:last-child {
  border: 0;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 .mdd-m-1 .mdd-m-l-1 {
  display: block;
  padding: 10px 25px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #454545;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 .mdd-m-1 .mdd-m-l-1:hover {
  color: #401FE5;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 .mdd-m-1 .mdd-m-l-1.active {
  color: #27C1B2;
}
.cs-main-menu-1 .main-li-1 .main-dd-d-1 .mdd-m-1 .mdd-m-l-1.active::before {
  content: "";
  background-color: #27C1B2;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: -15px;
}

.cs-header-row-2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  align-items: center;
}
.cs-header-row-2 .cshr2-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 65px;
  flex: 0 0 65px;
}
.cs-header-row-2 .cshr2-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: calc(100% - 195px);
  flex: 0 0 calc(100% - 195px);
}

.cs-header-btn-1 {
  display: flex;
  position: relative;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  line-height: 1;
  color: #272727;
  background-color: #F0EDFF;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.cs-header-btn-1:hover {
  border-color: #3F22E4;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  color: #3F22E4;
}
.cs-header-btn-1.notification {
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
}
.cs-header-btn-1.notification::before {
  content: "";
  position: absolute;
  top: 8px;
  right: 12px;
  background-color: #401FE5;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.cs-header-btn-2 {
  display: flex;
  position: relative;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  line-height: 1;
  color: #401FE5;
  background-color: #F0EDFF;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.cs-header-btn-2:hover {
  border-color: #3F22E4;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  color: #3F22E4;
}
.cs-header-btn-2.active {
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
}

.cs-header-btn-3 {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 45px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: #F6FDFD;
  background-color: #401FE5;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  white-space: nowrap;
}
.cs-header-btn-3 i {
  font-size: 20px;
  padding-left: 5px;
  position: relative;
  top: 2px;
}
.cs-header-btn-3:hover {
  border-color: #3F22E4;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  color: #401FE5;
}

.cs-globe-dd-1.dd-active-1 .csgdd1-d-1 {
  opacity: 1;
  visibility: visible;
}
.cs-globe-dd-1 .csgdd1-d-1 {
  background-color: #ffffff;
  border-radius: 18px;
  border: 0.5px solid #401FE5;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-top: 0px;
  left: 10px;
  z-index: 99;
  width: calc(100% - 20px);
  transition: all 0.2s ease;
  opacity: 0;
  visibility: hidden;
}
.cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px 0px;
}
.cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 .mdd-m-i-1 {
  border-bottom: 1px solid #EDEDED;
}
.cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 .mdd-m-i-1:last-child {
  border: 0;
}
.cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 .csgdd1-m-l-1 {
  display: block;
  padding: 10px 25px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #454545;
}
.cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 .csgdd1-m-l-1:hover {
  color: #401FE5;
}
.cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 .csgdd1-m-l-1.active {
  color: #27C1B2;
}
.cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 .csgdd1-m-l-1.active::before {
  content: "";
  background-color: #27C1B2;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: -15px;
}

.cs-header-btn-4 {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 45px;
  padding: 2px;
  align-items: center;
  border-radius: 22px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #F6FDFD;
  background-color: #401FE5;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.cs-header-btn-4 .image-d-1 {
  flex: 0 0 40px;
  max-width: 40px;
}
.cs-header-btn-4 .image-d-1 img {
  max-height: 40px;
  border-radius: 50%;
}
.cs-header-btn-4 .image-d-1 .fake-char-i-1 {
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 1px;
  background-color: #27C1B2;
  color: #ffffff;
}
.cs-header-btn-4 .name-d-1 {
  flex: 0 0 calc(100% - 40px);
  max-width: calc(100% - 40px);
  padding-left: 5px;
}
.cs-header-btn-4 .name-d-1 .name-t-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #F6FDFD;
  margin: 0;
  max-height: 22px;
  min-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: all 0.3s ease;
}
.cs-header-btn-4:hover {
  border-color: #3F22E4;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  color: #401FE5;
}
.cs-header-btn-4:hover .name-t-1 {
  color: #401FE5;
}

.cs-humberge-m-1 {
  cursor: pointer;
  position: relative;
  height: 14px;
  max-width: 32px;
  width: 100%;
  margin: 0 auto;
}

.cs-humberge-m-1-top {
  border-radius: 1px;
  height: 4px;
  width: 100%;
  background: #401FE5;
  position: absolute;
  transform: rotate(0deg);
  top: 0;
  animation-name: topreverse;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-fill-mode: forwards;
}

.cs-humberge-m-1-bottom {
  border-radius: 1px;
  height: 4px;
  width: 100%;
  background: #401FE5;
  position: absolute;
  transform: rotate(0deg);
  bottom: 0;
  animation-name: bottomreverse;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-fill-mode: forwards;
}

.cs-humberge-m-1.is-active .cs-humberge-m-1-top {
  top: 28.8333333333%;
  animation-name: top;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-fill-mode: forwards;
}
.cs-humberge-m-1.is-active .cs-humberge-m-1-bottom {
  bottom: 45.8333333333%;
  animation-name: bottom;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-fill-mode: forwards;
}
.cs-humberge-m-1.is-active .no-animation {
  animation: none;
}

@-webkit-keyframes top {
  0% {
    top: 0%;
  }
  60% {
    top: 45.8333333333%;
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-moz-keyframes top {
  0% {
    top: 0%;
  }
  60% {
    top: 45.8333333333%;
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes top {
  0% {
    top: 0%;
  }
  60% {
    top: 45.8333333333%;
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-webkit-keyframes bottom {
  0% {
    bottom: 0%;
  }
  60% {
    bottom: 45.8333333333%;
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@-moz-keyframes bottom {
  0% {
    bottom: 0%;
  }
  60% {
    bottom: 45.8333333333%;
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes bottom {
  0% {
    bottom: 0%;
  }
  60% {
    bottom: 45.8333333333%;
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@-webkit-keyframes topreverse {
  0% {
    transform: rotate(45deg);
    top: 45.8333333333%;
  }
  60% {
    transform: rotate(0deg);
    top: 45.8333333333%;
  }
  100% {
    top: 0%;
  }
}
@-moz-keyframes topreverse {
  0% {
    transform: rotate(45deg);
    top: 45.8333333333%;
  }
  60% {
    transform: rotate(0deg);
    top: 45.8333333333%;
  }
  100% {
    top: 0%;
  }
}
@keyframes topreverse {
  0% {
    transform: rotate(45deg);
    top: 45.8333333333%;
  }
  60% {
    transform: rotate(0deg);
    top: 45.8333333333%;
  }
  100% {
    top: 0%;
  }
}
@-webkit-keyframes bottomreverse {
  0% {
    transform: rotate(-45deg);
    bottom: 45.8333333333%;
  }
  60% {
    transform: rotate(0deg);
    bottom: 45.8333333333%;
  }
  100% {
    bottom: 0%;
  }
}
@-moz-keyframes bottomreverse {
  0% {
    transform: rotate(-45deg);
    bottom: 45.8333333333%;
  }
  60% {
    transform: rotate(0deg);
    bottom: 45.8333333333%;
  }
  100% {
    bottom: 0%;
  }
}
@keyframes bottomreverse {
  0% {
    transform: rotate(-45deg);
    bottom: 45.8333333333%;
  }
  60% {
    transform: rotate(0deg);
    bottom: 45.8333333333%;
  }
  100% {
    bottom: 0%;
  }
}
.cs-mobile-menu-d-1 {
  position: fixed;
  top: 94px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 98;
  display: block;
  margin: 0;
  width: 100%;
  height: 0px;
  background-color: #ffffff;
  overflow: hidden;
  transition: background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1), height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
}
.cs-mobile-menu-d-1.active {
  height: calc(100vh - 94px);
}
.cs-mobile-menu-d-1.active .content-area-1 {
  opacity: 1;
  overflow-y: auto;
}
.cs-mobile-menu-d-1.active .image-area-1 .image-3 {
  left: 5vw;
}
.cs-mobile-menu-d-1 .content-area-1 {
  display: block;
  max-width: 600px;
  margin: 0 auto;
  padding: 45px 40px;
  opacity: 0;
  transition: opacity 0.3s ease;
  transition-delay: 250ms;
  overflow: hidden;
  max-height: 60vh;
}
.cs-mobile-menu-d-1 .image-area-1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.cs-mobile-menu-d-1 .image-area-1 .image-1 {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
  transform: translateX(-50%);
}
.cs-mobile-menu-d-1 .image-area-1 .image-2 {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: -1;
  width: 100%;
  max-width: unset;
}
.cs-mobile-menu-d-1 .image-area-1 .image-3 {
  position: absolute;
  bottom: 0;
  left: 90vw;
  z-index: 1;
  transition: all 2.5s ease;
  transition-delay: 0.3s;
}

.cs-mobile-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 30px;
}
.cs-mobile-menu-1 .level-1 {
  line-height: 1;
  margin-bottom: 5px;
}
.cs-mobile-menu-1 .level-link-1 {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #401FE5;
  padding: 10px 20px 10px 0px;
  transition: all 0.3s ease;
}
.cs-mobile-menu-1 .level-2 {
  line-height: 1;
  margin-bottom: 5px;
  border-bottom: 1px solid #401FE5;
}
.cs-mobile-menu-1 .level-link-2 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #401FE5;
  padding: 10px 10px 10px 0px;
  transition: all 0.3s ease;
}
.cs-mobile-menu-1 .level-link-3 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #401FE5;
  padding: 10px 10px 10px 0px;
  transition: all 0.3s ease;
}
.cs-mobile-menu-1 .dd-menu-l-1::after, .cs-mobile-menu-1 .dd-menu-l-2::after {
  content: "\e95e";
  font-family: "bain" !important;
  font-size: 8px;
  line-height: 1;
  position: absolute;
  right: 4px;
  top: 15px;
  z-index: 1;
  pointer-events: none;
  transition: all 0.3s ease;
}
.cs-mobile-menu-1 .dd-menu-l-1.active::after, .cs-mobile-menu-1 .dd-menu-l-2.active::after {
  transform: rotate(-180deg);
}
.cs-mobile-menu-1 .dd-menu-d-1 {
  display: none;
  background-color: #F0EDFF;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
}
.cs-mobile-menu-1 .dd-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-mobile-menu-1 .dd-menu-1 .level-2:last-child {
  border: 0;
  margin: 0;
}

.cs-footer-1 {
  background-color: #401FE5;
  padding: 35px 0px;
  position: relative;
  z-index: 1;
}

.cs-footer-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cs-footer-row-1 .csfr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 240px;
  flex: 0 0 240px;
}
.cs-footer-row-1 .csfr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc((100% - 240px) / 5);
  flex: 0 0 calc((100% - 240px) / 5);
}

.cs-footer-image-1 {
  margin-bottom: 40px;
}
.cs-footer-image-1 img {
  max-height: 190px;
}

.cs-download-app-1 {
  margin-bottom: 30px;
}
.cs-download-app-1 .text-1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}
.cs-download-app-1 .image-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-download-app-1 .image-m-1 li {
  margin-bottom: 15px;
  margin-right: 8px;
}
.cs-download-app-1 .image-m-1 li:last-child {
  margin-right: 0px;
}
.cs-download-app-1 .image-m-1 li img {
  max-height: 42px;
  min-height: 42px;
  width: 100%;
  height: 100%;
}

.cs-social-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cs-social-menu-1 li {
  margin-right: 8px;
  margin-bottom: 10px;
}
.cs-social-menu-1 li:last-child {
  margin-right: 0px;
}
.cs-social-menu-1 li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-size: 18px;
  line-height: 1;
  color: #27C1B2;
  background-color: transparent;
  transition: all 0.4s ease;
}
.cs-social-menu-1 li a:hover {
  color: #27C1B2;
  background-color: #ffffff;
}

.cs-wbg-head-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}
.cs-wbg-head-1 span {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0px 3px;
}
.cs-wbg-head-1 span::before {
  content: "";
  height: 8px;
  width: 100%;
  display: block;
  background-color: #331AB1;
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: -1;
  border-radius: 12px;
}

.cs-footer-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-footer-menu-1 li {
  margin-bottom: 15px;
}
.cs-footer-menu-1 li a {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #ffffff;
  transition: all 0.3s ease;
}
.cs-footer-menu-1 li a:hover {
  opacity: 0.5;
}

.cs-footer-menu-2 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.cs-footer-menu-2 li {
  position: relative;
  z-index: 1;
  margin-right: 24px;
}
.cs-footer-menu-2 li::after {
  content: "";
  display: block;
  position: absolute;
  right: -12px;
  top: 2px;
  z-index: 1;
  width: 1px;
  height: 20px;
  background-color: #ffffff;
}
.cs-footer-menu-2 li:last-child {
  margin-right: 0px;
}
.cs-footer-menu-2 li:last-child::after {
  display: none;
}
.cs-footer-menu-2 li a {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #ffffff;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.cs-footer-menu-2 li.active a {
  opacity: 1;
}

.cs-full-container-1 {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}

.cs-slider-1 {
  background-color: #ffffff;
}
.cs-slider-1.owl-theme .owl-nav {
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
  max-width: 1390px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding: 0px 45px;
}
.cs-slider-1.owl-theme .owl-nav [class*=owl-] {
  font-size: 30px;
  line-height: 1;
  color: #27C1B2;
}
.cs-slider-1.owl-theme .owl-nav [class*=owl-]:first-child {
  margin-right: 20px;
}
.cs-slider-1.owl-theme .owl-nav [class*=owl-]:hover, .cs-slider-1.owl-theme .owl-nav [class*=owl-]:focus {
  background: transparent;
}
.cs-slider-1.owl-theme .owl-nav [class*=owl-]:hover {
  opacity: 0.6;
}
.cs-slider-1 .slider-container-1 {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 550px;
  background-blend-mode: multiply;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-slider-1 .slider-container-2 {
  background-position: right !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  height: 550px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-slider-1 .slider-content-1 {
  max-width: 460px;
  width: 100%;
  padding-left: 30px;
}
.cs-slider-1 .desc-1 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 30px;
}
.cs-slider-1 .desc-2 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 30px;
}
.cs-slider-1 .action-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-slider-1 .action-menu-1 li {
  margin-right: 25px;
  margin-bottom: 10px;
}
.cs-slider-1 .action-menu-1 li:last-child {
  margin-right: 0px;
}
.cs-slider-1 .logo-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 15px;
  bottom: -120px;
  z-index: 1;
}
.cs-slider-1 .logo-m-1 li {
  margin-bottom: 15px;
}
.cs-slider-1 .logo-m-1 li:last-child {
  margin-bottom: 0;
}
.cs-slider-1 .logo-m-1 li img {
  min-height: 55px;
  max-width: 155px;
}

.cs-white-btn-1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: #ffffff;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #041614;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-white-btn-1 i {
  padding-left: 10px;
}
.cs-white-btn-1:hover {
  color: #ffffff;
  background-color: #041614;
}

.cs-white-outline-btn-1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: transparent;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-white-outline-btn-1 i {
  padding-left: 10px;
}
.cs-white-outline-btn-1:hover {
  color: #041614;
  background-color: #ffffff;
}

.cs-primary-btn-1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: #27C1B2;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-primary-btn-1 i {
  padding-left: 10px;
}
.cs-primary-btn-1:hover {
  color: #ffffff;
  background-color: #401FE5;
}

.cs-black-outline-btn-1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: transparent;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #041614;
  border: 1px solid #041614;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-black-outline-btn-1 i {
  padding-left: 10px;
}
.cs-black-outline-btn-1:hover {
  color: #ffffff;
  background-color: #041614;
}

.cs-secondary-btn-1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 58px;
  background-color: #401FE5;
  border-radius: 30px;
  min-width: 130px;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-secondary-btn-1 i {
  padding-right: 10px;
}
.cs-secondary-btn-1:hover {
  color: #401FE5;
  background-color: #ffffff;
  border-color: #401FE5;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
}

.cs-homepage-sec-2 {
  margin-top: -35px;
  margin-bottom: 80px;
  position: relative;
  z-index: 2;
}

.cs-loc-date-d-1 {
  max-width: 970px;
  min-height: 70px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 40px;
  border: 1px solid #3830DC;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin: 0 auto;
  align-items: center;
}
.cs-loc-date-d-1 .csld1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 310px;
  flex: 0 0 310px;
  padding: 6px 15px;
  border: 1px solid transparent;
  border-right-color: #3830DC;
  transition: all 0.2s ease;
}
.cs-loc-date-d-1 .csld1-col-1.active {
  background-color: #E7F2F2;
  border-radius: 40px;
  border-color: #3830DC;
}
.cs-loc-date-d-1 .csld1-col-1.active .cs-icon-box-1::after {
  content: "\e949";
  font-size: 12px;
}
.cs-loc-date-d-1 .csld1-col-1.active .search-m-d-1 {
  opacity: 1;
  visibility: visible;
}
.cs-loc-date-d-1 .csld1-col-1.deactive {
  border-right-color: #ffffff;
}
.cs-loc-date-d-1 .csld1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 500px;
  flex: 0 0 500px;
  padding: 6px 15px;
  border-radius: 40px;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}
.cs-loc-date-d-1 .csld1-col-2.active {
  background-color: #E7F2F2;
  border-radius: 40px;
  border-color: #3830DC;
}
.cs-loc-date-d-1 .csld1-col-2.active .cs-icon-box-1::after {
  content: "\e949";
  font-size: 12px;
}
.cs-loc-date-d-1 .csld1-col-2 .row .col-12:first-child .cs-icon-box-1::after {
  display: none;
}
.cs-loc-date-d-1 .csld1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 158px;
  flex: 0 0 158px;
  padding: 6px;
  text-align: right;
}

.cs-icon-box-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  cursor: pointer;
  position: relative;
  align-items: center;
}
.cs-icon-box-1::after {
  content: "\e95e";
  font-family: "bain" !important;
  pointer-events: none;
  font-size: 10px;
  line-height: 1;
  color: #041614;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.cs-icon-box-1 .csib1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 45px;
  flex: 0 0 45px;
  text-align: center;
}
.cs-icon-box-1 .csib1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: calc(100% - 45px);
  flex: 0 0 calc(100% - 45px);
}
.cs-icon-box-1 .desc-1 {
  font-size: 34px;
  line-height: 1;
  color: #7287FD;
  margin: 0;
}
.cs-icon-box-1 .desc-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
  margin: 0;
}
.cs-icon-box-1 label {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: #7287FD;
  margin-bottom: 8px;
  cursor: pointer;
}
.cs-icon-box-1 input {
  padding: 1px 0px;
  height: auto;
  border: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #041614;
  background-color: transparent;
}
.cs-icon-box-1 input:focus {
  box-shadow: none;
  outline: none;
  color: #041614;
  background-color: transparent;
}
.cs-icon-box-1 input::placeholder {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #041614;
}
.cs-icon-box-1 .search-m-d-1 {
  position: absolute;
  left: -10px;
  top: 75px;
  width: calc(100% + 25px);
  z-index: 9;
  border-radius: 40px;
  background-color: #E7F2F2;
  border: 1px solid #3830DC;
  padding: 20px 25px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.cs-icon-box-1 .search-m-d-1.active {
  opacity: 1;
  visibility: visible;
}
.cs-icon-box-1 .search-m-d-1 .smm-d-1 {
  position: relative;
  max-height: 150px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #F4F5F7;
}
.cs-icon-box-1 .search-m-d-1 .smm-d-1::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.cs-icon-box-1 .search-m-d-1 .smm-d-1::-webkit-scrollbar-track {
  background: #F4F5F7;
}
.cs-icon-box-1 .search-m-d-1 .smm-d-1::-webkit-scrollbar-thumb {
  background: #D3D3D3;
  border-radius: 20px;
}
.cs-icon-box-1 .search-m-d-1 .smm-d-1::-webkit-scrollbar-thumb:hover {
  background: #D3D3D3;
}
.cs-icon-box-1 .search-m-d-1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-icon-box-1 .search-m-d-1 ul li {
  line-height: 1;
}
.cs-icon-box-1 .search-m-d-1 a {
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
.cs-icon-box-1 .search-m-d-1 a:hover {
  opacity: 0.6;
}

.cs-datetime-picker-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.daterangepicker {
  background-color: #E7F2F2;
  border-radius: 40px;
  border: 1px solid #3830DC;
  user-select: none;
}
.daterangepicker::before, .daterangepicker::after {
  display: none;
}
.daterangepicker .calendar-table {
  background-color: #E7F2F2;
  border: 0;
}
.daterangepicker .drp-calendar.left {
  border-right: 1px solid #3830DC;
  padding: 15px 0 8px 8px;
}
.daterangepicker .drp-calendar.right {
  padding: 15px 8px 8px 8px;
}
.daterangepicker .drp-buttons {
  border-color: #3830DC;
  padding: 15px 20px;
}
.daterangepicker td {
  position: relative;
}
.daterangepicker td.in-range {
  background-color: rgba(39, 193, 178, 0.3);
}
.daterangepicker td.disabled {
  color: #AAAAAA;
  background: transparent;
}
.daterangepicker td.off {
  color: #AAAAAA;
  background: transparent;
}
.daterangepicker td.off.in-range {
  color: #AAAAAA;
  background: transparent;
}
.daterangepicker td.off.start-date {
  color: #AAAAAA;
  background: transparent;
}
.daterangepicker td.off.end-date {
  color: #AAAAAA;
  background: transparent;
}
.daterangepicker td.available:hover {
  background-color: rgba(39, 193, 178, 0.4);
  color: #272727;
}
.daterangepicker td.active {
  background-color: #27C1B2;
}
.daterangepicker td.active:hover {
  background-color: #27C1B2;
}
.daterangepicker td.today {
  background-color: transparent;
  color: #272727;
  border: 2px solid #5DBEB2;
  font-weight: 700;
}
.daterangepicker th.available:hover {
  background-color: rgba(39, 193, 178, 0.4);
  color: #272727;
}
.daterangepicker option.disabled {
  color: #AAAAAA;
  background: transparent;
}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  background: transparent;
  border: 1px solid #3830DC;
  border-radius: 10px;
  padding: 2px 5px;
}
.daterangepicker .applyBtn1 {
  background-color: #27C1B2 !important;
  border-radius: 10px !important;
  height: 30px !important;
  padding: 2px 10px !important;
  min-width: 110px !important;
  font-size: 12px !important;
  line-height: 1 !important;
  font-weight: 700 !important;
  color: #F6FDFD !important;
}
.daterangepicker .applyBtn1:focus {
  outline: none;
  box-shadow: none;
}
.daterangepicker .cancelBtn1 {
  background-color: transparent !important;
  border-radius: 10px !important;
  height: 30px !important;
  padding: 2px 10px !important;
  min-width: 110px !important;
  font-size: 12px !important;
  line-height: 1 !important;
  font-weight: 700 !important;
  color: #696969 !important;
  text-decoration: underline !important;
}
.daterangepicker .cancelBtn1:focus {
  outline: none;
  box-shadow: none;
}
.daterangepicker .drp-selected {
  display: none;
}
.daterangepicker.single .drp-calendar.left {
  border-right: 0;
}
.daterangepicker.single .drp-buttons {
  padding: 15px 8px;
  text-align: center;
}
.daterangepicker.single .drp-buttons .btn {
  margin-left: 0;
  margin-right: 8px;
}

.cs-tab-slider-d-1 {
  position: relative;
  overflow-x: hidden;
}

.cs-homepage-sec-3 {
  margin-bottom: 80px;
}

.cs-slider-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cs-slider-row-1 .cssr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 300px;
  flex: 0 0 300px;
}
.cs-slider-row-1 .cssr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 300px);
  flex: 0 0 calc(100% - 300px);
  padding-left: 70px;
}
.cs-slider-row-1 .cssr1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 300px);
  flex: 0 0 calc(100% - 300px);
  padding-right: 70px;
}

.cs-slider-cont-1 .bg-image-1 {
  position: absolute;
  right: -50px;
  top: 0;
  z-index: -1;
}
.cs-slider-cont-1 .bg-image-1.right {
  left: -50px;
}
.cs-slider-cont-1 .bg-image-1 img {
  width: 600px;
  max-width: unset;
}

.cs-slider-2.owl-theme .owl-dots .owl-dot span {
  margin: 5px;
  background: #D1D1D1;
  transition: all 0.2s ease;
}
.cs-slider-2.owl-theme .owl-dots .owl-dot.active span {
  background-color: #27C1B2;
  width: 24px;
}

.cs-tab-container-1 {
  padding: 30px 0px 10px;
  max-width: 820px;
}

.cs-wbg-head-2 {
  font-size: 44px;
  line-height: 58px;
  font-weight: 800;
  color: #041614;
  margin-bottom: 50px;
}
.cs-wbg-head-2 span {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0px 3px;
}
.cs-wbg-head-2 span::before {
  content: "";
  height: 25px;
  width: 100%;
  display: block;
  background-color: #BEEBE7;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
  border-radius: 12px;
}
.cs-wbg-head-2.color-1 span::before {
  background-color: #D0D7FF;
}
.cs-wbg-head-2.color-2 span::before {
  background-color: #DEDEDE;
}

.cs-navTabs-1 {
  justify-content: space-between;
}
.cs-navTabs-1.nav {
  margin-bottom: 40px;
}
.cs-navTabs-1.nav-tabs {
  border-color: #707070;
}
.cs-navTabs-1.nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #041614;
  padding: 15px 20px;
  position: relative;
  transition: all 0.3s ease;
}
.cs-navTabs-1.nav-tabs .nav-link.active {
  color: #27C1B2;
  background-color: transparent;
  border-color: unset;
}
.cs-navTabs-1.nav-tabs .nav-link.active::after {
  content: "";
  width: 50px;
  height: 5px;
  border-radius: 5px;
  background-color: #27C1B2;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.cs-navTabs-1.nav-tabs .nav-item.show {
  color: #27C1B2;
  background-color: transparent;
  border-color: unset;
}
.cs-navTabs-1.color-1.nav-tabs .nav-link.active {
  color: #401FE5;
}
.cs-navTabs-1.color-1.nav-tabs .nav-link.active::after {
  background-color: #401FE5;
}
.cs-navTabs-1.color-1.nav-tabs .nav-item.show {
  color: #401FE5;
}

.cs-navTabs-content-1 {
  padding: 0px 25px;
}
.cs-navTabs-content-1 .in-content-1 h1, .cs-navTabs-content-1 .in-content-1 h2, .cs-navTabs-content-1 .in-content-1 h3, .cs-navTabs-content-1 .in-content-1 h4, .cs-navTabs-content-1 .in-content-1 h5, .cs-navTabs-content-1 .in-content-1 h6 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 25px;
}
.cs-navTabs-content-1 .in-content-1 p {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-navTabs-content-1 .in-content-1 ul, .cs-navTabs-content-1 .in-content-1 ol {
  padding-right: 15px;
}
.cs-navTabs-content-1 .in-content-1 li {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-navTabs-content-1 .in-content-1 a {
  color: #27C1B2;
}

.cs-homepage-sec-4 {
  margin-bottom: 80px;
}

.cs-homepage-sec-5 {
  margin-bottom: 65px;
}
.cs-homepage-sec-5 .left-side {
  text-align: left;
  margin-bottom: 15px;
  border-right: 2px solid #5DBEB2;
}
.cs-homepage-sec-5 .right-side {
  text-align: right;
  margin-bottom: 15px;
}
.cs-homepage-sec-5 .image-d-1 {
  margin-bottom: 10px;
}
.cs-homepage-sec-5 .content-d-1 {
  margin-bottom: 40px;
}

.cs-global-desc-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 25px;
}

.cs-primary-btn-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: #27C1B2;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-primary-btn-2:hover {
  color: #ffffff;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
}

.cs-secondary-btn-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: #401FE5;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-secondary-btn-2:hover {
  color: #ffffff;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(64, 31, 229, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(64, 31, 229, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(64, 31, 229, 0.65);
}

.cs-homepage-sec-6 {
  margin-bottom: 60px;
}
.cs-homepage-sec-6 .header-area-1 {
  padding-right: 120px;
}
.cs-homepage-sec-6 .slider-area-1 {
  padding-left: 14.5%;
}

.cs-slider-3.owl-carousel .owl-stage {
  padding-left: 0 !important;
}
.cs-slider-3.owl-theme .owl-nav {
  position: absolute;
  top: -100px;
  left: -15px;
  max-width: 1230px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  text-align: right;
  padding: 0px 15px;
}
.cs-slider-3.owl-theme .owl-nav [class*=owl-] {
  font-size: 45px;
  line-height: 1;
  color: #401FE5;
  margin: 0 10px;
}
.cs-slider-3.owl-theme .owl-nav [class*=owl-]:hover {
  color: #401FE5;
  background-color: transparent;
}
.cs-slider-3.owl-theme .owl-nav .disabled {
  color: #696969;
  opacity: 1;
}
.cs-slider-3.owl-theme .owl-nav .disabled:hover {
  color: #696969;
}

.cs-comment-box-1 {
  padding: 25px 20px;
  margin-bottom: 15px;
  background-color: #F6FDFD;
  border: 0.8px solid #5DBEB2;
  border-radius: 20px;
}
.cs-comment-box-1 .head-area-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 20px;
}
.cs-comment-box-1 .head-area-1 .ha1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 112px;
  flex: 0 0 112px;
}
.cs-comment-box-1 .head-area-1 .ha1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: calc(100% - 112px);
  flex: 0 0 calc(100% - 112px);
}
.cs-comment-box-1 .user-profile-1 {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: #401FE5;
  border-radius: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  line-height: 60px;
  font-weight: 700;
  color: #ffffff;
  height: 92px;
  width: 100%;
}
.cs-comment-box-1 .head-1 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 5px;
}
.cs-comment-box-1 .desc-1 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 5px;
}
.cs-comment-box-1 .desc-2 {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 20px;
  max-height: 60px;
  min-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cs-comment-box-1 .desc-3 {
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 0px;
}
.cs-comment-box-1 .link-1 {
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #041614;
  text-decoration: underline !important;
}
.cs-comment-box-1 .star-list-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-comment-box-1 .star-list-1 li {
  font-size: 18px;
  color: #F0C76A;
  margin-right: 5px;
}
.cs-comment-box-1 .star-list-1 li:last-child {
  margin-right: 0px;
}
.cs-comment-box-1 .star-list-1 li::before {
  content: "\e922";
  font-family: "bain" !important;
}
.cs-comment-box-1 .star-list-1 li.full-star::before {
  content: "\e94d";
}
.cs-comment-box-1 .star-list-1 li.half-star::before {
  content: "\e94c";
}
.cs-comment-box-1 .bottom-area-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.cs-comment-box-1 .bottom-area-1 .ba1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: calc(100% - 130px);
  flex: 0 0 calc(100% - 130px);
}
.cs-comment-box-1 .bottom-area-1 .ba1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 130px;
  flex: 0 0 130px;
  text-align: right;
}

.cs-homepage-sec-7 {
  margin-bottom: 40px;
}
.cs-homepage-sec-7 .header-area-1 {
  padding-right: 120px;
}
.cs-homepage-sec-7 .cs-global-desc-1 {
  margin-bottom: 60px;
}
.cs-homepage-sec-7 .cs-slider-4.owl-theme .owl-nav {
  top: -185px;
}

.cs-slider-4.owl-theme .owl-nav {
  position: absolute;
  top: -100px;
  left: -15px;
  max-width: 1230px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  text-align: right;
  padding: 0px 15px;
}
.cs-slider-4.owl-theme .owl-nav [class*=owl-] {
  font-size: 45px;
  line-height: 1;
  color: #401FE5;
  margin: 0 10px;
}
.cs-slider-4.owl-theme .owl-nav [class*=owl-]:hover {
  color: #401FE5;
  background-color: transparent;
}
.cs-slider-4.owl-theme .owl-nav .disabled {
  color: #696969;
  opacity: 1;
}
.cs-slider-4.owl-theme .owl-nav .disabled:hover {
  color: #696969;
}

.cs-city-box-1 {
  text-align: center;
  margin-bottom: 15px;
}
.cs-city-box-1 .image-div-1 {
  margin-bottom: 15px;
}
.cs-city-box-1 .image-div-1 img {
  max-height: 195px;
  width: auto !important;
  margin: 0 auto;
}
.cs-city-box-1 .head-1 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  color: #041614;
  margin: 0;
}
.cs-city-box-1 .head-1 i {
  font-size: 17px;
  padding-left: 10px;
}

.cs-homepage-sec-8 {
  margin-bottom: 70px;
}
.cs-homepage-sec-8 .cs-full-container-1 {
  overflow-x: hidden;
}
.cs-homepage-sec-8 .cs-wbg-head-2 {
  margin-bottom: 20px;
}
.cs-homepage-sec-8 .cs-global-desc-1 {
  max-width: 490px;
}

.cs-download-app-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}
.cs-download-app-row-1 .csdar1-col-2 {
  position: relative;
}
.cs-download-app-row-1 .download-app-i-1 {
  height: 580px;
}
.cs-download-app-row-1 .download-app-i-1 img {
  max-width: 100%;
  width: 100%;
}

.cs-download-app-2 {
  margin-bottom: 0px;
  position: absolute;
  bottom: 0;
  left: 15.5%;
}
.cs-download-app-2 .image-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-download-app-2 .image-m-1 li {
  margin-bottom: 15px;
  margin-right: 10px;
}
.cs-download-app-2 .image-m-1 li:last-child {
  margin-right: 0px;
}
.cs-download-app-2 .image-m-1 li img {
  max-height: 50px;
  min-height: 50px;
  width: 100%;
  height: 100%;
}

.cs-homepage-sec-9 {
  margin-bottom: 70px;
}

.cs-hwl-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  margin-bottom: 35px;
}
.cs-hwl-row-1 .cshwlr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 150px);
  flex: 0 0 calc(100% - 150px);
  margin-bottom: 15px;
}
.cs-hwl-row-1 .cshwlr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 150px;
  flex: 0 0 150px;
  text-align: right;
  margin-bottom: 15px;
}

.cs-global-link-1 {
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #401FE5;
  margin: 0;
  text-decoration: underline !important;
  transition: all 0.3s ease;
}
.cs-global-link-1:hover {
  color: #27C1B2;
}

.cs-campaign-box-1 {
  margin-bottom: 40px;
}
.cs-campaign-box-1 .image-div-1 {
  margin-bottom: 10px;
}
.cs-campaign-box-1 .image-div-1 img {
  border-radius: 20px 20px 0 0;
  height: 250px;
}
.cs-campaign-box-1 .text-div-1 .head-1 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
  max-height: 38px;
  min-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.cs-campaign-box-1 .text-div-1 .desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
  max-height: 48px;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cs-campaign-box-1 .text-div-1 .desc-1 a {
  color: #401FE5;
}
.cs-campaign-box-1 .text-div-1 .desc-2 {
  text-align: right;
}
.cs-campaign-box-1 .text-div-1 .link-1 {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #401FE5;
  text-decoration: underline !important;
  transition: all 0.3s ease;
}
.cs-campaign-box-1 .text-div-1 .link-1:hover {
  color: #27C1B2;
  text-decoration: none !important;
}

.cs-homepage-sec-10 {
  margin-bottom: 60px;
}
.cs-homepage-sec-10 .slider-content-1 {
  padding-top: 115px;
}

.cs-blog-content-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cs-blog-content-row-1 .csbcr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 340px;
  flex: 0 0 340px;
  margin-bottom: 15px;
}
.cs-blog-content-row-1 .csbcr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 340px);
  flex: 0 0 calc(100% - 340px);
  margin-bottom: 15px;
}

.cs-blog-box-1 {
  margin-bottom: 15px;
}
.cs-blog-box-1 .image-div-1 {
  margin-bottom: 10px;
  border-radius: 20px 20px 0 0;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 113% 100% !important;
  height: 200px;
  transition: all 0.4s ease;
}
.cs-blog-box-1:hover .image-div-1 {
  background-size: 133% 120% !important;
}
.cs-blog-box-1 .head-1 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px;
  max-height: 60px;
  min-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cs-blog-box-1 .desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
  max-height: 48px;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cs-blog-box-1 .link-1 {
  display: block;
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #401FE5;
  transition: all 0.3s ease;
}
.cs-blog-box-1 .link-1 span {
  text-decoration: underline;
}
.cs-blog-box-1 .link-1 i {
  font-size: 12px;
  padding-left: 5px;
}
.cs-blog-box-1 .link-1:hover {
  color: #27C1B2;
}
.cs-blog-box-1 .link-1:hover span {
  text-decoration: none;
}

.cs-homepage-sec-11 {
  margin-bottom: 60px;
}
.cs-homepage-sec-11 .content-area-1 {
  max-width: 950px;
  margin: 0 auto;
  padding: 30px 0px 0px;
}

.cs-accordion-1 .card {
  border: 0;
  border-bottom: 0.5px solid #D6DADE;
  margin-bottom: 5px;
}
.cs-accordion-1 .card-header {
  border: 0;
  border-radius: 0;
  padding: 15px;
  background-color: transparent;
}
.cs-accordion-1 .card-header:first-child {
  border-radius: 0;
}
.cs-accordion-1 .btn-link {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  padding: 0 15px 0 15px;
  border: 0;
  width: 100%;
  text-align: left;
  white-space: normal;
  position: relative;
}
.cs-accordion-1 .btn-link:hover, .cs-accordion-1 .btn-link:focus {
  color: #041614;
}
.cs-accordion-1 .btn-link::before {
  content: "";
  width: 5px;
  height: 105%;
  border-radius: 4px;
  background-color: #27C1B2;
  position: absolute;
  left: 0;
  top: -2px;
  z-index: 1;
}
.cs-accordion-1 .btn-link::after {
  content: "\e95c";
  font-family: "bain" !important;
  font-size: 8px;
  color: #041614;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s ease;
}
.cs-accordion-1 .btn-link.collapsed::after {
  transform: translateY(-50%) rotate(180deg);
}
.cs-accordion-1 .card-body {
  padding: 0;
}
.cs-accordion-1 .card-body h1, .cs-accordion-1 .card-body h2, .cs-accordion-1 .card-body h3, .cs-accordion-1 .card-body h4, .cs-accordion-1 .card-body h5, .cs-accordion-1 .card-body h6 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 25px;
}
.cs-accordion-1 .card-body p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-accordion-1 .card-body ul, .cs-accordion-1 .card-body ol {
  padding-right: 15px;
}
.cs-accordion-1 .card-body li {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-accordion-1 .card-body a {
  color: #401FE5;
}

.cs-accordion-2 .card {
  border: 0;
  border-bottom: 0;
  margin-bottom: 0px;
  border-bottom: 0.5px solid #D6DADE;
}
.cs-accordion-2 .card-header {
  border: 0;
  border-radius: 0;
  padding: 15px 35px;
  background-color: transparent;
}
.cs-accordion-2 .card-header:first-child {
  border-radius: 0;
}
.cs-accordion-2 .btn-link {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #401FE5;
  padding: 0 15px 0 0px;
  border: 0;
  width: 100%;
  text-align: left;
  white-space: normal;
  position: relative;
}
.cs-accordion-2 .btn-link:hover, .cs-accordion-2 .btn-link:focus {
  color: #401FE5;
}
.cs-accordion-2 .btn-link::before {
  display: none;
}
.cs-accordion-2 .btn-link::after {
  content: "\e907";
  font-family: "bain" !important;
  font-size: 14px;
  color: #401FE5;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s ease;
}
.cs-accordion-2 .btn-link.collapsed {
  color: #041614;
}
.cs-accordion-2 .btn-link.collapsed::after {
  color: #041614;
  content: "\e94a";
  transform: translateY(-50%) rotate(0deg);
}
.cs-accordion-2 .card-body {
  padding: 25px 35px;
  background-color: #F6FDFD;
}
.cs-accordion-2 .card-body h1, .cs-accordion-2 .card-body h2, .cs-accordion-2 .card-body h3, .cs-accordion-2 .card-body h4, .cs-accordion-2 .card-body h5, .cs-accordion-2 .card-body h6 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 25px;
}
.cs-accordion-2 .card-body p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-accordion-2 .card-body ul, .cs-accordion-2 .card-body ol {
  padding-right: 15px;
}
.cs-accordion-2 .card-body li {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-accordion-2 .card-body a {
  color: #401FE5;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.cs-modal-1 .modal-dialog {
  max-width: 650px;
  margin: 3rem auto;
  padding: 15px;
}
.cs-modal-1 .modal-content {
  border-radius: 12px;
  padding: 0;
  border: 0;
}
.cs-modal-1 .close {
  font-size: 22px;
  line-height: 1;
  opacity: 1;
  color: #1A1A1A;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}
.cs-modal-1 .modal-body {
  padding: 0;
  border-radius: 12px;
}
.cs-modal-1 .modal-body img {
  border-radius: 12px;
  width: 100%;
  max-width: unset;
}

.cs-modal-2 .modal-dialog-centered {
  max-width: 650px;
  margin: 0 auto;
  padding: 15px;
}
.cs-modal-2 .modal-content {
  border-radius: 12px;
  padding: 0;
  border: 0;
}
.cs-modal-2 .close {
  font-size: 22px;
  line-height: 1;
  opacity: 1;
  color: #1A1A1A;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}
.cs-modal-2 .modal-body {
  padding: 0;
  border-radius: 12px;
}
.cs-modal-2 .modal-body .image-d-1 {
  border-radius: 12px 12px 0 0;
}
.cs-modal-2 .modal-body .image-d-1 img {
  border-radius: 12px 12px 0 0;
  width: 100%;
  max-width: unset;
}
.cs-modal-2 .modal-body .text-d-1 {
  padding: 15px;
  background-color: #E7F2F2;
  border-radius: 0 0 12px 12px;
}
.cs-modal-2 .modal-body .text-d-1 .in-content-1 {
  max-width: 510px;
  margin: 0 auto;
}
.cs-modal-2 .modal-body .text-d-1 h1, .cs-modal-2 .modal-body .text-d-1 h2, .cs-modal-2 .modal-body .text-d-1 h3, .cs-modal-2 .modal-body .text-d-1 h4, .cs-modal-2 .modal-body .text-d-1 h5, .cs-modal-2 .modal-body .text-d-1 h6 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 20px;
}
.cs-modal-2 .modal-body .text-d-1 p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 15px;
}
.cs-modal-2 .modal-body .text-d-1 ul, .cs-modal-2 .modal-body .text-d-1 ol {
  padding-right: 15px;
}
.cs-modal-2 .modal-body .text-d-1 li {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 15px;
}
.cs-modal-2 .modal-body .text-d-1 a {
  color: #401FE5;
}

.cs-login-main-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.cs-login-main-row-1 .cslmr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 50%;
  flex: 0 0 50%;
}
.cs-login-main-row-1 .cslmr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 50%;
  flex: 0 0 50%;
}

.cs-login-bg-div-1 {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: calc(100vh - 90px);
  height: 100%;
  background-blend-mode: multiply;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  border-radius: 0 50px 50px 0;
}

.cs-login-text-c-1 {
  max-width: 700px;
  padding: 0px 15px;
  position: relative;
  width: 100%;
  margin: 0 0 80px auto;
  z-index: 1;
}
.cs-login-text-c-1::before {
  content: url(../img/other/cloud-bg-1.svg);
  position: absolute;
  left: -65px;
  top: -110px;
  z-index: -1;
}
.cs-login-text-c-1 .text-d-1 {
  max-width: 640px;
}
.cs-login-text-c-1 .text-d-1 .head-1 {
  font-size: 54px;
  line-height: 70px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.cs-login-text-c-1 .text-d-1 .head-1 strong {
  font-weight: 700;
}
.cs-login-text-c-1 .text-d-1 .desc-1 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0px;
}

.cs-login-form-c-1 {
  max-width: 700px;
  padding: 30px 15px;
  position: relative;
  width: 100%;
  margin: 0 auto 0 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-login-form-c-1 .form-d-1 {
  max-width: 490px;
  margin: 0 auto;
  text-align: center;
}
.cs-login-form-c-1 .form-d-2 {
  max-width: 320px;
  margin: 0 auto;
}
.cs-login-form-c-1 .form-d-2 .form-input-1 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.cs-wbg-head-3 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 800;
  color: #041614;
  margin-bottom: 20px;
}
.cs-wbg-head-3 span {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0px 3px;
}
.cs-wbg-head-3 span::before {
  content: "";
  height: 25px;
  width: 100%;
  display: block;
  background-color: #BEEBE7;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
  border-radius: 12px;
}
.cs-wbg-head-3.color-1 span::before {
  background-color: #D0D7FF;
}
.cs-wbg-head-3.color-2 span::before {
  background-color: #DEDEDE;
}

.cs-global-desc-2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 25px;
}

.cs-form-1 .form-group {
  margin-bottom: 20px;
}
.cs-form-1 .invalid-feedback {
  padding: 5px 20px;
}
.cs-form-1 .iti {
  width: 100% !important;
}
.cs-form-1 .iti .iti__arrow {
  width: 10px;
  height: 10px;
  border: 0;
  position: relative;
  margin-left: 5px;
}
.cs-form-1 .iti .iti__arrow::after {
  content: "\e95e";
  font-family: "bain" !important;
  color: #696969;
  font-size: 6px;
  position: absolute;
  right: 0px;
  top: 2px;
}
.cs-form-1 .iti .iti__arrow.iti__arrow--up::after {
  content: "\e95c";
}
.cs-form-1 .iti .iti--separate-dial-code, .cs-form-1 .iti .iti__selected-dial-code {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #696969;
}
.cs-form-1 .iti--separate-dial-code .iti__selected-flag {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  padding: 0 8px 0 8px;
}
.cs-form-1 .iti--separate-dial-code .iti__selected-flag::after {
  content: "";
  width: 0.7px;
  height: 18px;
  background-color: #707070;
  opacity: 0.4;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.cs-form-1 .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.cs-form-1 label {
  display: block;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 12px;
  text-align: left;
}
.cs-form-1 ::placeholder {
  color: rgba(105, 105, 105, 0.5);
}
.cs-form-1 input:not([type=submit]), .cs-form-1 select {
  border-radius: 14px;
  border: 0.5px solid #696969;
  height: 50px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #041614;
  transition: all 0.2s ease;
  padding: 5px 20px;
}
.cs-form-1 input:not([type=submit]):focus, .cs-form-1 select:focus {
  outline: none;
  border-color: #27C1B2;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}
.cs-form-1 .form-control:disabled, .cs-form-1 .form-control:read-only {
  background-color: #ffffff;
  cursor: not-allowed;
}
.cs-form-1 .form-control[id^=newFileModalUpload] {
  height: 100%;
  cursor: pointer !important;
}
.cs-form-1 textarea {
  resize: none;
  border-radius: 14px;
  border: 0.5px solid #696969;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  transition: all 0.2s ease;
  padding: 15px 20px;
}
.cs-form-1 textarea:focus {
  outline: none;
  border-color: #27C1B2;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}
.cs-form-1 .info-text-1 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 20px;
}
.cs-form-1 .info-text-1 a {
  font-weight: 700;
  color: #401FE5;
  text-decoration: underline !important;
}
.cs-form-1 .info-text-1 a:hover {
  text-decoration: none !important;
}
.cs-form-1 .date-box-1 {
  position: relative;
}
.cs-form-1 .date-box-1 label::before {
  content: "\e94f";
  font-family: "bain" !important;
  font-size: 20px;
  color: #696969;
  position: absolute;
  left: 10px;
  bottom: 15px;
  z-index: 1;
}
.cs-form-1 .date-box-1 input {
  padding-left: 45px;
}
.cs-form-1 .date-i-1 label::before {
  content: "\e94f";
  font-family: "bain" !important;
  font-size: 23px;
  color: #696969;
  position: absolute;
  left: 15px;
  top: 48px;
}
.cs-form-1 .date-i-1 input {
  padding-left: 40px;
}

.cs-primary-btn-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: #27C1B2;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-primary-btn-3:hover {
  color: #ffffff;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
}
.cs-primary-btn-3:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cs-verification-i-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}
.cs-verification-i-1 li {
  margin-right: 15px;
  width: 50px;
}
.cs-verification-i-1 li:last-child {
  margin-right: 0;
}
.cs-verification-i-1 li input {
  font-size: 30px !important;
  color: #242424 !important;
  font-weight: 700 !important;
  height: 64px !important;
  text-align: center;
  padding: 5px !important;
  border: 1.2px solid #DEEAEA !important;
  border-radius: 8px !important;
}
.cs-verification-i-1 li input.active {
  border-color: #27C1B2 !important;
}
.cs-verification-i-1.error input {
  border-color: #E04149 !important;
}
.cs-verification-i-1.error input.active {
  border-color: #E04149 !important;
}

.cs-countdown-div-1 {
  position: relative;
  padding: 25px 10px 40px;
}
.cs-countdown-div-1 .countdown-div-data {
  position: absolute;
  top: 46%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  color: #041614;
}
.cs-countdown-div-1 svg {
  transform: rotate(-90deg);
}
.cs-countdown-div-1 .circle_animation {
  stroke-dasharray: 315;
  stroke-dashoffset: 315;
}

.cs-sweetalert2-1 {
  max-width: 660px !important;
  width: 100% !important;
}
.cs-sweetalert2-1 .swal2-toast {
  top: 90px;
  cursor: pointer;
}
.cs-sweetalert2-1 .swal2-toast.swal2-popup {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(28, 28, 28, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(28, 28, 28, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(28, 28, 28, 0.1);
  min-height: 90px;
  align-items: center;
  border-radius: 10px;
}
.cs-sweetalert2-1 .swal2-toast.swal2-popup::before {
  content: "";
  background-color: #ffffff;
  width: 25px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.cs-sweetalert2-1 .swal2-toast.swal2-popup.swal2-toast .swal2-title {
  margin: 0px 30px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #272727;
}
.cs-sweetalert2-1.color-1 .swal2-toast.swal2-popup {
  background-color: #D0D7FF;
}
.cs-sweetalert2-1.color-1 .swal2-toast.swal2-popup::before {
  background-color: #3830DC;
}
.cs-sweetalert2-1.color-2 .swal2-toast.swal2-popup {
  background-color: #FFCCCF;
}
.cs-sweetalert2-1.color-2 .swal2-toast.swal2-popup::before {
  background-color: #E04149;
}
.cs-sweetalert2-1.color-3 .swal2-toast.swal2-popup {
  background-color: #FFE0B5;
}
.cs-sweetalert2-1.color-3 .swal2-toast.swal2-popup::before {
  background-color: #EFA036;
}

.cs-all-overflow-h {
  overflow: hidden;
}

.cs-checkbox-1 {
  position: relative;
}
.cs-checkbox-1 label {
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  color: #041614 !important;
  margin: 0;
  cursor: pointer;
  border: 0.5px solid #696969;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 15px 20px;
  border-radius: 15px;
  transition: all 0.3s ease;
}
.cs-checkbox-1 input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.cs-checkbox-1 input:checked + label {
  background-color: #27C1B2;
  border-color: #27C1B2;
  color: #ffffff !important;
}

.custom-range-1 {
  padding: 0px 25px;
  margin-bottom: 40px;
}
.custom-range-1 .text-div-1 {
  height: 1px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}
.custom-range-1 .text-div-1 input {
  display: none;
}
.custom-range-1 .text-div-1 span {
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #1A1A1A;
  width: 100px;
  text-align: center;
  padding-top: 5px;
}
.custom-range-1 .ui-widget.ui-widget-content {
  border: 0;
  height: 8px;
  border-radius: 10px;
  background-color: #DBDBDB;
}
.custom-range-1 .ui-widget-header {
  background: #27C1B2;
}
.custom-range-1 .ui-slider .ui-slider-handle {
  background: #27C1B2;
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.custom-range-1 .ui-slider-horizontal .ui-slider-handle {
  top: -0.5em;
  margin-left: -12px;
}
.custom-range-1 .ui-slider-horizontal .ui-slider-handle:focus {
  outline: none;
}

.cs-signal-cont-1 .cssc1-d-1 {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.cs-signal-cont-1 .cssc1-d-1.active {
  opacity: 1;
  visibility: visible;
}
.cs-signal-cont-1 .cssc1-d-1 .cssc1d1-inn-1 {
  position: absolute;
  right: -50px;
  top: 90px;
  z-index: 10;
  background-color: #ffffff;
  border: 0.5px solid #EDEDED;
  border-radius: 20px;
  width: 475px;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(113, 113, 113, 0.1);
  padding: 0px 5px;
}
.cs-signal-cont-1 .cssc1-d-1 .cssc1d1-inn-2 {
  position: fixed;
  left: 0;
  top: 90px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 90px);
  background-color: #000000;
  opacity: 0.6;
  z-index: 9;
}
.cs-signal-cont-1 .cssc1-d-1 .close-btn-a-1 {
  padding: 10px 15px 0px;
  text-align: right;
}
.cs-signal-cont-1 .cssc1-d-1 .close-btn-a-1 .close-btn-1 {
  width: 30px;
  height: 30px;
  padding: 3px;
  font-size: 16px;
  line-height: 1;
  color: #1A1A1A;
  background-color: transparent;
  border: 0;
}
.cs-signal-cont-1 .cssc1-d-1 .form-content-1 {
  position: relative;
  max-height: 65vh;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #F4F5F7;
}
.cs-signal-cont-1 .cssc1-d-1 .form-content-1::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.cs-signal-cont-1 .cssc1-d-1 .form-content-1::-webkit-scrollbar-track {
  background: #F4F5F7;
}
.cs-signal-cont-1 .cssc1-d-1 .form-content-1::-webkit-scrollbar-thumb {
  background: #D3D3D3;
  border-radius: 20px;
}
.cs-signal-cont-1 .cssc1-d-1 .form-content-1::-webkit-scrollbar-thumb:hover {
  background: #D3D3D3;
}
.cs-signal-cont-1 .cssc1-d-1 .form-bottom-content-1 {
  padding: 30px 0px 20px;
  max-width: 325px;
  margin: 0 auto;
}
.cs-signal-cont-1 .cssc1-d-1 .form-val-cont-1 {
  padding: 15px 15px 12px;
}
.cs-signal-cont-1 .cssc1-d-1 .form-val-cont-1.border-w-1 {
  border-bottom: 1px solid #E8E8E8;
}
.cs-signal-cont-1 .cssc1-d-1 .form-val-cont-1 .fvc1-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 20px;
  text-align: left;
}
.cs-signal-cont-1 .cssc1-d-1 .form-val-cont-1 .item-list-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-signal-cont-1 .cssc1-d-1 .form-val-cont-1 .item-list-1 li {
  line-height: 1;
  margin-right: 24px;
  margin-bottom: 10px;
}
.cs-signal-cont-1 .cssc1-d-1 .form-val-cont-1 .item-list-1 li:nth-child(3n) {
  margin-right: 0px;
}

.cs-search-locDate-area-1 {
  background-color: #ffffff;
  padding: 15px 0px 25px;
}
.cs-search-locDate-area-1 .inn-cont-1 {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
}

.cs-rentcar-section-2 {
  position: relative;
  z-index: 1;
  transition: all 0.4s ease;
}
.cs-rentcar-section-2.active::before {
  content: "";
  -webkit-backdrop-filter: saturate(200%) blur(5px);
  backdrop-filter: saturate(200%) blur(5px);
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.cs-rentcar-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.cs-rentcar-row-1 .csrcr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 50%;
  flex: 0 0 50%;
  min-height: calc(100vh - 200px);
  border: 1px solid #EDEDED;
  border-top: 0px;
}
.cs-rentcar-row-1 .csrcr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 50%;
  flex: 0 0 50%;
}

.cs-page-filter-d-1 {
  background-color: #ffffff;
  border: 1px solid #EDEDED;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 25px 0px 10px;
  margin-bottom: 15px;
}
.cs-page-filter-d-1 .inner-f-div-1 {
  max-width: 740px;
  width: 100%;
  padding: 0px 30px;
  margin: 0 0 0 auto;
}
.cs-page-filter-d-1 .filter-mm-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 5;
}
.cs-page-filter-d-1 .filter-mm-1 .fmm-item-1 {
  position: relative;
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 15px;
}
.cs-page-filter-d-1 .filter-mm-1 .fmm-item-1:last-child {
  margin-right: 0px;
}
.cs-page-filter-d-1 .filter-mm-1 .fmm-action-1 {
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 12px;
  min-height: 50px;
  min-width: 130px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #041614;
}
.cs-page-filter-d-1 .filter-mm-1 .fmm-action-1 i {
  padding-right: 12px;
  position: relative;
  top: -1px;
}
.cs-page-filter-d-1 .fmm-f-div-1 {
  background-color: #ffffff;
  border: 0.5px solid #EDEDED;
  border-radius: 14px;
  width: 100%;
  min-width: 490px;
  padding: 10px 0px 0px;
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 4;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
}
.cs-page-filter-d-1 .fmm-f-div-1.active {
  opacity: 1;
  visibility: visible;
}
.cs-page-filter-d-1 .fmm-f-div-1.type-2 {
  min-width: 320px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-input-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-input-m-1 li {
  line-height: 1;
  border-bottom: 1px solid rgba(237, 237, 237, 0.5);
  padding: 15px 25px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-top-area-1 {
  padding: 10px 15px;
  text-align: right;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-top-area-1 .close-btn-1 {
  width: 30px;
  height: 30px;
  padding: 3px;
  font-size: 16px;
  line-height: 1;
  color: #1A1A1A;
  background-color: transparent;
  border: 0;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-middle-area-1 {
  position: relative;
  max-height: 45vh;
  min-height: 45vh;
  overflow: hidden;
  overflow-y: auto;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-bottom-area-1 {
  padding: 30px 30px 15px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-bottom-area-1 a, .cs-page-filter-d-1 .fmm-f-div-1 .fmm-bottom-area-1 button {
  min-width: auto;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 {
  padding: 20px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1.border-w-1 {
  border-bottom: 1px solid #E8E8E8;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 20px;
  text-align: left;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-d-1 .fmm-item-list-1 {
  padding-top: 20px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-d-1 .fmm-item-list-2 {
  padding-top: 20px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1.jsAccDiv1 .fmm-head-1 {
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1.jsAccDiv1 .fmm-head-1::after {
  content: "\e95e";
  font-family: "bain" !important;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  font-size: 10px;
  transition: all 0.3s ease;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1.jsAccDiv1 .fmm-item-d-1 {
  display: none;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1.jsAccDiv1.active .fmm-head-1::after {
  transform: translate(-50%, -50%) rotate(180deg);
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1.jsAccDiv1.show-in .fmm-item-d-1 {
  display: block;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 li {
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  width: calc(33.3333333333% - 15px);
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 li:nth-child(3n) {
  margin-right: 0px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 .cs-checkbox-1 label {
  padding: 15px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-2 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-2 li {
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  width: calc(50% - 10px);
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-2 li:nth-child(2n) {
  margin-right: 0px;
}
.cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-2 .cs-checkbox-1 label {
  padding: 15px;
}

.cs-page-list-1 {
  max-width: 740px;
  width: 100%;
  padding: 0px 30px;
  margin: 0 0 0 auto;
}
.cs-page-list-1 .inner-pl-1 {
  position: relative;
  max-height: calc(100vh - 320px);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #F4F5F7;
  padding-right: 5px;
  padding-left: 5px;
}
.cs-page-list-1 .inner-pl-1::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.cs-page-list-1 .inner-pl-1::-webkit-scrollbar-track {
  background: #F4F5F7;
}
.cs-page-list-1 .inner-pl-1::-webkit-scrollbar-thumb {
  background: #D3D3D3;
  border-radius: 20px;
}
.cs-page-list-1 .inner-pl-1::-webkit-scrollbar-thumb:hover {
  background: #D3D3D3;
}

.cs-list-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}
.cs-list-row-1 .cslr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
  max-width: 50%;
  flex: 0 0 50%;
}

.cs-car-box-1 {
  position: relative;
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  margin-bottom: 25px;
}
.cs-car-box-1.not-available::before {
  content: "";
  background-color: #83908E;
  border: 1px solid #EDEDED;
  border-radius: 20px;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  opacity: 0.45;
}
.cs-car-box-1 .cscb-top-area-1 {
  padding: 20px 12px;
}
.cs-car-box-1 .cscb-bottm-area-1 {
  background-color: #F0F0F0;
  text-align: center;
  border-radius: 0 0 20px 20px;
}
.cs-car-box-1 .cscb-bottm-area-1 .desc-2 {
  padding: 20px 10px;
}
.cs-car-box-1 .like-btn-1 {
  font-size: 20px;
  line-height: 1;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  z-index: 0;
  right: 12px;
  top: 18px;
}
.cs-car-box-1 .like-btn-1::before {
  content: "\e940";
  font-family: "bain" !important;
  position: relative;
  top: 1px;
}
.cs-car-box-1 .like-btn-1.active {
  color: #FF081B;
}
.cs-car-box-1 .like-btn-1.active::before {
  content: "\e923";
  font-family: "bain" !important;
}
.cs-car-box-1 .head-1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 8px;
}
.cs-car-box-1 .desc-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #838383;
  margin-bottom: 8px;
}
.cs-car-box-1 .desc-2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 0px;
}
.cs-car-box-1 .desc-3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #696969;
  margin-bottom: 0px;
}
.cs-car-box-1 .desc-3 i {
  text-align: center;
  display: inline-block;
  min-width: 35px;
  font-size: 22px;
  position: relative;
  top: 4px;
  padding-right: 8px;
  color: #000000;
}
.cs-car-box-1 .status-1 {
  position: relative;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #838383;
  margin-bottom: 0px;
}
.cs-car-box-1 .status-1::before {
  content: "";
  display: inline-block;
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #838383;
}
.cs-car-box-1 .status-1.suitable {
  color: #35D634;
}
.cs-car-box-1 .status-1.suitable::before {
  background-color: #35D634;
}
.cs-car-box-1 .status-1.res-available {
  color: #FFBA00;
}
.cs-car-box-1 .status-1.res-available::before {
  background-color: #FFBA00;
}
.cs-car-box-1 .status-1.full {
  color: #8BB1AB;
}
.cs-car-box-1 .status-1.full::before {
  background-color: #8BB1AB;
}
.cs-car-box-1 .img-div-1 {
  text-align: center;
  border-bottom: 1px solid #EDEDED;
}
.cs-car-box-1 .img-div-1 img {
  max-height: 240px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.cs-car-box-1 .info-div-1 {
  padding: 20px 10px 5px;
}
.cs-car-box-1 .info-div-1 .idr1-col-1 .cs-desc-1 {
  position: relative;
}
.cs-car-box-1 .info-div-1 .idr1-col-1 .cs-desc-1 .cs-cd-info-hover {
  position: absolute;
  top: -15px;
  left: -10px;
}
.cs-car-box-1 .info-div-1 .idr1-col-1 .cs-desc-1 .cs-cd-info-hover i {
  font-size: 12px;
}
.cs-car-box-1 .info-div-1 .idr1-col-1 .cs-desc-1 .cs-cd-info-hover .cs-speech-box {
  line-height: 14px;
}
.cs-car-box-1 .info-drow-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -6px;
  margin-left: -6px;
}
.cs-car-box-1 .info-drow-1 .idr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 6px;
  padding-left: 6px;
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 15px;
}

.cs-checkbox-2 {
  position: relative;
}
.cs-checkbox-2 label {
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  color: #454545 !important;
  margin: 0;
  cursor: pointer;
  border: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  text-align: left;
  min-height: auto;
  padding: 0px;
  border-radius: 0px;
  transition: all 0.3s ease;
}
.cs-checkbox-2 input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.cs-checkbox-2 input:checked + label {
  color: #454545 !important;
  font-weight: 700 !important;
}
.cs-checkbox-2 input:checked + label::after {
  content: "\e947";
  font-family: "bain" !important;
  color: #27C1B2 !important;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.select2 {
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 0;
  height: 0;
  left: auto;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: auto;
  width: auto;
}

.cs-global-select2-container-1 label {
  display: block;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 12px;
  text-align: left;
}
.cs-global-select2-container-1 ::placeholder {
  color: rgba(105, 105, 105, 0.5);
}
.cs-global-select2-container-1 .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
  content: "\e95c";
  font-family: "bain" !important;
}
.cs-global-select2-container-1 .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .cs-global-select2-container-1 .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 12px;
}
.cs-global-select2-container-1 .select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .cs-global-select2-container-1 .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-radius: 12px;
}
.cs-global-select2-container-1 .select2-container--default .select2-selection--single, .cs-global-select2-container-1 .select2-container--default .select2-selection--multiple {
  cursor: pointer;
  background-color: #ffffff;
  border: 0.5px solid #696969;
  border-radius: 12px;
  height: 50px;
}
.cs-global-select2-container-1 .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 25px;
  position: absolute;
  top: 12px;
  right: 20px;
  width: 14px;
}
.cs-global-select2-container-1 .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
  content: "\e95e";
  font-family: "bain" !important;
  font-size: 8px;
  color: #696969;
}
.cs-global-select2-container-1 .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  padding: 12px 25px;
}
.cs-global-select2-container-1 .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #041614;
  opacity: 0.5;
}
.cs-global-select2-container-1 .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #041614;
  opacity: 0.5;
}
.cs-global-select2-container-1 .select2-container--default .select2-search--inline .select2-search__field {
  cursor: pointer;
  margin: 10px 0px 0px -10px;
  color: #041614;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}
.cs-global-select2-container-1 .select2-container--default .select2-search--inline .select2-search__field::placeholder {
  color: #041614;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 25px;
}
.cs-global-select2-container-1.type-2 label {
  position: absolute;
  z-index: 1;
  margin-left: 14px;
  margin-top: 14px;
  color: #7287FD;
  font-size: 24px;
}
.cs-global-select2-container-1.type-2 .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 45px;
}

.select2-container--open .cs-global-select2-dd-1.select2-dropdown--below {
  border: 0.5px solid #EDEDED;
  border-radius: 14px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
}
.select2-container--open .cs-global-select2-dd-1.select2-dropdown--above {
  border: 0.5px solid #EDEDED;
  border-radius: 14px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
}

.cs-global-select2-dd-1 .select2-results__option {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #041614;
  padding: 10px 0px;
  border: 0;
  border-bottom: 1px solid rgba(237, 237, 237, 0.5);
}
.cs-global-select2-dd-1 .select2-results__option:last-child {
  border: 0;
}

.select2-container--default .cs-global-select2-dd-1 .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ffffff;
  color: #041614;
}
.select2-container--default .cs-global-select2-dd-1 .select2-results__option--selected {
  background-color: #ffffff;
  color: #041614;
  font-weight: 700;
}
.select2-container--default .cs-global-select2-dd-1 .select2-search--dropdown {
  position: relative;
  padding: 0;
  margin-bottom: 15px;
}
.select2-container--default .cs-global-select2-dd-1 .select2-search--dropdown::before {
  content: "\e94e";
  font-family: "bain" !important;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  pointer-events: none;
  color: #3830DC;
}
.select2-container--default .cs-global-select2-dd-1 .select2-search--dropdown .select2-search__field {
  border: 1px solid rgba(114, 135, 253, 0.5);
  border-radius: 10px;
  padding-left: 30px;
}
.select2-container--default .cs-global-select2-dd-1 .select2-search--dropdown .select2-search__field:focus {
  outline: none;
  box-shadow: none;
}

.cs-global-select2-container-2 label {
  display: block;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 12px;
  text-align: left;
}
.cs-global-select2-container-2 ::placeholder {
  color: rgba(105, 105, 105, 0.5);
}
.cs-global-select2-container-2 .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
  content: "\e95c";
  font-family: "bain" !important;
}
.cs-global-select2-container-2 .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .cs-global-select2-container-2 .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 12px;
}
.cs-global-select2-container-2 .select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .cs-global-select2-container-2 .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-radius: 12px;
}
.cs-global-select2-container-2 .select2-container--default .select2-selection--single, .cs-global-select2-container-2 .select2-container--default .select2-selection--multiple {
  cursor: pointer;
  background-color: #ffffff;
  border: 0.5px solid #696969;
  border-radius: 12px;
  height: 50px;
}
.cs-global-select2-container-2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 25px;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 14px;
}
.cs-global-select2-container-2 .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
  content: "\e95e";
  font-family: "bain" !important;
  font-size: 8px;
  color: #696969;
}
.cs-global-select2-container-2 .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  padding: 12px 25px;
}
.cs-global-select2-container-2 .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #041614;
  opacity: 0.5;
}
.cs-global-select2-container-2 .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #041614;
  opacity: 0.5;
}
.cs-global-select2-container-2 .select2-container--default .select2-search--inline .select2-search__field {
  cursor: pointer;
  margin: 10px 0px 0px -10px;
  color: #041614;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}
.cs-global-select2-container-2 .select2-container--default .select2-search--inline .select2-search__field::placeholder {
  color: #041614;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 25px;
}

.select2-container--open .cs-global-select2-dd-2.select2-dropdown--below {
  border: 0.5px solid #EDEDED;
  border-radius: 14px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
}
.select2-container--open .cs-global-select2-dd-2.select2-dropdown--above {
  border: 0.5px solid #EDEDED;
  border-radius: 14px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
}

.cs-global-select2-dd-2 .select2-results__option {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #041614;
  padding: 10px 0px;
  border: 0;
  border-bottom: 1px solid rgba(237, 237, 237, 0.5);
}
.cs-global-select2-dd-2 .select2-results__option:last-child {
  border: 0;
}

.select2-container--default .cs-global-select2-dd-2 .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ffffff;
  color: #041614;
}
.select2-container--default .cs-global-select2-dd-2 .select2-results__option--selected {
  background-color: #ffffff;
  color: #041614;
  font-weight: 700;
}
.select2-container--default .cs-global-select2-dd-2 .select2-search--dropdown {
  position: relative;
  padding: 0;
  margin-bottom: 15px;
}
.select2-container--default .cs-global-select2-dd-2 .select2-search--dropdown::before {
  content: "\e94e";
  font-family: "bain" !important;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  pointer-events: none;
  color: #3830DC;
}
.select2-container--default .cs-global-select2-dd-2 .select2-search--dropdown .select2-search__field {
  border: 1px solid rgba(114, 135, 253, 0.5);
  border-radius: 10px;
  padding-left: 30px;
}
.select2-container--default .cs-global-select2-dd-2 .select2-search--dropdown .select2-search__field:focus {
  outline: none;
  box-shadow: none;
}

.cs-order-summary-m-d-1 {
  background-color: #FAFAFA;
  padding: 0px 0px 45px;
}

.cs-orderSum-cont-1 {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
}

.cs-orderSum-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cs-orderSum-row-1 .csorr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 510px);
  flex: 0 0 calc(100% - 510px);
}
.cs-orderSum-row-1 .csorr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 510px;
  flex: 0 0 510px;
}

.cs-orderSum-head-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 20px;
}

.cs-orderSum-stic-1 {
  position: sticky;
  top: 120px;
  z-index: 1;
}

.cs-orderSum-right-a-1 {
  background-color: #F6FDFD;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 15px 0px 5px;
  margin-bottom: 20px;
}
.cs-orderSum-right-a-1 .csosr-top-a-1 {
  padding: 0px 40px;
}
.cs-orderSum-right-a-1 .csosr-bottom-a-1 {
  padding: 15px 40px;
}
.cs-orderSum-right-a-1 .csosr-bottom-a-1 .sum-sub-btn-1 {
  max-width: 310px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  margin-top: 15px;
}
.cs-orderSum-right-a-1 .sum-table-1 {
  margin-bottom: 0;
}
.cs-orderSum-right-a-1 .sum-table-1 tr td {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
  padding: 15px 0px;
}
.cs-orderSum-right-a-1 .sum-table-1 tr td:last-child {
  text-align: right;
}
.cs-orderSum-right-a-1 .sum-table-1 tr td:first-child {
  padding-right: 5px;
}
.cs-orderSum-right-a-1 .sum-table-1 tr td:first-child::after {
  content: ":";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
}
.cs-orderSum-right-a-1 .sum-table-1 tr:nth-child(3) td:first-child {
  color: #27C1B2;
}
.cs-orderSum-right-a-1 .sum-table-2 {
  margin-bottom: 0;
}
.cs-orderSum-right-a-1 .sum-table-2 tr td {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
  padding: 15px 0px;
}
.cs-orderSum-right-a-1 .sum-table-2 tr td:last-child {
  text-align: right;
  font-size: 24px;
  font-weight: 700;
}
.cs-orderSum-right-a-1 .sum-table-2 tr td:first-child {
  padding-right: 5px;
}
.cs-orderSum-right-a-1 .sum-table-2 tr td:first-child::after {
  content: ":";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
}

.cs-package-box-1 {
  margin-bottom: 5px;
  border-radius: 12px;
  background-color: #F7F8FD;
  padding: 12px;
}
.cs-package-box-1 .pack-desc-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #401FE5;
  margin-bottom: 5px;
}
.cs-package-box-1 .pack-desc-2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 10px;
}
.cs-package-box-1 .pack-desc-3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 12px;
}

.cs-speech-info-box-1 {
  cursor: pointer;
  position: relative;
  display: inline-block;
  top: 2px;
  padding-left: 8px;
}
.cs-speech-info-box-1 .icon-a-1 {
  font-size: 20px;
  line-height: 1;
  display: block;
  color: #7287FD;
}
.cs-speech-info-box-1 .speech-box-container {
  position: absolute;
  left: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 5;
}
.cs-speech-info-box-1 .speech-box-container .speech-box {
  min-width: 210px;
  background: #ffffff;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid #401FE5;
  color: #041614;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.cs-speech-info-box-1 .speech-box-container .speech-box p {
  color: #041614;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.cs-speech-info-box-1.form-v-1 {
  position: absolute;
  padding: 0;
  right: 15px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
.cs-speech-info-box-1.form-v-1 .speech-box-container {
  left: auto;
  right: 25px;
  top: 0px;
}
.cs-speech-info-box-1:hover .speech-box-container {
  opacity: 1;
  visibility: visible;
}

.cs-check-input-1 {
  position: relative;
}
.cs-check-input-1 input {
  cursor: pointer !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  opacity: 0 !important;
}
.cs-check-input-1 input:checked + label, .cs-check-input-1 input:not(:checked) + label {
  position: relative !important;
  padding-left: 35px !important;
  padding-right: 0 !important;
  cursor: pointer !important;
  font-size: 14px !important;
  line-height: 25px !important;
  color: #272727 !important;
  display: inline-block !important;
  left: 0 !important;
  top: 0 !important;
}
.cs-check-input-1 input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 24px;
  height: 24px;
  border: 1px solid #B7B7B7;
  background: #ffffff;
  border-radius: 4px;
}
.cs-check-input-1 input:checked + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 1px;
  width: 24px;
  height: 24px;
  border: 1px solid #27C1B2;
  background: #27C1B2;
  border-radius: 4px;
}
.cs-check-input-1 input:checked + label:after {
  content: "\e947";
  font-family: "bain" !important;
  position: absolute;
  left: 5px;
  top: 8px;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
}
.cs-check-input-1 label a {
  color: #272727;
  text-decoration: underline !important;
}
.cs-check-input-1 label span {
  color: #272727;
  text-decoration: underline !important;
}

.cs-discount-coupon-d-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}
.cs-discount-coupon-d-1 .csdcd1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  max-width: calc(100% - 146px);
  flex: 0 0 calc(100% - 146px);
}
.cs-discount-coupon-d-1 .csdcd1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 146px;
  flex: 0 0 146px;
}
.cs-discount-coupon-d-1 .csdcd1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 100%;
  flex: 0 0 100%;
}
.cs-discount-coupon-d-1 ::placeholder {
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  color: #041614 !important;
}
.cs-discount-coupon-d-1 input {
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  color: #041614 !important;
  height: 50px !important;
  border-radius: 12px !important;
  border: 1px solid #EDEDED !important;
  padding: 10px 15px 10px 55px !important;
}
.cs-discount-coupon-d-1 input:focus {
  box-shadow: none;
  outline: none;
  border-color: #27C1B2 !important;
}
.cs-discount-coupon-d-1 input.active {
  border-color: #27C1B2 !important;
}
.cs-discount-coupon-d-1 label {
  position: absolute !important;
  left: 22px !important;
  top: 50% !important;
  z-index: 1 !important;
  transform: translateY(-50%) !important;
  color: #401FE5 !important;
  font-size: 20px !important;
  line-height: 1 !important;
  margin: 0 !important;
}
.cs-discount-coupon-d-1 .disCo-btn-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #27C1B2;
  padding: 10px;
  transition: all 0.3s ease;
  border: 1px solid #27C1B2;
  background-color: transparent;
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
}
.cs-discount-coupon-d-1 .disCo-btn-1:hover {
  background-color: #27C1B2;
  color: #ffffff;
}
.cs-discount-coupon-d-1 .disCo-btn-1.active {
  opacity: 1;
  visibility: visible;
}
.cs-discount-coupon-d-1 .disCo-desc-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #696969;
  padding-top: 20px;
  margin-bottom: 10px;
}
.cs-discount-coupon-d-1 .disCo-desc-1.successful {
  display: none;
}
.cs-discount-coupon-d-1 .disCo-desc-1.successful::before {
  content: "\e947";
  font-family: "bain" !important;
  display: inline-flex;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #20C553;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 1;
  color: #ffffff;
  margin-right: 10px;
}
.cs-discount-coupon-d-1 .disCo-desc-1.unsuccessful {
  display: none;
}
.cs-discount-coupon-d-1 .disCo-desc-1.unsuccessful::before {
  content: "\e949";
  font-family: "bain" !important;
  display: inline-flex;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #E04149;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 1;
  color: #ffffff;
  margin-right: 10px;
}
.cs-discount-coupon-d-1.successful .disCo-btn-1 {
  opacity: 0 !important;
  visibility: hidden !important;
}
.cs-discount-coupon-d-1.successful .disCo-desc-1.successful {
  display: block !important;
}
.cs-discount-coupon-d-1.unsuccessful .disCo-btn-1 {
  opacity: 0 !important;
  visibility: hidden !important;
}
.cs-discount-coupon-d-1.unsuccessful .disCo-desc-1.unsuccessful {
  display: block !important;
}
.cs-discount-coupon-d-1.unsuccessful input {
  border-color: #E04149 !important;
}

.cs-orderSum-left-a-1 {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  border-radius: 20px;
  padding: 15px 0px;
}
.cs-orderSum-left-a-1 .csosla-sec-1 {
  padding: 15px;
  border-bottom: 1px solid #EDEDED;
}
.cs-orderSum-left-a-1 .csosla-sec-1:last-child {
  border: 0;
}
.cs-orderSum-left-a-1 .csosla-sec-1 .csosla-sec-cont-1 {
  max-width: 510px;
  width: 100%;
  margin: 0 auto;
}
.cs-orderSum-left-a-1 .img-div-1 {
  text-align: center;
  margin-bottom: 10px;
}
.cs-orderSum-left-a-1 .img-div-1 img {
  max-height: 135px;
  height: 100%;
}
.cs-orderSum-left-a-1 .head-1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 12px;
}
.cs-orderSum-left-a-1 .desc-1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #838383;
  margin-bottom: 12px;
}
.cs-orderSum-left-a-1 .desc-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #696969;
  margin-bottom: 20px;
}
.cs-orderSum-left-a-1 .in-table-1 {
  margin: 0;
}
.cs-orderSum-left-a-1 .in-table-1 tr td {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #696969;
  padding: 10px 0px;
}
.cs-orderSum-left-a-1 .in-table-1 tr td:first-child {
  font-weight: 700;
  color: #041614;
}
.cs-orderSum-left-a-1 .in-table-1 tr td:last-child {
  text-align: right;
}
.cs-orderSum-left-a-1 .in-table-2 {
  margin: 0;
}
.cs-orderSum-left-a-1 .in-table-2 tr td {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #696969;
  padding: 10px 0px;
}
.cs-orderSum-left-a-1 .in-table-2 tr td:first-child {
  font-weight: 600;
}
.cs-orderSum-left-a-1 .in-table-2 tr td:last-child {
  text-align: right;
}
.cs-orderSum-left-a-1 .head-2 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 15px;
}
.cs-orderSum-left-a-1 .package-list-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0px -6px;
}
.cs-orderSum-left-a-1 .package-list-1 li {
  line-height: 1;
  width: 25%;
  padding: 0px 6px;
}
.cs-orderSum-left-a-1 .pac-add-btn-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #401FE5;
  border: 0;
  padding: 0;
  margin-bottom: 20px;
  background-color: transparent;
}
.cs-orderSum-left-a-1 .pac-add-btn-1 i {
  padding-right: 5px;
  font-size: 20px;
  position: relative;
  top: 3px;
}
.cs-orderSum-left-a-1 .list-div-1 {
  margin-bottom: 3px;
}
.cs-orderSum-left-a-1 .list-div-1 .list-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -6px;
}
.cs-orderSum-left-a-1 .list-div-1 .list-1 li {
  line-height: 1;
  width: 50%;
  padding: 0px 6px;
  margin-bottom: 15px;
}
.cs-orderSum-left-a-1 .s-box-div-1 {
  max-width: 275px;
  width: 100%;
  margin-bottom: 25px;
}

.cs-card-box-1 {
  position: relative;
}
.cs-card-box-1 label {
  display: flex;
  position: relative;
  min-height: 80px;
  background-color: #ffffff;
  border: 1px solid #EAECF4;
  border-radius: 12px;
  padding: 12px 35px 12px 10px;
  margin: 0;
  cursor: pointer;
}
.cs-card-box-1 label .cscb1-col-1 {
  flex: 0 0 35px;
  max-width: 35px;
}
.cs-card-box-1 label .cscb1-col-2 {
  flex: 0 0 calc(100% - 35px);
  max-width: calc(100% - 35px);
  padding-left: 10px;
}
.cs-card-box-1 input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}
.cs-card-box-1 input:checked + label::before, .cs-card-box-1 input:not(:checked) + label::before {
  content: "";
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #EAEAEA;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 1;
  transition: all 0.3s ease;
}
.cs-card-box-1 input:checked + label::before {
  background-color: #20C553;
}
.cs-card-box-1 input:checked + label::after {
  content: "\e947";
  font-family: "bain" !important;
  position: absolute;
  right: 14px;
  top: 22px;
  z-index: 2;
  font-size: 12px;
  color: #ffffff;
}
.cs-card-box-1 .cscb1-desc-1 {
  font-size: 24px;
  line-height: 1;
  color: #7287FD;
  margin: 0;
}
.cs-card-box-1 .cscb1-desc-2 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 5px;
}
.cs-card-box-1 .cscb1-desc-3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 0px;
}

.cs-modal-3 .modal-dialog {
  max-width: 500px;
  margin: 3rem auto;
  padding: 15px;
}
.cs-modal-3 .modal-content {
  border-radius: 18px;
  padding: 0;
  border: 0;
}
.cs-modal-3 .close {
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: #1A1A1A;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  text-shadow: none;
}
.cs-modal-3 .modal-body {
  padding: 30px 70px;
  border-radius: 18px;
}
.cs-modal-3 .modal-head-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #0C0C0C;
  margin-bottom: 25px;
}
.cs-modal-3 .scroll-modal-a-1 {
  position: relative;
  max-height: 500px;
  padding-right: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #F4F5F7;
}
.cs-modal-3 .scroll-modal-a-1::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.cs-modal-3 .scroll-modal-a-1::-webkit-scrollbar-track {
  background: #F4F5F7;
}
.cs-modal-3 .scroll-modal-a-1::-webkit-scrollbar-thumb {
  background: rgba(114, 135, 253, 0.15);
  border-radius: 20px;
}
.cs-modal-3 .scroll-modal-a-1::-webkit-scrollbar-thumb:hover {
  background: #7287FD;
}

.cs-modal-4 .modal-dialog {
  max-width: 640px;
  margin: 3rem auto;
  padding: 15px;
}
.cs-modal-4 .modal-content {
  border-radius: 18px;
  padding: 0;
  border: 0;
}
.cs-modal-4 .close {
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: #1A1A1A;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  text-shadow: none;
}
.cs-modal-4 .modal-body {
  padding: 30px 50px;
  border-radius: 18px;
}
.cs-modal-4 .modal-head-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #0C0C0C;
  margin-bottom: 25px;
}
.cs-modal-4 .modal-head-1.cancel-icon-1::before {
  content: "\e934";
  font-family: "bain" !important;
  display: inline-flex;
  margin-right: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E04149;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -2px;
}
.cs-modal-4 .modal-ab-icon-b-1 {
  display: flex;
  width: 50px;
  height: 50px;
  background-color: #EAECF4;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 1;
  color: #401FE5;
  position: absolute;
  left: 30px;
  top: 30px;
}
.cs-modal-4 .modal-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #EDEDED;
}
.cs-modal-4 .modal-desc-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 25px;
}
.cs-modal-4 .modal-desc-3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 15px;
}
.cs-modal-4 .scroll-modal-a-1 {
  position: relative;
  max-height: 500px;
  padding-right: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #F4F5F7;
}
.cs-modal-4 .scroll-modal-a-1::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.cs-modal-4 .scroll-modal-a-1::-webkit-scrollbar-track {
  background: #F4F5F7;
}
.cs-modal-4 .scroll-modal-a-1::-webkit-scrollbar-thumb {
  background: rgba(114, 135, 253, 0.15);
  border-radius: 20px;
}
.cs-modal-4 .scroll-modal-a-1::-webkit-scrollbar-thumb:hover {
  background: #7287FD;
}
.cs-modal-4 .scroll-modal-a-1 h1, .cs-modal-4 .scroll-modal-a-1 h2, .cs-modal-4 .scroll-modal-a-1 h3, .cs-modal-4 .scroll-modal-a-1 h4, .cs-modal-4 .scroll-modal-a-1 h5, .cs-modal-4 .scroll-modal-a-1 h6 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 25px;
}
.cs-modal-4 .scroll-modal-a-1 p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-modal-4 .scroll-modal-a-1 ul, .cs-modal-4 .scroll-modal-a-1 ol {
  padding-right: 15px;
}
.cs-modal-4 .scroll-modal-a-1 li {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 15px;
}
.cs-modal-4 .scroll-modal-a-1 a {
  color: #27C1B2;
}
.cs-modal-4 .bottom-btn-a-1 {
  text-align: center;
  display: none;
}
.cs-modal-4 .modal-form-short-d-1 {
  max-width: 375px;
  margin: 0 auto;
}

.cs-secondary-btn-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background-color: #401FE5;
  border-radius: 30px;
  min-width: 130px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-secondary-btn-3 i {
  padding-left: 20px;
}
.cs-secondary-btn-3:hover {
  color: #401FE5;
  background-color: #ffffff;
  border-color: #401FE5;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
}

.cs-secondary-btn-4 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background-color: #401FE5;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-secondary-btn-4:hover {
  color: #ffffff;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(64, 31, 229, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(64, 31, 229, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(64, 31, 229, 0.65);
}

.cs-speech-info-box-2 {
  cursor: pointer;
  position: relative;
  display: inline-block;
  top: 2px;
  padding-left: 8px;
}
.cs-speech-info-box-2 .icon-a-1 {
  font-size: 20px;
  line-height: 1;
  display: block;
  color: #7287FD;
}
.cs-speech-info-box-2 .speech-box-container {
  position: absolute;
  left: -85px;
  top: 0px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 9;
}
.cs-speech-info-box-2 .speech-box-container.active {
  opacity: 1;
  visibility: visible;
}
.cs-speech-info-box-2 .speech-box-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #000000;
  opacity: 0.6;
}
.cs-speech-info-box-2 .speech-box-container .speech-box {
  min-width: 510px;
  background: #ffffff;
  border-radius: 12px;
  padding: 10px 15px;
  border: 1px solid #F5BC41;
}
.cs-speech-info-box-2 .speech-box-container .speech-box p {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 5px;
}
.cs-speech-info-box-2 .speech-box-container .speech-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-speech-info-box-2 .speech-box-container .speech-box ul li {
  position: relative;
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 15px;
  padding-left: 16px;
}
.cs-speech-info-box-2 .speech-box-container .speech-box ul li:last-child {
  margin: 0;
}
.cs-speech-info-box-2 .speech-box-container .speech-box ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #FFBA00;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 4px;
  z-index: 1;
}
.cs-speech-info-box-2 .speech-head-1 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 5px;
}
.cs-speech-info-box-2 .speech-close-btn-1 {
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 20px;
  height: 20px;
  padding: 0;
  color: #272727;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
}

.cs-modal-info-div-1 {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.cs-modal-info-div-1 .icon-text-1 {
  font-size: 54px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 30px;
}
.cs-modal-info-div-1 .icon-text-1 span {
  display: inline-flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: #E04149;
  border-radius: 50%;
}
.cs-modal-info-div-1 .icon-text-1.type-2 {
  font-size: 40px;
}
.cs-modal-info-div-1 .csmid1-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #696969;
  letter-spacing: 0.1px;
  margin-bottom: 35px;
}
.cs-modal-info-div-1 .csmid1-desc-1 strong {
  font-weight: 700;
  color: #041614;
}
.cs-modal-info-div-1 .csmid1-head-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #0C0C0C;
  margin-bottom: 20px;
}

.cs-white-btn-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background-color: #ffffff;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #041614;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-white-btn-2 i {
  padding-left: 10px;
}
.cs-white-btn-2:hover {
  color: #ffffff;
  background-color: #041614;
}

.cs-lottie-cont-1 {
  max-width: 470px;
  width: 100%;
  margin: 0 auto 20px;
}

.cs-order-successful-cont-1 {
  max-width: 670px;
  width: 100%;
  margin: 0 auto 30px;
}
.cs-order-successful-cont-1 .csosc1-head-1 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 15px;
}
.cs-order-successful-cont-1 .csosc1-desc-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}
.cs-order-successful-cont-1 .csosc1-desc-1 strong, .cs-order-successful-cont-1 .csosc1-desc-1 a {
  color: #401FE5;
  font-weight: 700;
}
.cs-order-successful-cont-1 .csosc1-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.cs-order-successful-cont-1 .csosc1-row-1 .csosc1r1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: calc(100% - 120px);
  flex: 0 0 calc(100% - 120px);
}
.cs-order-successful-cont-1 .csosc1-row-1 .csosc1r1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 120px;
  flex: 0 0 120px;
}

.cs-order-successful-info-div-1 {
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  margin-bottom: 30px;
  padding: 50px 45px;
}
.cs-order-successful-info-div-1 .csosid1-image-div-1 {
  position: relative;
  text-align: center;
  max-width: 234px;
  width: 100%;
}
.cs-order-successful-info-div-1 .csosid1-image-div-1::before {
  content: "";
  background-color: #99E5DD;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: absolute;
  z-index: 0;
  left: -10px;
  top: -10px;
}
.cs-order-successful-info-div-1 .csosid1-image-div-1 img {
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
}
.cs-order-successful-info-div-1 .csosid1-head-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 10px;
}
.cs-order-successful-info-div-1 .csosid1-head-2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #838383;
  margin-bottom: 5px;
}
.cs-order-successful-info-div-1 .csosid1-head-3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 15px;
}
.cs-order-successful-info-div-1 .csosid1-head-4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #696969;
  margin-bottom: 0px;
}
.cs-order-successful-info-div-1 .csosid1-c-1 {
  border-right: 1px solid #EDEDED;
}
.cs-order-successful-info-div-1 .csosid1-info-div-1 {
  position: relative;
  text-align: left;
  max-width: 200px;
  margin: 0 0 0 auto;
  width: 100%;
}
.cs-order-successful-info-div-1 .csosid1-hr-1 {
  border-color: #EDEDED;
  margin: 2rem 0;
}

.cs-account-main-div-1 {
  background-color: #FAFAFA;
  padding: 30px 0px;
}

.cs-account-m-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cs-account-m-row-1 .csamr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 320px;
  flex: 0 0 320px;
  margin-bottom: 15px;
}
.cs-account-m-row-1 .csamr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 320px);
  flex: 0 0 calc(100% - 320px);
  margin-bottom: 15px;
}

.cs-account-left-div-1 {
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #EAECF4;
  padding: 20px 0px;
}

.cs-account-menu-section-1 {
  padding: 0px 15px 35px;
}

.cs-account-info-d-1 {
  text-align: center;
}
.cs-account-info-d-1 .account-i-1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin: 0 auto;
  border: 2px solid #7287FD;
  border-radius: 50%;
  margin-bottom: 15px;
  background-color: #27C1B2;
  font-size: 42px;
  line-height: 1;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}
.cs-account-info-d-1 .account-i-1::before {
  content: "";
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.4;
}
.cs-account-info-d-1 .account-i-1::after {
  content: "\e924";
  font-family: "bain" !important;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  font-size: 30px;
  line-height: 1;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cs-account-info-d-1 .account-i-1:hover::before {
  background-color: #041614;
}
.cs-account-info-d-1 .account-i-1:hover::after {
  opacity: 1;
  visibility: visible;
}
.cs-account-info-d-1 .account-head-1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 0px;
}

.cs-account-menu-section-2 {
  padding: 0px 0px 25px;
}

.cs-account-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-account-menu-1 .acc-level-1 {
  line-height: 1;
  padding-right: 35px;
  margin-bottom: 15px;
}
.cs-account-menu-1 .acc-level-link-1 {
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
  padding: 7px 15px 7px 30px;
  display: flex;
  min-height: 40px;
  align-items: center;
  transition: all 0.3s ease;
}
.cs-account-menu-1 .acc-level-link-1:hover {
  color: #27C1B2;
}
.cs-account-menu-1 .acc-level-link-1 i {
  font-size: 25px;
  padding-right: 8px;
  display: inline-block;
  width: 35px;
  text-align: center;
}
.cs-account-menu-1 .acc-level-link-1 i.bain-empty-heart {
  font-size: 22px;
}
.cs-account-menu-1 .acc-level-link-1.active {
  font-weight: 700;
  color: #401FE5;
  background-color: #EAECF4;
  border-radius: 0px 20px 20px 0px;
}
.cs-account-menu-1 .acc-level-link-1.active + .acc-sub-m-d-1 {
  display: block;
}
.cs-account-menu-1 .acc-sub-m-d-1 {
  padding: 25px 35px 20px;
  display: none;
}
.cs-account-menu-1 .acc-level-2-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  padding-left: 22px;
}
.cs-account-menu-1 .acc-level-2-m-1::before {
  content: "";
  width: 2px;
  height: 90%;
  background-color: #EAECF4;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.cs-account-menu-1 .acc-level-2-m-1 li {
  position: relative;
  margin-bottom: 20px;
}
.cs-account-menu-1 .acc-level-2-m-1 li::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #EAECF4;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: -26px;
  z-index: 1;
}
.cs-account-menu-1 .acc-level-2-m-1 li:last-child {
  margin: 0;
}
.cs-account-menu-1 .acc-level-2-m-1 li a {
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #696969;
  transition: all 0.3s ease;
}
.cs-account-menu-1 .acc-level-2-m-1 li a:hover {
  color: #27C1B2;
}
.cs-account-menu-1 .acc-level-2-m-1 li.active::before {
  background-color: #401FE5;
}
.cs-account-menu-1 .acc-level-2-m-1 li.active a {
  color: #041614;
  font-weight: 600;
}

.cs-account-logout-d-1 {
  border-top: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  padding: 23px 30px;
}
.cs-account-logout-d-1 .logout-link-1 {
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #696969;
  transition: all 0.3s ease;
}
.cs-account-logout-d-1 .logout-link-1:hover {
  color: #E04149;
}

.cs-account-menu-section-3 {
  padding: 0px 23px 10px;
}

.cs-account-menu-section-4 {
  padding: 10px 23px;
}

.cs-colored-link-box-1 {
  border: 1px solid #000000;
  padding: 12px 13px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 14px;
}
.cs-colored-link-box-1 .cclb1-desc-1 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}
.cs-colored-link-box-1 .cclb1-link-1 {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0px;
  text-decoration: underline !important;
}
.cs-colored-link-box-1.color-1 {
  background-color: rgba(234, 236, 244, 0.3);
  border-color: #3830DC;
}
.cs-colored-link-box-1.color-1 .cclb1-link-1 {
  color: #401FE5;
}
.cs-colored-link-box-1.color-2 {
  background-color: rgba(246, 253, 253, 0.3);
  border-color: #27C1B2;
}
.cs-colored-link-box-1.color-2 .cclb1-link-1 {
  color: #27C1B2;
}

.cs-account-right-div-1 {
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #EAECF4;
  padding: 25px;
  min-height: 100%;
}

.cs-account-form-d-1 {
  max-width: 690px;
  width: 100%;
  margin: 0 auto;
}
.cs-account-form-d-1 .cs-nc-step {
  display: none;
}

.cs-account-navs-d-1 {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.cs-navTabs-2 {
  justify-content: space-between;
}
.cs-navTabs-2.nav-tabs {
  border-color: #707070;
}
.cs-navTabs-2.nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #041614;
  padding: 15px 20px;
  position: relative;
  transition: all 0.3s ease;
}
.cs-navTabs-2.nav-tabs .nav-link.active {
  color: #401FE5;
  background-color: transparent;
  border-color: unset;
  font-weight: 700;
}
.cs-navTabs-2.nav-tabs .nav-link.active::after {
  content: "";
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #401FE5;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.cs-navTabs-2.nav-tabs .nav-item.show {
  color: #401FE5;
  background-color: transparent;
  border-color: unset;
}

.cs-account-verification-p-1 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #13B212;
  padding-top: 15px;
  margin-bottom: 15px;
}
.cs-account-verification-p-1 i {
  font-size: 20px;
  padding-right: 5px;
  position: relative;
  top: 3px;
}

.cs-account-verification-p-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #041614;
  padding-top: 15px;
  margin-bottom: 15px;
}
.cs-account-verification-p-2 i {
  font-size: 20px;
  padding-right: 5px;
  position: relative;
  top: 3px;
  color: #E04149;
}
.cs-account-verification-p-2 a {
  color: #041614;
  text-decoration: underline !important;
}
.cs-account-verification-p-2 a:hover {
  color: #041614;
  text-decoration: none !important;
}

.cs-form-info-d-1 {
  max-width: 475px;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #707070;
}
.cs-form-info-d-1 .csfid1-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin: 0;
}
.cs-form-info-d-1 span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  background: rgb(39, 193, 178);
  background: linear-gradient(180deg, rgb(39, 193, 178) 0%, rgb(40, 180, 181) 20%, rgb(46, 147, 192) 40%, rgb(54, 94, 209) 70%, rgb(64, 31, 229) 100%);
  border-radius: 50%;
  font-size: 16px;
  line-height: 1;
  color: #FDFDFD;
}
.cs-form-info-d-1 a {
  color: #1A1A1A;
}
.cs-form-info-d-1 a:hover {
  color: #1A1A1A;
}

.cs-primary-btn-4 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background-color: #27C1B2;
  border-radius: 14px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-primary-btn-4 i {
  padding-right: 10px;
  font-size: 24px;
}
.cs-primary-btn-4:hover {
  color: #ffffff;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
}

.cs-white-btn-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background-color: #ffffff;
  border-radius: 14px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #041614;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-white-btn-3 i {
  padding-left: 10px;
}
.cs-white-btn-3:hover {
  color: #ffffff;
  background-color: #041614;
}

.cs-account-btn-d-1 {
  display: none;
}

.cs-edit-form-d-1 {
  position: relative;
  z-index: 0;
}
.cs-edit-form-d-1 .csefd1-btn-1 {
  position: absolute;
  font-size: 20px;
  line-height: 1;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
  height: 40px;
  z-index: 1;
  border-radius: 8px;
  width: 40px;
  border: 0;
  background-color: #ffffff;
}

.cs-account-approves-div-1 {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}
.cs-account-approves-div-1 .csaad1-table-1 {
  margin-bottom: 20px;
}
.cs-account-approves-div-1 .csaad1-table-1 tr td {
  border-bottom: 1px solid #707070;
  padding: 24px 0px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
}
.cs-account-approves-div-1 .csaad1-table-1 tr td:last-child {
  text-align: right;
}
.cs-account-approves-div-1 .csaad1-status-desc-1 {
  margin: 0;
  font-weight: 400;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-1 {
  color: #E04149;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-1::after {
  content: "\e949";
  font-family: "bain" !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #E04149;
  margin-left: 10px;
  position: relative;
  top: -1px;
  font-size: 10px;
  line-height: 1;
  color: #ffffff;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-2 {
  color: #35D634;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-2::after {
  content: "\e947";
  font-family: "bain" !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #35D634;
  margin-left: 10px;
  position: relative;
  top: -1px;
  font-size: 10px;
  line-height: 1;
  color: #ffffff;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-3 {
  color: #FFBA00;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-3::after {
  content: "\e947";
  font-family: "bain" !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFBA00;
  margin-left: 10px;
  position: relative;
  top: -1px;
  font-size: 10px;
  line-height: 1;
  color: #ffffff;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-4 {
  color: #3F22E4;
}
.cs-account-approves-div-1 .csaad1-status-desc-1.status-4::after {
  content: "\e947";
  font-family: "bain" !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3F22E4;
  margin-left: 10px;
  position: relative;
  top: -1px;
  font-size: 10px;
  line-height: 1;
  color: #ffffff;
}

.cs-address-empty-d-1 {
  text-align: center;
  padding-top: 10px;
}
.cs-address-empty-d-1 img {
  margin-bottom: 25px;
}
.cs-address-empty-d-1 .csaed1-desc-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 20px;
}

.cs-single-btn-d-1 {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0px;
}

.cs-primary-btn-5 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background-color: #27C1B2;
  border-radius: 14px;
  min-width: 145px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-primary-btn-5 i {
  padding-right: 10px;
}
.cs-primary-btn-5:hover {
  color: #ffffff;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
}

.cs-address-div-1 {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px 30px;
}

.cs-address-box-1 {
  margin-bottom: 25px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #EAECF4;
  border-radius: 8px;
  padding: 20px 15px 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}
.cs-address-box-1 .csab1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
  text-align: center;
}
.cs-address-box-1 .csab1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 80px);
  flex: 0 0 calc(100% - 80px);
}
.cs-address-box-1 .csab1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
}
.cs-address-box-1 .csab1-icon-1 {
  font-size: 30px;
  line-height: 1;
  color: rgba(114, 135, 253, 0.5);
  margin-bottom: 0px;
}
.cs-address-box-1 .csab1-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 12px;
}
.cs-address-box-1 .csab1-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 0px;
}
.cs-address-box-1 .csab1-action-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-address-box-1 .csab1-action-m-1 li {
  line-height: 1;
  margin-bottom: 15px;
}
.cs-address-box-1 .csab1-action-m-1 li:last-child {
  margin: 0;
}
.cs-address-box-1 .csab1-action-m-1 li button {
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #696969;
  width: 100%;
  height: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: all 0.3s ease;
}
.cs-address-box-1 .csab1-action-m-1 li button:hover {
  color: #401FE5;
}

.cs-account-mobile-d-1 {
  padding: 0px 30px 20px;
}
.cs-account-mobile-d-1 .csamd1-btn-1 {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 12px;
  min-height: 40px;
  border: 0;
  padding: 5px 30px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #ffffff;
  background-color: #401FE5;
  position: relative;
}
.cs-account-mobile-d-1 .csamd1-btn-1::after {
  content: "\e95e";
  font-family: "bain" !important;
  transition: all 0.3s ease;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  z-index: 1;
}
.cs-account-mobile-d-1 .csamd1-btn-1.active::after {
  transform: translateY(-50%) rotate(180deg);
}

.cs-card-empty-d-1 {
  text-align: center;
  padding-top: 10px;
}
.cs-card-empty-d-1 img {
  margin-bottom: 25px;
}
.cs-card-empty-d-1 .csced1-desc-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 20px;
}

.cs-account-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 30px;
}

.cs-card-box-2 {
  margin-bottom: 25px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #EAECF4;
  border-radius: 8px;
  padding: 15px 10px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  align-items: center;
}
.cs-card-box-2 .csab2-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
  text-align: center;
}
.cs-card-box-2 .csab2-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 80px);
  flex: 0 0 calc(100% - 80px);
}
.cs-card-box-2 .csab2-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
}
.cs-card-box-2 .csab2-icon-1 {
  font-size: 30px;
  line-height: 1;
  color: rgba(114, 135, 253, 0.5);
  margin-bottom: 0px;
}
.cs-card-box-2 .csab2-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 12px;
}
.cs-card-box-2 .csab2-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 0px;
}
.cs-card-box-2 .csab2-action-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-card-box-2 .csab2-action-m-1 li {
  line-height: 1;
  margin-bottom: 15px;
}
.cs-card-box-2 .csab2-action-m-1 li:last-child {
  margin: 0;
}
.cs-card-box-2 .csab2-action-m-1 li button {
  font-size: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #696969;
  width: 100%;
  height: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: all 0.3s ease;
}
.cs-card-box-2 .csab2-action-m-1 li button:hover {
  color: #401FE5;
}

.cs-global-congratulations-d-1 {
  background-color: #ffffff;
  padding: 25px 0px 100px;
}
.cs-global-congratulations-d-1 .csgcd1-lottie-cont-1 {
  max-width: 400px;
  width: 100%;
  margin: 0 auto 20px;
}
.cs-global-congratulations-d-1 .csgcd1-lottie-cont-2 {
  max-width: 400px;
  width: 100%;
  margin: 0 auto 20px;
}
.cs-global-congratulations-d-1 .csgcd1-text-cont-1 {
  padding: 20px 0px;
  text-align: center;
}
.cs-global-congratulations-d-1 .csgcd1-head-1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 20px;
}
.cs-global-congratulations-d-1 .csgcd1-desc-1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 10px;
}
.cs-global-congratulations-d-1 .csgcd1-lottie-d-1 {
  position: relative;
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
}
.cs-global-congratulations-d-1 .csgcd1-lottie-d-1 .csgcd1-lottie-cont-1 {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.cs-global-congratulations-d-1 .csgcd1-lottie-d-1 .csgcd1-lottie-cont-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.cs-upload-container-1 {
  position: relative;
  margin: 0 auto 50px;
  max-width: 400px;
  width: 100%;
  border-radius: 18px;
  border: 2px dashed #27C1B2;
  text-align: center;
}
.cs-upload-container-1 input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.cs-upload-container-1 label {
  cursor: pointer;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 60px 25px 20px;
}
.cs-upload-container-1 .csuc1-desc-1 {
  font-size: 64px;
  line-height: 1;
  color: #DEEAEA;
  margin-bottom: 20px;
}
.cs-upload-container-1 .csuc1-desc-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #041614;
  margin-bottom: 8px;
}
.cs-upload-container-1 .csuc1-desc-2 span {
  font-weight: 600;
  color: #401FE5;
  text-decoration: underline;
}
.cs-upload-container-1 .csuc1-desc-2 strong {
  font-weight: 700;
}
.cs-upload-container-1 .csuc1-desc-3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #696969;
  opacity: 0.5;
  margin-bottom: 20px;
}
.cs-upload-container-1 .csuc1-desc-4 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #696969;
  margin-bottom: 0px;
}
.cs-upload-container-1 .jsInfoTextUp1.error {
  color: #E04149;
}
.cs-upload-container-1 .jsInfoTextUp1.successful {
  color: #401FE5;
}
.cs-upload-container-1 .jsFileUploadInputInfoT1.error {
  color: #E04149;
}
.cs-upload-container-1 .jsFileUploadInputInfoT1.successful {
  color: #401FE5;
}

.cs-global-upload-btn-1 {
  position: relative;
  border-radius: 14px;
  display: block;
  background-color: #27C1B2;
  width: 200px;
  margin: 0 auto;
}
.cs-global-upload-btn-1 svg {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  fill: #ffffff;
}
.cs-global-upload-btn-1 .csgub1-anime-d-1 {
  height: 4px;
  margin: -2px 0 0 0;
  position: absolute;
  top: 50%;
  left: 72px;
  right: 25px;
  background-color: #E6E6E6;
  display: none;
  border-radius: 2px;
}
.cs-global-upload-btn-1 .csgub1-anime-d-1 span {
  position: absolute;
  background-color: #27C1B2;
  height: 6px;
  left: 0;
  top: 0;
  width: 0;
  display: block;
  border-radius: 14px;
}
.cs-global-upload-btn-1 a {
  position: relative;
  display: block;
  background-color: #27C1B2;
  z-index: 2;
  line-height: 50px;
  height: 50px;
  border-radius: 14px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #F6FDFD;
}
.cs-global-upload-btn-1 a:hover {
  color: #F6FDFD;
}
.cs-global-upload-btn-1 a span {
  cursor: pointer;
  display: block;
}
.cs-global-upload-btn-1.active {
  border: 1px solid #7287FD;
  background: rgb(117, 137, 245);
  background: linear-gradient(90deg, rgba(117, 137, 245, 0.21) 0%, rgba(205, 210, 239, 0.28) 30%, rgb(255, 255, 255) 100%);
  border-radius: 50px;
}
.cs-global-upload-btn-1.active a {
  border-radius: 50px;
  background-color: #401FE5;
}

.cs-global-croppie-d-1 {
  width: 300px;
  margin: 0 auto;
}
.cs-global-croppie-d-1.croppie-container .cr-boundary {
  border: 2px dashed #27C1B2;
}
.cs-global-croppie-d-1.croppie-container .cr-slider-wrap {
  width: 100%;
  margin: 30px auto 15px;
}

.cs-global-croppie-step-2 {
  display: none;
}

.cs-account-head-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 15px;
}

.cs-global-add-btn-1 {
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 700 !important;
  color: #401FE5 !important;
  border: 0;
  padding: 0;
  margin-bottom: 20px;
  background-color: transparent;
}
.cs-global-add-btn-1 i {
  padding-right: 5px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.cs-file-box-1 {
  margin-bottom: 25px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #EAECF4;
  border-radius: 8px;
  padding: 20px 15px 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}
.cs-file-box-1 .csfb1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
  text-align: center;
}
.cs-file-box-1 .csfb1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 80px);
  flex: 0 0 calc(100% - 80px);
}
.cs-file-box-1 .csfb1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
}
.cs-file-box-1 .csfb1-icon-1 {
  font-size: 30px;
  line-height: 1;
  color: rgba(114, 135, 253, 0.5);
  margin-bottom: 0px;
}
.cs-file-box-1 .csfb1-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 12px;
}
.cs-file-box-1 .csfb1-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 0px;
}
.cs-file-box-1 .csfb1-action-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-file-box-1 .csfb1-action-m-1 li {
  line-height: 1;
  margin-bottom: 15px;
}
.cs-file-box-1 .csfb1-action-m-1 li:last-child {
  margin: 0;
}
.cs-file-box-1 .csfb1-action-m-1 li button {
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #696969;
  width: 100%;
  height: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: all 0.3s ease;
}
.cs-file-box-1 .csfb1-action-m-1 li button:hover {
  color: #401FE5;
}
.cs-file-box-1 .csfb1-pdf-d-1 {
  border: 0.5px solid #EDEDED;
  border-radius: 8px;
  min-height: 40px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.cs-file-box-1 .csfb1-pdf-d-1 canvas {
  width: 100%;
  border-radius: 8px;
}

.cs-account-earning-d-1 {
  min-height: 54px;
  padding: 12px 10px;
  min-width: 250px;
  border-radius: 0px 0px 20px 20px;
  background-color: #EAECF4;
  position: absolute;
  top: 0;
  right: 7rem;
  z-index: 1;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(135, 143, 172, 0.5);
  -moz-box-shadow: 0px 3px 6px 0px rgba(135, 143, 172, 0.5);
  box-shadow: 0px 3px 6px 0px rgba(135, 143, 172, 0.5);
}
.cs-account-earning-d-1 .csaed1-desc-1 {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #041614;
  margin: 0;
}
.cs-account-earning-d-1 .csaed1-desc-1 span {
  display: inline-flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
  color: #401FE5;
  margin-right: 5px;
}
.cs-account-earning-d-1 .csaed1-desc-1 strong {
  font-weight: 700;
}

.select2-container--open .cs-global-select2-dd-3.select2-dropdown--below {
  border: 0.5px solid #EDEDED;
  border-radius: 14px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
}
.select2-container--open .cs-global-select2-dd-3.select2-dropdown--above {
  border: 0.5px solid #EDEDED;
  border-radius: 14px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(113, 113, 113, 0.1);
}

.cs-global-select2-dd-3 .select2-results__option {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #041614;
  padding: 10px 0px;
  border: 0;
  border-bottom: 1px solid rgba(237, 237, 237, 0.5);
}
.cs-global-select2-dd-3 .select2-results__option:last-child {
  border: 0;
}

.select2-container--default .cs-global-select2-dd-3 .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ffffff;
  color: #041614;
}
.select2-container--default .cs-global-select2-dd-3 .select2-results__option--selected {
  background-color: #ffffff;
  color: #041614;
  font-weight: 700;
  position: relative;
}
.select2-container--default .cs-global-select2-dd-3 .select2-results__option--selected::after {
  content: "\e947";
  font-family: "bain" !important;
  font-size: 14px;
  color: #27C1B2;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.select2-container--default .cs-global-select2-dd-3 .select2-search--dropdown {
  position: relative;
  padding: 0;
  margin-bottom: 15px;
}
.select2-container--default .cs-global-select2-dd-3 .select2-search--dropdown::before {
  content: "\e94e";
  font-family: "bain" !important;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  pointer-events: none;
  color: #3830DC;
}
.select2-container--default .cs-global-select2-dd-3 .select2-search--dropdown .select2-search__field {
  border: 1px solid rgba(114, 135, 253, 0.5);
  border-radius: 10px;
  padding-left: 30px;
}
.select2-container--default .cs-global-select2-dd-3 .select2-search--dropdown .select2-search__field:focus {
  outline: none;
  box-shadow: none;
}
.select2-container--default .cs-global-select2-dd-3 .select2-results > .select2-results__options {
  scrollbar-width: thin;
  scrollbar-color: #F4F5F7;
}
.select2-container--default .cs-global-select2-dd-3 .select2-results > .select2-results__options::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.select2-container--default .cs-global-select2-dd-3 .select2-results > .select2-results__options::-webkit-scrollbar-track {
  background: #F4F5F7;
}
.select2-container--default .cs-global-select2-dd-3 .select2-results > .select2-results__options::-webkit-scrollbar-thumb {
  background: rgba(114, 135, 253, 0.15);
  border-radius: 20px;
}
.select2-container--default .cs-global-select2-dd-3 .select2-results > .select2-results__options::-webkit-scrollbar-thumb:hover {
  background: #7287FD;
}

.cs-global-select2-container-3 label {
  display: block;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1A1A1A;
  margin-bottom: 12px;
  text-align: left;
}
.cs-global-select2-container-3 ::placeholder {
  color: rgba(105, 105, 105, 0.5);
}
.cs-global-select2-container-3 .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
  content: "\e95c";
  font-family: "bain" !important;
}
.cs-global-select2-container-3 .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .cs-global-select2-container-3 .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 12px;
}
.cs-global-select2-container-3 .select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .cs-global-select2-container-3 .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-radius: 12px;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--single, .cs-global-select2-container-3 .select2-container--default .select2-selection--multiple {
  cursor: pointer;
  background-color: #ffffff;
  border: 0.5px solid #696969;
  border-radius: 12px;
  height: 50px;
  padding: 10px 0px;
  overflow: hidden;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 25px;
  position: absolute;
  top: 12px;
  right: 20px;
  width: 14px;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
  content: "\e95e";
  font-family: "bain" !important;
  font-size: 8px;
  color: #696969;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  padding: 12px 25px;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #041614;
  opacity: 0.5;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #041614;
  opacity: 0.5;
}
.cs-global-select2-container-3 .select2-container--default .select2-search--inline .select2-search__field {
  cursor: pointer;
  margin: 2px 0px;
  color: #041614;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  padding: 0px 20px;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
}
.cs-global-select2-container-3 .select2-container--default .select2-search--inline .select2-search__field::placeholder {
  color: #041614;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  padding: 0;
}
.cs-global-select2-container-3 .select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-flex;
  margin: 0;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  display: none;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 0 5px 0px 0px;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__choice::after {
  content: ",";
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__choice:first-child {
  margin-left: 20px;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__choice:last-child {
  margin-right: 10px;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__choice:last-child::after {
  display: none;
}
.cs-global-select2-container-3 .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding: 0px;
}
.cs-global-select2-container-3 .selection {
  position: relative;
}
.cs-global-select2-container-3 .selection::after {
  content: "\e95e";
  font-family: "bain" !important;
  font-size: 8px;
  color: #696969;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  background-color: #ffffff;
  height: 30px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-global-select2-container-3 .select2-container--open .selection::after {
  content: "\e95c";
  font-family: "bain" !important;
}

.cs-hr-1 {
  border-color: #707070;
  margin-bottom: 25px;
  margin-top: 25px;
}

.cs-global-upload-cont-1 {
  max-width: 510px;
  width: 100%;
  margin-bottom: 30px;
}

.cs-iw-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #272727;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
}
.cs-iw-desc-1 i {
  color: #7287FD;
  font-size: 23px;
  position: relative;
  top: 5px;
  padding-right: 10px;
}

.cs-uploaded-box-1 {
  position: relative;
  border-radius: 14px;
  border: 1px dashed #27C1B2;
  margin-bottom: 15px;
  min-height: 195px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.cs-uploaded-box-1::before {
  content: "";
  border-radius: 14px;
  background-color: rgba(4, 22, 20, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.cs-uploaded-box-1 .csub1-btn-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 3;
  background-color: #FDFDFD;
  font-size: 18px;
  line-height: 1;
  color: #401FE5;
}
.cs-uploaded-box-1 input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.cs-checkbox-toggle-1 {
  height: 30px;
  display: inline-block;
}
.cs-checkbox-toggle-1 .switch {
  display: inline-block;
  height: 28px;
  position: relative;
  width: 60px;
  margin: 0;
}
.cs-checkbox-toggle-1 .switch input {
  display: none;
}
.cs-checkbox-toggle-1 .slider {
  background-color: #B2C1C7;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.cs-checkbox-toggle-1 .slider::before {
  background-color: #ffffff;
  content: "";
  height: 24px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 24px;
  top: 2px;
}
.cs-checkbox-toggle-1 .slider.round {
  border-radius: 20px;
}
.cs-checkbox-toggle-1 .slider.round::before {
  border-radius: 50%;
}
.cs-checkbox-toggle-1 input:checked + .slider {
  background-color: #27C1B2;
}
.cs-checkbox-toggle-1 input:checked + .slider::before {
  transform: translateX(30px);
}

.cs-toggle-desc-1 {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  position: relative;
  top: -8px;
  padding-left: 10px;
}

.cs-account-form-d-2 {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}

.cs-back-page-d-1 {
  padding: 10px 0px 20px;
}
.cs-back-page-d-1 .csbpd1-text-1 {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
}
.cs-back-page-d-1 .csbpd1-text-1 i {
  font-size: 15px;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.cs-earnings-info-div-1 {
  border-radius: 20px;
  padding: 16px;
  background: rgb(39, 193, 178);
  background: linear-gradient(180deg, rgba(39, 193, 178, 0.05) 0%, rgba(40, 180, 181, 0.05) 20%, rgba(46, 147, 192, 0.05) 40%, rgba(54, 94, 209, 0.05) 80%, rgba(64, 31, 229, 0.05) 100%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}
.cs-earnings-info-div-1 .cseid1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 25%;
  flex: 0 0 25%;
}
.cs-earnings-info-div-1 .cseid1-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 8px;
}
.cs-earnings-info-div-1 .cseid1-desc-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #272727;
  margin: 0;
}

.cs-doughnut-container-1 {
  position: relative;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0px 50px;
}

.cs-favorites-d-1 {
  text-align: center;
  padding-top: 10px;
}
.cs-favorites-d-1 img {
  margin-bottom: 25px;
}
.cs-favorites-d-1 .csfd1-desc-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 35px;
}
.cs-favorites-d-1 .csfd1-btn-d-1 {
  max-width: 250px;
  width: 100%;
  margin: 0 auto 30px;
}

.cs-secondary-btn-5 {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 45px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #F6FDFD;
  background-color: #401FE5;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.cs-secondary-btn-5 i {
  font-size: 18px;
  padding-left: 15px;
  position: relative;
  top: 0px;
}
.cs-secondary-btn-5:hover {
  border-color: #3F22E4;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  -moz-box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  box-shadow: 0px 5px 8px 0px rgba(64, 34, 229, 0.3);
  color: #401FE5;
}

.cs-account-navs-d-2 {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.cs-fake-navTabs-1 {
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #707070;
  line-height: 1;
}
.cs-fake-navTabs-1 a {
  display: block;
  border-radius: 0;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #041614;
  padding: 15px 20px;
  position: relative;
  transition: all 0.3s ease;
}
.cs-fake-navTabs-1 a:hover {
  color: #041614;
}
.cs-fake-navTabs-1 a.active {
  color: #401FE5;
  background-color: transparent;
  border-color: unset;
}
.cs-fake-navTabs-1 a.active::after {
  content: "";
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #401FE5;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.cs-rentals-cont-1 {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}

.cs-rentals-info-1 {
  margin-bottom: 15px;
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 12px 0px 24px;
}
.cs-rentals-info-1.type-1 {
  padding-bottom: 0px;
}
.cs-rentals-info-1.type-1 .csri1-table-1 {
  margin-bottom: 0px;
}
.cs-rentals-info-1.type-1 .csri1-table-1 tr:last-child td {
  border: 0;
}
.cs-rentals-info-1 .csri1-table-1 {
  margin-bottom: 15px;
}
.cs-rentals-info-1 .csri1-table-1 td {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
  border-bottom: 1px solid #EDEDED;
  padding: 12px 24px;
}
.cs-rentals-info-1 .csri1-table-1 td:first-child {
  font-weight: 400;
  color: #838383;
}
.cs-rentals-info-1 .csri1-table-1 td:last-child {
  text-align: right;
}
.cs-rentals-info-1 .csri1-countdown-1 {
  max-width: 260px;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #000000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px 7px 32px;
  position: relative;
  min-height: 36px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #ffffff;
}
.cs-rentals-info-1 .csri1-countdown-1::before {
  content: "\e913";
  font-family: "bain" !important;
  font-size: 22px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.cs-rentals-info-1 .csri1-countdown-1.type-1 {
  background-color: #35D634;
  -webkit-box-shadow: 0px 3px 6px 0px rgb(108, 210, 80);
  -moz-box-shadow: 0px 3px 6px 0px rgb(108, 210, 80);
  box-shadow: 0px 3px 6px 0px rgb(108, 210, 80);
}
.cs-rentals-info-1 .csri1-countdown-1.type-2 {
  background-color: #FFB100;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(255, 177, 0, 0.45);
  -moz-box-shadow: 0px 3px 6px 0px rgba(255, 177, 0, 0.45);
  box-shadow: 0px 3px 6px 0px rgba(255, 177, 0, 0.45);
}
.cs-rentals-info-1 .csri1-result-d-1 {
  background-color: #27C1B2;
  text-align: center;
  border-radius: 0 0 20px 20px;
  padding: 16px 10px;
}
.cs-rentals-info-1 .csri1-result-d-1 .csri1rd1-t-1 {
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #272727;
}
.cs-rentals-info-1 .csri1-result-d-1 .csri1rd1-t-1::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #401FE5;
  border-radius: 50%;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.cs-rentals-info-1 .csri1-result-d-1.completed {
  background-color: #CFEFBE;
}
.cs-rentals-info-1 .csri1-result-d-1.completed .csri1rd1-t-1 {
  color: #272727;
}
.cs-rentals-info-1 .csri1-result-d-1.completed .csri1rd1-t-1::before {
  background-color: #52BE19;
}
.cs-rentals-info-1 .csri1-result-d-1.cancelled {
  background-color: #FFEFEF;
}
.cs-rentals-info-1 .csri1-result-d-1.cancelled .csri1rd1-t-1 {
  color: #E04149;
}
.cs-rentals-info-1 .csri1-result-d-1.cancelled .csri1rd1-t-1::before {
  background-color: #E04149;
}

.cs-rentals-info-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 15px;
  position: relative;
  padding-left: 24px;
}
.cs-rentals-info-desc-1::before {
  content: "\e946";
  font-family: "bain" !important;
  color: #7287FD;
  font-size: 21px;
  position: absolute;
  left: 0;
  top: 6px;
}
.cs-rentals-info-desc-1 img {
  max-height: 38px;
}

.cs-acc-bot-btn-mar-1 {
  margin-bottom: 100px;
}

.cs-account-bottom-btn-d-1 {
  padding: 23px 15px;
  background-color: #ffffff;
  margin-top: -90px;
  position: relative;
  z-index: 1;
  text-align: center;
  -webkit-box-shadow: 0px -3px 30px 0px rgba(64, 31, 229, 0.05);
  -moz-box-shadow: 0px -3px 30px 0px rgba(64, 31, 229, 0.05);
  box-shadow: 0px -3px 30px 0px rgba(64, 31, 229, 0.05);
}
.cs-account-bottom-btn-d-1 .csabbd1-btn-1 {
  margin: 0 auto;
  max-width: 250px;
}

.cs-account-head-3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 25px;
}

.cs-rentals-details-d-1 {
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 12px 0px 24px;
}
.cs-rentals-details-d-1.type-1 {
  padding-bottom: 0px;
}
.cs-rentals-details-d-1.type-1 .csri1-table-1 {
  margin-bottom: 0px;
}
.cs-rentals-details-d-1.type-1 .csri1-table-1 tr:last-child td {
  border: 0;
}
.cs-rentals-details-d-1 .csrdd1-table-1 {
  margin-bottom: 20px;
}
.cs-rentals-details-d-1 .csrdd1-table-1 td {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
  border-bottom: 1px solid #EDEDED;
  padding: 12px 24px;
}
.cs-rentals-details-d-1 .csrdd1-table-1 td:first-child {
  font-weight: 400;
  color: #838383;
}
.cs-rentals-details-d-1 .csrdd1-table-1 td:last-child {
  text-align: right;
}
.cs-rentals-details-d-1 .csrdd1-countdown-1 {
  max-width: 260px;
  margin: 0 20px;
  border-radius: 20px;
  background-color: #000000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px 7px 32px;
  position: relative;
  min-height: 36px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #ffffff;
}
.cs-rentals-details-d-1 .csrdd1-countdown-1::before {
  content: "\e913";
  font-family: "bain" !important;
  font-size: 22px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.cs-rentals-details-d-1 .csrdd1-countdown-1.type-1 {
  background-color: #35D634;
  -webkit-box-shadow: 0px 3px 6px 0px rgb(108, 210, 80);
  -moz-box-shadow: 0px 3px 6px 0px rgb(108, 210, 80);
  box-shadow: 0px 3px 6px 0px rgb(108, 210, 80);
}
.cs-rentals-details-d-1 .csrdd1-countdown-1.type-2 {
  background-color: #FFB100;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(255, 177, 0, 0.45);
  -moz-box-shadow: 0px 3px 6px 0px rgba(255, 177, 0, 0.45);
  box-shadow: 0px 3px 6px 0px rgba(255, 177, 0, 0.45);
}
.cs-rentals-details-d-1 .csrdd1-countdown-1.type-3 {
  background-color: #401FE5;
}
.cs-rentals-details-d-1 .csrdd1-result-d-1 {
  padding: 0px 25px;
}
.cs-rentals-details-d-1 .csrdd1-result-desc-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #272727;
  margin: 0;
}
.cs-rentals-details-d-1 .csrdd1-result-desc-1::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #401FE5;
  border-radius: 50%;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.cs-rentals-details-d-1 .csrdd1-result-desc-1.completed {
  color: #52BE19;
}
.cs-rentals-details-d-1 .csrdd1-result-desc-1.completed::before {
  background-color: #52BE19;
}
.cs-rentals-details-d-1 .csrdd1-result-desc-1.cancelled {
  color: #E04149;
}
.cs-rentals-details-d-1 .csrdd1-result-desc-1.cancelled::before {
  background-color: #E04149;
}

.cs-rentals-payment-d-1 {
  margin-bottom: 35px;
  background-color: #F6FDFD;
  padding: 5px 25px 20px;
}
.cs-rentals-payment-d-1 .csrpd1-table-1 {
  margin-bottom: 0px;
}
.cs-rentals-payment-d-1 .csrpd1-table-1 tr td {
  padding: 8px 0px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
}
.cs-rentals-payment-d-1 .csrpd1-table-1 tr td:last-child {
  text-align: right;
}
.cs-rentals-payment-d-1 .csrpd1-table-1 tr:last-child td {
  border-top: 1px dashed #696969;
  font-weight: 700;
  padding-top: 15px;
}
.cs-rentals-payment-d-1 .csrpd1-table-1 tr:nth-last-child(2) td {
  padding-bottom: 15px;
}

.cs-action-input-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-action-input-menu-1 li {
  line-height: 1;
  margin-bottom: 25px;
}

.cs-check-input-2 {
  position: relative;
}
.cs-check-input-2 input {
  cursor: pointer !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  opacity: 0 !important;
}
.cs-check-input-2 input:checked + label, .cs-check-input-2 input:not(:checked) + label {
  position: relative !important;
  padding-right: 35px !important;
  padding-left: 0 !important;
  cursor: pointer !important;
  font-size: 14px !important;
  line-height: 25px !important;
  color: #272727 !important;
  display: block !important;
  left: 0 !important;
  top: 0 !important;
  margin: 0 !important;
}
.cs-check-input-2 input:not(:checked) + label:before {
  content: "";
  position: absolute;
  right: 0px;
  top: 1px;
  width: 24px;
  height: 24px;
  border: 1px solid #B7B7B7;
  background: #ffffff;
  border-radius: 4px;
}
.cs-check-input-2 input:checked + label:before {
  content: "";
  position: absolute;
  right: 0px;
  top: 1px;
  width: 24px;
  height: 24px;
  border: 1px solid #27C1B2;
  background: #27C1B2;
  border-radius: 4px;
}
.cs-check-input-2 input:checked + label:after {
  content: "\e947";
  font-family: "bain" !important;
  position: absolute;
  right: 4px;
  top: 8px;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
}

.cs-icon-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 40px;
}
.cs-icon-row-1 .csir1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 25%;
  flex: 0 0 25%;
  border-right: 1px solid #DBDBDB;
}
.cs-icon-row-1 .csir1-col-1:last-child {
  border-right: 0;
}

.cs-icon-box-2 {
  text-align: center;
}
.cs-icon-box-2 .img-div-1 {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 52px;
}
.cs-icon-box-2 .img-div-1 img {
  max-height: 52px;
}
.cs-icon-box-2 .csib2-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #696969;
  margin-bottom: 10px;
}
.cs-icon-box-2 .csib2-desc-2 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 0px;
}

.cs-iw-desc-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #272727;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
}
.cs-iw-desc-2 i {
  color: #696969;
  font-size: 23px;
  position: relative;
  top: 2px;
  padding-right: 10px;
}

.cs-rentals-timeout-d-1 {
  position: relative;
  padding: 16px 0px;
  background-color: #F6FDFD;
  margin-bottom: 20px;
}
.cs-rentals-timeout-d-1::before, .cs-rentals-timeout-d-1::after {
  content: "";
  background-color: #F6FDFD;
  height: 100%;
  position: absolute;
  width: 25px;
  top: 0;
  z-index: 1;
}
.cs-rentals-timeout-d-1::before {
  left: -25px;
}
.cs-rentals-timeout-d-1::after {
  right: -25px;
}

.cs-global-table-cont-1 {
  max-width: 350px;
}

.cs-global-table-1 {
  margin: 0;
}
.cs-global-table-1 tr td {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  padding: 10px 0px;
}
.cs-global-table-1 tr td:last-child {
  text-align: right;
  font-weight: 700;
}

.cs-iw-link-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #401FE5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.cs-iw-link-1 i {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #EAECF4;
  margin-right: 10px;
}
.cs-iw-link-1 span {
  text-decoration: underline;
}
.cs-iw-link-1:hover {
  color: #401FE5;
}
.cs-iw-link-1:hover span {
  text-decoration: none;
}

.cs-hr-2 {
  border-color: #EDEDED;
  margin-bottom: 25px;
  margin-top: 25px;
}

.cs-rating-box-1 {
  display: block;
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
}
.cs-rating-box-1 .rating {
  border: none;
  float: left;
  margin: 0;
  padding: 0;
}
.cs-rating-box-1 .rating > input {
  display: none;
}
.cs-rating-box-1 .rating > label {
  color: #EDEDED;
  float: right;
  margin: 0 !important;
}
.cs-rating-box-1 .rating > label:before {
  content: "\e94d";
  font-family: "bain" !important;
  margin: 5px;
  font-size: 30px;
  display: inline-block;
}
.cs-rating-box-1 .rating > .half:before {
  content: "\e94c";
  position: absolute;
}
.cs-rating-box-1 .rating > input:checked ~ label, .cs-rating-box-1 .rating:not(:checked) > label:hover, .cs-rating-box-1 .rating:not(:checked) > label:hover ~ label {
  color: #FFC850;
}
.cs-rating-box-1 .rating > input:checked + label:hover, .cs-rating-box-1 .rating > input:checked ~ label:hover, .cs-rating-box-1 .rating > label:hover ~ input:checked ~ label, .cs-rating-box-1 .rating > input:checked ~ label:hover ~ label {
  color: #FFC850;
}

.cs-navTabs-3 {
  justify-content: space-between;
}
.cs-navTabs-3.nav {
  margin-bottom: 30px;
}
.cs-navTabs-3.nav-tabs {
  border-color: #707070;
}
.cs-navTabs-3.nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #041614;
  padding: 10px 15px;
  position: relative;
  transition: all 0.3s ease;
}
.cs-navTabs-3.nav-tabs .nav-link.active {
  color: #27C1B2;
  background-color: transparent;
  border-color: unset;
  font-weight: 700;
}
.cs-navTabs-3.nav-tabs .nav-link.active::after {
  content: "";
  width: 80px;
  height: 5px;
  border-radius: 5px;
  background-color: #27C1B2;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.cs-navTabs-3.nav-tabs .nav-item.show {
  color: #27C1B2;
  background-color: transparent;
  border-color: unset;
}

.cs-rentals-doc-d-1 {
  max-width: 400px;
  margin: 0 auto;
}

.jsRentalsDocsModalStep-2 {
  display: none;
}

.cs-rentals-modal-doc-cont-1 {
  text-align: center;
}
.cs-rentals-modal-doc-cont-1 .csrmdc1-img-1 {
  margin-bottom: 35px;
}
.cs-rentals-modal-doc-cont-1 .csrmdc1-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 60px;
}

.cs-account-cars-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
  margin-bottom: 10px;
}
.cs-account-cars-row-1 .csacr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  max-width: calc(100% - 455px);
  flex: 0 0 calc(100% - 455px);
  margin-bottom: 20px;
}
.cs-account-cars-row-1 .csacr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 220px;
  flex: 0 0 220px;
  margin-bottom: 10px;
}
.cs-account-cars-row-1 .csacr1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 235px;
  flex: 0 0 235px;
  margin-bottom: 10px;
}

.cs-secondary-btn-6 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background-color: #EAECF4;
  border-radius: 10px;
  min-width: 145px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #401FE5;
  border: 0;
  padding: 5px 10px;
  transition: all 0.3s ease;
}
.cs-secondary-btn-6 i {
  padding-right: 10px;
}
.cs-secondary-btn-6:hover {
  color: #401FE5;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(234, 236, 244, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(234, 236, 244, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(234, 236, 244, 0.65);
}

.cs-account-cars-search-1 {
  position: relative;
  max-width: 260px;
}
.cs-account-cars-search-1 i {
  position: absolute;
  font-size: 25px;
  line-height: 1;
  color: #696969;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.cs-account-cars-search-1 input {
  padding-left: 50px !important;
}

.cs-account-cars-status-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}
.cs-account-cars-status-m-1 li {
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 15px;
}
.cs-account-cars-status-m-1 button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  background-color: #ffffff;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  min-width: 110px;
  padding: 5px;
}
.cs-account-cars-status-m-1 button.active {
  background-color: #F4F5F7;
}
.cs-account-cars-status-m-1 button.type-1::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #33D73A;
  margin-right: 8px;
}
.cs-account-cars-status-m-1 button.type-2::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #D9474F;
  margin-right: 8px;
}
.cs-account-cars-status-m-1 button.type-3::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #007AFF;
  margin-right: 8px;
}
.cs-account-cars-status-m-1 button.type-4::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #C5CDCD;
  margin-right: 8px;
}
.cs-account-cars-status-m-1 button.type-5::before {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #EFA036;
  margin-right: 8px;
}

.cs-account-cars-row-2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -11px;
  margin-left: -11px;
}
.cs-account-cars-row-2 .csacr2-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 11px;
  padding-left: 11px;
  max-width: 33.3333333333%;
  flex: 0 0 33.3333333333%;
}

.cs-cars-box-1 {
  position: relative;
  min-height: 220px;
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 35px 15px;
  margin-bottom: 30px;
}
.cs-cars-box-1 .cscb1-head-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 8px;
}
.cs-cars-box-1 .cscb1-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #838383;
  margin-bottom: 8px;
  max-height: 72px;
  min-height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.cs-cars-box-1 .cscb1-desc-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 0px;
  background-color: #F0F0F0;
  border-radius: 20px;
  min-height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  max-width: 160px;
  width: 100%;
}
.cs-cars-box-1::before {
  content: "";
  background-color: #F0F0F0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 0;
}
.cs-cars-box-1.status-1::before {
  background-color: #33D73A;
}
.cs-cars-box-1.status-2::before {
  background-color: #D9474F;
}
.cs-cars-box-1.status-3::before {
  background-color: #007AFF;
}
.cs-cars-box-1.status-4::before {
  background-color: #C5CDCD;
}
.cs-cars-box-1.status-5::before {
  background-color: #EFA036;
}

.cs-iw-head-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #401FE5;
  border: 0;
  padding: 0;
  margin-bottom: 25px;
  background-color: transparent;
}
.cs-iw-head-1 i {
  padding-right: 5px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.cs-iwb-head-1 {
  position: relative;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 24px;
  padding-bottom: 12px;
  padding-right: 26px;
  border-bottom: 1px solid #707070;
}
.cs-iwb-head-1.active::after {
  content: "\e947";
  font-family: "bain" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #1DC321;
  font-size: 12px;
  line-height: 1;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.cs-global-desc-3 {
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
  color: #272727;
  margin-bottom: 25px;
}

.cs-modal-5 .modal-dialog {
  max-width: 980px;
  margin: 3rem auto;
  padding: 15px;
}
.cs-modal-5 .modal-content {
  border-radius: 18px;
  padding: 0;
  border: 0;
}
.cs-modal-5 .close {
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: #1A1A1A;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  text-shadow: none;
}
.cs-modal-5 .modal-body {
  padding: 30px 70px;
  border-radius: 18px;
}
.cs-modal-5 .cs-damage-btn-group {
  margin: 30px auto;
  max-width: 310px;
}
.cs-modal-5 .modal-header {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}
.cs-modal-5 .modal-footer {
  justify-content: center;
}
.cs-modal-5 #damage_area_modal_label {
  color: #0C0C0C;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
.cs-modal-5 .cs-hasar-bolgesi-main {
  background-size: cover;
  max-width: 410px;
  width: 100%;
  height: 652px;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid black;
  margin: 0 auto;
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check {
  padding: 0;
  width: 30%;
  border-bottom: 1px solid #401FE5;
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check:nth-child(3n+2) {
  width: 40% !important;
  border-top: none;
  border-right: 1px solid #401FE5;
  border-left: 1px solid #401FE5;
  border-bottom: 1px solid #401FE5;
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check:nth-child(n+7) {
  border-bottom: none;
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check .cs-choose-check {
  display: none;
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check label {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check .form-check-input {
  display: none;
  cursor: pointer;
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label {
  background: rgba(39, 193, 178, 0.5);
  background: linear-gradient(180deg, rgba(39, 193, 178, 0.5) 0%, rgba(40, 180, 181, 0.5) 19%, rgba(46, 147, 192, 0.5) 38%, rgba(54, 94, 209, 0.5) 71%, rgba(64, 31, 229, 0.5) 100%);
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label .cs-choose-check {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
}
.cs-modal-5 .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label .cs-choose-check .cs-cc-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.cs-cars-upload-list-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;
}
.cs-cars-upload-list-1 .cscul1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 7px;
  padding-left: 7px;
  max-width: 20%;
  flex: 0 0 20%;
}

.cs-cars-upload-box-1 {
  position: relative;
  margin-bottom: 14px;
  border-radius: 14px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 120px;
  transition: all 0.3s ease;
}
.cs-cars-upload-box-1::before {
  content: "";
  border-radius: 14px;
  background-color: #041614;
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.cs-cars-upload-box-1 .cscub1-delete-btn-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #FDFDFD;
  font-size: 15px;
  line-height: 1;
  color: #401FE5;
  border: 0;
}
.cs-cars-upload-box-1:hover::before {
  visibility: visible;
  opacity: 0.6;
}
.cs-cars-upload-box-1:hover .cscub1-delete-btn-1 {
  visibility: visible;
  opacity: 1;
}

.cs-rentals-info-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 15px;
  position: relative;
  padding-left: 32px;
}
.cs-rentals-info-head-1::before {
  content: "\e946";
  font-family: "bain" !important;
  color: #7287FD;
  font-size: 21px;
  position: absolute;
  left: 0;
  top: 0px;
}

.cs-rentals-info-desc-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #696969;
  margin-bottom: 25px;
}

.cs-cars-work-plain-cont-1 {
  max-width: 1010px;
  width: 100%;
  margin: 0 auto;
}

.cs-cars-wp-breadcrumb-1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding-top: 10px;
}
.cs-cars-wp-breadcrumb-1 ul li {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
}
.cs-cars-wp-breadcrumb-1 ul li::after {
  content: "";
  background-color: #EDEDED;
  width: 1px;
  height: 20px;
  display: inline-block;
  margin-right: 20px;
  margin-left: 15px;
  position: relative;
  top: 4px;
}
.cs-cars-wp-breadcrumb-1 ul li:last-child::after {
  display: none;
}
.cs-cars-wp-breadcrumb-1 ul li a {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
}
.cs-cars-wp-breadcrumb-1 ul li a:hover {
  color: #041614;
}

.cs-cars-wp-box-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  padding: 35px 30px;
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  margin-bottom: 30px;
}
.cs-cars-wp-box-1 .cscwb1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 230px;
  flex: 0 0 230px;
  text-align: center;
}
.cs-cars-wp-box-1 .cscwb1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 470px);
  flex: 0 0 calc(100% - 470px);
}
.cs-cars-wp-box-1 .cscwb1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 240px;
  flex: 0 0 240px;
}
.cs-cars-wp-box-1 .cscwb1-head-1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 12px;
}
.cs-cars-wp-box-1 .cscwb1-desc-1 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #838383;
  margin-bottom: 0px;
}
.cs-cars-wp-box-1 .cscwb1-price-1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 0px;
  min-height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #F0F0F0;
  border-radius: 20px;
  padding: 5px 10px;
  min-width: 160px;
}
.cs-cars-wp-box-1 .cscwb1-price-1::after {
  content: "";
  background-color: #F0F0F0;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.cs-cars-wp-box-1 .cscwb1-price-1.status-1::after {
  background-color: #33D73A;
}
.cs-cars-wp-box-1 .cscwb1-price-1.status-2::after {
  background-color: #D9474F;
}
.cs-cars-wp-box-1 .cscwb1-price-1.status-3::after {
  background-color: #007AFF;
}
.cs-cars-wp-box-1 .cscwb1-price-1.status-4::after {
  background-color: #C5CDCD;
}

.cs-cars-wp-empty-div-1 {
  text-align: center;
  margin-bottom: 45px;
}
.cs-cars-wp-empty-div-1 img {
  width: auto;
  max-height: 230px;
  margin-bottom: 40px;
}
.cs-cars-wp-empty-div-1 .cscwed1-desc-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 45px;
}
.cs-cars-wp-empty-div-1 .cscwed1-btn-1 {
  max-width: 310px;
  width: 100%;
}

.cs-cars-wp-list-box-1 {
  margin-bottom: 25px;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #EAECF4;
  border-radius: 12px;
  padding: 10px 15px 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  align-items: center;
}
.cs-cars-wp-list-box-1 .cscwlb1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
  text-align: center;
}
.cs-cars-wp-list-box-1 .cscwlb1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 80px);
  flex: 0 0 calc(100% - 80px);
}
.cs-cars-wp-list-box-1 .cscwlb1-col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
  max-width: 40px;
  flex: 0 0 40px;
}
.cs-cars-wp-list-box-1 .cscwlb1-icon-1 {
  font-size: 30px;
  line-height: 1;
  color: #401FE5;
  margin-bottom: 0px;
}
.cs-cars-wp-list-box-1 .cscwlb1-head-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 0px;
}
.cs-cars-wp-list-box-1 .cscwlb1-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 0px;
}
.cs-cars-wp-list-box-1 .cscwlb1-action-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs-cars-wp-list-box-1 .cscwlb1-action-m-1 li {
  line-height: 1;
  margin-bottom: 5px;
}
.cs-cars-wp-list-box-1 .cscwlb1-action-m-1 li:last-child {
  margin: 0;
}
.cs-cars-wp-list-box-1 .cscwlb1-action-m-1 li button {
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #696969;
  width: 100%;
  height: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: all 0.3s ease;
}
.cs-cars-wp-list-box-1 .cscwlb1-action-m-1 li button:hover {
  color: #401FE5;
}
.cs-cars-wp-list-box-1 .cscwlb1-action-m-1 li a {
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #696969;
  width: 100%;
  height: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: all 0.3s ease;
}
.cs-cars-wp-list-box-1 .cscwlb1-action-m-1 li a:hover {
  color: #401FE5;
}

.cs-cars-wp-head-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 30px;
}

.cs-cars-wp-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.cs-cars-wp-row-1 .cscwpr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 20px;
}
.cs-cars-wp-row-1 .cscwpr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 20px;
}

.cs-cars-wp-btn-a-1 {
  max-width: 310px;
  margin: 0 auto 50px;
  padding-top: 50px;
}

.cs-cars-wp-c-box-1 {
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 25px;
  height: 100%;
}
.cs-cars-wp-c-box-1 .cscwpcb1-in-1 {
  max-width: 320px;
  margin: 0 auto;
}
.cs-cars-wp-c-box-1 .cscwpcb1-br-d-1 {
  border-top: 1px solid #EDEDED;
  padding-top: 35px;
  margin-top: 35px;
}

.cs-cars-wp-c-box-2 {
  background-color: #F6FDFD;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 10px 15px 0px;
  height: 100%;
}

.cs-cars-wp-table-1 thead th {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #272727;
  padding: 20px 10px;
  vertical-align: middle;
}
.cs-cars-wp-table-1 tbody td {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #272727;
  padding: 20px 10px;
  vertical-align: middle;
  position: relative;
}
.cs-cars-wp-table-1 .activeDayTimeClass1 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc(100% - 20px);
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
}
.cs-cars-wp-table-1 .time-add-btn-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  line-height: 1;
  color: #007AFF;
  width: 35px;
  height: 30px;
  border: 0;
  background-color: transparent;
}
.cs-cars-wp-table-1 .table-ii-1 {
  font-size: 22px;
  line-height: 1;
  color: #27C1B2;
  display: block;
  text-align: center;
  padding-right: 10px;
}
.cs-cars-wp-table-1 input[type=time] {
  background: transparent;
  border: none;
  outline: none;
}

.cs-check-input-3 {
  position: relative;
  min-height: 30px;
}
.cs-check-input-3 input {
  cursor: pointer !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  opacity: 0 !important;
}
.cs-check-input-3 input:checked + label, .cs-check-input-3 input:not(:checked) + label {
  position: relative !important;
  padding-left: 35px !important;
  padding-right: 0 !important;
  cursor: pointer !important;
  font-size: 14px !important;
  line-height: 25px !important;
  color: #272727 !important;
  display: inline-block !important;
  left: 0 !important;
  top: 0 !important;
  margin-bottom: 15px !important;
}
.cs-check-input-3 input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 24px;
  height: 24px;
  border: 1px solid #B7B7B7;
  background: #ffffff;
  border-radius: 4px;
}
.cs-check-input-3 input:checked + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 24px;
  height: 24px;
  border: 1px solid #27C1B2;
  background: #ffffff;
  border-radius: 4px;
}
.cs-check-input-3 input:checked + label:after {
  content: "\e947";
  font-family: "bain" !important;
  position: absolute;
  left: 5px;
  top: 6px;
  font-size: 12px;
  line-height: 1;
  color: #27C1B2;
}

.daterangepicker.mnt-type-2 {
  border-radius: 20px;
}
.daterangepicker.mnt-type-2 .drp-calendar.left {
  padding-right: 8px;
}
.daterangepicker.mnt-type-2 .drp-buttons {
  text-align: center;
}

.cs-cars-wp-c-box-3 {
  background-color: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(69, 69, 69, 0.1);
  padding: 25px;
  height: 100%;
}
.cs-cars-wp-c-box-3 .cscwpcb3-in-1 {
  max-width: 320px;
  margin: 0 auto;
}

.cs-cars-wp-form-d-1 .empty {
  border-color: #E04149 !important;
}

.cs-cars-wp-form-d-2 {
  display: none;
}

.cs-soon-div-1 {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 100vh;
  height: 100%;
  background-blend-mode: multiply;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 2%;
}

.cs-soon-container-1 {
  max-width: 1390px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.cs-soon-top-s-1 {
  text-align: center;
  margin-bottom: 100px;
}
.cs-soon-top-s-1 img {
  margin-bottom: 50px;
  max-height: 160px;
}
.cs-soon-top-s-1 .head-1 {
  font-size: 80px;
  line-height: 90px;
  font-weight: 800;
  color: #ffffff;
  letter-spacing: 5px;
  margin-bottom: 0px;
}

.cs-soon-row-1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}
.cs-soon-row-1 .cssr1-col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: calc(100% - 480px);
  flex: 0 0 calc(100% - 480px);
}
.cs-soon-row-1 .cssr1-col-2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 480px;
  flex: 0 0 480px;
}

.cs-soon-text-con-1 {
  max-width: 580px;
}

.cs-soon-head-1 {
  font-size: 44px;
  line-height: 52px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 25px;
}

.cs-soon-desc-1 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 25px;
}

.cs-soon-image-m-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-soon-image-m-1 li {
  margin-bottom: 15px;
  margin-right: 8px;
}
.cs-soon-image-m-1 li:last-child {
  margin-right: 0px;
}
.cs-soon-image-m-1 li img {
  max-height: 44px;
  min-height: 44px;
}

.cs-file-name-item-1, .cs-file-name-item-1::placeholder {
  border: none;
  color: #041614 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  margin-bottom: 12px !important;
  padding: 0 !important;
}

.cs-file-name-item-1:focus-visible, .cs-file-name-item-1:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.cs-vale-form-c-1 {
  max-width: 700px;
  padding: 30px 15px;
  position: relative;
  width: 100%;
  margin: 0 auto 0 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-vale-form-c-1 .form-d-1 {
  max-width: 490px;
  margin: 0 auto;
}
.cs-vale-form-c-1 .form-d-1 .cs-wbg-head-3 {
  text-align: center;
}
.cs-vale-form-c-1 .form-d-2 {
  max-width: 320px;
  margin: 0 auto;
}
.cs-vale-form-c-1 .form-d-2 .cs-global-document-upload-1 {
  width: 100%;
}
.cs-vale-form-c-1 .form-d-2 .form-input-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.cs-vale-form-c-1 .form-d-2 .cs-primary-btn-3 {
  opacity: 0.5;
  pointer-events: none;
}
.cs-vale-form-c-1 .form-d-2 .cs-primary-btn-3.active {
  cursor: pointer;
  opacity: 1 !important;
  pointer-events: visible !important;
}
.cs-vale-form-c-1 .form-d-3 {
  margin: 0 auto;
}
.cs-vale-form-c-1 .form-d-3 .form-input-1 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-1 {
  border: 1px solid #27C1B2;
  border-radius: 18px;
  padding: 7px;
  position: relative;
  text-align: center;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-1 .cs-vale-title {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-1 .cs-vale-desc {
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-1 .cs-vale-icon-1 {
  border: 1px solid #27C1B2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  width: max-content;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-1 .cs-vale-icon-1 i {
  color: #27C1B2;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-2 {
  border: 1px solid #401FE5;
  border-radius: 18px;
  padding: 7px;
  position: relative;
  text-align: center;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-2 .cs-vale-title {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-2 .cs-vale-desc {
  color: #000000;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-2 .cs-vale-icon-2 {
  border: 1px solid #401FE5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  width: max-content;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
}
.cs-vale-form-c-1 .form-d-3 .cs-vale-main-1 .cs-vale-select-2 .cs-vale-icon-2 i {
  color: #401FE5;
}

.cs-vale-info {
  margin: 0 auto;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  gap: 20px;
  text-align: center;
}
.cs-vale-info .cs-desc-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}
.cs-vale-info .cs-link-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #EFA036;
  text-decoration: underline !important;
}
.cs-vale-info .cs-link-2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #E04149;
  text-decoration: underline !important;
}

.cs-global-area-1 .cs-cookie-fly-d-1 {
  background-color: #401FE5;
  padding: 15px 50px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 20;
  -webkit-box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease;
}
.cs-global-area-1 .cs-cookie-fly-d-1.deactive {
  opacity: 0;
  visibility: hidden;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .desc-1 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .desc-1 a {
  color: #27C1B2;
  font-weight: 600;
  text-decoration: underline;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .btn-menu-1 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .btn-menu-1 li {
  line-height: 1;
  margin-bottom: 15px;
  padding: 0px 10px;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .cookies-btn-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 40px;
  padding: 5px 15px;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  transition: all 0.2s ease;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .cookies-btn-1:hover {
  background-color: #ffffff;
  color: #401FE5;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .cookies-btn-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  background-color: #27C1B2;
  border: 1px solid #27C1B2;
  border-radius: 40px;
  padding: 5px 15px;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  transition: all 0.2s ease;
}
.cs-global-area-1 .cs-cookie-fly-d-1 .cookies-btn-2:hover {
  background-color: transparent;
  color: #27C1B2;
}
.cs-global-area-1 .cs-modal-7 .modal-content {
  border: none;
  border-radius: 14px;
}
.cs-global-area-1 .cs-modal-7 .modal-content .cs-wbg-head-2 {
  font-size: 25px;
}
.cs-global-area-1 .cs-modal-7 .modal-content .cs-wbg-head-2 span::before {
  content: none;
}
.cs-global-area-1 .cs-modal-7 .modal-content .modal-body {
  padding: 0 25px 25px;
}
.cs-global-area-1 .cs-modal-7 .modal-content .modal-body .cs-desc-1 {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.cs-language-select {
  border: none;
  align-items: center;
  background-color: #f0edff;
  border: 1px solid transparent;
  border-radius: 50%;
  color: #401fe5;
  display: flex;
  font-size: 24px;
  height: 45px;
  justify-content: center;
  line-height: 1;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
}
.cs-language-select:hover, .cs-language-select:focus, .cs-language-select:focus-within, .cs-language-select:focus-visible {
  outline: none;
}
.cs-language-select:hover {
  background-color: #fff;
  border-color: #3f22e4;
  color: #3f22e4;
}

.scroll-modal-a-1 table {
  width: 100% !important;
}

.cs-rentals-info-wrapper .cs-iwb-head-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-rentals-info-wrapper .cs-iwb-head-1 span {
  font-size: 20px;
  text-transform: capitalize;
}
.cs-rentals-info-wrapper .cs-iwb-head-1 .cs-delete-btn {
  color: #27C1B2;
}
.cs-rentals-info-wrapper .cs-iwb-head-1 .cs-delete-btn:hover {
  color: #401FE5;
}
.cs-rentals-info-wrapper .cs-iwb-head-1 .cs-delete-btn i {
  font-size: 20px;
}

.calendar-fully {
  pointer-events: none;
}
.calendar-fully::after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 7px;
  left: 50%;
  position: absolute;
  bottom: -2px;
  transform: translateX(-50%);
  width: 7px;
  background: #E04149;
}

.calendar-half::after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 7px;
  left: 50%;
  position: absolute;
  bottom: -2px;
  transform: translateX(-50%);
  width: 7px;
  background: #FFBA00;
}

.calendar-invalid {
  pointer-events: none;
}
.calendar-invalid::after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 7px;
  left: 50%;
  position: absolute;
  bottom: -2px;
  transform: translateX(-50%);
  width: 7px;
  background: #AAAAAA;
}

.calendar-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.calendar-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.calendar-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.leaflet-popup-content-wrapper .leaflet-popup-content .cs-car-box-1 {
  box-shadow: none;
  margin: 0;
}
.leaflet-popup-content-wrapper .leaflet-popup-content .cs-car-box-1 .img-div-1 img {
  height: 210px;
}
.leaflet-popup-content-wrapper .leaflet-popup-content .cs-car-box-1 .cscb-top-area-1 {
  padding: 0;
}
.leaflet-popup-content-wrapper .leaflet-popup-content .cs-car-box-1 .cscb-bottm-area-1 .desc-2 {
  padding: 5px;
}
.leaflet-popup-content-wrapper .leaflet-popup-content .cs-car-box-1 .info-div-1 {
  padding: 5px;
}
.leaflet-popup-content-wrapper .leaflet-popup-content .cs-car-box-1 .info-drow-1 .idr1-col-1 {
  margin-bottom: 5px;
}
.leaflet-popup-content-wrapper .leaflet-popup-content .cs-car-box-1 p {
  margin: 0;
  margin-bottom: 5px;
}

.cs-global-breadcrumb-1 {
  padding: 35px 0;
}
.cs-global-breadcrumb-1 .breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
}
.cs-global-breadcrumb-1 .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "|";
  color: #041614;
}
.cs-global-breadcrumb-1 .breadcrumb .breadcrumb-item + .breadcrumb-item:last-child::before {
  color: #27C1B2;
}
.cs-global-breadcrumb-1 .breadcrumb .breadcrumb-item .cs-breadcrumb-link {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-global-breadcrumb-1 .breadcrumb .breadcrumb-item .cs-breadcrumb-link i {
  font-size: 22px;
}
.cs-global-breadcrumb-1 .breadcrumb .breadcrumb-item.active {
  color: #27C1B2;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.cs-about-us-sec-1 .cs-logo-text-title-1 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 800;
  color: #401FE5;
}
.cs-about-us-sec-1 .cs-logo-text-title-1 span {
  color: #27C1B2;
}
.cs-about-us-sec-1 .cs-desc-1 {
  font-size: 20px;
  line-height: 28px;
  color: #696969;
}
.cs-about-us-sec-1 .cs-about-us-content-wrapper-1 {
  padding: 25px 0;
}
.cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 {
  display: flex;
  padding: 10px 0;
  gap: 17px;
}
.cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 .cs-about-us-imagebox-1 {
  flex: 0 0 388px;
  max-width: 388px;
  width: 100%;
  border-radius: 45px;
}
.cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 .cs-about-us-imagebox-1 img {
  width: 100%;
  border-radius: 45px;
}
.cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 .cs-about-us-textbox-1 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
}

.cs-banner-sec-1 .cs-banner-wrapper {
  height: 315px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.cs-banner-sec-1 .cs-banner-wrapper .cs-aub-banner-1 {
  max-width: 100% !important;
}
.cs-banner-sec-1 .cs-banner-wrapper .cs-banner-textbox-1 {
  text-align: center;
  max-width: 650px;
  width: 100%;
}
.cs-banner-sec-1 .cs-banner-wrapper .cs-banner-textbox-1 .cs-title-1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  color: #ffffff;
}
.cs-banner-sec-1 .cs-banner-wrapper .cs-banner-textbox-1 .cs-desc-1 {
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
}

.cs-banner-socialbox-1 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 195px;
  height: 45px;
  background-color: rgba(64, 31, 229, 0.871);
  border-radius: 23px 23px 0px 0px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-banner-socialbox-1 i {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
}

.cs-career-sec-1 .cs-career-content-wrapper-1 {
  padding: 25px 0;
}
.cs-career-sec-1 .cs-career-content-wrapper-1 .cs-career-item-1 {
  display: flex;
  padding: 10px 0;
  gap: 17px;
  align-items: center;
}
.cs-career-sec-1 .cs-career-content-wrapper-1 .cs-career-item-1 .cs-career-imagebox-1 {
  flex: 0 0 388px;
  max-width: 388px;
  width: 100%;
  border-radius: 45px;
}
.cs-career-sec-1 .cs-career-content-wrapper-1 .cs-career-item-1 .cs-career-imagebox-1 img {
  width: 100%;
  border-radius: 45px;
}
.cs-career-sec-1 .cs-career-content-wrapper-1 .cs-career-item-1 .cs-career-textbox-1 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
}

.cs-career-sec-2 .cs-career-advert-wrapper {
  width: 100%;
  background-color: #F6FDFD;
  padding: 30px 0;
  margin: 30px 0;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 {
  display: flex;
  gap: 30px;
  justify-content: center;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item {
  display: block;
  position: relative;
  width: 310px;
  padding: 15px 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #ffffff;
  border-radius: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item .cs-item-top-area {
  width: 130px;
  height: 13px;
  background-color: #401FE5;
  border-radius: 0px 0px 20px 20px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item .cs-title-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #272727;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item .cs-desc-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #272727;
  margin: 0;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item .cs-item-info-1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 25px 0 0px;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item .cs-item-info-1 .cs-desc-2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #401FE5;
  margin: 0;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item .cs-item-info-1 span.cs-desc-1 {
  text-align: right;
}
.cs-career-sec-2 .cs-career-advert-wrapper .cs-career-advert-itembox-1 .cs-career-advert-item .cs-item-info-1 span .cs-desc-1 {
  font-size: 13px;
}

.cs-career-detail-sec-1 {
  margin-bottom: 150px;
}
.cs-career-detail-sec-1 .cs-career-detail-main-1 {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  padding-right: 8.5%;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper {
  flex: 1;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 .cs-item-info-1 {
  display: contents;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 .cs-item-info-1 .cs-desc-2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #401FE5;
  margin: 0;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 .cs-item-info-1 span.cs-desc-1 {
  text-align: right;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 .cs-item-info-1 span .cs-desc-1 {
  font-size: 13px;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-textbox-1 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-textbox-1 .cs-text-list-1 {
  padding: 1rem;
}
.cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-textbox-1 .cs-text-list-1 li::marker {
  content: " - ";
  font-size: 1.2em;
}
.cs-career-detail-sec-1 .cs-career-detail-inputs-1 {
  flex: 1;
}
.cs-career-detail-sec-1 .cs-career-detail-inputs-1 .cs-cd-apply-1 {
  margin: 0 auto;
  max-width: 365px;
}
.cs-career-detail-sec-1 .cs-career-detail-inputs-1 .cs-cd-apply-1 .cs-form-1 input[type=checkbox] {
  height: auto;
}
.cs-career-detail-sec-1 .cs-career-detail-inputs-1 .cs-cd-apply-1 .cs-form-1 .cs-form-check-text-1 {
  font-size: 14px;
  line-height: 22px;
  color: #272727;
}
.cs-career-detail-sec-1 .cs-career-detail-inputs-1 .cs-cd-apply-1 .cs-form-1 .cs-form-check-text-1 .cs-form-check-text-link-1 {
  color: #272727;
  text-decoration: underline !important;
}

.cs-upload-file-1 {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cs-upload-file-1:hover {
  color: #27C1B2;
}
.cs-upload-file-1:hover .cs-square-plus {
  border: 2px solid #27C1B2;
}
.cs-upload-file-1 .cs-square-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #401FE5;
  padding: 1px 1px;
  border-radius: 3px;
}
.cs-upload-file-1 .cs-square-plus i {
  font-size: 12px;
}

.cs-half-banner-1 {
  background: rgb(39, 193, 178);
  background: linear-gradient(180deg, rgb(39, 193, 178) 0%, rgb(40, 180, 181) 18%, rgb(46, 147, 192) 38%, rgb(54, 94, 209) 66%, rgb(64, 31, 229) 100%);
  position: relative;
  flex: 1;
  border-radius: 0px 50px 0px 0px;
  padding: 50px 0;
}
.cs-half-banner-1 .cs-textbox-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.cs-half-banner-1 .cs-textbox-1 .cs-title-1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  color: #ffffff;
}
.cs-half-banner-1 .cs-textbox-1 .cs-desc-1 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
  color: #ffffff;
}
.cs-half-banner-1 .cs-textbox-1 .cs-item-info-1 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.cs-half-banner-1 .cs-textbox-1 .cs-item-info-1 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff;
}

.cs-dd-modal-file-1 .modal-title {
  color: #0C0C0C;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
.cs-dd-modal-file-1 .modal-content {
  border-radius: 25px;
  padding-bottom: 32px;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper {
  max-width: 397px;
  height: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #27C1B2;
  border-radius: 18px;
  margin: 0 auto;
  position: relative;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper .cs-dd-modal-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  text-align: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper .cs-dd-modal-text .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper .cs-dd-modal-text .cs-desc-1 .cs-desc-strong-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper .cs-dd-modal-text .cs-desc-1 .cs-upload-label-1 {
  text-decoration: underline !important;
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper .cs-dd-modal-text .cs-desc-2 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper .cs-dd-modal-text .cs-desc-3 {
  color: #696969;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper .cs-dd-modal-text .cs-file-name-text {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-dd-modal-file-1 .cs-modal-input-wrapper input[type=file] {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.cs-file-info-js-1 {
  display: none;
  white-space: nowrap;
  align-items: center;
}
.cs-file-info-js-1 .cs-file-name-text {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-file-info-js-1 .cs-file-reset-btn-1 {
  border: none;
  background: none;
}

.cs-form-flexbox-1 {
  display: flex;
  gap: 10px;
}
.cs-form-flexbox-1 .cs-upload-file-1 {
  white-space: nowrap;
}
.cs-form-flexbox-1 .cs-file-name-text {
  display: inline-block;
  max-width: 17ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cs-popular-search {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-popular-search .cs-ps-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 5px 12px;
  color: #041614;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  transition: all 0.3s;
}
.cs-popular-search .cs-ps-item.active {
  color: #27C1B2;
}
.cs-popular-search .cs-ps-item:hover {
  background: #27C1B2;
  color: #ffffff;
  transition: all 0.3s;
}
.cs-popular-search .cs-desc-1 {
  margin: 0;
  font-size: 18px !important;
  line-height: 26px !important;
  font-weight: 600;
}

.cs-faq-sec-1 {
  padding: 50px 0;
}

.cs-form-subtitle {
  color: #1A1A1A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-bottom: 1px solid #1A1A1A;
  padding-bottom: 10px;
}

.cs-contact-us-sec-1 .cs-contact-us-main {
  padding: 30px 0;
}
.cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-wrapper {
  display: flex;
}
.cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-wrapper .cs-contact-us-imagebox-1 {
  flex: 0 0 50%;
}
.cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-wrapper .cs-contact-us-imagebox-1 img {
  width: 100%;
}
.cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-form-wrapper .cs-form-1 {
  max-width: 350px;
  width: 100%;
}
.cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-form-wrapper .cs-arabanipaylas-line {
  position: absolute;
  left: -30px;
  top: 45%;
  z-index: 0;
  max-width: max-content;
}
.cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-form-wrapper .cs-full-input {
  width: 100%;
}
.cs-contact-us-sec-1 .cs-form-login-1 {
  border: 1px solid #27C1B2;
  border-radius: 20px;
  padding: 16px;
}
.cs-contact-us-sec-1 .cs-form-login-1 .cs-desc-1 {
  color: #696969;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin: 0;
}
.cs-contact-us-sec-1 .cs-form-login-1 .cs-form-login-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-contact-us-sec-1 .cs-form-login-1 .cs-form-login-top .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
}

.cs-primary-link-1 {
  color: #27C1B2;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  text-decoration: underline !important;
  text-transform: capitalize;
}
.cs-primary-link-1:hover {
  transition: all 0.3s linear;
  color: #401FE5;
  text-decoration: underline !important;
}

.cs-primary-select2-1 .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px !important;
}
.cs-primary-select2-1 .select2-container .selection .select2-selection--single {
  border: 0.5px solid #272727;
  border-radius: 14px;
  color: #041614;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  line-height: 24px;
  padding: 5px 20px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.cs-select2-dd-1 {
  border-radius: 14px !important;
  transform: translateY(5px);
  padding: 20px 0;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #454545;
}
.cs-select2-dd-1 .select2-results {
  border-radius: 14px;
}
.cs-select2-dd-1 .select2-results__options .select2-results__option--highlighted.select2-results__option--selected {
  background-color: #ffffff;
  color: #454545;
  position: relative;
}
.cs-select2-dd-1 .select2-results__options .select2-results__option--highlighted.select2-results__option--selected::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #27C1B2;
}
.cs-select2-dd-1 .select2-results__options .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #BEEBE7;
  color: #000000;
}
.cs-select2-dd-1 .select2-results__option {
  padding: 16px 20px 10px;
  border-bottom: 1px solid #DEDEDE;
}
.cs-select2-dd-1 .select2-results__option--selected {
  background-color: #ffffff;
  position: relative;
}
.cs-select2-dd-1 .select2-results__option--selected::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #27C1B2;
}

.cs-car-detail-sec-1 .cs-car-detail-main-1 {
  display: flex;
  gap: 100px;
  margin-bottom: 50px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 {
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-form-1 .date-box-1 input {
  max-width: 350px;
  width: 100% !important;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-price-1 {
  padding: 0 15px;
  height: 60px;
  background: #401FE5;
  border-radius: 0px 22px 0px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-price-1 .cs-desc-1 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-price-1 .cs-desc-2 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-favorite-1 a {
  color: #FF081B;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-info-status-1 {
  border-bottom: 1px solid #EDEDED;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-info-status-1 .cs-title-1 {
  color: #041614;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-info-status-1 .cs-desc-1 {
  color: #838383;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-info-status-1 .cs-status-1 {
  font-size: 16px;
  line-height: 24px;
  color: #35D634;
  font-weight: 400;
  text-transform: capitalize;
  margin-left: 10px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-feature-1 {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #EDEDED;
  gap: 10px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-feature-1 .cs-desc-1 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-feature-1 .cs-desc-1 i {
  font-size: 24px;
  color: #000000;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-feature-1 .cs-desc-1 .cs-cd-info-hover {
  position: absolute;
  top: -15px;
  left: 15%;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-feature-1 .cs-desc-1 .cs-cd-info-hover i {
  font-size: 12px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-rules-1 {
  display: flex;
  gap: 16px;
  padding: 16px 0;
  border-bottom: 1px solid #EDEDED;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-rules-1 .cs-rules-item-1 {
  width: 165px;
  height: 45px;
  background-color: #F7F8FD;
  color: #041614;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-rules-1 .cs-rules-item-1 i {
  color: #401FE5;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper {
  padding: 16px 0;
  border-bottom: 1px solid #EDEDED;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 {
  list-style: none !important;
  padding: 0;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 li:not(.collapse) {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 li:not(.collapse) .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 li:not(.collapse) .cs-desc-2 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 li:not(.collapse) .cs-desc-2 .bain-star {
  color: #F0C76A;
  margin: 0 5px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 li:not(.collapse) .bain-angle-up {
  font-size: 10px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 li:not(.collapse).cs-list-item-1 {
  display: flex;
  flex-direction: column;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 .cs-collapse-list .cs-desc-1 {
  color: #007AFF !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-list-wrapper .cs-cd-list-1 .cs-list-map-1 {
  width: 100%;
  height: 175px;
  border-radius: 14px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper {
  padding: 16px 0;
  border-bottom: 1px solid #EDEDED;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 {
  background-color: #F7F8FD;
  color: #041614;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 8px;
  width: 120px;
  height: 110px;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 p {
  margin: 0;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 .cs-title-1 {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 .cs-title-2 {
  color: #041614;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 .cs-desc-1 {
  color: #696969;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-form-1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
  align-items: center;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-form-1 .cs-cd-form-main-1 .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-form-1 .cs-cd-form-main-1 .cs-desc-1 span {
  color: #27C1B2;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-form-1 .cs-cd-form-main-1 .cs-date-messages {
  padding: 16px 0;
}
.cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-form-1 .cs-cd-form-main-1 .cs-date-messages .cs-desc-1 {
  color: #272727;
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 {
  max-width: 400px;
  margin: 0 auto;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-image-head {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 15px;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-image-head .cs-title-1 {
  color: #041614;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  margin: 0;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-image-head .cs-link-1 {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images [class^=col] {
  padding: 10px;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images .row {
  margin: 0;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images .cs-image-grid-1 .cs-cd-image-item-1 {
  border-radius: 14px;
  display: block;
  width: 100%;
  height: 370px;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images .cs-image-grid-1 .cs-cd-image-item-1 img {
  border-radius: 14px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images .cs-image-grid-1 .cs-cd-image-item-2 {
  border-radius: 14px;
  display: block;
  max-width: 190px;
  height: 175px;
}
.cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images .cs-image-grid-1 .cs-cd-image-item-2 img {
  border-radius: 14px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cs-car-detail-sec-1 .like-btn-1 {
  font-size: 20px;
  line-height: 1;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.cs-car-detail-sec-1 .like-btn-1::before {
  content: "\e940";
  font-family: "bain" !important;
  position: relative;
  top: 1px;
}
.cs-car-detail-sec-1 .like-btn-1.active {
  color: #FF081B;
}
.cs-car-detail-sec-1 .like-btn-1.active::before {
  content: "\e923";
  font-family: "bain" !important;
}
.cs-car-detail-sec-1 .cs-list-area-3 {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding-left: 18px;
}
.cs-car-detail-sec-1 .cs-list-area-3::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  content: "";
  display: block;
}
.cs-car-detail-sec-1 .cs-list-yellow {
  color: #FFBA00;
}
.cs-car-detail-sec-1 .cs-list-yellow::before {
  background: #FFBA00;
}
.cs-car-detail-sec-1 .cs-list-grey {
  color: #8BB1AB;
}
.cs-car-detail-sec-1 .cs-list-grey::before {
  background: #8BB1AB;
}
.cs-car-detail-sec-1 .cs-list-green {
  color: #35D634;
}
.cs-car-detail-sec-1 .cs-list-green::before {
  background: #35D634;
}

.cs-cd-info-hover {
  cursor: pointer;
  position: relative;
}
.cs-cd-info-hover .bain-info {
  color: #401FE5;
  font-size: 20px;
}
.cs-cd-info-hover .cs-speech-box-container {
  display: none;
  position: absolute;
  left: -60px;
  z-index: 99;
}
.cs-cd-info-hover .cs-speech-box-container .cs-speech-box {
  min-width: 210px;
  background: #ffffff;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid #401FE5;
  color: #041614;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.cs-cd-info-hover:hover .cs-speech-box-container {
  display: block;
}

.cs-register-form-c-1 {
  max-width: 700px;
  padding: 30px 15px;
  position: relative;
  width: 100%;
  margin: 0 auto 0;
  z-index: 1;
  max-height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cs-register-form-c-1 #next {
  opacity: 0.5;
  pointer-events: none;
}
.cs-register-form-c-1 #next.active {
  cursor: pointer;
  opacity: 1 !important;
  pointer-events: visible !important;
}
.cs-register-form-c-1 .cs-backtoprofile-1 {
  position: absolute;
  left: 10px;
  top: 65px;
}
.cs-register-form-c-1 .form-d-1 {
  margin: 0 auto;
  text-align: center;
}
.cs-register-form-c-1 .form-d-1 .cs-form-tabs-1 {
  border-bottom: 1px solid #041614;
  max-width: 440px;
  margin: 0 auto;
}
.cs-register-form-c-1 .form-d-1 .cs-form-tabs-1 .cs-form-tabs-nav-1 .nav {
  justify-content: center;
  flex-wrap: nowrap;
}
.cs-register-form-c-1 .form-d-1 .cs-form-tabs-1 .cs-form-tabs-nav-1 .nav .nav-link {
  border: none;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: #041614;
  white-space: nowrap;
}
.cs-register-form-c-1 .form-d-1 .cs-form-tabs-1 .cs-form-tabs-nav-1 .nav .nav-link.active {
  border: none;
  position: relative;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #27C1B2;
}
.cs-register-form-c-1 .form-d-1 .cs-form-tabs-1 .cs-form-tabs-nav-1 .nav .nav-link.active::before {
  content: " ";
  width: 100%;
  height: 5px;
  border-radius: 25px;
  background-color: #27C1B2;
  display: flex;
  position: absolute;
  bottom: -2px;
  left: 0;
}
.cs-register-form-c-1 .form-d-1 .cs-form-tabs-1 .cs-form-tabs-nav-1 .nav .nav-link:hover {
  border: none;
}
.cs-register-form-c-1 .form-d-2 {
  margin: 0 auto;
}
.cs-register-form-c-1 .form-d-2 h3.cs-title-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .form-row {
  height: 760px;
  overflow: hidden;
  overflow-y: scroll;
  justify-content: center;
}
.cs-register-form-c-1 .form-d-2 .form-row::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.cs-register-form-c-1 .form-d-2 .form-row::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}
.cs-register-form-c-1 .form-d-2 .form-row::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-row {
  height: auto !important;
  overflow-y: hidden !important;
}
.cs-register-form-c-1 .form-d-2 .form-row .cs-foreign-national {
  justify-content: center;
}
.cs-register-form-c-1 .form-d-2 .form-row .cs-foreign-national .col-12 {
  padding: 0 !important;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1 {
  max-width: 350px;
  position: relative;
  text-align: left;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1.form-check {
  display: flex;
  padding-left: 25px;
  margin: 0;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1.form-check input[type=checkbox] {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  accent-color: #1FA5A7;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1.form-check label {
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  color: #272727;
  margin-left: 10px;
  user-select: none;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1.form-check label span {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #272727;
  text-decoration: underline !important;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1 input[type=number]::-webkit-outer-spin-button, .cs-register-form-c-1 .form-d-2 .form-row .form-input-1 input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1 .cs-cd-info-hover {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.cs-register-form-c-1 .form-d-2 .form-row .form-input-1 .cs-cd-info-hover .cs-speech-box-container {
  right: 0;
  left: revert;
}
.cs-register-form-c-1 .form-d-2 .form-row .cs-form-subtitle-1 {
  color: #1A1A1A;
  font-size: 15px;
  line-height: 23px;
  font-weight: 700;
  border-bottom: 1px solid #1A1A1A;
  padding-bottom: 10px;
  text-align: left;
}
.cs-register-form-c-1 .form-d-2 .form-row .cs-form-subtitle-1 span {
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 50%;
  background: rgb(39, 193, 178);
  background: linear-gradient(180deg, rgb(39, 193, 178) 0%, rgb(40, 180, 181) 18%, rgb(46, 147, 192) 38%, rgb(54, 94, 209) 66%, rgb(64, 31, 229) 100%);
}
.cs-register-form-c-1 .form-d-2 .cs-input-line-1 {
  width: 100%;
  height: 1px;
  background-color: #707070;
  margin: 30px 0;
}
.cs-register-form-c-1 .form-d-2 .cs-form-image-message-1 {
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 10px;
}
.cs-register-form-c-1 .form-d-2 .cs-form-image-message-1 .cs-desc-1 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #272727;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper {
  width: 100;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-title-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-video-rules-d-1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-video-rules-d-1 .cs-desc-1 {
  font-size: 14px;
  line-height: 22px;
  color: #272727;
  font-weight: 600;
  text-align: left;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-video-rules-d-1 img {
  width: 110px;
  height: 140px;
  object-fit: contain;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video {
  margin: 30px 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body {
  max-width: 397px;
  height: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #27C1B2;
  border-radius: 18px;
  margin: 0 auto;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  text-align: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper input[type=file] {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 30px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 .cs-desc-strong-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 .cs-upload-label-1 {
  text-decoration: underline !important;
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper .cs-desc-2 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper .cs-desc-3 {
  color: #696969;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video .cs-rav-body .cs-rav-input-wrapper .cs-file-name-text {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video-2 {
  display: none;
  flex-direction: column;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video-2 .cs-rav2-head {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video-2 .cs-rav2-head img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video-2 .cs-rav2-head .cs-desc-1 {
  color: #041614;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video-2 .cs-upload-container-1 .cs-register-video-wrapper {
  width: 100%;
  height: 300px;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video-2 .cs-upload-container-1 .cs-register-video-wrapper .cs-video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(39, 193, 178, 0.6392156863);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.cs-register-form-c-1 .form-d-2 .cs-step-3-wrapper .cs-register-add-video-2 .cs-upload-container-1 .cs-register-video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper {
  width: 100%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-title-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-photo-rules-1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-photo-rules-1 .cs-desc-1 {
  font-size: 14px;
  line-height: 22px;
  color: #272727;
  font-weight: 400;
  text-align: center;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-photo-rules-1 .cs-desc-1 span {
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-photo-rules-1 img {
  width: 170px;
  height: 185px;
  object-fit: contain;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image {
  margin: 30px 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body {
  max-width: 397px;
  height: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #27C1B2;
  border-radius: 18px;
  margin: 0 auto;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  text-align: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper input[type=file] {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 30px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 .cs-desc-strong-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 .cs-upload-label-1 {
  text-decoration: underline !important;
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper .cs-desc-2 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper .cs-desc-3 {
  color: #696969;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image .cs-rav-body .cs-rav-input-wrapper .cs-file-name-text {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 {
  display: none;
  flex-direction: column;
  margin: 30px 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-register-image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-register-image-wrapper img {
  border-radius: 18px;
  width: 100%;
  object-fit: cover;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-register-image-wrapper .cs-trash-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-register-image-wrapper .cs-trash-button i {
  color: #696969;
  font-size: 20px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-succes-file-message {
  margin-top: -35px;
  margin-left: 25px;
  text-align: left;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-succes-file-message .cs-sfm-icon {
  width: 26px;
  height: 26px;
  background-color: #401FE5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-succes-file-message .cs-sfm-icon i {
  color: #ffffff;
}
.cs-register-form-c-1 .form-d-2 .cs-step-4-wrapper .cs-register-add-image-2 .cs-succes-file-message .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper {
  width: 100%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-title-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-rules-list-1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-rules-list-1 ul {
  text-align: left;
  list-style-type: none;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-rules-list-1 ul li {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-weight: 600;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-rules-list-1 ul li::before {
  content: " ";
  background-color: #401FE5;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 7px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file {
  margin: 30px 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body {
  max-width: 397px;
  height: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #27C1B2;
  border-radius: 18px;
  margin: 0 auto;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  text-align: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper input[type=file] {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 30px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 .cs-desc-strong-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper .cs-desc-1 .cs-upload-label-1 {
  text-decoration: underline !important;
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper .cs-desc-2 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper .cs-desc-3 {
  color: #696969;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-resigter-add-file .cs-rav-body .cs-rav-input-wrapper .cs-file-name-text {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 {
  display: none;
  flex-direction: column;
  margin: 30px 0;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper {
  width: 100%;
  height: 310px;
  border-radius: 18px;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper .csfb1-pdf-d-1 {
  height: 100%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper #pdfViewerCanvas1 {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper iframe {
  border-radius: 18px;
  width: 100%;
  height: 100%;
  border: none;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper .cs-trash-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper .cs-trash-button i {
  color: #696969;
  font-size: 20px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper2 {
  width: 100%;
  height: 310px;
  border-radius: 18px;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper2 .csfb1-pdf-d-2 {
  height: 100% !important;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper2 #pdfViewerCanvas2 {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper2 iframe {
  border-radius: 18px;
  width: 100%;
  height: 100%;
  border: none;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper2 .cs-trash-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper2 .cs-trash-button i {
  color: #696969;
  font-size: 20px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper3 {
  width: 100%;
  height: 310px;
  border-radius: 18px;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper3 .csfb1-pdf-d-3 {
  height: 100% !important;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper3 #pdfViewerCanvas3 {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper3 iframe {
  border-radius: 18px;
  width: 100%;
  height: 100%;
  border: none;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper3 .cs-trash-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper3 .cs-trash-button i {
  color: #696969;
  font-size: 20px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper4 {
  width: 100%;
  height: 310px;
  border-radius: 18px;
  position: relative;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper4 .csfb1-pdf-d-4 {
  height: 100% !important;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper4 #pdfViewerCanvas4 {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper4 iframe {
  border-radius: 18px;
  width: 100%;
  height: 100%;
  border: none;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper4 .cs-trash-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-register-file-wrapper4 .cs-trash-button i {
  color: #696969;
  font-size: 20px;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-succes-file-message {
  margin-top: -35px;
  margin-left: 25px;
  text-align: left;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-succes-file-message .cs-sfm-icon {
  width: 26px;
  height: 26px;
  background-color: #401FE5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-succes-file-message .cs-sfm-icon i {
  color: #ffffff;
}
.cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-register-add-file-2 .cs-succes-file-message .cs-desc-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.cs-register-button-group-1 {
  margin: 30px 0;
}
.cs-register-button-group-1 .cs-reload-btn-1 {
  color: #272727;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  border: none;
  background-color: transparent;
}

.cs-input-image-wrapper-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 30px;
}
.cs-input-image-wrapper-1 .cs-input-box-dashed-1 {
  width: 180px;
  height: 180px;
  border: 1px dashed #27C1B2;
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.cs-input-image-wrapper-1 .cs-input-box-dashed-1 .cs-text-box-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0 !important;
}
.cs-input-image-wrapper-1 .cs-input-box-dashed-1 .cs-text-box-1 i {
  color: #DEEAEA;
  font-size: 36px;
}
.cs-input-image-wrapper-1 .cs-input-box-dashed-1 .cs-text-box-1 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  font-weight: 600;
  margin: 0;
}
.cs-input-image-wrapper-1 .cs-input-box-dashed-1::before {
  content: "";
  border-radius: 14px;
  background-color: rgba(4, 22, 20, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: none;
}
.cs-input-image-wrapper-1 .cs-input-box-dashed-1 input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.cs-input-image-wrapper-1 .csub1-btn-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 3;
  background-color: #FDFDFD;
  font-size: 18px;
  line-height: 1;
  color: #401FE5;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.cs-address-icon-input-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #696969;
  border-radius: 14px !important;
}
.cs-address-icon-input-1 input {
  border: none !important;
}
.cs-address-icon-input-1 input:focus, .cs-address-icon-input-1 input:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
.cs-address-icon-input-1 a {
  color: #696969;
  font-size: 24px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.cs-address-icon-input-1 a:hover {
  color: #27C1B2;
}

.cs-primary-step-line-2 {
  width: 185px;
  height: 1px;
  background-color: #DBDBDB;
  position: relative;
  margin: 30px auto;
}
.cs-primary-step-line-2 .cs-step-line-item-1 {
  width: 18px;
  height: 18px;
  background-color: #DBDBDB;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-8px);
}
.cs-primary-step-line-2 .cs-step-line-item-1:nth-child(2) {
  left: 25%;
}
.cs-primary-step-line-2 .cs-step-line-item-1:nth-child(3) {
  left: 50%;
}
.cs-primary-step-line-2 .cs-step-line-item-1:nth-child(4) {
  left: 75%;
}
.cs-primary-step-line-2 .cs-step-line-item-1:first-child {
  left: 0;
}
.cs-primary-step-line-2 .cs-step-line-item-1:last-child {
  left: 100%;
}
.cs-primary-step-line-2 .cs-step-line-item-1.active {
  background-color: #27C1B2;
  border: 3px solid #91EFE6;
}
.cs-primary-step-line-2.four-item .cs-step-line-item-1 {
  width: 18px;
  height: 18px;
  background-color: #DBDBDB;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-8px);
}
.cs-primary-step-line-2.four-item .cs-step-line-item-1:nth-child(2) {
  left: 33%;
}
.cs-primary-step-line-2.four-item .cs-step-line-item-1:nth-child(3) {
  left: 66%;
}
.cs-primary-step-line-2.four-item .cs-step-line-item-1:first-child {
  left: 0;
}
.cs-primary-step-line-2.four-item .cs-step-line-item-1:last-child {
  left: 100%;
}
.cs-primary-step-line-2.four-item .cs-step-line-item-1.active {
  background-color: #27C1B2;
  border: 3px solid #91EFE6;
}

.cs-add-adress-modal-1 .modal-content {
  border-radius: 18px;
}
.cs-add-adress-modal-1 .modal-content .modal-header {
  border: none;
}
.cs-add-adress-modal-1 .modal-content .modal-header .modal-title {
  margin-left: calc(50% - 60px);
}
.cs-add-adress-modal-1 .modal-content .cs-register-form-c-1 {
  padding: 0;
}
.cs-add-adress-modal-1 .modal-content .cs-register-form-c-1 .form-d-2 .form-row {
  height: 500px;
}
.cs-add-adress-modal-1 .modal-content .cs-register-form-c-1 .form-d-2 .form-row .form-input-1 {
  margin-bottom: 20px;
}
.cs-add-adress-modal-1 .cs-primary-btn-3 {
  opacity: 0.5;
  pointer-events: none;
}
.cs-add-adress-modal-1 .cs-primary-btn-3.active {
  cursor: pointer;
  opacity: 1 !important;
  pointer-events: visible !important;
}

.cs-primary-select2-2 .select2-container {
  width: 100% !important;
}
.cs-primary-select2-2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px !important;
}
.cs-primary-select2-2 .select2-container .select2-dropdown {
  padding: 50px;
}
.cs-primary-select2-2 .select2-container .selection .select2-selection--single {
  border: 0.5px solid #272727;
  border-radius: 14px;
  color: #041614;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  line-height: 24px;
  padding: 5px 20px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.input-warning-1 input {
  border-color: red !important;
  color: red !important;
  accent-color: red !important;
}

.step-d-1:not(.cs-nowidth-1) {
  max-width: 470px;
  padding: 0 15px;
}

.step-d-1.cs-nowidth-1 {
  max-width: 470px;
  width: 100%;
  padding: 0 10px;
}

.step-d-1:not(.active) {
  display: none;
}

.checkbox-wrapper-6 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.checkbox-wrapper-6 .tgl {
  display: none;
  box-sizing: border-box;
}
.checkbox-wrapper-6 .tgl:after, .checkbox-wrapper-6 .tgl:before {
  box-sizing: border-box;
}
.checkbox-wrapper-6 .tgl * {
  box-sizing: border-box;
}
.checkbox-wrapper-6 .tgl *:after, .checkbox-wrapper-6 .tgl *:before {
  box-sizing: border-box;
}
.checkbox-wrapper-6 .tgl + .tgl-btn {
  box-sizing: border-box;
}
.checkbox-wrapper-6 .tgl::-moz-selection, .checkbox-wrapper-6 .tgl:after::-moz-selection, .checkbox-wrapper-6 .tgl:before::-moz-selection {
  background: none;
}
.checkbox-wrapper-6 .tgl *::-moz-selection, .checkbox-wrapper-6 .tgl *:after::-moz-selection, .checkbox-wrapper-6 .tgl *:before::-moz-selection {
  background: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection, .checkbox-wrapper-6 .tgl::selection, .checkbox-wrapper-6 .tgl:after::selection, .checkbox-wrapper-6 .tgl:before::selection {
  background: none;
}
.checkbox-wrapper-6 .tgl *::selection, .checkbox-wrapper-6 .tgl *:after::selection, .checkbox-wrapper-6 .tgl *:before::selection {
  background: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn::selection {
  background: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:after, .checkbox-wrapper-6 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:after {
  left: 0;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  display: none;
}
.checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
  left: 50%;
}
.checkbox-wrapper-6 .tgl-light + .tgl-btn {
  background: #F0F0F0;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #ffffff;
  transition: all 0.2s ease;
}
.checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
  background: #27C1B2;
}

.cs-about-us-business-sec-1 .cs-aub-main-1 {
  margin: 50px 0px 150px;
}
.cs-about-us-business-sec-1 .cs-aub-main-1 .cs-aub-item-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cs-about-us-business-sec-1 .cs-aub-main-1 .cs-aub-item-1 img {
  width: 197px;
  height: 197px;
  object-fit: cover;
}
.cs-about-us-business-sec-1 .cs-aub-main-1 .cs-aub-item-1 .cs-link-1 {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: underline !important;
}

.cs-notification-sec-1 .cs-notification-main-1 {
  max-width: 785px;
  margin: 0 auto;
  min-height: 100vh;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-not-empty-d-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-not-empty-d-1 img {
  margin: 20px 0;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-not-empty-d-1 .cs-desc-1 {
  color: #272727;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .cs-notification-nav-1 {
  border-bottom: 1px solid #041614;
  width: 100%;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .nav.nav-tabs {
  justify-content: space-evenly;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .nav.nav-tabs .nav-item {
  border: none;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #696969;
  position: relative;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .nav.nav-tabs .nav-item.active {
  color: #401FE5;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .nav.nav-tabs .nav-item.active::after {
  content: " ";
  display: block;
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background-color: #401FE5;
  position: absolute;
  bottom: -2.5px;
  left: 0;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content {
  width: 100%;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper {
  padding: 16px 0;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #EAECF4;
  margin-bottom: 20px;
  width: 100%;
  height: 120px;
  cursor: pointer;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1.unread-notification {
  background-color: #F6FDFD;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 i {
  font-size: 24px;
  color: #27C1B2;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 .cs-notification-item-d-1 {
  display: flex;
  align-items: center;
  gap: 16px;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 .cs-notification-item-d-1 .cs-textbox-1 .cs-title-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  word-break: break-all;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 .cs-notification-item-d-1 .cs-textbox-1 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #696969;
  font-weight: 400;
  word-break: break-all;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 .cs-notification-item-d-1 .cs-textbox-1 .cs-desc-1 span {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 .cs-textbox-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 .cs-textbox-2 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #696969;
  font-weight: 400;
  white-space: nowrap;
  word-break: break-all;
}
.cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 .cs-textbox-2 i {
  font-size: 24px;
  color: #696969;
}

.notificationNoImageModal .modal-dialog {
  max-width: 610px;
}
.notificationNoImageModal .modal-dialog .modal-content {
  border-radius: 18px;
  padding: 20px 50px;
}
.notificationNoImageModal .modal-dialog .modal-content .modal-header {
  padding-bottom: 0;
}
.notificationNoImageModal .modal-dialog .modal-content .modal-body {
  padding-top: 0;
}
.notificationNoImageModal .modal-dialog .modal-content .modal-body .cs-title-1 {
  text-align: center;
  color: #0C0C0C;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
.notificationNoImageModal .modal-dialog .modal-content .modal-body .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
}
.notificationNoImageModal .modal-dialog .modal-content .modal-body .cs-desc-1 span {
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.cs-modal-notification .modal-dialog {
  max-width: 650px;
  margin: 3rem auto;
  padding: 15px;
}
.cs-modal-notification .modal-content {
  border-radius: 20px;
  padding: 0;
  border: 0;
}
.cs-modal-notification .close {
  font-size: 22px;
  line-height: 1;
  opacity: 1;
  color: #1A1A1A;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}
.cs-modal-notification .modal-body {
  padding: 0;
  border-radius: 20px;
}
.cs-modal-notification .modal-body .image-d-1 {
  border-radius: 20px 20px 0 0;
}
.cs-modal-notification .modal-body .image-d-1 img {
  border-radius: 20px 20px 0 0;
  width: 100%;
  max-width: unset;
}
.cs-modal-notification .modal-body .text-d-1 {
  padding: 15px;
  background-color: #E7F2F2;
  border-radius: 0 0 20px 20px;
}
.cs-modal-notification .modal-body .text-d-1 .in-content-1 {
  max-width: 510px;
  margin: 0 auto;
}
.cs-modal-notification .modal-body .text-d-1 .in-content-1 h1, .cs-modal-notification .modal-body .text-d-1 .in-content-1 h2, .cs-modal-notification .modal-body .text-d-1 .in-content-1 h3, .cs-modal-notification .modal-body .text-d-1 .in-content-1 h4, .cs-modal-notification .modal-body .text-d-1 .in-content-1 h5, .cs-modal-notification .modal-body .text-d-1 .in-content-1 h6 {
  text-align: center;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #272727;
  margin-bottom: 20px;
}
.cs-modal-notification .modal-body .text-d-1 .in-content-1 p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 15px;
}
.cs-modal-notification .modal-body .text-d-1 .in-content-1 p span {
  font-weight: 700;
}
.cs-modal-notification .modal-body .text-d-1 .in-content-1 ul, .cs-modal-notification .modal-body .text-d-1 .in-content-1 ol {
  padding-right: 15px;
}
.cs-modal-notification .modal-body .text-d-1 .in-content-1 li {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  margin-bottom: 15px;
}
.cs-modal-notification .modal-body .text-d-1 .in-content-1 a {
  color: #401FE5;
}

.cs-sa-back-button .swal2-popup {
  display: flex !important;
  justify-content: space-between;
}
.cs-sa-back-button .swal2-popup .swal2-cancel {
  background-color: transparent;
  color: #696969;
}
.cs-sa-back-button .swal2-popup .swal2-cancel:hover {
  background-color: transparent;
  background-image: revert;
  color: #ffffff;
}
.cs-sa-back-button .swal2-popup .swal2-cancel:focus {
  box-shadow: none;
  border: none;
}

.cs-sharecar-btn1 button {
  opacity: 0.5;
  pointer-events: none;
}
.cs-sharecar-btn1 button.active {
  cursor: pointer;
  opacity: 1 !important;
  pointer-events: visible !important;
}

.valeModal .modal-dialog {
  max-width: 875px;
}
.valeModal .modal-dialog .cs-vale-modal-main {
  display: flex;
}
.valeModal .modal-dialog .cs-vale-modal-main img {
  width: 100%;
}
.valeModal .modal-dialog .cs-vale-modal-main .cs-vale-modal-content {
  padding: 0 15px;
}
.valeModal .modal-dialog .cs-vale-modal-main .cs-vale-modal-content .cs-title-1 {
  color: #401FE5;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
.valeModal .modal-dialog .cs-vale-modal-main .cs-vale-modal-content .cs-desc-1 {
  color: #041614;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
.valeModal .modal-footer {
  justify-content: center;
  padding: 0;
}

.cs-p-campaign-sec-1 .cs-sec-campaign-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 40px;
  gap: 10px;
}
.cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-imgbox {
  max-width: 485px;
  width: 100%;
}
.cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-imgbox img {
  width: 100%;
}
.cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox {
  flex: 2;
}
.cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox .cs-title1 {
  color: #041614;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
}
.cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox .cs-desc1 {
  color: #696969;
  font-size: 20px;
  line-height: 28px;
}
.cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox .cs-campaign-btn {
  align-items: center;
  background-color: #401FE5;
  border: 1px solid transparent;
  border-radius: 12px;
  color: #ffffff;
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.2px;
  line-height: 22px;
  min-height: 44px;
  min-width: 145px;
  padding: 5px 20px;
  text-align: center;
  transition: all 0.3s ease;
}
.cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox .cs-campaign-btn:hover {
  transition: all 0.3s ease;
  background-color: #ffffff;
  color: #401FE5;
  border: 1px solid #401FE5;
}

.cs-no-campaign {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  margin-bottom: 150px;
}
.cs-no-campaign .cs-no-campaign-text {
  text-align: center;
  color: #272727;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  max-width: 395px;
  width: 100%;
}
.cs-no-campaign .no-campaign-img {
  max-width: 175px;
  width: 100%;
  object-fit: cover;
}

#campaignDetailModal .modal-dialog {
  max-width: 700px;
  margin: 3rem auto;
  padding: 15px;
}
#campaignDetailModal .modal-dialog .modal-content {
  border: none;
  border-radius: 14px;
  padding: 0 20px;
}
#campaignDetailModal .modal-dialog .modal-header {
  padding: 10px 0 0 0;
  border: none;
}
#campaignDetailModal .modal-dialog .modal-body .cs-banner {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
#campaignDetailModal .cs-modal-title1 {
  font-size: 29px;
  line-height: 37px;
  color: #000000;
  font-weight: 700;
  margin-top: 10px;
}
#campaignDetailModal .cs-modal-desc1 {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 5px;
}
#campaignDetailModal .cs-modal-toast {
  background: #EAECF4;
  padding: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}
#campaignDetailModal .cs-modal-toast .cs-modaltoast-text {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
}
#campaignDetailModal .cs-campaign-code {
  border: 2px dashed #27C1B2;
  padding: 15px 10px;
  border-radius: 22px;
  width: 100%;
  background: #e7f2f2 !important;
}
#campaignDetailModal .cs-campaign-code h4 {
  margin: 0;
  font-weight: 600;
  color: #27C1B2;
}

.cs-p-blog-sec-1 .cs-blog-box-1 .image-div-1 {
  position: relative;
}
.cs-p-blog-sec-1 .content-area-1 {
  margin: 0 auto;
  max-width: 950px;
}
.cs-p-blog-sec-1 .cs-blog-search-area .cs-title1 {
  color: #041614;
  font-size: 30px;
  line-height: 38px;
  font-weight: 800;
  text-align: center;
}
.cs-p-blog-sec-1 .cs-blog-search-area .cs-desc {
  font-size: 20px;
  line-height: 28px;
  color: #696969;
  text-align: center;
}
.cs-p-blog-sec-1 .cs-blog-list-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 30px 0;
}

.cs-new-text {
  color: #ffffff;
  font-size: 17px;
  line-height: 25px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  left: 20px;
  background: #007AFF;
  border-radius: 30px;
  padding: 2px 20px;
}

.cs-date-text {
  color: #041614;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  position: absolute;
  bottom: 10px;
  right: 20px;
  background: #FDFDFD;
  border-radius: 30px;
  padding: 2px 20px;
}

.cs-p-blogdetail-sec-1 {
  margin: 20px 0;
  min-height: 75vh;
}
.cs-p-blogdetail-sec-1 .cs-blog-detail-image {
  position: relative;
  float: left;
  margin-right: 20px;
  max-width: 570px;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.cs-p-blogdetail-sec-1 .cs-blog-detail-image img {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.cs-p-blogdetail-sec-1 .cs-blog-detail-caption {
  margin-bottom: 150px;
}
.cs-p-blogdetail-sec-1 .cs-blog-detail-caption .cs-blog-detail-date {
  color: #041614;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.cs-p-blogdetail-sec-1 .cs-blog-detail-caption .cs-blog-detail-title {
  color: #000000;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 10px;
}
.cs-p-blogdetail-sec-1 .cs-blog-detail-caption .cs-blog-detail-desc {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}

.cs-search-box {
  display: flex;
  width: 100%;
  border: 1px solid #3830DC;
  align-items: center;
  border-radius: 35px;
  height: 70px;
  justify-content: space-between;
  padding: 0 8px 0 20px;
  background-color: #ffffff;
}
.cs-search-box input {
  width: 100%;
  height: 100%;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
}
.cs-search-box input:focus-visible {
  border: none;
  outline: none;
  box-shadow: none;
}
.cs-search-box .cs-search-btn {
  background: #3830DC;
  color: #ffffff;
  padding: 12px 30px;
  border-radius: 35px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  border: none;
}

.cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main {
  margin-bottom: 300px;
}
.cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main .cs-blog-detail-content {
  display: flex;
}
.cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main .cs-blog-detail-image {
  margin-bottom: 10px !important;
  position: relative;
  float: left;
  margin-right: 20px;
  max-width: 385px;
  width: 100%;
  border-radius: 45px;
  flex: 0 0 50%;
}
.cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main .cs-blog-detail-image img {
  width: 100%;
  border-radius: 45px;
}
.cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main .cs-blog-detail-title {
  color: #696969;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
.cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main .cs-blog-detail-desc {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}
.cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main .cs-blog-detail-caption {
  flex: 1 0 50%;
}

.cs-p-arabanipaylas-sec-main {
  margin-bottom: 50px;
}
.cs-p-arabanipaylas-sec-main .cs-wbg-head-2 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
}
.cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-car-btn-1 {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-arabanipaylas-line {
  border: 1px solid #696969;
  border-radius: 14px;
  padding: 10px;
}
.cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-image-box-1 img {
  max-width: 624px;
  width: 100%;
}
.cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-text-box-1 {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-text-box-1 .cs-title1 {
  font-size: 24px;
  line-height: 32px;
  color: #272727;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}
.cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-text-box-1 .cs-primary-btn-3 {
  max-width: 320px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
}

.cs-primary-checkbox-1 {
  display: flex;
  width: auto;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  width: 100%;
}
.cs-primary-checkbox-1 label {
  user-select: none;
}
.cs-primary-checkbox-1 .cs-primary-label {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-weight: 600;
  cursor: pointer;
}
.cs-primary-checkbox-1 input[type=radio].cs-primary-check {
  display: none;
}
.cs-primary-checkbox-1 input[type=radio].cs-primary-check + label {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid #83908E;
  border-radius: 50%;
  background: transparent;
  transition: 0.2s ease all;
}
.cs-primary-checkbox-1 input[type=radio].cs-primary-check:checked + label {
  border: 4px solid #27C1B2;
}

.cs-p-arabanipaylas-sec-2 {
  margin-bottom: 30px;
  display: none;
}
.cs-p-arabanipaylas-sec-2.active {
  display: flex;
  flex-direction: column;
}
.cs-p-arabanipaylas-sec-2 .cs-arabani-pay-bry-1-0-main {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.cs-p-arabanipaylas-sec-2 .cs-arabani-pay-bry-1-0-main .cs-step-line-size {
  max-width: 200px;
  width: 100%;
  margin-bottom: 20px;
}
.cs-p-arabanipaylas-sec-2 .cs-arabani-pay-bry-1-0-main .cs-image-box-1 img {
  max-width: 624px;
  width: 100%;
}
.cs-p-arabanipaylas-sec-2 .cs-arabani-pay-bry-1-0-main .cs-textbox-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  gap: 30px;
}
.cs-p-arabanipaylas-sec-2 .cs-arabani-pay-bry-1-0-main .cs-textbox-1 .cs-sharecar-btn1 {
  display: flex;
  justify-content: center;
}
.cs-p-arabanipaylas-sec-2 .cs-arabani-pay-bry-1-0-main .cs-textbox-1 .cs-sharecar-btn1 button {
  max-width: 310px;
  width: 100%;
}

.cs-p-arabanipaylas-sec-3,
.cs-p-arabanipaylas-sec-4,
.cs-p-arabanipaylas-sec-5,
.cs-p-arabanipaylas-sec-6 {
  margin-bottom: 50px;
  display: none;
}
.cs-p-arabanipaylas-sec-3.active,
.cs-p-arabanipaylas-sec-4.active,
.cs-p-arabanipaylas-sec-5.active,
.cs-p-arabanipaylas-sec-6.active {
  display: flex;
  flex-direction: column;
}
.cs-p-arabanipaylas-sec-3 .cs-check-group,
.cs-p-arabanipaylas-sec-4 .cs-check-group,
.cs-p-arabanipaylas-sec-5 .cs-check-group,
.cs-p-arabanipaylas-sec-6 .cs-check-group {
  display: none;
  flex-direction: column;
}
.cs-p-arabanipaylas-sec-3 .cs-file-buttons,
.cs-p-arabanipaylas-sec-4 .cs-file-buttons,
.cs-p-arabanipaylas-sec-5 .cs-file-buttons,
.cs-p-arabanipaylas-sec-6 .cs-file-buttons {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cs-p-arabanipaylas-sec-3 #cs-file-img-div,
.cs-p-arabanipaylas-sec-4 #cs-file-img-div,
.cs-p-arabanipaylas-sec-5 #cs-file-img-div,
.cs-p-arabanipaylas-sec-6 #cs-file-img-div {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.cs-p-arabanipaylas-sec-3 #cs-file-img-div img,
.cs-p-arabanipaylas-sec-4 #cs-file-img-div img,
.cs-p-arabanipaylas-sec-5 #cs-file-img-div img,
.cs-p-arabanipaylas-sec-6 #cs-file-img-div img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
}
.cs-p-arabanipaylas-sec-3 .cs-file-info-js-1,
.cs-p-arabanipaylas-sec-4 .cs-file-info-js-1,
.cs-p-arabanipaylas-sec-5 .cs-file-info-js-1,
.cs-p-arabanipaylas-sec-6 .cs-file-info-js-1 {
  border: 1px solid #EAECF4;
  padding: 20px;
  border-radius: 12px;
}
.cs-p-arabanipaylas-sec-3 .cs-file-date-text,
.cs-p-arabanipaylas-sec-4 .cs-file-date-text,
.cs-p-arabanipaylas-sec-5 .cs-file-date-text,
.cs-p-arabanipaylas-sec-6 .cs-file-date-text {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}
.cs-p-arabanipaylas-sec-3 .cs-image-box-1 img,
.cs-p-arabanipaylas-sec-4 .cs-image-box-1 img,
.cs-p-arabanipaylas-sec-5 .cs-image-box-1 img,
.cs-p-arabanipaylas-sec-6 .cs-image-box-1 img {
  max-width: 624px;
  width: 100%;
}
.cs-p-arabanipaylas-sec-3 .cs-sharecar-btn1 button,
.cs-p-arabanipaylas-sec-4 .cs-sharecar-btn1 button,
.cs-p-arabanipaylas-sec-5 .cs-sharecar-btn1 button,
.cs-p-arabanipaylas-sec-6 .cs-sharecar-btn1 button {
  max-width: 310px;
  width: 100%;
  margin: 0 auto;
}
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-step-line-size,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-step-line-size,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-step-line-size,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-step-line-size {
  max-width: 200px;
  width: 100%;
}
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1,
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1,
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main {
  width: 100%;
}
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-cars-upload-list-1 .cscul1-col-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-cars-upload-list-1 .cscul1-col-1 {
  flex: 0 0 33.3%;
  max-width: 33.3%;
  min-height: 1px;
  padding-left: 7px;
  padding-right: 7px;
  position: relative;
  width: 100%;
}
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-4 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-5 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-global-document-upload-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-rentals-info-comp-1 .cs-iwb-head-1,
.cs-p-arabanipaylas-sec-6 .cs-arabani-pay-bry-1-1-main .cs-car-file-input-main .cs-iwb-head-1 {
  color: #27C1B2;
}
.cs-p-arabanipaylas-sec-3 .cs-buttons,
.cs-p-arabanipaylas-sec-4 .cs-buttons,
.cs-p-arabanipaylas-sec-5 .cs-buttons,
.cs-p-arabanipaylas-sec-6 .cs-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-self: center;
  align-self: center;
  max-width: 370px;
  width: 100%;
}
.cs-p-arabanipaylas-sec-3 .cs-buttons label,
.cs-p-arabanipaylas-sec-4 .cs-buttons label,
.cs-p-arabanipaylas-sec-5 .cs-buttons label,
.cs-p-arabanipaylas-sec-6 .cs-buttons label {
  cursor: pointer;
  margin: 0 !important;
}
.cs-p-arabanipaylas-sec-3 .cs-buttons input,
.cs-p-arabanipaylas-sec-4 .cs-buttons input,
.cs-p-arabanipaylas-sec-5 .cs-buttons input,
.cs-p-arabanipaylas-sec-6 .cs-buttons input {
  display: none !important;
}
.cs-p-arabanipaylas-sec-3 .cs-buttons .cs-buttons-item,
.cs-p-arabanipaylas-sec-4 .cs-buttons .cs-buttons-item,
.cs-p-arabanipaylas-sec-5 .cs-buttons .cs-buttons-item,
.cs-p-arabanipaylas-sec-6 .cs-buttons .cs-buttons-item {
  border: 1px solid #696969;
  padding: 10px 5px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
.cs-p-arabanipaylas-sec-3 .cs-buttons .cs-buttons-item.active,
.cs-p-arabanipaylas-sec-4 .cs-buttons .cs-buttons-item.active,
.cs-p-arabanipaylas-sec-5 .cs-buttons .cs-buttons-item.active,
.cs-p-arabanipaylas-sec-6 .cs-buttons .cs-buttons-item.active {
  background-color: #27C1B2;
  color: #ffffff;
  border: 1px solid transparent;
}
.cs-p-arabanipaylas-sec-3 .cs-buttons .cs-buttons-item.active label,
.cs-p-arabanipaylas-sec-4 .cs-buttons .cs-buttons-item.active label,
.cs-p-arabanipaylas-sec-5 .cs-buttons .cs-buttons-item.active label,
.cs-p-arabanipaylas-sec-6 .cs-buttons .cs-buttons-item.active label {
  color: #ffffff;
}
.cs-p-arabanipaylas-sec-3 .cs-textbox-1,
.cs-p-arabanipaylas-sec-4 .cs-textbox-1,
.cs-p-arabanipaylas-sec-5 .cs-textbox-1,
.cs-p-arabanipaylas-sec-6 .cs-textbox-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 450px;
  width: 100%;
  gap: 15px;
}
.cs-p-arabanipaylas-sec-3 .cs-textbox-1 .cs-title1,
.cs-p-arabanipaylas-sec-4 .cs-textbox-1 .cs-title1,
.cs-p-arabanipaylas-sec-5 .cs-textbox-1 .cs-title1,
.cs-p-arabanipaylas-sec-6 .cs-textbox-1 .cs-title1 {
  font-size: 16px;
  line-height: 24px;
  color: #1A1A1A;
  font-weight: 600;
}
.cs-p-arabanipaylas-sec-3 .cs-textbox-1 .cs-sharecar-textbox,
.cs-p-arabanipaylas-sec-4 .cs-textbox-1 .cs-sharecar-textbox,
.cs-p-arabanipaylas-sec-5 .cs-textbox-1 .cs-sharecar-textbox,
.cs-p-arabanipaylas-sec-6 .cs-textbox-1 .cs-sharecar-textbox {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 20px;
  margin-top: 30px;
}

.cs-primary-step-line-1 {
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
  position: relative;
}
.cs-primary-step-line-1 .cs-step-line-item-1 {
  width: 18px;
  height: 18px;
  background-color: #DBDBDB;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-8px);
  left: 50%;
}
.cs-primary-step-line-1 .cs-step-line-item-1:first-child {
  left: 0;
}
.cs-primary-step-line-1 .cs-step-line-item-1:last-child {
  left: 100%;
}
.cs-primary-step-line-1 .cs-step-line-item-1:nth-child(2) {
  left: 25%;
}
.cs-primary-step-line-1 .cs-step-line-item-1:nth-child(4) {
  left: 75%;
}
.cs-primary-step-line-1 .cs-step-line-item-1:nth-child(3) {
  left: 50%;
}
.cs-primary-step-line-1 .cs-step-line-item-1.active {
  background-color: #27C1B2;
  border: 3px solid #91EFE6;
}

#sharecar-modal1 .modal-content {
  border-radius: 18px;
}
#sharecar-modal1 .modal-header {
  border: none !important;
}
#sharecar-modal1 .cs-sharecar-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
#sharecar-modal1 .cs-sharecar-main .cs-alert {
  color: #0C0C0C;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
#sharecar-modal1 .cs-sharecar-main .cs-desc1 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
#sharecar-modal1 .cs-sharecar-main .cs-sharecar-btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.cs-primary-check-1 {
  margin-bottom: 20px;
}
.cs-primary-check-1 .form-check-input {
  width: 22px;
  height: 22px !important;
  border: 1px solid #B7B7B7;
  accent-color: #27C1B2;
  border-radius: 4px;
}
.cs-primary-check-1 .form-check-label {
  margin-left: 10px;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
  color: #272727;
}
.cs-primary-check-1 .form-check-label a {
  text-decoration: underline !important;
  color: #272727;
}

#insurance_policy .modal-dialog {
  max-width: 610px;
  width: 100%;
}
#insurance_policy .modal-dialog .modal-content {
  border-radius: 18px;
}
#insurance_policy .modal-dialog .cs-modal-title1 {
  color: #0C0C0C;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
#insurance_policy .modal-dialog .cs-modal-desc1 {
  font-size: 16px;
  line-height: 24px;
  color: #0C0C0C;
}
#insurance_policy .modal-dialog .cs-modal-textbox {
  height: 445px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 30px;
  padding: 10px 20px;
}
#insurance_policy .modal-dialog .cs-modal-textbox::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
#insurance_policy .modal-dialog .cs-modal-textbox::-webkit-scrollbar {
  width: 4px;
}
#insurance_policy .modal-dialog .cs-modal-textbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(114, 135, 253, 0.15);
}
#insurance_policy .cs-globe-dd-1 {
  display: flex;
  justify-content: center;
}
#insurance_policy .cs-globe-dd-1 .cs-header-btn-3 {
  max-width: 320px;
  width: 100%;
  padding: 5px 20px;
}
#insurance_policy .cs-globe-dd-1 .cs-header-btn-3 .ds-in-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cs-giveupbtn {
  color: #272727;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  align-items: center;
  border: 0;
  border-radius: 12px;
  display: inline-flex;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 0.2px;
  line-height: 22px;
  min-height: 44px;
  min-width: 145px;
  padding: 5px 20px;
  text-align: center;
  cursor: pointer;
}

.cs-sharecar-modal-1 #cs-dd-modal-text2 {
  width: 100%;
  height: 100%;
}
.cs-sharecar-modal-1 #cs-dd-modal-text2 .cs-file-img-2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cs-sharecar-modal-1 .cs-trash-icon {
  color: #401FE5;
  background: rgba(114, 135, 253, 0.11);
  padding: 10px 12px;
  border-radius: 50%;
}
.cs-sharecar-modal-1 .modal-footer {
  justify-content: center !important;
  padding: 0 !important;
  border: none !important;
}
.cs-sharecar-modal-1 .cs-file-reset-btn-2 {
  background: none;
  border: none;
}
.cs-sharecar-modal-1 .cs-sharecarmodal-footer {
  display: none;
  flex-direction: column;
}
.cs-sharecar-modal-1 .cs-sharecarmodal-footer .cs-modal-buttons {
  display: flex;
}
.cs-sharecar-modal-1 .cs-sharecarmodal-footer .cs-modal-buttons .cs-yesbtn {
  background-color: #27C1B2;
}
.cs-sharecar-modal-1 .cs-sharecarmodal-footer .cs-modal-buttons .cs-yesbtn:hover {
  box-shadow: 0 4px 6px 0 rgba(39, 193, 178, 0.65);
  color: #ffffff;
}

#form_city_1, #mission_start_location, #mission_end_location, #mission_location {
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  background-color: #ffffff;
}
#form_city_1::placeholder, #mission_start_location::placeholder, #mission_end_location::placeholder, #mission_location::placeholder {
  color: #041614;
  opacity: 0.5;
}
#form_city_1:focus, #mission_start_location:focus, #mission_end_location:focus, #mission_location:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.cs-location-search-area {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
  height: auto;
}
.cs-location-search-area.deactive {
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.cs-location-icondiv {
  align-items: center;
  border: 0.5px solid #696969;
  border-radius: 12px;
  display: block;
  display: flex;
  font-size: 1.25rem;
  padding: 0 25px;
  width: 100%;
}
.cs-location-icondiv input {
  border: none;
}
.cs-location-icondiv input:focus {
  box-shadow: none !important;
}
.cs-location-icondiv i {
  color: #7287FD;
  font-weight: 900;
  font-size: 20px;
}

.cs-syc-sec1 .cs-syc-sec-1-title {
  font-size: 35px;
  line-height: 43px;
  color: #041614;
  font-weight: 700;
  max-width: 540px;
}
.cs-syc-sec1 .cs-syc-sec-1-title span {
  color: #401FE5;
}
.cs-syc-sec1 .cs-syc-1-main {
  align-items: flex-start !important;
  justify-content: space-between;
  margin: 0;
}
.cs-syc-sec1 .cs-syc-1-main .cs-start-btn {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
  position: relative;
}
.cs-syc-sec1 .cs-syc-1-main .cs-start-btn .js-choice-box {
  z-index: 2;
  width: max-content;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 1;
  right: 15px;
}
.cs-syc-sec1 .cs-syc-1-main .cs-start-btn .dropdown {
  width: 100%;
}
.cs-syc-sec1 .cs-syc-1-main .cs-start-btn .dropdown .dropdown-menu .dropdown-item:active {
  background-color: #27C1B2;
}
.cs-syc-sec1 .cs-syc-1-main .cs-start-btn .cs-start-dropdown::after {
  content: none;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox {
  border: 1px solid #3B2BDC;
  padding: 20px 45px;
  border-radius: 60px;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #F6FDFD;
  display: flex;
  justify-content: center;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-info-direction {
  position: relative;
  width: 100%;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-info-direction .cs-info-message {
  position: absolute;
  width: 200px;
  height: auto;
  border-radius: 14px;
  background: #ffffff;
  bottom: -135px;
  box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
  right: -150px;
  width: 200px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  z-index: 3;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-info-direction .cs-info-message span {
  font-size: 14px;
  line-height: 14px;
  text-wrap: wrap;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-info-direction:hover .cs-info-message {
  position: absolute;
  width: 200px;
  height: auto;
  border-radius: 14px;
  background: #ffffff;
  bottom: -135px;
  box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
  right: -150px;
  width: 200px;
  padding: 10px;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
  z-index: 3;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-info-direction:hover .cs-info-message span {
  font-size: 14px;
  line-height: 14px;
  text-wrap: wrap;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-1 {
  font-size: 43px;
  line-height: 51px;
  color: #041614;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  white-space: nowrap;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-2 {
  font-size: 20px;
  line-height: 28px;
  color: #041614;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-2 i {
  color: #7287FD;
  font-weight: 900;
  font-size: 20px;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
  padding-left: 8.5%;
  padding-top: 10px;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-main-img {
  max-width: 770px;
  width: 100%;
  height: 550px;
  object-fit: cover;
}
.cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
  position: absolute;
  bottom: 10px;
  right: 8.5%;
  max-width: 736px;
  width: 100%;
}

.cs-syc-sec-2 {
  height: 230px;
  width: 100%;
  background-color: #F7F8FF;
}
.cs-syc-sec-2 .container {
  height: 100%;
}
.cs-syc-sec-2 .cs-syc-2-banner {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  height: 100%;
}
.cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: space-between;
  gap: 5px;
}
.cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content .cs-img-banner-1 {
  max-width: 255px;
  width: 100%;
}
.cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content .cs-title-banner-1 {
  font-size: 56px;
  line-height: 54px;
  color: #401FE5;
  text-align: center;
  font-weight: 800;
}
.cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content .cs-desc-banner-1 {
  font-size: 30px;
  line-height: 38px;
  color: #041614;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.cs-syc-sec-3 {
  margin: 50px 0;
}
.cs-syc-sec-3 .cs-button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 45px;
}
.cs-syc-sec-3 .cs-syc-3-box-main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: space-between;
  align-items: center;
  gap: 25px;
}
.cs-syc-sec-3 .cs-syc-3-box-main .cs-syc-3-box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #EDEDED;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 340px;
  justify-content: center;
  padding: 0 20px;
}
.cs-syc-sec-3 .cs-syc-3-box-main .cs-syc-3-box-content .cs-syc-sec3-title {
  color: #272727;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
}
.cs-syc-sec-3 .cs-syc-3-box-main .cs-syc-3-box-content .cs-syc-sec3-desc {
  color: #272727;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.cs-primary-outline-btn-1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: #F6FDFD;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #27C1B2;
  border: 1px solid #27C1B2;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-primary-outline-btn-1 i {
  padding-left: 10px;
}
.cs-primary-outline-btn-1:hover {
  color: #ffffff;
  background-color: #27C1B2;
}

.cs-syc-sec-4 {
  background-color: #F6FDFD;
  height: 510px;
  margin: 50px 0;
}
.cs-syc-sec-4 .container {
  height: 100%;
}
.cs-syc-sec-4 .cs-syc-sec4-main {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content1 {
  align-self: flex-start;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 {
  position: absolute;
  left: 10%;
  bottom: 20px;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-wbg-head-2 {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 20px;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-wbg-head-2 span::before {
  background-color: #7287FD;
  height: 12px;
  opacity: 0.4;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 {
  position: absolute;
  right: 10%;
  max-width: 565px;
  top: 25px;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 .cs-wbg-head-2 {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 20px;
  text-align: right;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 .cs-wbg-head-2 span::before {
  height: 12px;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 .cs-sys-sec4-title {
  font-size: 22px;
  line-height: 30px;
  color: #041614;
  text-align: right;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 {
  position: absolute;
  left: 10%;
  max-width: 565px;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-wbg-head-2 {
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 20px;
  text-align: left;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-wbg-head-2 span::before {
  height: 12px;
}
.cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-sys-sec4-title {
  font-size: 22px;
  line-height: 30px;
  color: #041614;
  text-align: left;
}

.cs-p-vehicle-owner-main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid #EAECF4;
  padding: 20px 16px;
  margin: 35px 0;
  background: #ffffff;
}
.cs-p-vehicle-owner-main .cs-p-vehicle-textbox-1 {
  display: flex;
  gap: 30px;
  align-items: center;
}
.cs-p-vehicle-owner-main .cs-p-vehicle-textbox-1 .cs-profile-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.cs-p-vehicle-owner-main .cs-p-vehicle-textbox-1 .fake-char-i-1 {
  display: flex;
  height: 80px;
  width: 80px;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 1px;
  background-color: #401FE5;
  color: #ffffff;
}
.cs-p-vehicle-owner-main .cs-textarea .cs-name-1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #041614;
}
.cs-p-vehicle-owner-main .cs-textarea .cs-name-1 .cs-super-vhc-owner {
  font-size: 12px;
  color: #ffffff;
  font-weight: 700;
  background: #401FE5;
  background: linear-gradient(180deg, #401FE5 0%, #201073 100%);
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 18px;
}
.cs-p-vehicle-owner-main .cs-textarea .cs-name-1 .cs-super-vhc-owner i {
  margin-right: 10px;
  font-size: 16px;
}
.cs-p-vehicle-owner-main .cs-textarea .cs-location-phone-area {
  display: flex;
  gap: 25px;
}
.cs-p-vehicle-owner-main .cs-textarea .cs-location-phone-area .cs-text-info-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041614;
}
.cs-p-vehicle-owner-main .cs-textarea .cs-location-phone-area .cs-text-info-1 i {
  color: #401FE5;
  background-color: #EAECF4;
  border-radius: 50%;
  padding: 10px;
  margin-right: 5px;
  font-size: 16px;
}
.cs-p-vehicle-owner-main .cs-date-1 {
  display: flex;
  gap: 20px;
}
.cs-p-vehicle-owner-main .cs-date-1 .cs-text-1 {
  font-size: 16px;
  line-height: 24px;
  color: #696969;
  font-weight: 400;
  margin-left: 3px;
}
.cs-p-vehicle-owner-main .cs-date-1 .cs-text-2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #041614;
}

.cs-p-vehicle-arrangement {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.cs-p-vehicle-arrangement .cs-text-3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #041614;
  border-right: 1px solid #401FE5;
  margin-right: 20px;
  padding-right: 20px;
}
.cs-p-vehicle-arrangement .cs-text-4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: #401FE5;
}
.cs-p-vehicle-arrangement .cs-text-4 i {
  margin-left: 5px;
  color: #401FE5;
  font-size: 15px;
}
.cs-p-vehicle-arrangement .cs-arrangement-button {
  color: #041614;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 12px;
  padding-right: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 8px 24px;
}
.cs-p-vehicle-arrangement .cs-arrangement-button i {
  margin-right: 5px;
}

.cs-car-list-main {
  margin: 20px 0 !important;
}
.cs-car-list-main p {
  margin: 0;
}
.cs-car-list-main .cs-d-detailbox {
  margin-bottom: 30px;
}
.cs-car-list-main .cs-car-list-content {
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.cs-car-list-main .cs-car-list-content .cs-car-list-content-1 {
  padding: 15px 15px 0 15px;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-4 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-4 .cs-list-area-4-img {
  width: 235px;
  height: 125px;
  object-fit: cover;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-1 .like-btn-1 {
  font-size: 20px;
  line-height: 1;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  z-index: 0;
  right: 30px;
  top: 18px;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-1 .like-btn-1::before {
  content: "\e940";
  font-family: "bain" !important;
  position: relative;
  top: 1px;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-1 .like-btn-1.active {
  color: #FF081B;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-1 .like-btn-1.active::before {
  content: "\e923";
  font-family: "bain" !important;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-1 .cs-text-1 {
  color: #041614;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-1 i {
  color: #696969;
  font-size: 22px;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-2 {
  font-size: 16px;
  line-height: 24px;
  color: #838383;
  font-weight: 600;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-3 {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding-left: 18px;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-3::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  content: "";
  display: block;
}
.cs-car-list-main .cs-car-list-content .cs-list-yellow {
  color: #FFBA00;
}
.cs-car-list-main .cs-car-list-content .cs-list-yellow::before {
  background: #FFBA00;
}
.cs-car-list-main .cs-car-list-content .cs-list-grey {
  color: #8BB1AB;
}
.cs-car-list-main .cs-car-list-content .cs-list-grey::before {
  background: #8BB1AB;
}
.cs-car-list-main .cs-car-list-content .cs-list-green {
  color: #35D634;
}
.cs-car-list-main .cs-car-list-content .cs-list-green::before {
  background: #35D634;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-5 {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #EDEDED;
  padding: 15px 0;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-5 i {
  color: #000000;
  font-size: 25px;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-5 .cs-text-5 {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #696969;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-6 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: #F0F0F0;
  border-radius: 0 0 20px 20px;
}
.cs-car-list-main .cs-car-list-content .cs-list-area-6 .cs-text-6 {
  font-size: 20px;
  line-height: 28px;
  color: #041614;
  font-weight: 700;
}
.cs-car-list-main .cs-primary-btn-1 {
  min-width: 310px;
}
.cs-car-list-main .cs-current-past-mission-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  gap: 30px;
}
.cs-car-list-main .nav-tabs {
  max-width: 326px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border: none;
  border-bottom: 1px solid #707070;
  padding-bottom: 15px;
  z-index: 9;
}
.cs-car-list-main .nav-tabs .nav-link {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  background-color: transparent;
  border: none;
}
.cs-car-list-main .nav-tabs .nav-link.active {
  color: #401FE5;
  font-weight: 700;
  background-color: transparent;
  border: none;
  position: relative;
}
.cs-car-list-main .nav-tabs .nav-link.active::after {
  background: #401FE5;
  height: 8px;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: -18px;
  display: block;
  border-radius: 5px;
}

.cs-profile-container-1 {
  max-width: 1010px;
  margin: 0 auto;
  padding: 0 15px;
}

.cs-car-list-main-2 {
  margin: 30px 0 !important;
}
.cs-car-list-main-2 .cs-d-detailbox {
  margin-bottom: 30px;
}
.cs-car-list-main-2 .cs-list-main-2 {
  padding: 15px;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 8px 24px;
  min-height: 355px;
  max-height: 355px;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #EDEDED;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-1 .cs-pp-text-area {
  display: flex;
  gap: 15px;
  align-items: center;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-1 .cs-pp-text-area .cs-pp-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-1 .cs-pp-text-area .fake-char-i-1 {
  display: flex;
  height: 80px;
  width: 80px;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 1px;
  background-color: #401FE5;
  color: #ffffff;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-1 .cs-profile-title-1 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-1 .cs-star-list {
  display: flex;
  gap: 3px;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-1 .cs-star-list i {
  color: #F0C76A;
  font-size: 17px;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-2 .cs-desc-1 {
  font-size: 16px;
  line-height: 24px;
  color: #696969;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cs-car-list-main-2 .cs-list-main-2 .cs-list-item-2-2 .cs-date-desc {
  font-size: 14px;
  line-height: 22px;
  color: #696969;
}
.cs-car-list-main-2 .cs-like-star i {
  color: #401FE5;
  font-size: 20px;
  padding: 6px;
  background: #EAECF4;
  border-radius: 50%;
}

.cs-backtoprofile-1 {
  margin: 30px 0 10px 0;
  padding-left: 10px;
  display: flex;
}
.cs-backtoprofile-1.deactive {
  display: none;
}
.cs-backtoprofile-1 .cs-btp-text {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 600;
}
.cs-backtoprofile-1 i {
  margin-right: 8px;
  font-size: 14px;
}

.cs-p-vehicle-rating-1 i {
  color: #F0C76A;
  font-size: 20px;
}
.cs-p-vehicle-rating-1 .cs-rate-text {
  font-size: 20px;
  line-height: 28px;
  color: #041614;
  font-weight: 700;
}

.cs-vehicle-detail-main-1 {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 0 10px;
}
.cs-vehicle-detail-main-1 .cs-title-1 {
  font-size: 18px;
  line-height: 26px;
  color: #041614;
  font-weight: 700;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #EDEDED;
}
.cs-vehicle-detail-main-1 .cs-title-2,
.cs-vehicle-detail-main-1 .cs-title-3 {
  font-size: 18px;
  line-height: 26px;
  color: #041614;
  font-weight: 600;
}

.cs-car-reservation-calendar {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  align-items: center;
}
.cs-car-reservation-calendar .cs-rc-left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.cs-car-reservation-calendar .cs-rc-left .cs-rc-img {
  width: 200px;
  height: 85px;
  object-fit: cover;
}
.cs-car-reservation-calendar .cs-rc-left .cs-title-name {
  font-size: 18px;
  line-height: 26px;
  color: #041614;
  font-weight: 600;
  margin-bottom: 5px;
}
.cs-car-reservation-calendar .cs-rc-left .cs-title-plate {
  font-size: 16px;
  line-height: 24px;
  color: #838383;
}
.cs-car-reservation-calendar .cs-rc-right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.cs-car-reservation-calendar .cs-rc-right .cs-title-price {
  background-color: #F0F0F0;
  color: #041614;
  border-radius: 20px;
  padding: 5px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-car-reservation-calendar .cs-rc-right .cs-blue-disc {
  width: 22px;
  height: 22px;
  background-color: #007EFF;
  border-radius: 50%;
}

.cs-calendar-list {
  margin: 25px 0;
}
.cs-calendar-list .cs-calendar-list-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.cs-calendar-list .cs-calendar-list-area .cs-date-list {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main {
  padding: 12px;
  border-radius: 12px;
  background-color: #ffffff;
  border: 1px solid #EAECF4;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-main {
  padding: 15px 20px;
  position: relative;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-main::before {
  content: "";
  height: 100%;
  width: 6px;
  display: block;
  background: #ffffff;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-main .cs-date-title-1 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-main .cs-date-list span {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content {
  padding: 15px 20px;
  position: relative;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content::before {
  content: "";
  height: 100%;
  width: 6px;
  display: block;
  background: #FFBA00;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content .cs-date-title-1 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content .cs-date-list span {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-2 {
  padding: 15px 20px;
  position: relative;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-2::before {
  content: "";
  height: 100%;
  width: 6px;
  display: block;
  background: #E04149;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-2 .cs-date-title-1 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
}
.cs-calendar-list .cs-calendar-list-area .cs-cl-main .cs-cl-content-2 .cs-date-list span {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
}

.cs-count-mission-main {
  background-color: #ffffff;
  padding: 5px 15px 5px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 8px 24px;
  border-radius: 20px 0 0 20px;
}
.cs-count-mission-main i {
  font-size: 15px;
  color: #401FE5;
  padding: 8px 10px;
  background: #EAECF4;
  border-radius: 50%;
}
.cs-count-mission-main .cs-title-mission {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
}
.cs-count-mission-main .cs-result-mission {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.cs-hire-main-area .nav-tabs {
  max-width: 350px !important;
}
.cs-hire-main-area .nav-tabs .nav-link.active::after {
  left: 0 !important;
}

.cs-mission-main-area {
  margin: 20px 0;
}
.cs-mission-main-area .cs-primary-btn-1 {
  min-width: 310px;
}
.cs-mission-main-area .cs-current-past-mission-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  gap: 30px;
}
.cs-mission-main-area .nav-tabs {
  max-width: 326px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border: none;
  border-bottom: 1px solid #707070;
  padding-bottom: 15px;
}
.cs-mission-main-area .nav-tabs .nav-link {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  background-color: transparent;
  border: none;
}
.cs-mission-main-area .nav-tabs .nav-link.active {
  color: #401FE5;
  font-weight: 700;
  background-color: transparent;
  border: none;
  position: relative;
}
.cs-mission-main-area .nav-tabs .nav-link.active::after {
  background: #401FE5;
  height: 8px;
  width: 180px;
  content: "";
  position: absolute;
  left: -13px;
  bottom: -18px;
  display: block;
  border-radius: 5px;
}
.cs-mission-main-area .cs-mission-message {
  font-size: 18px;
  line-height: 26px;
  color: #041614;
  font-weight: 700;
}

.cs-create-mission-main {
  padding: 30px 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cs-create-mission-main .cs-form-1 .date-box-1 label:before {
  left: 28px;
}
.cs-create-mission-main .cs-primary-btn-1-local {
  min-width: 320px;
}
.cs-create-mission-main label {
  font-size: 16px;
  line-height: 24px;
  color: #1A1A1A;
  font-weight: 600;
}
.cs-create-mission-main input, .cs-create-mission-main .cs-location-icondiv, .cs-create-mission-main select {
  border: 0.5px solid #696969;
  font-size: 16px !important;
  border-radius: 14px;
  background-color: #ffffff;
}
.cs-create-mission-main input i, .cs-create-mission-main .cs-location-icondiv i, .cs-create-mission-main select i {
  color: #696969;
}
.cs-create-mission-main input:focus, .cs-create-mission-main .cs-location-icondiv:focus, .cs-create-mission-main select:focus {
  box-shadow: none;
  border: 0.5px solid #696969;
}
.cs-create-mission-main #form_city_1, .cs-create-mission-main #mission_end_location, .cs-create-mission-main #mission_location, .cs-create-mission-main #mission_start_location {
  font-size: 16px;
}
.cs-create-mission-main textarea {
  border: 0.5px solid #696969;
  border-radius: 14px;
  padding: 15px;
  resize: none;
  height: 80px;
  color: #041614;
  opacity: 0.5;
}
.cs-create-mission-main textarea:focus {
  box-shadow: none;
  border: 0.5px solid #696969;
}
.cs-create-mission-main textarea::placeholder {
  color: #041614;
  opacity: 0.5;
}

.cs-overlay-profile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 11;
  overflow: hidden;
}

.cs-overlay-profile-2 {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: saturate(200%) blur(5px);
  backdrop-filter: saturate(200%) blur(5px);
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 11;
  overflow: hidden;
}

.cs-sorting-area-1 {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.cs-sorting-area-1 .cs-sorting-modal-1,
.cs-sorting-area-1 .cs-sorting-modal-2,
.cs-sorting-area-1 .cs-sorting-modal-3,
.cs-sorting-area-1 .cs-sorting-modal-4 {
  height: auto;
  background: #ffffff;
  z-index: 12;
  position: absolute;
  right: 0;
  top: 50px;
  display: none;
  max-width: 320px;
  width: 100%;
  border-radius: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 30px;
}
.cs-sorting-area-1 .cs-sorting-modal-1 .cs-sort-btn-group,
.cs-sorting-area-1 .cs-sorting-modal-2 .cs-sort-btn-group,
.cs-sorting-area-1 .cs-sorting-modal-3 .cs-sort-btn-group,
.cs-sorting-area-1 .cs-sorting-modal-4 .cs-sort-btn-group {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.cs-sorting-area-1 .cs-location-modal-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cs-sorting-area-1 .cs-location-modal-list li {
  border-bottom: 1px solid rgba(237, 237, 237, 0.4470588235);
  padding-bottom: 10px;
  cursor: pointer;
}
.cs-sorting-area-1 .cs-location-modal-list li a {
  font-size: 16px;
  line-height: 24px;
  color: #454545;
}
.cs-sorting-area-1 .cs-location-modal-list li.active {
  position: relative;
}
.cs-sorting-area-1 .cs-location-modal-list li.active::after {
  position: absolute;
  right: -6px;
  top: 28%;
  transform: translateY(-50%);
  content: "\e947";
  font-family: "bain" !important;
  font-size: 17px;
  color: #27C1B2;
}
.cs-sorting-area-1 .cs-location-modal-list li.active a {
  color: #041614;
  font-weight: 700;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cs-start-location-modal,
.cs-end-location-modal,
.cs-location-modal {
  height: auto;
  background: #ffffff;
  z-index: 12;
  position: absolute;
  top: 100%;
  left: -10%;
  display: none;
  min-width: 425px;
  width: 100%;
  border-radius: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #707070;
  padding: 30px;
}
.cs-start-location-modal .cs-location-modal-list,
.cs-end-location-modal .cs-location-modal-list,
.cs-location-modal .cs-location-modal-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cs-start-location-modal .cs-location-modal-list li,
.cs-end-location-modal .cs-location-modal-list li,
.cs-location-modal .cs-location-modal-list li {
  border-bottom: 1px solid rgba(237, 237, 237, 0.4470588235);
  padding-bottom: 10px;
  cursor: pointer;
}
.cs-start-location-modal .cs-location-modal-list li a,
.cs-end-location-modal .cs-location-modal-list li a,
.cs-location-modal .cs-location-modal-list li a {
  font-size: 16px;
  line-height: 24px;
  color: #454545;
}
.cs-start-location-modal .cs-location-modal-list li.active,
.cs-end-location-modal .cs-location-modal-list li.active,
.cs-location-modal .cs-location-modal-list li.active {
  position: relative;
}
.cs-start-location-modal .cs-location-modal-list li.active::after,
.cs-end-location-modal .cs-location-modal-list li.active::after,
.cs-location-modal .cs-location-modal-list li.active::after {
  position: absolute;
  right: -6px;
  top: 28%;
  transform: translateY(-50%);
  content: "\e947";
  font-family: "bain" !important;
  font-size: 17px;
  color: #27C1B2;
}
.cs-start-location-modal .cs-location-modal-list li.active a,
.cs-end-location-modal .cs-location-modal-list li.active a,
.cs-location-modal .cs-location-modal-list li.active a {
  color: #041614;
  font-weight: 700;
  display: inline-block;
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cs-map-modal-1,
.cs-map-modal-2,
.cs-map-modal-3 {
  max-width: 950px;
  width: 95%;
  padding: 25px 0;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 16px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 13;
  background-color: #ffffff;
  opacity: 1;
  visibility: visible;
}
.cs-map-modal-1.active iframe,
.cs-map-modal-2.active iframe,
.cs-map-modal-3.active iframe {
  height: 175px !important;
  transition: all 0.5s;
  margin: 0;
}
.cs-map-modal-1.active2,
.cs-map-modal-2.active2,
.cs-map-modal-3.active2 {
  max-width: 600px;
}
.cs-map-modal-1.active2 iframe,
.cs-map-modal-2.active2 iframe,
.cs-map-modal-3.active2 iframe {
  height: 0 !important;
  transition: all 0.5s;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}
.cs-map-modal-1 #cs-close-btn-1,
.cs-map-modal-1 #cs-close-btn-2,
.cs-map-modal-1 #cs-close-btn-3,
.cs-map-modal-2 #cs-close-btn-1,
.cs-map-modal-2 #cs-close-btn-2,
.cs-map-modal-2 #cs-close-btn-3,
.cs-map-modal-3 #cs-close-btn-1,
.cs-map-modal-3 #cs-close-btn-2,
.cs-map-modal-3 #cs-close-btn-3 {
  position: absolute;
  top: 20px;
  right: 20px;
  text-decoration: none;
}
.cs-map-modal-1 #cs-close-btn-1 i,
.cs-map-modal-1 #cs-close-btn-2 i,
.cs-map-modal-1 #cs-close-btn-3 i,
.cs-map-modal-2 #cs-close-btn-1 i,
.cs-map-modal-2 #cs-close-btn-2 i,
.cs-map-modal-2 #cs-close-btn-3 i,
.cs-map-modal-3 #cs-close-btn-1 i,
.cs-map-modal-3 #cs-close-btn-2 i,
.cs-map-modal-3 #cs-close-btn-3 i {
  color: #1A1A1A;
  font-size: 13px;
}
.cs-map-modal-1 .cs-map-modal-title,
.cs-map-modal-2 .cs-map-modal-title,
.cs-map-modal-3 .cs-map-modal-title {
  color: #0C0C0C;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
  opacity: 1;
  visibility: visible;
  height: auto;
}
.cs-map-modal-1 .cs-map-modal-title.deactive,
.cs-map-modal-2 .cs-map-modal-title.deactive,
.cs-map-modal-3 .cs-map-modal-title.deactive {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.cs-map-modal-1 .cs-location-icondiv,
.cs-map-modal-2 .cs-location-icondiv,
.cs-map-modal-3 .cs-location-icondiv {
  max-width: 420px;
  margin: 0 auto;
}
.cs-map-modal-1 iframe,
.cs-map-modal-2 iframe,
.cs-map-modal-3 iframe {
  width: 100%;
  height: 450px;
  margin: 20px 0;
}
.cs-map-modal-1 .cs-footer-modal-area,
.cs-map-modal-2 .cs-footer-modal-area,
.cs-map-modal-3 .cs-footer-modal-area {
  display: flex;
  justify-content: center;
  gap: 10px;
  opacity: 1;
  visibility: visible;
  height: auto;
}
.cs-map-modal-1 .cs-footer-modal-area.deactive,
.cs-map-modal-2 .cs-footer-modal-area.deactive,
.cs-map-modal-3 .cs-footer-modal-area.deactive {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.cs-map-modal-1 .cs-form-button-group,
.cs-map-modal-2 .cs-form-button-group,
.cs-map-modal-3 .cs-form-button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cs-stop-sharing-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #E04149;
  text-decoration: underline !important;
}
.cs-stop-sharing-text:hover {
  text-decoration: underline !important;
  color: #E04149;
}

.cs-start-sharing-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #401FE5;
  text-decoration: underline !important;
}
.cs-start-sharing-text:hover {
  text-decoration: underline !important;
  color: #401FE5;
}

.cs-car-detail-main-area {
  margin: 30px 0 50px 0;
}
.cs-car-detail-main-area .cs-car-main {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
.cs-car-detail-main-area .cs-car-main .cs-car-content {
  border-radius: 12px;
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px;
}
.cs-car-detail-main-area .cs-car-main .cs-car-content .cs-card-1-title {
  font-size: 16px;
  font-weight: 700;
  color: #272727;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.cs-form-1-2 .form-group {
  padding: 0 !important;
}
.cs-form-1-2 .date-box-1 label:before {
  left: 134px !important;
}

#stop_share_modal .cs-file-box-1 .csfb1-head-1 {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#stop_share_modal .modal-content {
  width: 610px;
  border-radius: 20px;
}
#stop_share_modal form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#stop_share_modal .cs-padding-1 {
  width: 100%;
  padding: 0 20% !important;
}
#stop_share_modal #reason-end-text {
  height: 105px;
}
#stop_share_modal .modal-header {
  border: none;
  padding-bottom: 0;
}
#stop_share_modal .modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px 30px 10px;
  align-items: center;
}
#stop_share_modal .modal-body .cs-modal-title {
  text-align: center;
  color: #696969;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
#stop_share_modal .modal-body .cs-modal-desc {
  text-align: center;
  color: #696969;
  font-size: 16px;
  line-height: 24px;
}
#stop_share_modal .cs-modal-btn-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}
#stop_share_modal .cs-modal-btn-group button {
  flex: 1;
}

.cs-info-box-red {
  background: #FFEFEF;
  color: #E04149;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
}
.cs-info-box-red .cs-info-box-red-text {
  color: #E04149;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.cs-info-box-red i {
  color: #E04149;
  font-size: 27px;
}

.cs-primary-radius-btn {
  color: #ffffff;
  background-color: #401FE5;
  border-radius: 20px;
  padding: 8px 25px 8px 15px;
  border: 1px solid transparent;
  font-weight: 700;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  max-width: 275px;
  width: 100%;
}
.cs-primary-radius-btn:hover {
  color: #401FE5;
  background-color: #ffffff;
  border: 1px solid #401FE5;
  transition: all 0.5s;
}

.cs-green-radius-btn {
  color: #ffffff;
  background-color: #35D634;
  border-radius: 20px;
  padding: 8px 25px 8px 15px;
  border: 1px solid transparent;
  font-weight: 700;
  transition: all 0.5s;
  align-items: center;
  margin: 0 auto;
  max-width: 275px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.cs-green-radius-btn span {
  justify-self: center;
  margin-right: calc(50% - 40px) !important;
}
.cs-green-radius-btn:hover {
  color: #35D634;
  background-color: #ffffff;
  border: 1px solid #35D634;
  transition: all 0.5s;
}

.cs-yellow-radius-btn {
  color: #ffffff;
  background-color: #FFB100;
  border-radius: 20px;
  padding: 8px 25px 8px 15px;
  border: 1px solid transparent;
  font-weight: 700;
  transition: all 0.5s;
  align-items: center;
  margin: 0 auto;
  max-width: 275px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.cs-yellow-radius-btn:hover {
  color: #FFB100;
  background-color: #ffffff;
  border: 1px solid #FFB100;
  transition: all 0.5s;
}

.cs-rent-list-2 {
  padding: 15px 0 0 0 !important;
}
.cs-rent-list-2 ul {
  margin: 15px;
}
.cs-rent-list-2 ul li {
  padding: 10px 0 !important;
}
.cs-rent-list-2 ul li:last-child {
  border: none !important;
}

.cs-rent-list {
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.cs-rent-list ul {
  list-style: none;
  padding: 0;
}
.cs-rent-list ul .cs-rent-content {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #EDEDED;
}
.cs-rent-list ul .cs-rent-content .cs-rent-title {
  font-size: 16px;
  line-height: 24px;
  color: #838383;
}
.cs-rent-list ul .cs-rent-content .cs-rent-desc {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 600;
}

.cs-rent-end-area-1 {
  background-color: #CFEFBE;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  padding: 15px;
}
.cs-rent-end-area-1 span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #272727;
  position: relative;
}
.cs-rent-end-area-1 span::before {
  content: "";
  position: absolute;
  display: block;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #52BE19;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}

.cs-rent-end-area-2 {
  background-color: #FFEFEF;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  padding: 15px;
}
.cs-rent-end-area-2 span {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #E04149;
}
.cs-rent-end-area-2 span::before {
  content: "";
  position: absolute;
  display: block;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #E04149;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}

.cs-rent-list-pay .cs-pay-info-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.cs-rent-list-pay .cs-pay-info-buttons button {
  margin: 0 !important;
}
.cs-rent-list-pay .cs-pay-info-buttons .cs-pay-info-text-1 {
  display: flex;
  align-items: center;
}
.cs-rent-list-pay .cs-pay-info-buttons .cs-pay-info-text-1 span {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}
.cs-rent-list-pay .cs-pay-info-buttons .cs-pay-info-text-1 i {
  color: #7287FD;
  font-size: 22px;
}

.cs-pay-info-area {
  padding: 0 15px;
}
.cs-pay-info-area .cs-pay-info-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin: 30px 0 10px 0;
}
.cs-pay-info-area ul {
  padding: 0;
  width: 100%;
  list-style: none;
}
.cs-pay-info-area ul .cs-rent-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-pay-info-area ul .cs-rent-content:last-child {
  font-weight: 700;
}
.cs-pay-info-area .cs-dotted-line {
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: block;
  border-bottom: 1px dashed #696969;
}

.cs-completed-disc-1 {
  position: relative;
  color: #13B212;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-left: 30px;
}
.cs-completed-disc-1::before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #52BE19;
}

.cs-cancelled-disc-1 {
  position: relative;
  color: #FF081B;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-left: 30px;
}
.cs-cancelled-disc-1::before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #FF081B;
}

.cs-in-usage-now-disc-1 {
  position: relative;
  color: #401FE5;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-left: 30px;
}
.cs-in-usage-now-disc-1::before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #401FE5;
}

.cs-pay-info-area-2 .cs-pay-info-area-3 {
  margin: 30px 0;
  background: #F6FDFD;
  padding: 15px 30px;
}
.cs-pay-info-area-2 .nav-tabs {
  border-bottom: 1px solid #707070;
  background-color: #ffffff;
  gap: 20px;
  width: fit-content !important;
}
.cs-pay-info-area-2 .nav-tabs .nav-item .nav-link {
  border: none;
  background: transparent;
}
.cs-pay-info-area-2 .nav-tabs .nav-item .nav-link.active {
  border: none;
  position: relative;
  background: transparent;
}
.cs-pay-info-area-2 .nav-tabs .nav-item .nav-link.active::after {
  content: "";
  width: 80px;
  position: absolute;
  left: 5px;
  bottom: -3px;
  display: block;
  height: 6px;
  background-color: #27C1B2;
  border-radius: 4px;
}

.cs-main-comment-area-1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cs-main-comment-area-1 i {
  color: #401FE5;
  font-size: 22px;
  background: #EAECF4;
  padding: 5px;
  border-radius: 50%;
}
.cs-main-comment-area-1 .cs-comment-area-text-1 {
  font-size: 16px;
  line-height: 24px;
  color: #401FE5;
  font-weight: 700;
  margin: 0;
  text-decoration: underline;
}

.cs-main-comment-area-2 {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(237, 237, 237, 0.4470588235);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  gap: 20px;
  margin: 20px 0;
}
.cs-main-comment-area-2 .cs-comment-img-1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.cs-main-comment-area-2 .cs-star-name-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.cs-main-comment-area-2 .cs-star-name-text .cs-comment-name-1 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
}
.cs-main-comment-area-2 .cs-star-name-text i {
  font-size: 18px;
  color: #F0C76A;
}
.cs-main-comment-area-2 .cs-star-name-text .cs-comment-date-text-1 {
  font-size: 14px;
  color: #696969;
}
.cs-main-comment-area-2 .cs-comment-descr-1 {
  color: #696969;
  font-size: 16px;
  line-height: 24px;
}

.cs-rent-bill-main-1 {
  margin: 30px 0;
}
.cs-rent-bill-main-1 .cs-rent-bill-content {
  border-radius: 12px;
  border: 1px solid #EAECF4;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-rent-bill-main-1 .cs-rent-bill-content .cs-textbox-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EDEDED;
  padding: 25px 20px;
}
.cs-rent-bill-main-1 .cs-rent-bill-content .cs-textbox-1 i {
  font-size: 20px;
  color: #696969;
  margin-left: 10px;
}
.cs-rent-bill-main-1 .cs-rent-bill-content .cs-textbox-1 .cs-title-1 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
  margin: 0;
}
.cs-rent-bill-main-1 .cs-rent-bill-content .cs-rent-list-1 {
  padding: 25px 20px 0 20px;
}
.cs-rent-bill-main-1 .cs-rent-bill-content .cs-rent-list-1 li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}
.cs-rent-bill-main-1 .cs-rent-bill-content .cs-rent-list-1 li .cs-value-1 {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}
.cs-rent-bill-main-1 .cs-rent-bill-content .cs-rent-list-1 li .cs-response-1 {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-weight: 600;
}

.cs-rent-bill-btn-1 {
  max-width: 320px;
  width: 100%;
}

.cs-apply-form-btn,
.cs-apply-form-btn3,
.cs-apply-form-btn-2 {
  cursor: not-allowed;
  pointer-events: none;
  max-width: 320px;
  width: 100%;
  opacity: 0.5;
}
.cs-apply-form-btn.active,
.cs-apply-form-btn3.active,
.cs-apply-form-btn-2.active {
  cursor: pointer;
  pointer-events: visible !important;
  opacity: 1 !important;
}

.cs-rent-bill-main-2 .cs-title-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
  border-bottom: 1px solid #EDEDED;
  margin: 20px 0;
  padding-bottom: 10px;
}
.cs-rent-bill-main-2 .cs-rent-content-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 630px;
  width: 100%;
  margin: 0 auto;
}
.cs-rent-bill-main-2 .cs-rent-content-1 .cs-content-item {
  max-width: 105px;
  cursor: pointer;
  padding: 15px 25px;
  border: 1px solid #696969;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  transition: all 0.5s;
}
.cs-rent-bill-main-2 .cs-rent-content-1 .cs-content-item.active {
  background: #27C1B2;
  color: #ffffff;
  border: 1px solid transparent;
  transition: all 0.5s;
}
.cs-rent-bill-main-2 .cs-rent-content-2 {
  max-width: 325px;
  width: 100%;
  margin: 30px auto;
}
.cs-rent-bill-main-2 .cs-rent-content-2 .cs-rent-list-2 {
  padding: 0;
  list-style: none;
}
.cs-rent-bill-main-2 .cs-rent-content-2 .cs-rent-list-2 li {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  justify-content: space-between;
}
.cs-rent-bill-main-2 .cs-rent-content-2 label {
  font-weight: 600;
}
.cs-rent-bill-main-2 .cs-rent-content-2 #form_bill_1 {
  align-items: center;
  border: 0.5px solid #272727;
  border-radius: 14px;
  color: #041614;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  line-height: 24px;
  padding: 5px 20px;
  transition: all 0.2s ease;
}

.cs-continue-modal-content {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  opacity: 0;
  height: 0;
  visibility: hidden;
}
.cs-continue-modal-content.active {
  opacity: 1;
  visibility: visible;
  height: auto;
}

.cs-accept-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  height: 0;
  gap: 15px;
}
.cs-accept-modal-content .cs-accept-modal-title {
  font-size: 20px;
  line-height: 28px;
  color: #0C0C0C;
  font-weight: 700;
}
.cs-accept-modal-content .cs-accept-modal-desc {
  font-size: 16px;
  line-height: 24px;
  color: #696969;
  font-weight: 600;
  max-width: 330px;
  width: 100%;
  text-align: center;
}
.cs-accept-modal-content.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
  height: auto;
}

.cs-modal-iframe {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.cs-modal-iframe.deactive {
  opacity: 0;
  visibility: hidden;
  height: 0 !important;
}

#form_description_1 {
  height: 100px;
  resize: none;
}

.cs-profile-detail-wp-sec-1 {
  display: none;
  flex-direction: column;
}
.cs-profile-detail-wp-sec-1.active {
  display: flex;
}

.cs-profile-wp-title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #041614;
  margin-bottom: 25px;
}

.cs-profile-detail-wp-sec-2 {
  align-items: center;
  width: 100%;
  flex-direction: column;
  display: none;
}
.cs-profile-detail-wp-sec-2.active {
  display: flex;
  transition: all 0.5s;
}
.cs-profile-detail-wp-sec-2 .cs-templ-area-1 {
  padding: 0;
}
.cs-profile-detail-wp-sec-2 .cs-continue-btn-1 {
  max-width: 310px;
  width: 100%;
  margin: 50px 0 !important;
  cursor: not-allowed;
  max-width: 320px;
  opacity: 0.5;
  pointer-events: none;
}
.cs-profile-detail-wp-sec-2 .cs-continue-btn-1.active {
  cursor: pointer;
  opacity: 1 !important;
  pointer-events: visible !important;
}
.cs-profile-detail-wp-sec-2 .cs-checkbox-1 {
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-weight: 600;
}
.cs-profile-detail-wp-sec-2 .cs-profile-detail-wp-left {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 25px 75px;
  height: 100%;
}
.cs-profile-detail-wp-sec-2 .cs-profile-detail-wp-left form {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}
.cs-profile-detail-wp-sec-2 .cs-profile-detail-wp-right {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #F6FDFD;
  border-radius: 20px;
  padding: 25px 0;
  height: 100%;
}
.cs-profile-detail-wp-sec-2 .cs-table-check {
  margin: 0 !important;
  border: 1px solid #27C1B2;
  border-radius: 4px;
  width: 22px;
  height: 22px !important;
  accent-color: #1b998d;
}
.cs-profile-detail-wp-sec-2 .cs-table-check-1 {
  margin: 0 !important;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-weight: 600;
  cursor: pointer;
}
.cs-profile-detail-wp-sec-2 .cs-continue-btn-1 {
  max-width: 310px;
  margin: 0 auto;
}

.cs-profile-detail-wp-sec-3 {
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.cs-profile-detail-wp-sec-3.active {
  display: flex;
  transition: all 0.5s;
}
.cs-profile-detail-wp-sec-3 .cs-sec-3-container {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}
.cs-profile-detail-wp-sec-3 .cs-continue-btn-2 {
  max-width: 310px;
  width: 100%;
  margin: 25px 0 50px 0 !important;
  opacity: 0.5;
  pointer-events: none;
}
.cs-profile-detail-wp-sec-3 .cs-continue-btn-2.active {
  cursor: pointer;
  opacity: 1 !important;
  pointer-events: visible !important;
}
.cs-profile-detail-wp-sec-3 .date-box-1 label:before {
  bottom: 11px !important;
  left: 22px !important;
}
.cs-profile-detail-wp-sec-3 .cs-work-plan-input {
  accent-color: #1b998d !important;
  border: 1px solid #27C1B2 !important;
  border-radius: 4px !important;
  height: 22px !important;
  margin: 0 !important;
  width: 22px !important;
}
.cs-profile-detail-wp-sec-3 .cs-work-plan-input:focus {
  box-shadow: none !important;
}
.cs-profile-detail-wp-sec-3 .cs-work-plan-input:active {
  box-shadow: none !important;
}
.cs-profile-detail-wp-sec-3 .cs-work-plan-label {
  padding-left: 35px;
}
.cs-profile-detail-wp-sec-3 .cs-work-plan-container {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.cs-profile-detail-wp-sec-4 {
  align-items: center;
  width: 100%;
  flex-direction: column;
  display: none;
  padding: 0 15px;
}
.cs-profile-detail-wp-sec-4.active {
  display: flex;
}
.cs-profile-detail-wp-sec-4 .cs-template-btn-1 {
  max-width: 310px;
  width: 100%;
  margin: 50px 0 !important;
}
.cs-profile-detail-wp-sec-4 .cs-pd4-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 100%;
  margin-bottom: 30px;
}
.cs-profile-detail-wp-sec-4 .cs-pd4-main .cs-pd4-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;
}
.cs-profile-detail-wp-sec-4 .cs-pd4-main .cs-pd4-item .cs-edit-delete-btn-group {
  display: flex;
  flex-direction: column;
}
.cs-profile-detail-wp-sec-4 .cs-pd4-main .cs-pd4-item .cs-edit-delete-btn-group .cs-editbtn {
  color: #696969;
}
.cs-profile-detail-wp-sec-4 .cs-pd4-main .cs-pd4-item .cs-title-1 {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cs-profile-detail-wp-sec-4 .cs-pd4-main .cs-pd4-item .cs-title-1 i {
  color: #401FE5;
  font-size: 20px;
}

.cs-profile-detail-wp-table .cs-table-btn i {
  font-size: 22px;
  color: #007AFF;
}
.cs-profile-detail-wp-table td {
  border: none !important;
}
.cs-profile-detail-wp-table thead th, .cs-profile-detail-wp-table thead tr {
  border: none !important;
  text-align: center;
}
.cs-profile-detail-wp-table tbody tr, .cs-profile-detail-wp-table tbody th {
  border: none !important;
  text-align: center;
}

.cs-back-btn {
  color: #041614;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.cs-back-btn:hover {
  color: #401FE5;
}

.cs-item-yellow {
  color: #EFA036;
  font-size: 27px;
}

.cs-item-red {
  color: #E04149;
  font-size: 27px;
}

.cs-item-bg-red {
  background: #FFEFEF;
}

.cs-item-bg-yellow {
  background: #FFBA00;
}

.cs-item-bg-blue {
  background: #007AFF;
}

.cs-item-blue {
  color: #007AFF;
  font-size: 27px;
}

.cs-item-green {
  color: #35D634;
  font-size: 27px;
}

.cs-item-bg-green {
  background: #CFEFBE;
}

.cs-mission-list-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 14px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-mission-list-count .cs-mission-list-item {
  flex: 1;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.cs-mission-list-count .cs-mission-list-item.active {
  background: #E7F2F2;
}
.cs-mission-list-count .cs-mission-list-item::before {
  position: absolute;
  content: "";
  display: block;
  background: #696969;
  height: 24px;
  width: 1px;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cs-mission-list-count .cs-mission-list-item:last-child::before {
  content: none;
}
.cs-mission-list-count .cs-mission-list-item .cs-item-title-1 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}
.cs-mission-list-count .cs-mission-list-item .cs-item-title-2 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
  font-weight: 700;
}

.cs-mission-choise-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 650px;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  gap: 10px;
  padding-bottom: 15px;
}
.cs-mission-choise-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F0EDFF;
}
.cs-mission-choise-1::-webkit-scrollbar {
  height: 2px;
}
.cs-mission-choise-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #7287FD;
}
.cs-mission-choise-1 .cs-content-item {
  border: 1px solid #696969;
  color: #696969;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
  padding: 8px 15px;
  white-space: nowrap;
}
.cs-mission-choise-1 .cs-content-item.active {
  border: 1px solid #401FE5;
  color: #401FE5;
  font-weight: 600;
  background-color: #EAECF4;
}

.cs-mission-choise-main-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  width: 100%;
  padding: 0 15px;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #EAECF4;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  padding-right: 25px;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 .cs-item-left {
  height: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 12px 0 0 12px;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 .cs-item-left::after {
  position: absolute;
  right: 0;
  height: 100%;
  content: "";
  top: 0;
  background: #EAECF4;
  display: block;
  width: 1px;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 .cs-list {
  list-style: none;
  padding: 0;
  width: 100%;
  padding: 25px;
  padding-left: 15px;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 .cs-list li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 .cs-list li .cs-title-1 {
  font-size: 16px;
  line-height: 24px;
  color: #696969;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 .cs-list li .cs-title-2 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
}
.cs-mission-choise-main-1 .cs-mission-choise-list-1 .cs-list li .cs-title-3 {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 600;
}

.cs-cd-sec-1, .cs-cd-missions-1 {
  display: flex;
  flex-direction: column;
}
.cs-cd-sec-1.deactive, .cs-cd-missions-1.deactive {
  display: none !important;
}

.cs-cd-sec-2.active,
.cs-cd-sec-3.active,
.cs-cd-missions-2.active,
.cs-stc-sec-2.active {
  display: flex;
  flex-direction: column;
}

.cs-form-cdm .date-box-1 label:before {
  left: 18px;
}

#damage_area_modal .cs-damage-btn-group, #car-photo-add-modal .cs-damage-btn-group {
  margin: 30px auto;
  max-width: 310px;
}
#damage_area_modal .modal-header, #car-photo-add-modal .modal-header {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}
#damage_area_modal .modal-body, #car-photo-add-modal .modal-body {
  padding-top: 0 !important;
}
#damage_area_modal .modal-footer, #car-photo-add-modal .modal-footer {
  justify-content: center;
}
#damage_area_modal .modal-content, #car-photo-add-modal .modal-content {
  min-width: 610px;
  width: max-content;
  border-radius: 18px;
}
#damage_area_modal .modal-dialog, #car-photo-add-modal .modal-dialog {
  max-width: 610px;
  margin: 0 auto;
  padding: 15px;
}
#damage_area_modal #damage_area_modal_label, #car-photo-add-modal #damage_area_modal_label {
  color: #0C0C0C;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
#damage_area_modal .cs-hasar-bolgesi-main, #car-photo-add-modal .cs-hasar-bolgesi-main {
  background-size: cover;
  max-width: 410px;
  width: 100%;
  height: 652px;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid black;
  margin: 0 auto;
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check, #car-photo-add-modal .cs-hasar-bolgesi-main .form-check {
  padding: 0;
  width: 30%;
  border-bottom: 1px solid #401FE5;
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check:nth-child(3n+2), #car-photo-add-modal .cs-hasar-bolgesi-main .form-check:nth-child(3n+2) {
  width: 40% !important;
  border-top: none;
  border-right: 1px solid #401FE5;
  border-left: 1px solid #401FE5;
  border-bottom: 1px solid #401FE5;
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check:nth-child(n+7), #car-photo-add-modal .cs-hasar-bolgesi-main .form-check:nth-child(n+7) {
  border-bottom: none;
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check .cs-choose-check, #car-photo-add-modal .cs-hasar-bolgesi-main .form-check .cs-choose-check {
  display: none;
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check label, #car-photo-add-modal .cs-hasar-bolgesi-main .form-check label {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check .form-check-input, #car-photo-add-modal .cs-hasar-bolgesi-main .form-check .form-check-input {
  display: none;
  cursor: pointer;
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label, #car-photo-add-modal .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label {
  background: rgba(39, 193, 178, 0.5);
  background: linear-gradient(180deg, rgba(39, 193, 178, 0.5) 0%, rgba(40, 180, 181, 0.5) 19%, rgba(46, 147, 192, 0.5) 38%, rgba(54, 94, 209, 0.5) 71%, rgba(64, 31, 229, 0.5) 100%);
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label .cs-choose-check, #car-photo-add-modal .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label .cs-choose-check {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
}
#damage_area_modal .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label .cs-choose-check .cs-cc-text, #car-photo-add-modal .cs-hasar-bolgesi-main .form-check .form-check-input:checked + label .cs-choose-check .cs-cc-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.js-next:not(.active) {
  display: none !important;
}

.csfb1-pdf-d-2 {
  border: 0.5px solid #EDEDED;
  border-radius: 8px;
  min-height: 40px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.csfb1-pdf-d-2 canvas {
  width: 100%;
  border-radius: 8px;
}

.cs-file-box-2 .form-control:disabled, .cs-file-box-2 .form-control:read-only {
  background-color: #ffffff;
  cursor: not-allowed;
  opacity: 0 !important;
  pointer-events: none;
}

.cs-wbg-head-second {
  display: none !important;
}
.cs-wbg-head-second.active {
  display: flex !important;
}

.js-back-btn {
  display: none !important;
  margin-bottom: 20px;
  color: #27C1B2;
}
.js-back-btn.active {
  display: flex !important;
}

.cs-car-btn-group {
  display: none !important;
}
.cs-car-btn-group.active {
  display: flex !important;
}

.cs-damage-photos-main {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.cs-damage-photos-main .cs-damage-p-left {
  flex: 1;
}
.cs-damage-photos-main .cs-damage-p-left .cs-g-file-main {
  width: 400px;
  height: 370px;
  margin: 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.cs-damage-photos-main .cs-damage-p-left .cs-g-file-main input {
  height: 100%;
  width: 100%;
}
.cs-damage-photos-main .cs-damage-p-right {
  flex: 1;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
.cs-damage-photos-main .cs-damage-p-right .cs-upload-container-1 .csuc1-desc-1 {
  font-size: 45px !important;
}
.cs-damage-photos-main .cs-damage-p-right .cs-g-file-main {
  width: 190px;
  height: 175px;
  margin: 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.cs-damage-photos-main .cs-damage-p-right .cs-g-file-main input {
  height: 100%;
  width: 100%;
}

.cs-add-more-car, .cs-add-more-car-2 {
  display: none;
}
.cs-add-more-car.active, .cs-add-more-car-2.active {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: 10px;
}

.cs-car-photo-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.cs-car-photo-list .cs-list-item {
  background-size: cover;
  border-radius: 14px;
  height: 120px;
  position: relative;
}
.cs-car-photo-list .cs-list-item .cs-delete-car-p {
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.cs-car-photo-list .cs-list-item .cs-delete-car-p i {
  font-size: 17px;
  color: #696969;
}
.cs-car-photo-list .cs-list-item:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #041614;
  border-radius: 14px;
  opacity: 0.6;
}
.cs-car-photo-list .cs-list-item:hover .cs-delete-car-p {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
  z-index: 2;
}

.cs-damage-photos-main-2 .cs-damage-mod-title {
  font-size: 16px;
  line-height: 24px;
  color: #041614;
  font-weight: 700;
}
.cs-damage-photos-main-2 .cs-damage-mod-desc {
  font-size: 16px;
  line-height: 24px;
  color: #83908E;
  font-weight: 600;
}
.cs-damage-photos-main-2 textarea {
  height: 100px !important;
  border-radius: 14px;
  color: #83908E;
  border-color: #83908E;
  resize: none;
  margin-top: 10px;
  opacity: 0.5;
}

.cs-map-box-main {
  position: relative;
  display: flex;
}
.cs-map-box-main .cs-map-info-box {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 10px;
  height: 90px;
  max-width: 660px;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cs-map-box-main .cs-map-info-box .cs-text {
  font-size: 18px;
  line-height: 26px;
  color: #272727;
  font-weight: 600;
  padding: 30px 30px 30px 20px;
  margin: 0;
}
.cs-map-box-main .cs-border-left-area {
  background: rgb(39, 193, 178);
  background: linear-gradient(180deg, rgb(39, 193, 178) 0%, rgb(40, 180, 181) 19%, rgb(46, 147, 192) 38%, rgb(54, 94, 209) 71%, rgb(64, 31, 229) 100%);
  width: 24px;
  height: 100%;
  border-radius: 10px 0 0 10px;
}

.cs-form-2 .form-group {
  margin-bottom: 20px;
}
.cs-form-2 .invalid-feedback {
  padding: 5px 20px;
}
.cs-form-2 ::placeholder {
  color: rgba(105, 105, 105, 0.5);
}
.cs-form-2 input:not([type=submit]), .cs-form-2 select, .cs-form-2 input:not([type=checkbox]) {
  border-radius: 14px;
  border: 0.5px solid #696969;
  height: 50px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #041614;
  transition: all 0.2s ease;
  padding: 5px 20px;
}
.cs-form-2 input:not([type=submit]):focus, .cs-form-2 select:focus, .cs-form-2 input:not([type=checkbox]):focus {
  outline: none;
}
.cs-form-2 .form-control:disabled, .cs-form-2 .form-control:read-only {
  background-color: #ffffff;
  cursor: not-allowed;
}
.cs-form-2 textarea {
  resize: none;
  border-radius: 14px;
  border: 0.5px solid #696969;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #041614;
  transition: all 0.2s ease;
  padding: 15px 20px;
}
.cs-form-2 textarea:focus {
  outline: none;
  border-color: #27C1B2;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}
.cs-form-2 .info-text-1 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #041614;
  margin-bottom: 20px;
}
.cs-form-2 .info-text-1 a {
  font-weight: 700;
  color: #401FE5;
  text-decoration: underline !important;
}
.cs-form-2 .info-text-1 a:hover {
  text-decoration: none !important;
}
.cs-form-2 .date-box-1 {
  position: relative;
}
.cs-form-2 .date-box-1 label::before {
  content: "\e94f";
  font-family: "bain" !important;
  font-size: 20px;
  color: #696969;
  position: absolute;
  left: 10px;
  bottom: 15px;
  z-index: 1;
}
.cs-form-2 .date-box-1 input {
  padding-left: 45px;
}
.cs-form-2 .date-i-1 label::before {
  content: "\e94f";
  font-family: "bain" !important;
  font-size: 23px;
  color: #696969;
  position: absolute;
  left: 15px;
  top: 48px;
}
.cs-form-2 .date-i-1 input {
  padding-left: 40px;
}

.cs-duo-datepicker label:before {
  bottom: 11px !important;
  left: 14px !important;
}

.cs-new-mis-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cs-new-mis-form input:not([type=submit]), .cs-new-mis-form select, .cs-new-mis-form .select2-selection__rendered {
  opacity: 0.5;
}

.cs-car-btn-group-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.cs-car-btn-group-2 .cs-first-car {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  background-color: #ffffff;
  border-radius: 12px;
  min-width: 145px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-weight: 700;
  border: 1px solid #27C1B2;
  color: #27C1B2;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.cs-car-btn-group-2 .cs-first-car:hover {
  color: #27C1B2;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  -moz-box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
  box-shadow: 0px 4px 6px 0px rgba(39, 193, 178, 0.65);
}
.cs-car-btn-group-2 button {
  flex: 1;
}
.cs-car-btn-group-2.active {
  display: flex !important;
}

#cs-calendar-1 {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  background: #F6FDFD;
  border-radius: 20px;
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}
#cs-calendar-1 .cs-header {
  height: 50px;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 100;
  margin: 0 auto;
}
#cs-calendar-1 .cs-header h1 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 50px;
  font-weight: 100;
  letter-spacing: 1px;
  color: #272727;
  font-weight: 700;
}
#cs-calendar-1 .cs-left {
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  top: 50%;
  margin-top: -7.5px;
  cursor: pointer;
  color: #272727;
  border-width: 7.5px 10px 7.5px 0;
  border-color: transparent #272727 transparent transparent;
  left: 20px;
}
#cs-calendar-1 .cs-right {
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  top: 50%;
  margin-top: -7.5px;
  cursor: pointer;
  color: #272727;
  border-width: 7.5px 0 7.5px 10px;
  border-color: transparent transparent transparent #272727;
  right: 20px;
}
#cs-calendar-1 .month {
  opacity: 0;
  margin-top: 45px;
}
#cs-calendar-1 .month.new {
  -webkit-animation: fadeIn 1s ease-out;
  opacity: 1;
}
#cs-calendar-1 .month.in.prev {
  -webkit-animation: moveFromBottomFadeMonth 0.4s ease-out;
  -moz-animation: moveFromBottomFadeMonth 0.4s ease-out;
  animation: moveFromBottomFadeMonth 0.4s ease-out;
  opacity: 1;
}
#cs-calendar-1 .month.in.next {
  -webkit-animation: moveFromTopFadeMonth 0.4s ease-out;
  -moz-animation: moveFromTopFadeMonth 0.4s ease-out;
  animation: moveFromTopFadeMonth 0.4s ease-out;
  opacity: 1;
}
#cs-calendar-1 .month.out.prev {
  -webkit-animation: moveToBottomFadeMonth 0.4s ease-in;
  -moz-animation: moveToBottomFadeMonth 0.4s ease-in;
  animation: moveToBottomFadeMonth 0.4s ease-in;
  opacity: 1;
}
#cs-calendar-1 .month.out.next {
  -webkit-animation: moveToTopFadeMonth 0.4s ease-in;
  -moz-animation: moveToTopFadeMonth 0.4s ease-in;
  animation: moveToTopFadeMonth 0.4s ease-in;
  opacity: 1;
}
#cs-calendar-1 .week {
  width: 100%;
}
#cs-calendar-1 .day {
  display: inline-block;
  width: 14%;
  padding: 10px;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  position: relative;
  z-index: 100;
}
#cs-calendar-1 .day .day-events {
  list-style: none;
  margin-top: 3px;
  text-align: center;
  height: 12px;
  line-height: 6px;
  overflow: hidden;
}
#cs-calendar-1 .day .day-events span {
  vertical-align: top;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 12px;
  height: 12px;
  line-height: 5px;
  margin: 0 1px;
  border-radius: 50%;
}
#cs-calendar-1 .day.other {
  color: rgba(255, 255, 255, 0.3);
}
#cs-calendar-1 .day.today {
  color: #401FE5;
}
#cs-calendar-1 .day-name {
  font-size: 9px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.7px;
}
#cs-calendar-1 .day-number {
  font-size: 16px;
  letter-spacing: 1.5px;
}
#cs-calendar-1 .red {
  background: #E04149;
}
#cs-calendar-1 .yellow {
  background: #FFBA00;
}
#cs-calendar-1 .details {
  display: none;
}
#cs-calendar-1 .details.in {
  -webkit-animation: moveFromTopFade 0.5s ease both;
  -moz-animation: moveFromTopFade 0.5s ease both;
  animation: moveFromTopFade 0.5s ease both;
}
#cs-calendar-1 .details.out {
  -webkit-animation: moveToTopFade 0.5s ease both;
  -moz-animation: moveToTopFade 0.5s ease both;
  animation: moveToTopFade 0.5s ease both;
}
#cs-calendar-1 .arrow {
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -2px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent rgb(164, 164, 164) transparent;
  transition: all 0.7s ease;
}
#cs-calendar-1 .events {
  height: 75px;
  padding: 7px 0;
  overflow-y: auto;
  overflow-x: hidden;
}
#cs-calendar-1 .events.in {
  -webkit-animation: fadeIn 0.3s ease both;
  -moz-animation: fadeIn 0.3s ease both;
  animation: fadeIn 0.3s ease both;
}
#cs-calendar-1 .events.out {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
#cs-calendar-1 .event {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  padding: 2px 16px;
  vertical-align: top;
}
#cs-calendar-1 .event.empty {
  color: rgba(99, 99, 99, 0.2);
}
#cs-calendar-1 .event-category {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin: 6px 0 0;
  vertical-align: top;
}
#cs-calendar-1 .event span {
  display: inline-block;
  padding: 0 0 0 7px;
}
#cs-calendar-1 .legend {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
#cs-calendar-1 .entry {
  display: none;
}

.cs-empty-control {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
  padding: 0 20px;
}
.cs-empty-control .cs-fully {
  color: #272727;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
}
.cs-empty-control .cs-fully span {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #E04149;
  display: block;
}
.cs-empty-control .cs-free {
  color: #272727;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
}
.cs-empty-control .cs-free span {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #FFBA00;
  display: block;
}

.cs-day-names-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 30px 0;
}
.cs-day-names-row .day-name-cell {
  font-size: 16px;
  line-height: 24px;
  color: #27C1B2;
  font-weight: 700;
}