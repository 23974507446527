@use 'variables'
@use 'short-code'
.cs-p-campaign-sec-1
    .cs-sec-campaign-main
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
        border-radius: variables.$radius8
        padding: 40px
        margin-bottom: 40px
        gap: 10px
        .cs-campaign-imgbox
            max-width: 485px
            width: 100%
            img
                width: 100%
        .cs-campaign-textbox
            flex: 2
            .cs-title1
                color: variables.$color7
                font-size: 30px
                line-height: 38px
                font-weight: variables.$fontWeightBold
            .cs-desc1
                color: variables.$color20
                font-size: 20px
                line-height: 28px
            .cs-campaign-btn
                align-items: center
                background-color: variables.$secondColor
                border: 1px solid transparent
                border-radius: variables.$radius5
                color: variables.$whiteColor
                display: inline-flex
                font-size: 16px
                font-weight: 700
                justify-content: center
                letter-spacing: .2px
                line-height: 22px
                min-height: 44px
                min-width: 145px
                padding: 5px 20px
                text-align: center
                transition: all .3s ease
                &:hover
                    transition: all .3s ease
                    background-color: variables.$whiteColor
                    color: variables.$secondColor
                    border: 1px solid variables.$secondColor
.cs-no-campaign
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    width: 100%
    gap: 25px
    margin-bottom: 150px
    .cs-no-campaign-text
        text-align: center
        color: variables.$color11
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        max-width: 395px
        width: 100%
    .no-campaign-img
        max-width: 175px
        width: 100%
        object-fit: cover
#campaignDetailModal
    .modal-dialog
        max-width: 700px
        margin: 3rem auto
        padding: 15px
        .modal-content
            border: none
            border-radius: variables.$radius11
            padding: 0 20px
        .modal-header
            padding: 10px 0 0 0
            border: none
        .modal-body
            .cs-banner
                width: 100%
                border-top-left-radius: 20px
                border-top-right-radius: 20px
    .cs-modal-title1
        font-size: 29px
        line-height: 37px
        color: variables.$blackColor
        font-weight: variables.$fontWeightBold
        margin-top: 10px
    .cs-modal-desc1
        font-size: 16px
        line-height: 24px
        color: variables.$blackColor
        margin: 5px
    .cs-modal-toast
        background: variables.$color200
        padding: 25px
        display: flex
        align-items: center
        gap: 10px
        .cs-modaltoast-text
            font-size: 16px
            line-height: 24px
            color: variables.$color7
    .cs-campaign-code
        border: 2px dashed variables.$primaryColor
        padding:15px 10px
        border-radius: variables.$radius4
        width:100%
        background: #e7f2f2 !important
        h4
            margin: 0
            font-weight: variables.$fontWeightSemiBold
            color: variables.$primaryColor
.cs-p-blog-sec-1
    .cs-blog-box-1
        .image-div-1
            position: relative
    .content-area-1
        margin: 0 auto
        max-width: 950px
    .cs-blog-search-area
        .cs-title1
            color: variables.$color7
            font-size: 30px
            line-height: 38px
            font-weight: variables.$fontWeightExtraBold
            text-align: center
        .cs-desc
            font-size: 20px
            line-height: 28px
            color: variables.$color20
            text-align: center
    .cs-blog-list-main
        display: grid
        grid-template-columns: repeat(3,1fr)
        gap: 30px
        margin: 30px 0
.cs-new-text
    color: variables.$whiteColor
    font-size: 17px
    line-height: 25px
    font-weight: variables.$fontWeightSemiBold
    position: absolute
    top: 10px
    left: 20px
    background: variables.$color201
    border-radius: variables.$radius7
    padding: 2px 20px
.cs-date-text
    color: variables.$color7
    font-size: 14px
    line-height: 22px
    font-weight: variables.$fontWeightSemiBold
    position: absolute
    bottom: 10px
    right: 20px
    background: variables.$color202
    border-radius: variables.$radius7
    padding: 2px 20px
.cs-p-blogdetail-sec-1
    margin: 20px 0
    min-height: 75vh
    .cs-blog-detail-image
        position: relative
        float: left
        margin-right: 20px
        max-width: 570px
        width: 100%
        border-top-left-radius: 20px
        border-top-right-radius: 20px
        img
            width: 100%
            border-top-left-radius: 20px
            border-top-right-radius: 20px
    .cs-blog-detail-caption
        margin-bottom: 150px
        .cs-blog-detail-date
            color: variables.$color7
            font-size: 14px
            line-height: 22px
            font-weight: variables.$fontWeightBold
        .cs-blog-detail-title
            color: variables.$blackColor
            font-size: 24px
            line-height: 32px
            font-weight: variables.$fontWeightBold
            margin-top: 10px
        .cs-blog-detail-desc
            color: variables.$blackColor
            font-size: 16px
            line-height: 24px
.cs-search-box
    display: flex
    width: 100%
    border: 1px solid variables.$color15
    align-items: center
    border-radius: variables.$radius200
    height: 70px
    justify-content: space-between
    padding: 0 8px 0 20px
    background-color: variables.$whiteColor
    input
        width: 100%
        height: 100%
        border-radius: variables.$radius200
        border: none
        outline: none
        box-shadow: none
        &:focus-visible
            border: none
            outline: none
            box-shadow: none
    .cs-search-btn
        background: variables.$color15
        color: variables.$whiteColor
        padding: 12px 30px
        border-radius: variables.$radius200
        font-size: 22px
        line-height: 30px
        font-weight: variables.$fontWeightBold
        border: none
.cs-p-sigortavekoruma-sec-1
    .cs-blog-detail-main
        margin-bottom: 300px
        .cs-blog-detail-content
            display: flex
        .cs-blog-detail-image
            margin-bottom: 10px !important
            position: relative
            float: left
            margin-right: 20px
            max-width: 385px
            width: 100%
            border-radius: variables.$radius100
            flex: 0 0 50%
            img
                width: 100%
                border-radius: variables.$radius100
        .cs-blog-detail-title
            color: variables.$color20
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightBold
            margin-bottom: 10px
        .cs-blog-detail-desc
            color: variables.$blackColor
            font-size: 16px
            line-height: 24px
            margin-top: 20px
        .cs-blog-detail-caption
            flex: 1 0 50%
.cs-p-arabanipaylas-sec-main
    margin-bottom: 50px
    .cs-wbg-head-2
        display: flex
        justify-content: center
        width: 100%
    .cs-arabani-pay-bry-1-main
        display: flex
        align-items: flex-start
        justify-content: center
        gap: 50px
        .cs-car-btn-1
            margin: 0 auto
            display: flex
            justify-content: center
            align-items: center
        .cs-arabanipaylas-line
            border: 1px solid variables.$color20
            border-radius: variables.$radius11
            padding: 10px
        .cs-image-box-1
            img
                max-width: 624px
                width: 100%
        .cs-text-box-1
            max-width: 350px
            width: 100%
            margin: 0 auto
            display: flex
            flex-direction: column
            align-items: center
            .cs-title1
                font-size: 24px
                line-height: 32px
                color: variables.$color11
                margin-bottom: 20px
                font-weight: variables.$fontWeightBold
                text-align: center
            .cs-primary-btn-3
                max-width: 320px
                width: 100%
                margin-top: 10px
                margin-bottom: 50px
// Global checkbox primary
.cs-primary-checkbox-1
    display: flex
    width: auto
    align-items: center
    gap: 5px
    margin-bottom: 20px
    width: 100%
    label
        user-select: none
    .cs-primary-label
        font-size: 16px
        line-height: 24px
        color: variables.$color11
        font-weight: variables.$fontWeightSemiBold
        cursor: pointer
    input[type='radio']
        &.cs-primary-check
            display: none

    input[type='radio']
        &.cs-primary-check
            &+label
                display: inline-block
                width: 25px
                height: 25px
                border: 1px solid variables.$color203
                border-radius: variables.$radius202
                background: transparent
                transition: 0.2s ease all
    input[type='radio']
        &.cs-primary-check
            &:checked
                &+label
                    border: 4px solid variables.$primaryColor
// Global checkbox primary
.cs-p-arabanipaylas-sec-2
    margin-bottom: 30px
    display: none
    &.active
        display: flex
        flex-direction: column
    .cs-arabani-pay-bry-1-0-main
        display: flex
        align-items: flex-start
        justify-content: center
        .cs-step-line-size
            max-width: 200px
            width: 100%
            margin-bottom: 20px
        .cs-image-box-1
            img
                max-width: 624px
                width: 100%
        .cs-textbox-1
            align-items: center
            display: flex
            flex-direction: column
            margin: 0 auto
            max-width: 350px
            width: 100%
            gap: 30px
            .cs-sharecar-btn1
                display: flex
                justify-content: center
                button
                    max-width: 310px
                    width: 100%
.cs-p-arabanipaylas-sec-3,
.cs-p-arabanipaylas-sec-4,
.cs-p-arabanipaylas-sec-5,
.cs-p-arabanipaylas-sec-6
    margin-bottom: 50px
    display: none
    &.active
        display: flex
        flex-direction: column
    .cs-check-group
        display: none
        flex-direction: column
    .cs-file-buttons
        margin-left: 20px
        display: flex
        flex-direction: column
        gap: 10px
    #cs-file-img-div
        width: 40px
        height: 40px
        margin-right: 10px
        img
            width: 100%
            height: 100%
            border-radius: variables.$radius12
            object-fit: contain
    .cs-file-info-js-1
        border: 1px solid variables.$color200
        padding: 20px
        border-radius: variables.$radius5
    .cs-file-date-text
        font-size: 16px
        line-height: 24px
        color: variables.$color11
    .cs-image-box-1
        img
            max-width: 624px
            width: 100%
    .cs-sharecar-btn1
        button
            max-width: 310px
            width: 100%
            margin: 0 auto
    .cs-arabani-pay-bry-1-1-main
        display: flex
        align-items: flex-start
        justify-content: center
        .cs-step-line-size
            max-width: 200px
            width: 100%
        .cs-global-document-upload-1,
        .cs-rentals-info-comp-1,
        .cs-car-file-input-main
            width: 100%
            .cs-cars-upload-list-1
                .cscul1-col-1
                    flex: 0 0 33.3%
                    max-width: 33.3%
                    min-height: 1px
                    padding-left: 7px
                    padding-right: 7px
                    position: relative
                    width: 100%
            .cs-iwb-head-1
                color: variables.$primaryColor
    .cs-buttons
        display: grid
        grid-template-columns: repeat(3,1fr)
        gap: 10px
        justify-self: center
        align-self: center
        max-width: 370px
        width: 100%
        label
            cursor: pointer
            margin: 0 !important
        input
            display: none !important
        .cs-buttons-item
            border: 1px solid variables.$color20
            padding: 10px 5px
            border-radius: variables.$radius5
            display: flex
            justify-content: center
            color: variables.$color7
            font-size: 16px
            line-height: 24px
            cursor: pointer
            &.active
                background-color: variables.$primaryColor
                color: variables.$whiteColor
                border: 1px solid transparent
                label
                    color: variables.$whiteColor
    .cs-textbox-1
        align-items: center
        display: flex
        flex-direction: column
        margin: 0 auto
        max-width: 450px
        width: 100%
        gap: 15px
        .cs-title1
            font-size: 16px
            line-height: 24px
            color: variables.$color29
            font-weight: variables.$fontWeightSemiBold
        .cs-sharecar-textbox
            border-bottom: 1px solid variables.$borderColor
            padding-bottom: 20px
            margin-top: 30px
.cs-primary-step-line-1
    width: 100%
    height: 1px
    background-color: variables.$color204
    position: relative
    .cs-step-line-item-1
        width: 18px
        height: 18px
        background-color: variables.$color204
        border-radius: variables.$radius202
        position: absolute
        transform: translateY(-8px)
        left: 50%
        &:first-child
            left: 0
        &:last-child
            left: 100%
        &:nth-child(2)
            left: 25%
        &:nth-child(4)
            left: 75%
        &:nth-child(3)
            left: 50%
        &.active
            background-color: variables.$primaryColor
            border: 3px solid variables.$color205
#sharecar-modal1
    .modal-content
        border-radius: variables.$radius3
    .modal-header
        border: none !important
    .cs-sharecar-main
        display: flex
        flex-direction: column
        align-items: center
        gap: 20px
        .cs-alert
            color: variables.$color100
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightBold
        .cs-desc1
            color: variables.$color20
            font-size: 16px
            line-height: 24px
            text-align: center
        .cs-sharecar-btn-group
            display: flex
            justify-content: center
            align-items: center
            gap: 20px
// primary checkbox start
.cs-primary-check-1
    margin-bottom: 20px
    .form-check-input
        width: 22px
        height: 22px !important
        border: 1px solid variables.$color207
        accent-color: variables.$primaryColor
        border-radius: variables.$radius13
    .form-check-label
        margin-left: 10px
        font-size: 14px
        line-height: 27px
        cursor: pointer
        color: variables.$color11
        a
            text-decoration: underline !important
            color: variables.$color11
// primary checkbox end
#insurance_policy
    .modal-dialog
        max-width: 610px
        width: 100%
        .modal-content
            border-radius: variables.$radius3
        .cs-modal-title1
            color: variables.$color100
            text-align: center
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightBold
        .cs-modal-desc1
            font-size: 16px
            line-height: 24px
            color: variables.$color100
        .cs-modal-textbox
            height: 445px
            overflow: hidden
            overflow-y: auto
            margin-bottom: 30px
            padding: 10px 20px
            &::-webkit-scrollbar-track
                border-radius: variables.$radius2
                background-color: variables.$whiteColor
            &::-webkit-scrollbar
                width: 4px
            &::-webkit-scrollbar-thumb
                border-radius: variables.$radius2
                background-color: variables.$color208
    .cs-globe-dd-1
        display: flex
        justify-content: center
        .cs-header-btn-3
            max-width: 320px
            width: 100%
            padding: 5px 20px
            .ds-in-1
                display: flex
                justify-content: space-between
                width: 100%
.cs-giveupbtn
    color: variables.$color11
    background-color: variables.$whiteColor
    font-size: 18px
    line-height: 26px
    font-weight: variables.$fontWeightBold
    align-items: center
    border: 0
    border-radius: variables.$radius5
    display: inline-flex
    font-size: 16px
    justify-content: center
    letter-spacing: .2px
    line-height: 22px
    min-height: 44px
    min-width: 145px
    padding: 5px 20px
    text-align: center
    cursor: pointer
.cs-sharecar-modal-1
    #cs-dd-modal-text2
        width: 100%
        height: 100%
        .cs-file-img-2
            width: 100%
            height: 100%
            object-fit: contain
    .cs-trash-icon
        color: variables.$secondColor
        background: variables.$color209
        padding: 10px 12px
        border-radius: variables.$radius202
    .modal-footer
        justify-content: center !important
        padding: 0 !important
        border: none !important
    .cs-file-reset-btn-2
        background: none
        border: none
    .cs-sharecarmodal-footer
        display: none
        flex-direction: column
        .cs-modal-buttons
            display: flex
            .cs-yesbtn
                background-color: variables.$primaryColor
                &:hover
                    box-shadow: 0 4px 6px 0 rgba(39, 193, 178,.65)
                    color: variables.$whiteColor
#form_city_1,#mission_start_location,#mission_end_location,#mission_location
    border: none
    padding: 0.375rem 0.75rem
    font-size: 16px
    line-height: 24px
    color: variables.$color7
    background-color: variables.$whiteColor
    &::placeholder
        color: variables.$color7
        opacity: 0.5
    &:focus
        border: none
        outline: none
        box-shadow: none
.cs-location-search-area
    opacity: 1
    visibility: visible
    transition: all 0.5s
    height: auto
    &.deactive
        opacity: 0
        height: 0
        visibility: hidden
        transition: all 0.5s
.cs-location-icondiv
    align-items: center
    border: 0.5px solid variables.$color20
    border-radius: variables.$radius5
    display: block
    display: flex
    font-size: 1.25rem
    padding: 0 25px
    width: 100%
    input
        border: none
        &:focus
            box-shadow: none !important
    i
        color: variables.$color3
        font-weight: variables.$fontWeightBlack
        font-size: 20px
.cs-syc-sec1
    .cs-syc-sec-1-title
        font-size: 35px
        line-height: 43px
        color: variables.$color7
        font-weight: variables.$fontWeightBold
        max-width: 540px
        span
            color: variables.$secondColor
    .cs-syc-1-main
        align-items: flex-start !important
        justify-content: space-between
        margin: 0
        .cs-start-btn
            display: flex
            align-items: flex-end
            margin-bottom: 16px
            position: relative
            .js-choice-box
                z-index: 2
                width: max-content
                position: absolute
                top: 0
                display: flex
                flex-direction: column
                gap: 5px
                opacity: 1
                right: 15px
            .dropdown
                width: 100%
                .dropdown-menu
                    .dropdown-item
                        &:active
                            background-color: variables.$primaryColor
            .cs-start-dropdown
                &::after
                    content: none

        .cs-syc-infobox
            border: 1px solid variables.$color213
            padding: 20px 45px
            border-radius: variables.$radius201
            margin-top: 20px
            margin-bottom: 30px
            background-color: variables.$color13
            display: flex
            justify-content: center
            .cs-info-direction
                position: relative
                width: 100%
                .cs-info-message
                    position: absolute
                    width: 200px
                    height: auto
                    border-radius: variables.$radius11
                    background: variables.$whiteColor
                    bottom: -135px
                    box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2)
                    right: -150px
                    width: 200px
                    padding: 10px
                    opacity: 0
                    visibility: hidden
                    transition: all 0.5s
                    z-index: 3
                    span
                        font-size: 14px
                        line-height: 14px
                        text-wrap: wrap
                &:hover
                    .cs-info-message
                        position: absolute
                        width: 200px
                        height: auto
                        border-radius: variables.$radius11
                        background: variables.$whiteColor
                        bottom: -135px
                        box-shadow: 0 7px 29px 0 hsla(240,5%,41%,.2)
                        right: -150px
                        width: 200px
                        padding: 10px
                        opacity: 1
                        visibility: visible
                        transition: all 0.5s
                        z-index: 3
                        span
                            font-size: 14px
                            line-height: 14px
                            text-wrap: wrap

            .cs-title-1
                font-size: 43px
                line-height: 51px
                color: variables.$color7
                font-weight: variables.$fontWeightExtraBold
                display: flex
                align-items: center
                gap: 10px
                margin: 0
                white-space: nowrap
            .cs-title-2
                font-size: 20px
                line-height: 28px
                color: variables.$color7
                font-weight: variables.$fontWeightSemiBold
                display: flex
                align-items: center
                gap: 10px
                margin: 0
                i
                    color: variables.$color3
                    font-weight: variables.$fontWeightBlack
                    font-size: 20px
        .cs-syc-1-formbox
            padding-left: 8.5%
            padding-top: 10px
        .cs-syc-1-imgbox
            position: relative
            display: flex
            justify-content: flex-end
            padding-right: 0
            .cs-main-img
                max-width: 770px
                width: 100%
                height: 550px
                object-fit: cover
            .cs-right-car-img
                position: absolute
                bottom: 10px
                right: 8.5%
                max-width: 736px
                width: 100%
.cs-syc-sec-2
    height: 230px
    width: 100%
    background-color: variables.$color210
    .container
        height: 100%
    .cs-syc-2-banner
        display: grid
        grid-template-columns: repeat(5,1fr)
        align-items: center
        height: 100%
        .cs-syc-2-banner-content
            display: flex
            flex-direction: column
            align-items: center
            justify-items: space-between
            gap: 5px
            .cs-img-banner-1
                max-width: 255px
                width: 100%
            .cs-title-banner-1
                font-size: 56px
                line-height: 54px
                color: variables.$secondColor
                text-align: center
                font-weight: variables.$fontWeightExtraBold
            .cs-desc-banner-1
                font-size: 30px
                line-height: 38px
                color: variables.$color7
                font-weight: variables.$fontWeightSemiBold
                text-align: center
                width: 100%
.cs-syc-sec-3
    margin: 50px 0
    .cs-button-group
        display: flex
        justify-content: center
        gap: 20px
        margin-top: 45px
    .cs-syc-3-box-main
        display: grid
        grid-template-columns: repeat(4,1fr)
        justify-items: space-between
        align-items: center
        gap: 25px
        .cs-syc-3-box-content
            display: flex
            flex-direction: column
            align-items: center
            border-radius: variables.$radius7
            border: 1px solid variables.$borderColor
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
            height: 340px
            justify-content: center
            padding: 0 20px
            .cs-syc-sec3-title
                color: variables.$color11
                font-size: 20px
                line-height: 28px
                font-weight: variables.$fontWeightBold
                text-align: center
                margin: 20px 0
            .cs-syc-sec3-desc
                color: variables.$color11
                font-size: 18px
                line-height: 26px
                text-align: center
.cs-primary-outline-btn-1
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    min-height: 44px
    background-color: variables.$color13
    border-radius: variables.$radius5
    min-width: 145px
    font-size: 16px
    line-height: 22px
    letter-spacing: .2px
    font-weight: variables.$fontWeightBold
    color: variables.$primaryColor
    border: 1px solid variables.$primaryColor
    padding: 5px 20px
    transition: all .3s ease
    i
        padding:
            left: 10px
    &:hover
        color: variables.$whiteColor
        background-color: variables.$primaryColor
.cs-syc-sec-4
    background-color: variables.$color13
    height: 510px
    margin: 50px 0
    .container
        height: 100%
    .cs-syc-sec4-main
        display: flex
        width: 100%
        height: 100%
        justify-content: space-between
        align-items: flex-end
        position: relative
        .cs-sys-sec4-content1
            align-self: flex-start
        .cs-sys-sec4-content
            .cs-sec4-textbox1
                position: absolute
                left: 10%
                bottom: 20px
                .cs-wbg-head-2
                    font-size: 30px
                    line-height: 38px
                    margin-bottom: 20px
                    span
                        &::before
                            background-color: variables.$color3
                            height: 12px
                            opacity: 0.4
            .cs-sec4-textbox2
                position: absolute
                right: 10%
                max-width: 565px
                top: 25px
                .cs-wbg-head-2
                    font-size: 30px
                    line-height: 38px
                    margin-bottom: 20px
                    text-align: right
                    span
                        &::before
                            height: 12px
                .cs-sys-sec4-title
                    font-size: 22px
                    line-height: 30px
                    color: variables.$color7
                    text-align: right
            .cs-sec4-textbox1
                position: absolute
                left: 10%
                max-width: 565px
                .cs-wbg-head-2
                    font-size: 30px
                    line-height: 38px
                    margin-bottom: 20px
                    text-align: left
                    span
                        &::before
                            height: 12px
                .cs-sys-sec4-title
                    font-size: 22px
                    line-height: 30px
                    color: variables.$color7
                    text-align: left
.cs-p-vehicle-owner-main
    display: flex
    width: 100%
    justify-content: space-between
    border-radius: variables.$radius5
    border: 1px solid variables.$color200
    padding: 20px 16px
    margin: 35px 0
    background: variables.$whiteColor
    .cs-p-vehicle-textbox-1
        display: flex
        gap: 30px
        align-items: center
        .cs-profile-img
            width: 100px
            height: 100px
            object-fit: cover
            border-radius: variables.$radius202
        .fake-char-i-1
            display: flex
            height: 80px
            width: 80px
            font-size: 40px
            align-items: center
            justify-content: center
            border-radius: variables.$radius202
            padding: 1px
            background-color: variables.$secondColor
            color: variables.$whiteColor
    .cs-textarea
        .cs-name-1
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightBold
            color: variables.$color7
            .cs-super-vhc-owner
                font-size: 12px
                color: variables.$whiteColor
                font-weight: variables.$fontWeightBold
                background: variables.$secondColor
                background: linear-gradient(180deg, variables.$secondColor 0%, variables.$color214 100%)
                padding: 8px 20px
                margin-left: 30px
                border-radius: variables.$radius3
                i
                    margin-right: 10px
                    font-size: 16px
        .cs-location-phone-area
            display: flex
            gap: 25px
            .cs-text-info-1
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightSemiBold
                color: variables.$color7
                i
                    color: variables.$secondColor
                    background-color: variables.$color200
                    border-radius: variables.$radius202
                    padding: 10px
                    margin-right: 5px
                    font-size: 16px
    .cs-date-1
        display: flex
        gap: 20px
        .cs-text-1
            font-size: 16px
            line-height: 24px
            color: variables.$color20
            font-weight: variables.$fontWeightRegular
            margin-left: 3px
        .cs-text-2
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightBold
            color: variables.$color7
.cs-p-vehicle-arrangement
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center
    .cs-text-3
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightBold
        color: variables.$color7
        border-right: 1px solid variables.$secondColor
        margin-right: 20px
        padding-right: 20px
    .cs-text-4
        font-size: 18px
        line-height: 26px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$secondColor
        i
            margin-left: 5px
            color: variables.$secondColor
            font-size: 15px
    .cs-arrangement-button
        color: variables.$color7
        background-color: variables.$whiteColor
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        border: 1px solid variables.$borderColor
        border-radius: variables.$radius2
        padding: 12px
        padding-right: 30px
        box-shadow: variables.$color229 0px 8px 24px
        i
            margin-right: 5px
.cs-car-list-main
    margin: 20px 0 !important
    p
        margin: 0
    .cs-d-detailbox
        margin-bottom: 30px
    .cs-car-list-content
        border-radius: variables.$radius8
        box-shadow: variables.$color229 0px 2px 8px 0px
        .cs-car-list-content-1
            padding: 15px 15px 0 15px
        .cs-list-area-4
            width: 100%
            display: flex
            justify-content: center
            .cs-list-area-4-img
                width: 235px
                height: 125px
                object-fit: cover
        .cs-list-area-1
            display: flex
            align-items: center
            justify-content: space-between
            .like-btn-1
                font-size: 20px
                line-height: 1
                color: variables.$color20
                display: flex
                align-items: center
                justify-content: center
                width: 30px
                height: 30px
                border-radius: variables.$radius202
                background-color: variables.$whiteColor
                position: absolute
                z-index: 0
                right: 30px
                top: 18px
                &::before
                    @include short-code.bainIconCreate('\e940')
                    position: relative
                    top: 1px
                &.active
                    color: variables.$color40
                    &::before
                        @include short-code.bainIconCreate('\e923')
            .cs-text-1
                color: variables.$color7
                font-size: 18px
                line-height: 26px
                font-weight: variables.$fontWeightBold
            i
                color: variables.$color20
                font-size: 22px
        .cs-list-area-2
            font-size: 16px
            line-height: 24px
            color: variables.$color215
            font-weight: variables.$fontWeightSemiBold
        .cs-list-area-3
            font-size: 16px
            line-height: 24px
            position: relative
            padding-left: 18px
            &::before
                position: absolute
                left: 0
                top: 50%
                transform: translateY(-50%)
                width: 14px
                height: 14px
                border-radius: variables.$radius202
                content: ""
                display: block
        .cs-list-yellow
            color: variables.$color216
            &::before
                background: variables.$color216
        .cs-list-grey
            color: variables.$color217
            &::before
                background: variables.$color217
        .cs-list-green
            color: variables.$color218
            &::before
                background: variables.$color218
        .cs-list-area-5
            display: grid
            gap: 10px
            grid-template-columns: repeat(2,1fr)
            border-top: 1px solid variables.$borderColor
            padding: 15px 0
            i
                color: variables.$blackColor
                font-size: 25px
            .cs-text-5
                display: flex
                gap: 10px
                align-items: center
                font-size: 14px
                line-height: 22px
                color: variables.$color20
        .cs-list-area-6
            display: flex
            justify-content: center
            align-items: center
            height: 70px
            background-color: variables.$color219
            border-radius: 0 0 variables.$radius8 variables.$radius8
            .cs-text-6
                font-size: 20px
                line-height: 28px
                color: variables.$color7
                font-weight: variables.$fontWeightBold
    .cs-primary-btn-1
        min-width: 310px
    .cs-current-past-mission-content
        display: flex
        flex-direction: column
        align-items: center
        margin: 50px 0
        gap: 30px
    .nav-tabs
        max-width: 326px
        margin: 0 auto
        display: flex
        justify-content: center
        border: none
        border-bottom: 1px solid variables.$color23
        padding-bottom: 15px
        z-index: 9
        .nav-link
            font-size: 16px
            line-height: 24px
            color: variables.$color7
            background-color: transparent
            border: none
            &.active
                color: variables.$secondColor
                font-weight: variables.$fontWeightBold
                background-color: transparent
                border: none
                position: relative
                &::after
                    background: variables.$secondColor
                    height: 8px
                    width: 100%
                    content: ""
                    position: absolute
                    left: 0
                    bottom: -18px
                    display: block
                    border-radius: variables.$radius1
.cs-profile-container-1
    max-width: 1010px
    margin: 0 auto
    padding: 0 15px
.cs-car-list-main-2
    margin: 30px 0 !important
    .cs-d-detailbox
        margin-bottom: 30px
    .cs-list-main-2
        padding: 15px
        border-radius: variables.$radius8
        box-shadow: variables.$color229 0px 8px 24px
        min-height: 355px
        max-height: 355px
        .cs-list-item-2-1
            display: flex
            justify-content: space-between
            width: 100%
            margin-bottom: 15px
            padding-bottom: 15px
            border-bottom: 1px solid variables.$borderColor
            .cs-pp-text-area
                display: flex
                gap: 15px
                align-items: center
                .cs-pp-img
                    width: 80px
                    height: 80px
                    object-fit: cover
                    border-radius: variables.$radius202
                .fake-char-i-1
                    display: flex
                    height: 80px
                    width: 80px
                    font-size: 40px
                    align-items: center
                    justify-content: center
                    border-radius: variables.$radius202
                    padding: 1px
                    background-color: variables.$secondColor
                    color: variables.$whiteColor
            .cs-profile-title-1
                font-size: 16px
                line-height: 24px
                color: variables.$color7
                font-weight: variables.$fontWeightBold
                max-width: 150px
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
            .cs-star-list
                display: flex
                gap: 3px
                i
                    color: variables.$color27
                    font-size: 17px
        .cs-list-item-2-2
            .cs-desc-1
                font-size: 16px
                line-height: 24px
                color: variables.$color20
                display: -webkit-box
                -webkit-line-clamp: 7
                -webkit-box-orient: vertical
                overflow: hidden
                text-overflow: ellipsis
            .cs-date-desc
                font-size: 14px
                line-height: 22px
                color: variables.$color20
    .cs-like-star
        i
            color: variables.$secondColor
            font-size: 20px
            padding: 6px
            background: variables.$color200
            border-radius: variables.$radius202
.cs-backtoprofile-1
    margin: 30px 0 10px 0
    padding-left: 10px
    display: flex
    &.deactive
        display: none
    .cs-btp-text
        font-size: 16px
        line-height: 24px
        color: variables.$color7
        font-weight: variables.$fontWeightSemiBold
    i
        margin-right: 8px
        font-size: 14px
.cs-p-vehicle-rating-1
    i
        color: variables.$color27
        font-size: 20px
    .cs-rate-text
        font-size: 20px
        line-height: 28px
        color: variables.$color7
        font-weight: variables.$fontWeightBold
.cs-vehicle-detail-main-1
    display: flex
    justify-content: space-between
    margin: 15px 0
    padding: 0 10px
    .cs-title-1
        font-size: 18px
        line-height: 26px
        color: variables.$color7
        font-weight: variables.$fontWeightBold
        margin-right: 20px
        padding-right: 20px
        border-right: 1px solid variables.$borderColor
    .cs-title-2,
    .cs-title-3
        font-size: 18px
        line-height: 26px
        color: variables.$color7
        font-weight: variables.$fontWeightSemiBold
.cs-car-reservation-calendar
    display: flex
    justify-content: space-between
    background-color: variables.$whiteColor
    padding: 30px
    box-shadow: variables.$color206 0px 7px 29px 0px
    border-radius: variables.$radius8
    align-items: center
    .cs-rc-left
        display: flex
        align-items: center
        gap: 30px
        .cs-rc-img
            width: 200px
            height: 85px
            object-fit: cover
        .cs-title-name
            font-size: 18px
            line-height: 26px
            color: variables.$color7
            font-weight: variables.$fontWeightSemiBold
            margin-bottom: 5px
        .cs-title-plate
            font-size: 16px
            line-height: 24px
            color: variables.$color215
    .cs-rc-right
        display: flex
        align-items: center
        gap: 20px
        .cs-title-price
            background-color: variables.$color219
            color: variables.$color7
            border-radius: variables.$radius8
            padding: 5px 30px
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightBold
        .cs-blue-disc
            width: 22px
            height: 22px
            background-color: variables.$color221
            border-radius: variables.$radius202
.cs-calendar-list
    margin: 25px 0
    .cs-calendar-list-area
        display: flex
        flex-direction: column
        width: 100%
        gap: 20px
        .cs-date-list
            font-size: 16px
            line-height: 24px
            color: variables.$color7
        .cs-cl-main
            padding: 12px
            border-radius: variables.$radius5
            background-color: variables.$whiteColor
            border: 1px solid variables.$color200
            .cs-cl-content-main
                padding: 15px 20px
                position: relative
                &::before
                    content: ""
                    height: 100%
                    width: 6px
                    display: block
                    background: variables.$whiteColor
                    border-radius: variables.$radius102
                    position: absolute
                    left: 0
                    top: 50%
                    transform: translateY(-50%)
                .cs-date-title-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color7
                    font-weight: variables.$fontWeightBold
                    margin-bottom: 15px
                    display: block
                .cs-date-list
                    span
                        font-size: 16px
                        line-height: 24px
                        color: variables.$color7
            .cs-cl-content
                padding: 15px 20px
                position: relative
                &::before
                    content: ""
                    height: 100%
                    width: 6px
                    display: block
                    background: variables.$color216
                    border-radius: variables.$radius102
                    position: absolute
                    left: 0
                    top: 50%
                    transform: translateY(-50%)
                .cs-date-title-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color7
                    font-weight: variables.$fontWeightBold
                    margin-bottom: 15px
                    display: block
                .cs-date-list
                    span
                        font-size: 16px
                        line-height: 24px
                        color: variables.$color7
            .cs-cl-content-2
                padding: 15px 20px
                position: relative
                &::before
                    content: ""
                    height: 100%
                    width: 6px
                    display: block
                    background: variables.$color4
                    border-radius: variables.$radius102
                    position: absolute
                    left: 0
                    top: 50%
                    transform: translateY(-50%)
                .cs-date-title-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color7
                    font-weight: variables.$fontWeightBold
                    margin-bottom: 15px
                    display: block
                .cs-date-list
                    span
                        font-size: 16px
                        line-height: 24px
                        color: variables.$color7
.cs-count-mission-main
    background-color: variables.$whiteColor
    padding: 5px 15px 5px 5px
    display: flex
    align-items: center
    gap: 5px
    box-shadow: variables.$color229 0px 8px 24px
    border-radius: variables.$radius8 0 0 variables.$radius8
    i
        font-size: 15px
        color: variables.$secondColor
        padding: 8px 10px
        background: variables.$color200
        border-radius: variables.$radius202
    .cs-title-mission
        color: variables.$color7
        font-size: 16px
        line-height: 24px
    .cs-result-mission
        color: variables.$color7
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
.cs-hire-main-area
    .nav-tabs
        max-width: 350px !important
        .nav-link
            &.active
                &::after
                    left: 0 !important
.cs-mission-main-area
    margin: 20px 0
    .cs-primary-btn-1
        min-width: 310px
    .cs-current-past-mission-content
        display: flex
        flex-direction: column
        align-items: center
        margin: 50px 0
        gap: 30px
    .nav-tabs
        max-width: 326px
        margin: 0 auto
        display: flex
        justify-content: center
        border: none
        border-bottom: 1px solid variables.$color23
        padding-bottom: 15px
        .nav-link
            font-size: 16px
            line-height: 24px
            color: variables.$color7
            background-color: transparent
            border: none
            &.active
                color: variables.$secondColor
                font-weight: variables.$fontWeightBold
                background-color: transparent
                border: none
                position: relative
                &::after
                    background: variables.$secondColor
                    height: 8px
                    width: 180px
                    content: ""
                    position: absolute
                    left: -13px
                    bottom: -18px
                    display: block
                    border-radius: variables.$radius1

    .cs-mission-message
        font-size: 18px
        line-height: 26px
        color: variables.$color7
        font-weight: variables.$fontWeightBold
.cs-create-mission-main
    padding: 30px 18%
    display: flex
    flex-direction: column
    align-items: center
    .cs-form-1
        .date-box-1
            label
                &:before
                    left: 28px
    .cs-primary-btn-1-local
        min-width: 320px
    label
        font-size: 16px
        line-height: 24px
        color: variables.$color29
        font-weight: variables.$fontWeightSemiBold
    input,.cs-location-icondiv,select
        border: 0.5px solid variables.$color20
        font-size: 16px !important
        border-radius: variables.$radius11
        background-color: variables.$whiteColor
        i
            color: variables.$color20
        &:focus
            box-shadow: none
            border: 0.5px solid variables.$color20
    #form_city_1, #mission_end_location, #mission_location, #mission_start_location
        font-size: 16px
    textarea
        border: 0.5px solid variables.$color20
        border-radius: variables.$radius11
        padding: 15px
        resize: none
        height: 80px
        color: variables.$color7
        opacity: 0.5
        &:focus
            box-shadow: none
            border: 0.5px solid variables.$color20
        &::placeholder
            color: variables.$color7
            opacity: 0.5
.cs-overlay-profile
    display: none
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.6)
    z-index: 11
    overflow: hidden
.cs-overlay-profile-2
    display: none
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    -webkit-backdrop-filter: saturate(200%) blur(5px)
    backdrop-filter: saturate(200%) blur(5px)
    background-color: rgba(255, 255, 255, 0.6)
    z-index: 11
    overflow: hidden
.cs-sorting-area-1
    position: relative
    flex: 1
    display: flex
    justify-content: flex-end
    .cs-sorting-modal-1,
    .cs-sorting-modal-2,
    .cs-sorting-modal-3,
    .cs-sorting-modal-4
        height: auto
        background: variables.$whiteColor
        z-index: 12
        position: absolute
        right: 0
        top: 50px
        display: none
        max-width: 320px
        width: 100%
        border-radius: variables.$radius11
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
        padding: 30px
        .cs-sort-btn-group
            display: flex
            justify-content: center
            gap: 5px
    .cs-location-modal-list
        padding: 0
        list-style: none
        display: flex
        flex-direction: column
        gap: 15px
        li
            border-bottom: 1px solid variables.$color222
            padding-bottom: 10px
            cursor: pointer
            a
                font-size: 16px
                line-height: 24px
                color: variables.$color9
            &.active
                position: relative
                &::after
                    position: absolute
                    right: -6px
                    top: 28%
                    transform: translateY(-50%)
                    @include short-code.bainIconCreate('\e947')
                    font-size: 17px
                    color: variables.$primaryColor
                a
                    color: variables.$color7
                    font-weight: variables.$fontWeightBold
                    display: inline-block
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap

.cs-start-location-modal,
.cs-end-location-modal,
.cs-location-modal
    height: auto
    background: variables.$whiteColor
    z-index: 12
    position: absolute
    top: 100%
    left: -10%
    display: none
    min-width: 425px
    width: 100%
    border-radius: variables.$radius11
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
    border: 1px solid variables.$color23
    padding: 30px
    .cs-location-modal-list
        padding: 0
        list-style: none
        display: flex
        flex-direction: column
        gap: 15px
        li
            border-bottom: 1px solid variables.$color222
            padding-bottom: 10px
            cursor: pointer
            a
                font-size: 16px
                line-height: 24px
                color: variables.$color9
            &.active
                position: relative
                &::after
                    position: absolute
                    right: -6px
                    top: 28%
                    transform: translateY(-50%)
                    @include short-code.bainIconCreate('\e947')
                    font-size: 17px
                    color: variables.$primaryColor
                a
                    color: variables.$color7
                    font-weight: variables.$fontWeightBold
                    display: inline-block
                    width: 340px
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap
.cs-map-modal-1,
.cs-map-modal-2,
.cs-map-modal-3
    max-width: 950px
    width: 95%
    padding: 25px 0
    height: auto
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
    border-radius: variables.$radius203
    display: none
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 13
    background-color: variables.$whiteColor
    opacity: 1
    visibility: visible
    &.active
        iframe
            height: 175px !important
            transition: all 0.5s
            margin: 0
    &.active2
        max-width: 600px
        iframe
            height: 0 !important
            transition: all 0.5s
            margin: 0
            opacity: 0
            visibility: hidden
    #cs-close-btn-1,
    #cs-close-btn-2,
    #cs-close-btn-3
        position: absolute
        top: 20px
        right: 20px
        text-decoration: none
        i
            color: variables.$color29
            font-size: 13px
    .cs-map-modal-title
        color: variables.$color100
        font-weight: variables.$fontWeightBold
        margin-bottom: 15px
        text-align: center
        opacity: 1
        visibility: visible
        height: auto
        &.deactive
            opacity: 0
            visibility: hidden
            height: 0
    .cs-location-icondiv
        max-width: 420px
        margin: 0 auto
    iframe
        width: 100%
        height: 450px
        margin: 20px 0
    .cs-footer-modal-area
        display: flex
        justify-content: center
        gap: 10px
        opacity: 1
        visibility: visible
        height: auto
        &.deactive
            opacity: 0
            visibility: hidden
            height: 0
    .cs-form-button-group
        display: flex
        justify-content: center
        gap: 10px
.cs-stop-sharing-text
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightBold
    color: variables.$color4
    text-decoration: underline !important
    &:hover
        text-decoration: underline !important
        color: variables.$color4
.cs-start-sharing-text
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightBold
    color: variables.$secondColor
    text-decoration: underline !important
    &:hover
        text-decoration: underline !important
        color: variables.$secondColor
.cs-car-detail-main-area
    margin: 30px 0 50px 0
    .cs-car-main
        display: grid
        grid-template-columns: repeat(6,1fr)
        gap: 20px
        .cs-car-content
            border-radius: variables.$radius5
            background: variables.$whiteColor
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
            display: flex
            flex-direction: column
            align-items: center
            padding: 30px 10px
            .cs-card-1-title
                font-size: 16px
                font-weight: variables.$fontWeightBold
                color: variables.$color11
                text-align: center
                margin-top: 10px
                margin-bottom: 0
.cs-form-1-2
    .form-group
        padding: 0 !important
    .date-box-1
        label
            &:before
                left: 134px !important
#stop_share_modal
    .cs-file-box-1
        .csfb1-head-1
            max-width: 300px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
    .modal-content
        width: 610px
        border-radius: variables.$radius8
    form
        width: 100%
        display: flex
        flex-direction: column
        gap: 15px
    .cs-padding-1
        width: 100%
        padding: 0 20% !important
    #reason-end-text
        height: 105px
    .modal-header
        border: none
        padding-bottom: 0
    .modal-body
        display: flex
        justify-content: center
        flex-direction: column
        padding: 0 10px 30px 10px
        align-items: center
        .cs-modal-title
            text-align: center
            color: variables.$color20
            font-size: 20px
            line-height: 28px
            font-weight: variables.$fontWeightBold
        .cs-modal-desc
            text-align: center
            color: variables.$color20
            font-size: 16px
            line-height: 24px
    .cs-modal-btn-group
        display: flex
        justify-content: center
        gap: 20px
        button
            flex: 1
.cs-info-box-red
    background: variables.$color223
    color: variables.$color4
    padding: 15px
    display: flex
    align-items: center
    gap: 15px
    margin-bottom: 25px
    .cs-info-box-red-text
        color: variables.$color4
        font-size: 16px
        line-height: 24px
        margin: 0
    i
        color: variables.$color4
        font-size: 27px
.cs-primary-radius-btn
    color: variables.$whiteColor
    background-color: variables.$secondColor
    border-radius: variables.$radius8
    padding: 8px 25px 8px 15px
    border: 1px solid transparent
    font-weight: variables.$fontWeightBold
    transition: all 0.5s
    display: flex
    align-items: center
    width: fit-content
    margin: 0 auto
    max-width: 275px
    width: 100%
    &:hover
        color: variables.$secondColor
        background-color: variables.$whiteColor
        border: 1px solid variables.$secondColor
        transition: all 0.5s
.cs-green-radius-btn
    color: variables.$whiteColor
    background-color: variables.$color38
    border-radius: variables.$radius8
    padding: 8px 25px 8px 15px
    border: 1px solid transparent
    font-weight: variables.$fontWeightBold
    transition: all 0.5s
    align-items: center
    margin: 0 auto
    max-width: 275px
    width: 100%
    display: flex
    justify-content: space-between
    white-space: nowrap

    span
        justify-self: center
        margin-right: calc( 50% - 40px ) !important
    &:hover
        color: variables.$color38
        background-color: variables.$whiteColor
        border: 1px solid variables.$color38
        transition: all 0.5s
.cs-yellow-radius-btn
    color: variables.$whiteColor
    background-color: variables.$color224
    border-radius: variables.$radius8
    padding: 8px 25px 8px 15px
    border: 1px solid transparent
    font-weight: variables.$fontWeightBold
    transition: all 0.5s
    align-items: center
    margin: 0 auto
    max-width: 275px
    width: 100%
    display: flex
    justify-content: space-between
    white-space: nowrap
    &:hover
        color: variables.$color224
        background-color: variables.$whiteColor
        border: 1px solid variables.$color224
        transition: all 0.5s
.cs-rent-list-2
    padding: 15px 0 0 0 !important
    ul
        margin: 15px
        li
            padding: 10px 0 !important
            &:last-child
                border: none !important
.cs-rent-list
    background: variables.$whiteColor
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
    border-radius: variables.$radius8
    padding: 15px
    display: flex
    flex-direction: column
    ul
        list-style: none
        padding: 0
        .cs-rent-content
            display: flex
            justify-content: space-between
            padding: 15px 0
            border-bottom: 1px solid variables.$borderColor
            .cs-rent-title
                font-size: 16px
                line-height: 24px
                color: variables.$color36
            .cs-rent-desc
                font-size: 16px
                line-height: 24px
                color: variables.$color7
                font-weight: variables.$fontWeightSemiBold
.cs-rent-end-area-1
    background-color: variables.$color225
    border-radius: 0 0 variables.$radius8 variables.$radius8
    display: flex
    justify-content: center
    padding: 15px
    span
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color11
        position: relative
        &::before
            content: ""
            position: absolute
            display: block
            left: -35px
            top: 50%
            transform: translateY(-50%)
            background-color: variables.$color226
            border-radius: variables.$radius202
            width: 22px
            height: 22px

.cs-rent-end-area-2
    background-color: variables.$color223
    border-radius: 0 0 variables.$radius8 variables.$radius8
    display: flex
    justify-content: center
    padding: 15px
    span
        position: relative
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color4
        &::before
            content: ""
            position: absolute
            display: block
            left: -35px
            top: 50%
            transform: translateY(-50%)
            background-color: variables.$color4
            border-radius: variables.$radius202
            width: 22px
            height: 22px
.cs-rent-list-pay
    .cs-pay-info-buttons
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        gap: 20px
        button
            margin: 0 !important
        .cs-pay-info-text-1
            display: flex
            align-items: center
            span
                font-size: 16px
                line-height: 24px
                color: variables.$color11
            i
                color: variables.$color3
                font-size: 22px
.cs-pay-info-area
    padding: 0 15px
    .cs-pay-info-title
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightBold
        margin: 30px 0 10px 0
    ul
        padding: 0
        width: 100%
        list-style: none
        .cs-rent-content
            display: flex
            justify-content: space-between
            width: 100%
            padding: 5px 0
            font-size: 16px
            line-height: 24px
            font-weight: variables.$fontWeightSemiBold
            &:last-child
                font-weight: variables.$fontWeightBold

    .cs-dotted-line
        width: 100%
        padding-bottom: 15px
        margin-bottom: 15px
        display: block
        border-bottom: 1px dashed variables.$color20
.cs-completed-disc-1
    position: relative
    color: variables.$color227
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    padding-left: 30px
    &::before
        content: ""
        display: block
        position: absolute
        left: 0px
        top: 50%
        transform: translateY(-50%)
        width: 22px
        height: 22px
        border-radius: variables.$radius202
        background: variables.$color226
.cs-cancelled-disc-1
    position: relative
    color: variables.$color103
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    padding-left: 30px
    &::before
        content: ""
        display: block
        position: absolute
        left: 0px
        top: 50%
        transform: translateY(-50%)
        width: 22px
        height: 22px
        border-radius: variables.$radius202
        background: variables.$color103
.cs-in-usage-now-disc-1
    position: relative
    color: variables.$secondColor
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    padding-left: 30px
    &::before
        content: ""
        display: block
        position: absolute
        left: 0px
        top: 50%
        transform: translateY(-50%)
        width: 22px
        height: 22px
        border-radius: variables.$radius202
        background: variables.$secondColor
.cs-pay-info-area-2
    .cs-pay-info-area-3
        margin: 30px 0
        background: variables.$color13
        padding: 15px 30px
    .nav-tabs
        border-bottom: 1px solid variables.$color23
        background-color: variables.$whiteColor
        gap: 20px
        width: fit-content !important
        .nav-item
            .nav-link
                border: none
                background: transparent
                &.active
                    border: none
                    position: relative
                    background: transparent
                    &::after
                        content: ""
                        width: 80px
                        position: absolute
                        left: 5px
                        bottom: -3px
                        display: block
                        height: 6px
                        background-color: variables.$primaryColor
                        border-radius: variables.$radius13
.cs-main-comment-area-1
    display: flex
    align-items: center
    gap: 10px
    i
        color: variables.$secondColor
        font-size: 22px
        background: variables.$color200
        padding: 5px
        border-radius: variables.$radius202
    .cs-comment-area-text-1
        font-size: 16px
        line-height: 24px
        color: variables.$secondColor
        font-weight: variables.$fontWeightBold
        margin: 0
        text-decoration: underline
.cs-main-comment-area-2
    padding: 20px
    border-radius: variables.$radius8
    border: 1px solid variables.$color222
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
    display: flex
    gap: 20px
    margin: 20px 0
    .cs-comment-img-1
        width: 60px
        height: 60px
        border-radius: variables.$radius202
        object-fit: cover
    .cs-star-name-text
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        margin-bottom: 5px
        .cs-comment-name-1
            font-size: 16px
            line-height: 24px
            color: variables.$color7
            font-weight: variables.$fontWeightBold
        i
            font-size: 18px
            color: variables.$color27
        .cs-comment-date-text-1
            font-size: 14px
            color: variables.$color20
    .cs-comment-descr-1
        color: variables.$color20
        font-size: 16px
        line-height: 24px
.cs-rent-bill-main-1
    margin: 30px 0
    .cs-rent-bill-content
        border-radius: variables.$radius5
        border: 1px solid variables.$color200
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
        .cs-textbox-1
            width: 100%
            display: flex
            justify-content: space-between
            align-items: center
            border-bottom: 1px solid variables.$borderColor
            padding: 25px 20px
            i
                font-size: 20px
                color: variables.$color20
                margin-left: 10px
            .cs-title-1
                font-size: 16px
                line-height: 24px
                color: variables.$color7
                font-weight: variables.$fontWeightBold
                margin: 0
        .cs-rent-list-1
            padding: 25px 20px 0 20px
            li
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                margin-bottom: 25px
                .cs-value-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color11
                .cs-response-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color11
                    font-weight: variables.$fontWeightSemiBold
.cs-rent-bill-btn-1
    max-width: 320px
    width: 100%

.cs-apply-form-btn,
.cs-apply-form-btn3,
.cs-apply-form-btn-2
    cursor: not-allowed
    pointer-events: none
    max-width: 320px
    width: 100%
    opacity: 0.5
    &.active
        cursor: pointer
        pointer-events: visible !important
        opacity: 1 !important

.cs-rent-bill-main-2
    .cs-title-1
        width: 100%
        display: flex
        justify-content: center
        font-size: 16px
        line-height: 24px
        color: variables.$color7
        font-weight: variables.$fontWeightBold
        border-bottom: 1px solid variables.$borderColor
        margin: 20px 0
        padding-bottom: 10px
    .cs-rent-content-1
        display: flex
        flex-wrap: wrap
        justify-content: center
        max-width: 630px
        width: 100%
        margin: 0 auto
        .cs-content-item
            max-width: 105px
            cursor: pointer
            padding: 15px 25px
            border: 1px solid variables.$color20
            border-radius: variables.$radius5
            display: flex
            justify-content: center
            align-items: center
            font-size: 16px
            line-height: 24px
            color: variables.$color7
            transition: all 0.5s
            &.active
                background: variables.$primaryColor
                color: variables.$whiteColor
                border: 1px solid transparent
                transition: all 0.5s
    .cs-rent-content-2
        max-width: 325px
        width: 100%
        margin: 30px auto
        .cs-rent-list-2
            padding: 0
            list-style: none
            li
                width: 100%
                padding: 15px 0
                border-bottom: 1px solid variables.$borderColor
                display: flex
                justify-content: space-between
        label
            font-weight: variables.$fontWeightSemiBold
        #form_bill_1
            align-items: center
            border: 0.5px solid variables.$color11
            border-radius: variables.$radius11
            color: variables.$color7
            display: flex
            font-size: 16px
            font-weight: 400
            height: 50px
            line-height: 24px
            padding: 5px 20px
            transition: all .2s ease
.cs-continue-modal-content
    max-width: 320px
    width: 100%
    margin: 0 auto
    opacity: 0
    height: 0
    visibility: hidden
    &.active
        opacity: 1
        visibility: visible
        height: auto
.cs-accept-modal-content
    display: flex
    flex-direction: column
    align-items: center
    opacity: 0
    visibility: hidden
    transition: all 0.5s
    height: 0
    gap: 15px
    .cs-accept-modal-title
        font-size: 20px
        line-height: 28px
        color: variables.$color100
        font-weight: variables.$fontWeightBold
    .cs-accept-modal-desc
        font-size: 16px
        line-height: 24px
        color: variables.$color20
        font-weight: variables.$fontWeightSemiBold
        max-width: 330px
        width: 100%
        text-align: center
    &.active
        opacity: 1
        visibility: visible
        transition: all 0.5s
        height: auto
.cs-modal-iframe
    opacity: 1
    visibility: visible
    height: auto
    &.deactive
        opacity: 0
        visibility: hidden
        height: 0 !important
#form_description_1
    height: 100px
    resize: none
.cs-profile-detail-wp-sec-1
    display: none
    flex-direction: column
    &.active
        display: flex
.cs-profile-wp-title
    font-size: 18px
    line-height: 26px
    font-weight: variables.$fontWeightBold
    color: variables.$color7
    margin-bottom: 25px
.cs-profile-detail-wp-sec-2
    align-items: center
    width: 100%
    flex-direction: column
    display: none
    &.active
        display: flex
        transition: all .5s
    .cs-templ-area-1
        padding: 0
    .cs-continue-btn-1
        max-width: 310px
        width: 100%
        margin: 50px 0 !important
        cursor: not-allowed
        max-width: 320px
        opacity: .5
        pointer-events: none
        &.active
            cursor: pointer
            opacity: 1!important
            pointer-events: visible!important
    .cs-checkbox-1
        font-size: 16px
        line-height: 24px
        color: variables.$color11
        font-weight: variables.$fontWeightSemiBold
    .cs-profile-detail-wp-left
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
        background-color: variables.$whiteColor
        border-radius: variables.$radius8
        padding: 25px 75px
        height: 100%
        form
            max-width: 320px
            width: 100%
            margin: 0 auto
    .cs-profile-detail-wp-right
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
        background-color: variables.$color13
        border-radius: variables.$radius8
        padding: 25px 0
        height: 100%
    .cs-table-check
        margin: 0 !important
        border: 1px solid variables.$primaryColor
        border-radius: variables.$radius13
        width: 22px
        height: 22px !important
        accent-color: variables.$color228
    .cs-table-check-1
        margin: 0 !important
        font-size: 16px
        line-height: 24px
        color: variables.$color11
        font-weight: variables.$fontWeightSemiBold
        cursor: pointer
    .cs-continue-btn-1
        max-width: 310px
        margin: 0 auto
.cs-profile-detail-wp-sec-3
    align-items: center
    width: 100%
    flex-direction: column
    &.active
        display: flex
        transition: all .5s
    .cs-sec-3-container
        max-width: 320px
        width: 100%
        margin: 0 auto
    .cs-continue-btn-2
        max-width: 310px
        width: 100%
        margin: 25px 0 50px 0 !important
        opacity: .5
        pointer-events: none
        &.active
            cursor: pointer
            opacity: 1!important
            pointer-events: visible!important
    .date-box-1
        label
            &:before
                bottom: 11px !important
                left: 22px !important
    .cs-work-plan-input
        accent-color: variables.$color228 !important
        border: 1px solid variables.$primaryColor !important
        border-radius: variables.$radius13 !important
        height: 22px !important
        margin: 0!important
        width: 22px !important
        &:focus
            box-shadow: none !important
        &:active
            box-shadow: none !important
    .cs-work-plan-label
        padding-left: 35px
    .cs-work-plan-container
        max-width: 320px
        width: 100%
        margin: 0 auto
.cs-profile-detail-wp-sec-4
    align-items: center
    width: 100%
    flex-direction: column
    display: none
    padding: 0 15px
    &.active
        display: flex
    .cs-template-btn-1
        max-width: 310px
        width: 100%
        margin: 50px 0 !important
    .cs-pd4-main
        display: grid
        grid-template-columns: repeat(2,1fr)
        gap: 30px
        width: 100%
        margin-bottom: 30px
        .cs-pd4-item
            display: flex
            justify-content: space-between
            align-items: center
            padding: 20px
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
            border-radius: variables.$radius5
            .cs-edit-delete-btn-group
                display: flex
                flex-direction: column
                .cs-editbtn
                    color: variables.$color20
            .cs-title-1
                color: variables.$color7
                font-size: 16px
                line-height: 24px
                font-weight: variables.$fontWeightBold
                i
                    color: variables.$secondColor
                    font-size: 20px
.cs-profile-detail-wp-table
    .cs-table-btn
        i
            font-size: 22px
            color: variables.$color201
    td
        border: none !important
    thead
        th,tr
            border: none !important
            text-align: center
    tbody
        tr,th
            border: none !important
            text-align: center
.cs-back-btn
    color: variables.$color7
    font-size: 16px
    line-height: 24px
    font-weight: variables.$fontWeightSemiBold
    &:hover
        color: variables.$secondColor
.cs-item-yellow
    color: variables.$color34
    font-size: 27px
// .cs-item-bg-yellow
//     background: variables.$color34
.cs-item-red
    color: variables.$color4
    font-size: 27px
.cs-item-bg-red
    background: variables.$color223
.cs-item-bg-yellow
    background: variables.$color216
.cs-item-bg-blue
    background: variables.$color201
.cs-item-blue
    color: variables.$color201
    font-size: 27px
// .cs-item-bg-blue
//     background: variables.$color201
.cs-item-green
    color: variables.$color218
    font-size: 27px
.cs-item-bg-green
    background: variables.$color225
.cs-mission-list-count
    display: flex
    justify-content: center
    align-items: center
    height: 70px
    border-radius: variables.$radius11
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
    .cs-mission-list-item
        flex: 1
        display: flex
        gap: 5px
        align-items: center
        position: relative
        justify-content: center
        width: 100%
        height: 100%
        &.active
            background: variables.$color16
        &::before
            position: absolute
            content: ""
            display: block
            background: variables.$color20
            height: 24px
            width: 1px
            right: 0
            top: 50%
            transform: translate(-50%,-50%)
        &:last-child
            &::before
                content: none
        .cs-item-title-1
            margin: 0
            font-size: 16px
            line-height: 24px
            color: variables.$color11
        .cs-item-title-2
            margin: 0
            font-size: 16px
            line-height: 24px
            color: variables.$color11
            font-weight: variables.$fontWeightBold
.cs-mission-choise-1
    display: flex
    justify-content: flex-start
    align-items: center
    max-width: 650px
    width: 100%
    overflow: hidden
    overflow-x: auto
    gap: 10px
    padding-bottom: 15px
    &::-webkit-scrollbar-track
        border-radius: variables.$radius2
        background-color: variables.$color10
    &::-webkit-scrollbar
        height: 2px
    &::-webkit-scrollbar-thumb
        border-radius: variables.$radius2
        background-color: variables.$color3
    .cs-content-item
        border: 1px solid variables.$color20
        color: variables.$color20
        font-size: 16px
        line-height: 24px
        border-radius: variables.$radius2
        padding: 8px 15px
        white-space: nowrap
        &.active
            border: 1px solid variables.$secondColor
            color: variables.$secondColor
            font-weight: variables.$fontWeightSemiBold
            background-color: variables.$color200
.cs-mission-choise-main-1
    display: grid
    grid-template-columns: repeat(2,1fr)
    gap: 25px
    width: 100%
    padding: 0 15px
    .cs-mission-choise-list-1
        width: 100%
        border-radius: variables.$radius5
        border: 1px solid variables.$color200
        background-color: variables.$whiteColor
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
        display: flex
        align-items: center
        padding-right: 25px
        .cs-item-left
            height: 100%
            // border-right: 1px solid variables.$color200
            padding: 25px
            display: flex
            justify-content: center
            align-items: center
            position: relative
            border-radius: variables.$radius5 0 0 variables.$radius5
            &::after
                position: absolute
                right: 0
                height: 100%
                content: ""
                top: 0
                background: variables.$color200
                display: block
                width: 1px
        .cs-list
            list-style: none
            padding: 0
            width: 100%
            padding: 25px
            padding-left: 15px
            li
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                margin-bottom: 10px
                .cs-title-1
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color20
                .cs-title-2
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color7
                    font-weight: variables.$fontWeightBold
                .cs-title-3
                    font-size: 16px
                    line-height: 24px
                    color: variables.$color7
                    font-weight: variables.$fontWeightSemiBold
.cs-cd-sec-1,.cs-cd-missions-1
    display: flex
    flex-direction: column
    &.deactive
        display: none !important
.cs-cd-sec-2,
.cs-cd-sec-3,
.cs-cd-missions-2,
.cs-stc-sec-2

    &.active
        display: flex
        flex-direction: column

.cs-form-cdm
    .date-box-1
        label
            &:before
                left: 18px
#damage_area_modal,#car-photo-add-modal
    .cs-damage-btn-group
        margin: 30px auto
        max-width: 310px
    .modal-header
        padding-bottom: 0 !important
        border-bottom: 0 !important
    .modal-body
        padding-top: 0 !important
    .modal-footer
        justify-content: center
    .modal-content
        min-width: 610px
        width: max-content
        border-radius: variables.$radius3
    .modal-dialog
        max-width: 610px
        margin: 0 auto
        padding: 15px
    #damage_area_modal_label
        color: variables.$color100
        font-size: 20px
        line-height: 28px
        font-weight: variables.$fontWeightBold
        text-align: center
        margin-bottom: 15px
    .cs-hasar-bolgesi-main
        background-size: cover
        max-width: 410px
        width: 100%
        height: 652px
        background-repeat: no-repeat
        display: flex
        flex-wrap: wrap
        border: 1px solid black
        margin: 0 auto
        .form-check
            padding: 0
            width: 30%
            border-bottom: 1px solid variables.$secondColor
            &:nth-child(3n+2)
                width: 40% !important
                border-top: none
                border-right: 1px solid variables.$secondColor
                border-left: 1px solid variables.$secondColor
                border-bottom: 1px solid variables.$secondColor
            &:nth-child(n+7)
                border-bottom: none
            .cs-choose-check
                display: none
            label
                height: 100%
                width: 100%
                cursor: pointer
            .form-check-input
                display: none
                cursor: pointer
                &:checked
                    &+label
                        background: rgba(39, 193, 178, 0.5)
                        background: linear-gradient(180deg, rgba(39, 193, 178, 0.5) 0%, rgba(40, 180, 181, 0.5) 19%, rgba(46, 147, 192, 0.5) 38%, rgba(54, 94, 209, 0.5) 71%, rgba(64, 31, 229, 0.5) 100%)
                        .cs-choose-check
                            display: block
                            display: flex
                            flex-direction: column
                            align-items: center
                            justify-content: center
                            position: absolute
                            left: 50%
                            top: 65%
                            transform: translate(-50%,-50%)
                            .cs-cc-text
                                font-weight: variables.$fontWeightBold
                                font-size: 16px
                                line-height: 24px
                                color: variables.$whiteColor
.js-next:not(.active)
    display: none !important
.csfb1-pdf-d-2
    border: 0.5px solid variables.$borderColor
    border-radius: variables.$radius12
    min-height: 40px
    background-position: center !important
    background-repeat: no-repeat !important
    background-size: cover !important
    canvas
        width: 100%
        border-radius: variables.$radius12
.cs-file-box-2
    .form-control
        &:disabled, &:read-only
            background-color: variables.$whiteColor
            cursor: not-allowed
            opacity: 0 !important
            pointer-events: none
.cs-wbg-head-second
    display: none !important
    &.active
        display: flex !important
.js-back-btn
    display: none !important
    margin-bottom: 20px
    color: variables.$primaryColor
    &.active
        display: flex !important
.cs-car-btn-group
    display: none !important
    &.active
        display: flex !important
.cs-damage-photos-main
    display: flex
    justify-content: center
    gap: 20px
    .cs-damage-p-left
        flex: 1
        .cs-g-file-main
            width: 400px
            height: 370px
            margin: 0 !important
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            input
                height: 100%
                width: 100%
    .cs-damage-p-right
        flex: 1
        display: grid
        gap: 20px
        grid-template-columns: repeat(2,1fr)
        .cs-upload-container-1
            .csuc1-desc-1
                font-size: 45px !important
        .cs-g-file-main
            width: 190px
            height: 175px
            margin: 0 !important
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            input
                height: 100%
                width: 100%
.cs-add-more-car,.cs-add-more-car-2
    display: none
    &.active
        display: flex
        justify-content: flex-end
        align-items: baseline
        margin-top: 10px
.cs-car-photo-list
    width: 100%
    display: grid
    grid-template-columns: repeat(3,1fr)
    gap: 15px
    .cs-list-item
        background-size: cover
        border-radius: variables.$radius11
        height: 120px
        position: relative
        .cs-delete-car-p
            border-radius: variables.$radius202
            background-color: variables.$whiteColor
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 40px
            height: 40px
            display: flex
            align-items: center
            justify-content: center
            opacity: 0
            visibility: hidden
            transition: all 0.5s
            i
                font-size: 17px
                color: variables.$color20
        &:hover
            &:after
                content: ""
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                background-color: variables.$color7
                border-radius: variables.$radius11
                opacity: 0.6
            .cs-delete-car-p
                opacity: 1
                visibility: visible
                transition: all 0.5s
                z-index: 2
.cs-damage-photos-main-2
    .cs-damage-mod-title
        font-size: 16px
        line-height: 24px
        color: variables.$color7
        font-weight: variables.$fontWeightBold
    .cs-damage-mod-desc
        font-size: 16px
        line-height: 24px
        color: variables.$color203
        font-weight: variables.$fontWeightSemiBold
    textarea
        height: 100px !important
        border-radius: variables.$radius11
        color: variables.$color203
        border-color: variables.$color203
        resize: none
        margin-top: 10px
        opacity: 0.5
.cs-map-box-main
    position: relative
    display: flex
    .cs-map-info-box
        position: absolute
        top: 50px
        left: 50%
        transform: translateX(-50%)
        background: variables.$whiteColor
        border-radius: variables.$radius2
        height: 90px
        max-width: 660px
        width: 100%
        display: flex
        align-items: center
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
        .cs-text
            font-size: 18px
            line-height: 26px
            color: variables.$color11
            font-weight: variables.$fontWeightSemiBold
            padding: 30px 30px 30px 20px
            margin: 0
    .cs-border-left-area
        background: rgba(39, 193, 178, 1)
        background: linear-gradient(180deg, rgba(39, 193, 178, 1) 0%, rgba(40, 180, 181, 1) 19%, rgba(46, 147, 192, 1) 38%, rgba(54, 94, 209, 1) 71%, rgba(64, 31, 229, 1) 100%)
        width: 24px
        height: 100%
        border-radius: variables.$radius2 0 0 variables.$radius2
.cs-form-2
    .form-group
        margin:
            bottom: 20px
    .invalid-feedback
        padding: 5px 20px
    ::placeholder
        color: variables.$color30
    input:not([type="submit"]), select,input:not([type="checkbox"])
        border-radius: variables.$radius11
        border: 0.5px solid variables.$color20
        height: 50px
        font-size: 16px
        line-height: 24px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        transition: all .2s ease
        padding: 5px 20px
        &:focus
            outline: none
    .form-control
        &:disabled, &:read-only
            background-color: variables.$whiteColor
            cursor: not-allowed
    textarea
        resize: none
        border-radius: variables.$radius11
        border: 0.5px solid variables.$color20
        font-size: 16px
        line-height: 26px
        font-weight: variables.$fontWeightRegular
        color: variables.$color7
        transition: all .2s ease
        padding: 15px 20px
        &:focus
            outline: none
            border-color: variables.$primaryColor
            @include short-code.backgroundShadow1
    .info-text-1
        font-size: 16px
        line-height: 26px
        font-weight: variables.$fontWeightSemiBold
        color: variables.$color7
        margin:
            bottom: 20px
        a
            font-weight: variables.$fontWeightBold
            color: variables.$secondColor
            text-decoration: underline !important
            &:hover
                text-decoration: none !important
    .date-box-1
        position: relative
        label
            &::before
                @include short-code.bainIconCreate('\e94f')
                font-size: 20px
                color: variables.$color20
                position: absolute
                left: 10px
                bottom: 15px
                z-index: 1
        input
            padding:
                left: 45px
    .date-i-1
        label
            &::before
                @include short-code.bainIconCreate('\e94f')
                font-size: 23px
                color: variables.$color20
                position: absolute
                left: 15px
                top: 48px
        input
            padding:
                left: 40px
.cs-duo-datepicker
    label
        &:before
            bottom: 11px !important
            left: 14px !important
.cs-new-mis-form
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    input:not([type=submit]), select, .select2-selection__rendered
        opacity: 0.5
.cs-car-btn-group-2
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    .cs-first-car
        display: inline-flex
        align-items: center
        justify-content: center
        text-align: center
        min-height: 44px
        background-color: variables.$whiteColor
        border-radius: variables.$radius5
        min-width: 145px
        font-size: 16px
        line-height: 22px
        letter-spacing: .2px
        font-weight: variables.$fontWeightBold
        border: 1px solid variables.$primaryColor
        color: variables.$primaryColor
        padding: 5px 20px
        transition: all .3s ease
        &:hover
            color: variables.$primaryColor
            @include short-code.backgroundShadow5()
    button
        flex: 1
    &.active
        display: flex !important
#cs-calendar-1
    -webkit-transform: translate3d(0, 0, 0)
    -moz-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)
    margin: 0 auto
    overflow: hidden
    width: 100%
    background: variables.$color13
    border-radius: variables.$radius8
    padding: 10px
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px
    .cs-header
        height: 50px
        width: 100%
        text-align: center
        position: relative
        z-index: 100
        margin: 0 auto
        h1
            margin: 0
            padding: 0
            font-size: 20px
            line-height: 50px
            font-weight: 100
            letter-spacing: 1px
            color: variables.$color11
            font-weight: 700
    .cs-left
        position: absolute
        width: 0px
        height: 0px
        border-style: solid
        top: 50%
        margin-top: -7.5px
        cursor: pointer
        color: variables.$color11
        border-width: 7.5px 10px 7.5px 0
        border-color: transparent variables.$color11 transparent transparent
        left: 20px
    .cs-right
        position: absolute
        width: 0px
        height: 0px
        border-style: solid
        top: 50%
        margin-top: -7.5px
        cursor: pointer
        color: variables.$color11
        border-width: 7.5px 0 7.5px 10px
        border-color: transparent transparent transparent variables.$color11
        right: 20px
    .month
        opacity: 0
        margin-top: 45px
        &.new
            -webkit-animation: fadeIn 1s ease-out
            opacity: 1
        &.in
            &.prev
                -webkit-animation: moveFromBottomFadeMonth .4s ease-out
                -moz-animation: moveFromBottomFadeMonth .4s ease-out
                animation: moveFromBottomFadeMonth .4s ease-out
                opacity: 1
            &.next
                -webkit-animation: moveFromTopFadeMonth .4s ease-out
                -moz-animation: moveFromTopFadeMonth .4s ease-out
                animation: moveFromTopFadeMonth .4s ease-out
                opacity: 1
        &.out
            &.prev
                -webkit-animation: moveToBottomFadeMonth .4s ease-in
                -moz-animation: moveToBottomFadeMonth .4s ease-in
                animation: moveToBottomFadeMonth .4s ease-in
                opacity: 1
            &.next
                -webkit-animation: moveToTopFadeMonth .4s ease-in
                -moz-animation: moveToTopFadeMonth .4s ease-in
                animation: moveToTopFadeMonth .4s ease-in
                opacity: 1
    .week
        width: 100%
    .day
        display: inline-block
        width: 14%
        padding: 10px
        text-align: center
        vertical-align: top
        cursor: pointer
        position: relative
        z-index: 100
        .day-events
            list-style: none
            margin-top: 3px
            text-align: center
            height: 12px
            line-height: 6px
            overflow: hidden
            span
                vertical-align: top
                display: inline-block
                padding: 0
                margin: 0
                width: 12px
                height: 12px
                line-height: 5px
                margin: 0 1px
                border-radius: variables.$radius202
        &.other
            color: rgba(255, 255, 255, .3)
        &.today
            color: variables.$secondColor
    .day-name
        font-size: 9px
        text-transform: uppercase
        margin-bottom: 5px
        color: rgba(255, 255, 255, .5)
        letter-spacing: .7px
    .day-number
        font-size: 16px
        letter-spacing: 1.5px
    .red
        background: variables.$color4

    .yellow
        background: variables.$color37
    .details
        display: none
        &.in
            -webkit-animation: moveFromTopFade .5s ease both
            -moz-animation: moveFromTopFade .5s ease both
            animation: moveFromTopFade .5s ease both

        &.out
            -webkit-animation: moveToTopFade .5s ease both
            -moz-animation: moveToTopFade .5s ease both
            animation: moveToTopFade .5s ease both
    .arrow
        position: absolute
        top: -5px
        left: 50%
        margin-left: -2px
        width: 0px
        height: 0px
        border-style: solid
        border-width: 0 5px 5px 5px
        border-color: transparent transparent rgba(164, 164, 164, 1) transparent
        transition: all 0.7s ease

    .events
        height: 75px
        padding: 7px 0
        overflow-y: auto
        overflow-x: hidden
        &.in
            -webkit-animation: fadeIn .3s ease both
            -moz-animation: fadeIn .3s ease both
            animation: fadeIn .3s ease both
        &.out
            -webkit-animation-delay: .3s
            -moz-animation-delay: .3s
            animation-delay: .3s
    .event
        font-size: 16px
        line-height: 22px
        letter-spacing: .5px
        padding: 2px 16px
        vertical-align: top

        &.empty
            color: variables.$color229

        &-category
            height: 10px
            width: 10px
            display: inline-block
            margin: 6px 0 0
            vertical-align: top
        span
            display: inline-block
            padding: 0 0 0 7px
    .legend
        position: absolute
        bottom: 0
        width: 100%
        height: 30px
        line-height: 30px

    .entry
        display: none
.cs-empty-control
    display: flex
    align-items: center
    gap: 30px
    margin-top: 30px
    padding: 0 20px
    .cs-fully
        color: variables.$color11
        font-size: 16px
        line-height: 24px
        display: flex
        align-items: center
        gap: 5px
        padding: 0
        margin: 0
        span
            border-radius: variables.$radius202
            width: 12px
            height: 12px
            background-color: variables.$color4
            display: block

    .cs-free
        color: variables.$color11
        font-size: 16px
        line-height: 24px
        display: flex
        align-items: center
        gap: 5px
        padding: 0
        margin: 0
        span
            border-radius: variables.$radius202
            width: 12px
            height: 12px
            background-color: variables.$color37
            display: block
.cs-day-names-row
    display: flex
    justify-content: space-around
    width: 100%
    margin: 30px 0
    .day-name-cell
        font-size: 16px
        line-height: 24px
        color: variables.$primaryColor
        font-weight: variables.$fontWeightBold
