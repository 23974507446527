@use 'variables'

@mixin text-DO()
    text-decoration: none !important
    outline: none !important

@mixin fontAwesomeCreate($iconCode)
    content: $iconCode
    font-family: 'Font Awesome 5 Free'
    font-weight: 700

@mixin bainIconCreate($iconCode)
    content: $iconCode
    font-family: 'bain' !important

@mixin rowCreate($marginVal)
    display: -ms-flexbox
    display: flex
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    margin: 
        right: $marginVal
        left: $marginVal

@mixin colCreate($paddingVal, $widthVal)
    position: relative
    width: 100%
    min-height: 1px
    padding: 
        right: $paddingVal
        left: $paddingVal
    max-width: $widthVal
    flex: 0 0 $widthVal

@mixin backgroundShadow1()
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15)
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15)
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15)

@mixin backgroundShadow2()
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.33)
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.33)
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.33)

@mixin backgroundShadow3()
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1)
    -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1)
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1)

@mixin backgroundShadow4()
    -webkit-box-shadow: 0px 5px 8px 0px rgba(64,34,229,0.3)
    -moz-box-shadow: 0px 5px 8px 0px rgba(64,34,229,0.3)
    box-shadow: 0px 5px 8px 0px rgba(64,34,229,0.3)

@mixin backgroundShadow5()
    -webkit-box-shadow: 0px 4px 6px 0px rgba(39,193,178,0.65)
    -moz-box-shadow: 0px 4px 6px 0px rgba(39,193,178,0.65)
    box-shadow: 0px 4px 6px 0px rgba(39,193,178,0.65)

@mixin backgroundShadow6()
    -webkit-box-shadow: 0px 4px 6px 0px rgba(64,31,229,0.65)
    -moz-box-shadow: 0px 4px 6px 0px rgba(64,31,229,0.65)
    box-shadow: 0px 4px 6px 0px rgba(64,31,229,0.65)

@mixin backgroundShadow7()
    -webkit-box-shadow: 0px 3px 6px 0px rgba(28,28,28,0.1)
    -moz-box-shadow: 0px 3px 6px 0px rgba(28,28,28,0.1)
    box-shadow: 0px 3px 6px 0px rgba(28,28,28,0.1)

@mixin backgroundShadow8()
    -webkit-box-shadow: 0px 10px 20px 0px rgba(113,113,113,0.1)
    -moz-box-shadow: 0px 10px 20px 0px rgba(113,113,113,0.1)
    box-shadow: 0px 10px 20px 0px rgba(113,113,113,0.1)

@mixin backgroundShadow9()
    -webkit-box-shadow: 0px 3px 6px 0px rgba(69,69,69,0.1)
    -moz-box-shadow: 0px 3px 6px 0px rgba(69,69,69,0.1)
    box-shadow: 0px 3px 6px 0px rgba(69,69,69,0.1)

@mixin backgroundShadow10()
    -webkit-box-shadow: 0px 10px 30px 0px rgba(113,113,113,0.1)
    -moz-box-shadow: 0px 10px 30px 0px rgba(113,113,113,0.1)
    box-shadow: 0px 10px 30px 0px rgba(113,113,113,0.1)

@mixin backgroundShadow11()
    -webkit-box-shadow: 0px 3px 6px 0px rgba(135,143,172,0.5)
    -moz-box-shadow: 0px 3px 6px 0px rgba(135,143,172,0.5)
    box-shadow: 0px 3px 6px 0px rgba(135,143,172,0.5)

@mixin backgroundShadow12()
    -webkit-box-shadow: 0px 3px 6px 0px rgba(108,210,80,1)
    -moz-box-shadow: 0px 3px 6px 0px rgba(108,210,80,1)
    box-shadow: 0px 3px 6px 0px rgba(108,210,80,1)

@mixin backgroundShadow13()
    -webkit-box-shadow: 0px 3px 6px 0px rgba(255,177,0,0.45)
    -moz-box-shadow: 0px 3px 6px 0px rgba(255,177,0,0.45)
    box-shadow: 0px 3px 6px 0px rgba(255,177,0,0.45)

@mixin backgroundShadow14()
    -webkit-box-shadow: 0px -3px 30px 0px rgba(64,31,229,0.05)
    -moz-box-shadow: 0px -3px 30px 0px rgba(64,31,229,0.05)
    box-shadow: 0px -3px 30px 0px rgba(64,31,229,0.05)

@mixin backgroundShadow15()
    -webkit-box-shadow: 0px 4px 6px 0px rgba(234,236,244,0.65)
    -moz-box-shadow: 0px 4px 6px 0px rgba(234,236,244,0.65)
    box-shadow: 0px 4px 6px 0px rgba(234,236,244,0.65)

@mixin menuFlex1()
    list-style: none
    padding: 0
    margin: 0
    display: flex
    align-items: center
    flex-wrap: wrap

@mixin menuFlex2()
    list-style: none
    padding: 0
    margin: 0
    display: inline-flex
    align-items: center
    flex-wrap: wrap

@mixin menuClear1()
    list-style: none
    padding: 0
    margin: 0

@mixin textAfterDots($maxHeight, $minHeight, $lineCount)
    max-height: $maxHeight
    min-height: $minHeight
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: $lineCount
    -webkit-box-orient: vertical